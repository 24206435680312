import Button from "@mui/material/Button/Button";
import { useState } from "react";

import "../Attorneys.scss";
import Stepper from "@mui/material/Stepper/Stepper";
import Step from "@mui/material/Step/Step";
import StepButton from "@mui/material/StepButton/StepButton";
import React from "react";
import { useSelector } from "react-redux";
import UserForm from "../AttorneysDetails/UserForm";
import { UsersService } from "../../../../api";

const steps = ["Personal details", "Confirmation"];

export default function AttorneyInfo() {
  const [activeStep, setActiveStep] = useState(0);

  const user = useSelector((state: any) => state.user?.user);

  const handleNext = () => {
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
    UsersService.postApiUsersSetPending(user.id, true).then();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className="registration-holder">
      <h4 className="title text-center m-5">
        Welcome! This is your first time, so let's set you up!
      </h4>
      <div className="row justify-content-center">
        <div className="steps-holder">
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={activeStep > index}>
                <StepButton color="inherit">{label}</StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        <div>
          <React.Fragment>
            <div className="register-step-holder">
              {activeStep === 0 && (
                <div className="register-form-holder row m-0 p-5 pb-0">
                  <UserForm
                    user={user}
                    isEdit={true}
                    isNew={false}
                    isAttorney={true}
                    updateStored={true}
                    onSave={handleNext}
                  ></UserForm>
                </div>
              )}
              {activeStep === 2 && (
                <h5 className="title text-center pt-5">Payment Settings</h5>
              )}
              {activeStep === 1 && (
                <>
                  <h5 className="title text-center pt-5">Thank you!</h5>
                  <div className="text-center pb-4">
                    Your application is pending. It will be revised by our staff
                    in the next 48 hours. You will be notified when it is
                    complete.
                  </div>
                </>
              )}
              {/* <div
                className={
                  activeStep < steps.length - 1
                    ? "row justify-content-between btn-holder p-5"
                    : "row justify-content-center btn-holder p-5"
                }
              >
                {activeStep < steps.length - 1 && (
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={activeStep === 0}
                    className="btn"
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                )}
                {
                  activeStep < steps.length - 1 ? (
                    <>
                      <Button
                        variant="contained"
                        className="btn"
                        onClick={handleNext}
                      >
                        Next
                      </Button>
                    </>
                  ) : null
                  // <Button
                  //   variant="contained"
                  //   className="btn"
                  //   onClick={finishRegister}
                  // >
                  //   Done
                  // </Button>
                }
              </div> */}
            </div>
          </React.Fragment>
        </div>
      </div>
    </div>
  );
}
