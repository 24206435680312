/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EdmExpressionKind {
    NONE = 'None',
    BINARY_CONSTANT = 'BinaryConstant',
    BOOLEAN_CONSTANT = 'BooleanConstant',
    DATE_TIME_OFFSET_CONSTANT = 'DateTimeOffsetConstant',
    DECIMAL_CONSTANT = 'DecimalConstant',
    FLOATING_CONSTANT = 'FloatingConstant',
    GUID_CONSTANT = 'GuidConstant',
    INTEGER_CONSTANT = 'IntegerConstant',
    STRING_CONSTANT = 'StringConstant',
    DURATION_CONSTANT = 'DurationConstant',
    NULL = 'Null',
    RECORD = 'Record',
    COLLECTION = 'Collection',
    PATH = 'Path',
    IF = 'If',
    CAST = 'Cast',
    IS_TYPE = 'IsType',
    FUNCTION_APPLICATION = 'FunctionApplication',
    LABELED_EXPRESSION_REFERENCE = 'LabeledExpressionReference',
    LABELED = 'Labeled',
    PROPERTY_PATH = 'PropertyPath',
    NAVIGATION_PROPERTY_PATH = 'NavigationPropertyPath',
    DATE_CONSTANT = 'DateConstant',
    TIME_OF_DAY_CONSTANT = 'TimeOfDayConstant',
    ENUM_MEMBER = 'EnumMember',
    ANNOTATION_PATH = 'AnnotationPath',
}
