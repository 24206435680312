/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ApplicationRole {
    ADMIN = 'Admin',
    ATTORNEY = 'Attorney',
    STAFF_ATTORNEY = 'StaffAttorney',
    STAFF = 'Staff',
    USER = 'User',
    REFFERAL_STAFF = 'RefferalStaff',
    CLIO_STAFF = 'ClioStaff',
    CLIO_ADMIN = 'ClioAdmin',
    CLIO_MANAGER = 'ClioManager',
}
