import Table from "@mui/material/Table/Table";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import { useEffect, useMemo, useState } from "react";
import EnhancedTableHead from "../Admin/LeadsManagement/LeadsTables/LeadTableHeader";
import { Action, DEFAULT_ROWS_PER_PAGE, ExtensionsAdminHead, ExtensionsStaffHead, TableInteractionsProps } from "../Admin/LeadsManagement/LeadsTables/LeadsTableConstants";
import TableBody from "@mui/material/TableBody/TableBody";
import TableCell from "@mui/material/TableCell/TableCell";
import TableActionMenu from "../Admin/LeadsManagement/LeadsTables/LeadActionsMenu";
import { ApplicationRole, ClioService, ExtensionDetails, ExtensionsService, ExtensionsTableRequest, MatterPreviewResponse, TableFilter, TaskExtensionActionViewModel, TaskExtensionViewModel, TaskExtentionStatus, TaskPreviewResponse, UsersModel } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import Card from "@mui/material/Card/Card";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import AddIcon from "@mui/icons-material/Add";
import debounce from "@mui/material/utils/debounce";
import { DatePicker } from "@mui/x-date-pickers/DatePicker/DatePicker";
import dayjs from "dayjs";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { startSpinner, stopSpinner } from "../../redux/actions/spinnerActions";
import { StageColorsMapping } from "../Admin/LeadsManagement/shared/Constants";
import { HelpersService } from "../../services/Helpers";
import TableRow from "@mui/material/TableRow/TableRow";
import { Checkbox, FormControl, InputLabel, MenuItem, Select, TablePagination } from "@mui/material";
import "./Extensions.scss";

const MySwal = withReactContent(Swal);

interface ExtensionProps {
    archived?: boolean;
}

enum ExtensionAction {
    Request = "Request",
    Details = "Details",
    Approve = "Approve",
    Reject = "Reject",
}

const AllSelection = "Select all";

export default function ExtensionsTable(props: ExtensionProps) {
    const [visibleRows, setVisibleRows] = useState<TaskExtensionViewModel[]>([]);
    const [originalDate, setOriginalDate] = useState<any>(null);
    const [requestedDate, setRequestedDate] = useState<any>(null);
    const [updatedDate, setUpdatedDate] = useState<any>(null);
    const [showUpdatedDate, setShowUpdatedDate] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [adminMessage, setAdminMessage] = useState<string>("");

    const [inputValue, setInputValue] = useState("");
    const [matterValue, setMatterValue] = useState<MatterPreviewResponse | null>(null);
    const [matterOptions, setMatterOptions] = useState<readonly MatterPreviewResponse[]>([]);
    const [taskValue, setTaskValue] = useState<TaskPreviewResponse | null>(
        null
    );
    const [taskOptions, setTaskOptions] = useState<
        readonly TaskPreviewResponse[]
    >([]);

    const [requestDialogOpen, setRequestDialogOpen] = useState(false);
    const [selectedExtension, setSelectedExtension] = useState<TaskExtensionViewModel | null>(null);
    const [selectedExtensionDetails, setSelectedExtensionDetails] = useState<ExtensionDetails | null>(null);
    const [selectedAction, setSelectedAction] = useState<ExtensionAction>(ExtensionAction.Details);

    const [tableInteractions, setTableInteractions] =
        useState<TableInteractionsProps>({
            sortAsc: false,
            sortBy: undefined,
            filters: [],
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
        });
    const [totalCount, setTotalCount] = useState(0);
    const [bulkSelectedExtensions, setBulkSelectedExtensions] = useState<number[]>([]);
    const [selectedUser, setSelectedUser] = useState<string>();
    const [matterSearchValue, setMatterSearchValue] = useState<string>();
    const [dueSearchValue, setDueSearchValue] = useState<string>();
    const [usersList, setUsersList] = useState<UsersModel[]>([]);

    const user = useSelector((state: any) => state.user?.user);
    const dispatch = useDispatch();
    useEffect(() => {
        loadExtensions();
        ClioService.getApiClioClioUsers().then(users => {
            setUsersList(users);
        });
    }, []);

    useEffect(() => {
        setTableInteractions({
            ...tableInteractions,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            page: 0,
        });
    }, [props.archived]);

    useEffect(() => {
        loadExtensions();
    }, [tableInteractions]);

    const resetExtensionValue = () => {
        setSelectedExtension(null);
        setRequestedDate(null);
        setOriginalDate(null);
        setInputValue("");
        setMatterValue(null);
        setMatterOptions([]);
        setTaskValue(null);
        setTaskOptions([]);
        setMessage("");
        setAdminMessage("");
        setShowUpdatedDate(false);
    }

    const loadExtensions = () => {
        dispatch(startSpinner());
        var request = {
            archived: props.archived,
            limit: tableInteractions.rowsPerPage,
            offset: tableInteractions.rowsPerPage * tableInteractions.page,
            sortAscending: tableInteractions.sortAsc,
            sortBy: tableInteractions.sortBy,
            filters: tableInteractions.filters
        } as ExtensionsTableRequest;
        ExtensionsService.postApiExtensionsGetExtensions(request).then(result => {
            setTotalCount(result.totalCount ?? 0);
            setVisibleRows(result.extensions ?? []);
        }).finally(() => dispatch(stopSpinner()));
    }

    const handleExtensionAction = () => {
        if (selectedAction != ExtensionAction.Approve && selectedAction != ExtensionAction.Reject) {
            MySwal.fire({
                icon: "error",
                title: <p className="title">No action selected</p>,
            });
        }

        var extensionRequest = {
            clioTaskId: taskValue?.id,
            taskName: taskValue?.name,
            clioMatterId: matterValue?.id,
            materName: matterValue?.description,
            requestedDueDate: requestedDate,
            requestMessage: message,
            adminMessage: adminMessage,
            approve: selectedAction === ExtensionAction.Approve
        } as TaskExtensionViewModel;
        MySwal.fire({
            title: <strong>You are about to {selectedAction === ExtensionAction.Approve ? "approve" : "reject"} extension</strong>,
            html: (
                <div>
                    Please confirm.
                </div>
            ),
            icon: "info",
            showCancelButton: true,
            cancelButtonText: "No",
            confirmButtonText: "Yes",
            confirmButtonColor: "#ed633a",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                ExtensionsService.postApiExtensionsTaskExtentionApproval(selectedExtension?.id!, extensionRequest).then(() => {
                    MySwal.fire({
                        icon: "success",
                        title: <p className="title">Request {selectedAction === ExtensionAction.Approve ? "approved" : "rejected"}</p>,
                    });
                    loadExtensions();
                }).catch(() => {
                    MySwal.fire({
                        icon: "error",
                        title: <p className="title">Error {selectedAction === ExtensionAction.Approve ? "approving" : "rejecting"} request</p>,
                    });
                });
            }
        }).finally(() => setRequestDialogOpen(false));
    }

    const requestExtension = () => {
        var extensionRequest = {
            clioTaskId: taskValue?.id,
            taskName: taskValue?.name,
            clioMatterId: matterValue?.id,
            materName: matterValue?.description,
            currentDueDate: taskValue?.dueAt,
            requestedDueDate: requestedDate,
            requestMessage: message
        } as TaskExtensionViewModel;
        ExtensionsService.postApiExtensionsExtensionRequest(extensionRequest).then(() => {
            MySwal.fire({
                icon: "success",
                title: <p className="title">Request sent</p>,
            });
            setRequestDialogOpen(false);
            resetExtensionValue();
            loadExtensions();
        }).catch(() => {
            MySwal.fire({
                icon: "error",
                title: <p className="title">Error sending request</p>,
            });
        });
    }

    const editExtension = () => {
        var extensionRequest = {
            clioTaskId: taskValue?.id,
            taskName: taskValue?.name,
            clioMatterId: matterValue?.id,
            materName: matterValue?.description,
            currentDueDate: taskValue?.dueAt,
            requestedDueDate: requestedDate,
            requestMessage: message,
            adminMessage: adminMessage,
        } as TaskExtensionViewModel;

        ExtensionsService.postApiExtensionsExtensionUpdate(selectedExtension?.id!, extensionRequest).then(() => {
            MySwal.fire({
                icon: "success",
                title: <p className="title">Request updated</p>,
            });
            setRequestDialogOpen(false);
        }).catch(() => {
            MySwal.fire({
                icon: "error",
                title: <p className="title">Error updating request</p>,
            });
        });
        resetExtensionValue();
    }

    const handleOpenDetails = async (row: TaskExtensionViewModel, action: ExtensionAction) => {
        setSelectedAction(action);
        ExtensionsService.getApiExtensionsDetails(row.id!).then(details => {
            setSelectedExtensionDetails(details);
        });
        dispatch(startSpinner());
        const responseOptions = await ClioService.getApiClioMattersCurrentTasks(row.clioMatterId!);
        setTaskValue(responseOptions.find(t => t.id === row.clioTaskId) ?? null);

        const matterOptions = await ClioService.getApiClioMatters(row.materName!);
        setMatterValue(matterOptions.find(m => m.id === row.clioMatterId) ?? null);

        setRequestedDate(dayjs(row.requestedDueDate));
        setOriginalDate(dayjs(row.currentDueDate ?? responseOptions.find(t => t.id === row.clioTaskId)?.dueAt ?? null));
        setMessage(row.requestMessage ?? "");
        setSelectedExtension(row);
        dispatch(stopSpinner());
        setRequestDialogOpen(true);
    }

    async function loadTasks(matterId: number) {
        if (!matterId) {
            setTaskOptions([]);
            setTaskValue(null);
            setMessage("");
            return;
        }

        const responseOptions = await ClioService.getApiClioMattersCurrentTasks(matterId);
        setTaskOptions(responseOptions);
    }

    const fetchMatters = useMemo(
        () =>
            debounce((input: string) => {
                ClioService.getApiClioMatters(input).then((response) => {
                    let newOptions: readonly MatterPreviewResponse[] = [];
                    if (matterValue) {
                        newOptions = [matterValue];
                    }
                    if (response) {
                        newOptions = [...newOptions, ...response];
                    }

                    setMatterOptions(newOptions);
                });
            }, 200),
        []
    );

    useEffect(() => {
        if (inputValue === "") {
            setMatterOptions(matterValue ? [matterValue] : []);
            return undefined;
        }

        fetchMatters(inputValue);

        return () => { };
    }, [matterValue, inputValue, fetchMatters]);

    const handleChangePage = (event: unknown, newPage: number) => {
        const newTableInteractions = { ...tableInteractions, page: newPage };
        setTableInteractions(newTableInteractions);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newTableInteractions = {
            ...tableInteractions,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        };
        setTableInteractions(newTableInteractions);
    };

    const generateActionButton = () => {
        switch (selectedAction) {
            case ExtensionAction.Approve: {
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="col-6 mt-3"
                        onClick={handleExtensionAction}
                    >
                        Approve
                    </Button>)
            }
            case ExtensionAction.Reject: {
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="col-6 mt-3"
                        onClick={handleExtensionAction}
                    >
                        Reject
                    </Button>)
            }
            case ExtensionAction.Request: {
                return (
                    <Button
                        disabled={!matterValue || !taskValue || !requestedDate}
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="col-6 mt-3"
                        onClick={requestExtension}
                    >
                        Request
                    </Button>)
            }
            case ExtensionAction.Details: {
                return (
                    <></>)
            }
        }
    }

    const handleSelectAllClick = () => {
        if (bulkSelectedExtensions.length === visibleRows.length) {
            setBulkSelectedExtensions([]);
            return;
        }
        const newSelected = visibleRows.map((n) => n.id!);
        setBulkSelectedExtensions(newSelected);
        return;
    };

    const handleRowCheckboxClick = (e, id: number) => {
        e.preventDefault();
        e.stopPropagation();
        const selectedIndex = bulkSelectedExtensions.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = bulkSelectedExtensions.concat(id);
        } else if (selectedIndex === 0) {
            newSelected = bulkSelectedExtensions.slice(1);
        } else if (selectedIndex === bulkSelectedExtensions.length - 1) {
            newSelected = bulkSelectedExtensions.slice(0, -1);
        } else if (selectedIndex > 0) {
            newSelected = bulkSelectedExtensions
                .slice(0, selectedIndex)
                .concat(bulkSelectedExtensions.slice(selectedIndex + 1));
        }
        setBulkSelectedExtensions(newSelected);
    };

    const handleBulkAction = (approve: boolean) => {
        dispatch(startSpinner());
        ExtensionsService.postApiExtensionsBulkTaskExtentionApproval({ ids: bulkSelectedExtensions, approve: approve }).then(() => {
            MySwal.fire({
                icon: "success",
                title: <p className="title">Requests {approve ? "approved" : "rejected"}</p>,
            });
            loadExtensions();
        }).finally(() => dispatch(stopSpinner()));
    }

    const openActionDetails = (action: TaskExtensionActionViewModel) => {
        setShowUpdatedDate(true);
        setUpdatedDate(dayjs(action.updatedDueDate));
        setOriginalDate(dayjs(action.currentDueDate));
        setMessage(action.requestMessage ?? "");
        setAdminMessage(action.adminMessage ?? "");
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        newSortBy: keyof TaskExtensionActionViewModel
    ) => {
        const toggledOrder =
            tableInteractions.sortBy !== newSortBy
                ? true
                : !tableInteractions.sortAsc;
        const newTableInteractions = {
            ...tableInteractions,
            sortAsc: toggledOrder,
            sortBy: newSortBy,
            page: 0,
        };
        setTableInteractions(newTableInteractions);
    };

    const handleChangeFilter = (
        value: string | null = null,
        field: string
    ) => {
        var oldFilters = tableInteractions.filters ?? [];
        const existingFilterIndex = oldFilters.findIndex(
            (filter) => filter.filterField === field
        );

        if (existingFilterIndex !== -1) {
            const updatedFilters = [...oldFilters];
            updatedFilters[existingFilterIndex] = {
                filterField: field,
                filterValue: value ?? "",
            } as TableFilter;
            setTableInteractions((prevState) => ({
                ...prevState,
                filters: updatedFilters,
                page: 0,
            }));
        } else {
            setTableInteractions((prevState) => ({
                ...prevState,
                filters: [
                    ...oldFilters,
                    {
                        filterField: field,
                        filterValue: value ?? "",
                    } as TableFilter,
                ],
                page: 0,
            }));
        }
    };
    const updateNameSearch = (user: string) => {
        setSelectedUser(user);
        if (user == AllSelection) {
            user = "";
        }
        handleChangeFilter(user, "Name");
    };

    const updateMatterSearch = (value: string) => {
        setMatterSearchValue(value);
        if (value.length > 2 || value.length === 0) {
            handleChangeFilter(value, "MaterName");
        }
    };

    const updateDueSearch = (value: string) => {
        setDueSearchValue(value);
        handleChangeFilter(value, "Due");
    };

    return (
        <>
            <div className="row">
                {(user.roles.includes(ApplicationRole.CLIO_STAFF) || user.roles.includes(ApplicationRole.STAFF_ATTORNEY)) &&
                    <div className="col-6 d-flex mb-4">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            className="col-12 col-md-6 m-3"
                            onClick={() => { setSelectedAction(ExtensionAction.Request); setRequestDialogOpen(true); }}
                        >
                            Request extension
                        </Button>
                    </div>}
                {!props.archived && (user.roles.includes(ApplicationRole.ADMIN) || user.roles.includes(ApplicationRole.CLIO_ADMIN)) &&
                    <div className="col-6 d-flex mb-4">
                        <Button
                            disabled={bulkSelectedExtensions?.length === 0}
                            variant="contained"
                            color="primary"
                            className="col-12 col-md-6 m-3"
                            onClick={() => handleBulkAction(false)}>
                            Reject selected
                        </Button>
                        <Button
                            disabled={bulkSelectedExtensions?.length === 0}
                            variant="contained"
                            color="primary"
                            className="col-12 col-md-6 m-3"
                            onClick={() => handleBulkAction(true)}>
                            Approve selected
                        </Button>
                    </div>}
            </div>
            <div className="row">
                <div className="col-3 m-3">
                    <FormControl className="w-100">
                        <InputLabel id="type-label" required>Select User</InputLabel>
                        <Select
                            labelId="type-label"
                            className="min-width-200"
                            label="Select attorney*"
                            value={selectedUser}
                            onChange={e => updateNameSearch(e.target.value)}
                        >

                            <MenuItem key={0} value={AllSelection}>
                                {AllSelection}
                            </MenuItem>
                            {usersList.map((user) => {
                                return (
                                    <MenuItem key={user.id} value={user.name!}>
                                        {user.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <TextField
                    required
                    variant="outlined"
                    label="Search by matter"
                    className="col-3 m-3"
                    value={matterSearchValue}
                    onChange={(e) => updateMatterSearch(e.target.value)}
                /><TextField
                    required
                    variant="outlined"
                    label="Search by date"
                    className="col-3 mt-3"
                    value={dueSearchValue}
                    onChange={(e) => updateDueSearch(e.target.value)}
                /></div>
            <TableContainer className="sticky-holder">
                <Table aria-labelledby="tableTitle" size="medium" stickyHeader>
                    {visibleRows.length === 0 && (
                        <caption>
                            <div className="text-center caption-text">No leads</div>
                        </caption>
                    )}
                    <EnhancedTableHead
                        displaySelectAll
                        onSelectAllClick={handleSelectAllClick}
                        sortAsc={tableInteractions.sortAsc}
                        sortBy={tableInteractions.sortBy}
                        removeActions={true}
                        onRequestSort={handleRequestSort}
                        headCells={(user.roles.includes(ApplicationRole.ADMIN) || user.roles.includes(ApplicationRole.CLIO_ADMIN)) ? ExtensionsAdminHead : ExtensionsStaffHead}
                        rowCount={visibleRows.length}
                        numSelected={bulkSelectedExtensions.length}
                    />
                    <TableBody>
                        {visibleRows
                            ? visibleRows.map((row, index) => {
                                // var actions: Action[] = [];
                                // if (user.roles.includes(ApplicationRole.ADMIN) || user.roles.includes(ApplicationRole.CLIO_ADMIN)) {
                                //     switch (row.status) {
                                //         case TaskExtentionStatus.REQUESTED:
                                //         case TaskExtentionStatus.UNAUTHORIZED: {
                                //             actions = [
                                //                 { name: "Approve", function: () => handleOpenDetails(row, ExtensionAction.Approve) },
                                //                 { name: "Reject", function: () => handleOpenDetails(row, ExtensionAction.Reject) },
                                //             ];
                                //         }
                                //     }
                                // }

                                const isItemSelected = bulkSelectedExtensions.indexOf(row.id!) !== -1;
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        onClick={() => handleOpenDetails(row, ExtensionAction.Details)}
                                        selected={isItemSelected}
                                        className={isItemSelected ? "table-row-accent" : ""}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                onClick={(e) => handleRowCheckboxClick(e, row.id!)}
                                                inputProps={{
                                                    "aria-labelledby": labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            {/* <Button
                                                variant="contained"
                                                color={StageColorsMapping[row.status ?? TaskExtentionStatus.REQUESTED]}
                                                size="small"
                                            > */}
                                                {HelpersService.camelToString(
                                                    row.status
                                                )}
                                            {/* </Button> */}
                                        </TableCell>
                                        {(user.roles.includes(ApplicationRole.ADMIN) || user.roles.includes(ApplicationRole.CLIO_ADMIN)) &&
                                            <>
                                                <TableCell align="left">{row.requestedByName}</TableCell>
                                                <TableCell align="left">{row.totalOverdue}</TableCell>
                                            </>
                                        }
                                        <TableCell align="left">{row.taskName}</TableCell>
                                        <TableCell align="left">{row.materName}</TableCell>
                                        <TableCell align="left">{dayjs(row.currentDueDate).tz("America/Los_Angeles").format("MM/DD/YY")}</TableCell>
                                        <TableCell align="left">{!!row.requestedDueDate ? dayjs(row.requestedDueDate).tz("America/Los_Angeles").format("MM/DD/YY") : "No request"}</TableCell>
                                        <TableCell align="left">{!!row.updatedDueDate ? dayjs(row.updatedDueDate).tz("America/Los_Angeles").format("MM/DD/YY") : "Not updated"}</TableCell>
                                        <TableCell align="left">{(user.roles.includes(ApplicationRole.ADMIN) || user.roles.includes(ApplicationRole.CLIO_ADMIN)) ? row.requestMessage : row.adminMessage}</TableCell>
                                        <TableCell align="left"></TableCell>
                                        {/* <TableCell align="left">
                                            {actions.length > 0 && (
                                                <TableActionMenu
                                                    row={row}
                                                    actions={actions}
                                                ></TableActionMenu>
                                            )}
                                        </TableCell> */}
                                    </TableRow>);
                            }
                            )
                            : null}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalCount}
                rowsPerPage={tableInteractions.rowsPerPage}
                page={tableInteractions.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog open={requestDialogOpen} className="extension-dialog">
                <DialogContent>
                    <div className="row">
                        <div className="col-7">
                            <Card className="p-3 mt-2 sticky-form">
                                <div className="row mt-3">
                                    <Autocomplete
                                        disabled={(user.roles.includes(ApplicationRole.ADMIN) || user.roles.includes(ApplicationRole.CLIO_ADMIN)) && !!selectedExtension}
                                        getOptionLabel={(option) =>
                                            (typeof option === "string"
                                                ? option
                                                : option.description) as string
                                        }
                                        filterOptions={(x) => x}
                                        options={matterOptions}
                                        autoComplete
                                        includeInputInList
                                        filterSelectedOptions
                                        value={matterValue}
                                        noOptionsText="No matters"
                                        onChange={(
                                            event: any,
                                            selectedValue: MatterPreviewResponse | null
                                        ) => {
                                            setMatterOptions(
                                                selectedValue
                                                    ? [selectedValue, ...matterOptions]
                                                    : matterOptions
                                            );
                                            setMatterValue(selectedValue);
                                            loadTasks(selectedValue?.id as number);
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Matter" fullWidth />
                                        )}
                                    />
                                </div>
                                <div className="row mt-3">
                                    <Autocomplete
                                        disabled={(user.roles.includes(ApplicationRole.ADMIN) || user.roles.includes(ApplicationRole.CLIO_ADMIN)) && !!selectedExtension}
                                        options={taskOptions}
                                        value={taskValue}
                                        onChange={(
                                            event: any,
                                            selectedValue: TaskPreviewResponse | null
                                        ) => {
                                            setTaskValue(selectedValue);
                                            setOriginalDate(dayjs(selectedValue?.dueAt));
                                        }}
                                        getOptionLabel={(option) =>
                                            (typeof option === "string"
                                                ? option
                                                : option.name) as string
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} label="Choose a task" />
                                        )}
                                    />
                                </div>
                                {(user.roles.includes(ApplicationRole.ADMIN) || user.roles.includes(ApplicationRole.CLIO_ADMIN)) &&
                                    <>
                                        <div className="row mt-3">
                                            <div className="col-6">
                                                <DatePicker
                                                    disabled
                                                    label="Conversion date"
                                                    value={dayjs(selectedExtensionDetails?.conversionDate)}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <DatePicker
                                                    disabled
                                                    label="Termination date"
                                                    value={dayjs(selectedExtensionDetails?.terminationDate)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-6">
                                                <DatePicker
                                                    disabled
                                                    label="Earliest SOL"
                                                    value={dayjs(selectedExtensionDetails?.earliestSOL)}
                                                />
                                            </div>
                                        </div>
                                    </>}
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <DatePicker
                                            disabled
                                            label="Current due date"
                                            value={originalDate}
                                        />
                                    </div>
                                    {showUpdatedDate ?
                                        <div className="col-6">
                                            <DatePicker
                                                disablePast
                                                disabled={true}
                                                label="Updated due date"
                                                value={updatedDate}
                                            />
                                        </div>
                                        : <div className="col-6">
                                            <DatePicker
                                                disablePast
                                                disabled={selectedAction === ExtensionAction.Details}
                                                label="Requested due date"
                                                value={requestedDate}
                                                onChange={(date) => setRequestedDate(date)}
                                            />
                                        </div>

                                    }
                                </div>
                                <div className="col-12 mt-3">
                                    <TextField
                                        value={selectedExtensionDetails?.taskAssignee}
                                        disabled={true}
                                        className="col-12"
                                        label="Assignee" />
                                </div>
                                <div className="col-12 mt-3">
                                    <TextField
                                        value={message}
                                        disabled={(user.roles.includes(ApplicationRole.ADMIN) || user.roles.includes(ApplicationRole.CLIO_ADMIN)) && !!selectedExtension}
                                        onChange={(e) => setMessage(e.target.value)}
                                        className="col-12"
                                        label="Message"
                                        multiline
                                        rows={4}
                                    />
                                </div>
                                {(user.roles.includes(ApplicationRole.ADMIN) || user.roles.includes(ApplicationRole.CLIO_ADMIN)) && !!selectedExtension &&
                                    <div className="col-12 mt-3">
                                        <TextField
                                            disabled={selectedAction === ExtensionAction.Details}
                                            value={adminMessage}
                                            onChange={(e) => setAdminMessage(e.target.value)}
                                            className="col-12"
                                            label="Leave message for staff"
                                            multiline
                                            rows={4}
                                        />
                                    </div>
                                }
                            </Card>
                        </div>
                        <div className="col-5">
                            <h4>Extension Timeline</h4>
                            <div className="row mb-3">
                                <div className="col-4">Status change</div>
                                <div className="col-4">Action by</div>
                                <div className="col-4">Date</div>
                            </div>
                            <hr></hr>
                            {selectedExtensionDetails?.extensionActions?.map((action) => {
                                return (
                                    <div className="row pt-1 pb-1 action-row" onClick={() => openActionDetails(action)}>
                                        <div className="col-4">{HelpersService.camelToString(action.status)}</div>
                                        <div className="col-4">{HelpersService.camelToString(action.actionByName)}</div>
                                        <div className="col-4">{new Date(action.createdAt ?? "").toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}</div>
                                    </div>
                                );
                            })}

                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    {generateActionButton()}
                    <Button
                        variant="contained"
                        color="secondary"
                        className="col-6 mt-3"
                        onClick={() => { setRequestDialogOpen(false); resetExtensionValue(); }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}