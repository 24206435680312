import { useEffect, useState } from "react";
import {
  AttorneysStatusMapped,
  LeadType,
  Milestone,
} from "../shared/Constants";
import EmploymentForm from "./EmploymentForm";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { LeadsService } from "../../../../api/services/LeadsService";
import {
  AdminLeadStatus,
  ApplicationRole,
  AttorneyApprovalStatus,
  AttorneyLeadStatus,
  AttorneyStatus,
  LeadViewModel,
} from "../../../../api";
import { HelpersService } from "../../../../services/Helpers";
import { useLocation, useParams } from "react-router-dom";
import LeadForm from "./LeadForm";
import LeadMenu from "./LeadMenu/LeadMenu";
import { useDispatch, useSelector } from "react-redux";
import { setAttorneysData } from "../../../../redux/actions/attorneyReferActions";
import {
  startSpinner,
  stopSpinner,
} from "../../../../redux/actions/spinnerActions";
import { Table, TableBody, TableContainer } from "@mui/material";
import AttorneysRow from "./AttorneysTableBlock";

const MySwal = withReactContent(Swal);

let milestones: Milestone[] = [];

export default function LeadDetailsView() {
  //TODO: separate side menus for different roles and stages
  const [details, setDetails] = useState<LeadViewModel>([] as LeadViewModel);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [leadType, setLeadType] = useState<LeadType>(LeadType.Admin);
  const { id } = useParams();
  const location = useLocation();
  const user = useSelector((state: any) => state.user?.user);
  const attorneysData = useSelector((state: any) => state.attorneys?.attorneys);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id || !user) {
      return;
    }
    const queryParams = new URLSearchParams(location.search);

    var leadType = queryParams.get("leadType");
    const edit = queryParams.get("isEdit") ?? "false";
    if (!leadType) {
      const rolesSet = new Set(user.roles);

      switch (true) {
        case rolesSet.has(ApplicationRole.ADMIN):
        case rolesSet.has(ApplicationRole.STAFF): {
          leadType = LeadType.Admin;
          break;
        }
        case rolesSet.has(ApplicationRole.STAFF_ATTORNEY): {
          leadType = LeadType.StaffAttorney;
          break;
        }
        default: {
          leadType = LeadType.Attorney;
          break;
        }
      }
    }
    handleOpenDetails(
      +id,
      HelpersService.getEnumKeyByEnumValue(LeadType, leadType),
      JSON.parse(edit)
    );

    var elements = Array.from(
      document.getElementsByClassName("sticky-details-top-menu")
    );

    if (!elements) {
      return;
    }
    var newMargin = elements!
      .map((a) => a.clientHeight)
      .reduce(function (a, b) {
        return a + b;
      });
    document.getElementById("lead-details")!.style.top = newMargin + "px";
  }, [id, user]);

  const handleEditClick = () => {
    setIsEdit(!isEdit);
    if (isEdit) {
      EmploymentForm.resetFormWith();
    }
  };

  const handleOpenDetails = (id: number, leadType: LeadType, isEdit) => {
    dispatch(startSpinner());
    setLeadType(leadType);
    dispatch(startSpinner());
    LeadsService.getApiLeads(id)
      .then((lead: LeadViewModel) => {
        lead = HelpersService.parseLeadResponse(lead);
        if (lead.status === AdminLeadStatus.SHARED) {
          if (user.roles?.includes(ApplicationRole.ADMIN) || user.roles?.includes(ApplicationRole.REFFERAL_STAFF)) {
            LeadsService.getApiLeadsAttorneys(id).then((attorneys) => {
              var mappedAttorneys = Object.values(AttorneyStatus).map(
                (status) => {
                  return {
                    status: status,
                    attorneys: attorneys.filter(
                      (attorney) => attorney.status! === status
                    ),
                  } as AttorneysStatusMapped;
                }
              );
              dispatch(
                setAttorneysData({
                  sharedAttorneys: attorneys,
                  sharedAttorneysStatusMapped: mappedAttorneys,
                })
              );
            });
          }
          if (
            ((user.roles?.includes(ApplicationRole.ADMIN) || user.roles?.includes(ApplicationRole.REFFERAL_STAFF)) && lead.status === AdminLeadStatus.SHARED) 
            ||
            user.roles?.includes(ApplicationRole.ATTORNEY)
          ) {
            LeadsService.postApiLeadsSetOpen(id).then();
          }
        }

        setDetails(lead);
        setIsEdit(isEdit);
        dispatch(stopSpinner());
        if (
          lead.attorneyApprovalStatus === AttorneyApprovalStatus.IN_PROCESS ||
          lead.attorneyApprovalStatus ===
          AttorneyApprovalStatus.MORE_INFO_REQUESTED ||
          lead.attorneyApprovalStatus ===
          AttorneyApprovalStatus.MORE_INFO_PROVIDED ||
          lead.status === AdminLeadStatus.DRAFT ||
          lead.status === AdminLeadStatus.DECLINED ||
          lead.status === AdminLeadStatus.NGC ||
          lead.status === AdminLeadStatus.N_A ||
          lead.status === AdminLeadStatus.CSADECLINED ||
          lead.status === AdminLeadStatus.CLIENT_RETAINED ||
          lead.status === AdminLeadStatus.CLIENT_REFERED
        ) {
          milestones = [];
          return;
        }

        switch (lead.status) {
          case AdminLeadStatus.NEW: {
            milestones =
              lead.attorneyApprovalStatus === AttorneyApprovalStatus.REFER
                ? [
                  { name: "New", done: true },
                  { name: "Shared", done: false },
                  { name: "Winner selected", done: false },
                  { name: "Sent for signing", done: false },
                  { name: "Signed", done: false },
                  { name: "Paid", done: false },
                ]
                : [
                  { name: "New", done: true },
                  { name: "Contract sent", done: false },
                  { name: "Contract signed", done: false },
                  { name: "Supplemental assigned", done: false },
                  { name: "Supplemental filled", done: false },
                ];
            break;
          }
          //referral
          case AdminLeadStatus.SHARED: {
            milestones = [
              { name: "New", done: true },
              { name: "Shared", done: true },
              { name: "Winner selected", done: false },
              { name: "Sent for signing", done: false },
              { name: "Signed", done: false },
              { name: "Paid", done: false },
            ];
            break;
          }
          case AdminLeadStatus.INTRODUCTION: {
            milestones =
              leadType === LeadType.Attorney
                ? [
                  { name: "Accept", done: true },
                  { name: "Awaiting GLio confirmation", done: true },
                  //{ name: "Introduction", done: true },
                  //{ name: "Awaiting GLio introduction confirmation", done: false },
                  { name: "Opportunity won", done: false },
                  { name: "Pay & Sign", done: false },
                ]
                : [
                  { name: "New", done: true },
                  { name: "Shared", done: true },
                  { name: "Winner selected", done: true },
                  { name: "Sent for signing", done: false },
                  { name: "Signed", done: false },
                  { name: "Paid", done: false },
                ];
            break;
          }
          case AdminLeadStatus.WINNER_SELECTED: {
            milestones = [
              { name: "New", done: true },
              { name: "Shared", done: true },
              { name: "Winner selected", done: true },
              { name: "Sent for signing", done: false },
              { name: "Signed", done: false },
              { name: "Paid", done: false },
            ];
            break;
          }
          case AdminLeadStatus.SENT_FOR_SIGNING: {
            milestones = [
              { name: "New", done: true },
              { name: "Shared", done: true },
              { name: "Winner selected", done: true },
              { name: "Sent for signing", done: true },
              { name: "Signed", done: false },
              { name: "Paid", done: false },
            ];
            break;
          }
          case AdminLeadStatus.SIGNED: {
            milestones = [
              { name: "New", done: true },
              { name: "Shared", done: true },
              { name: "Winner selected", done: true },
              { name: "Sent for signing", done: true },
              { name: "Signed", done: true },
              { name: "Paid", done: false },
            ];
            break;
          }
          case AdminLeadStatus.PAID: {
            milestones = [
              { name: "New", done: true },
              { name: "Shared", done: true },
              { name: "Winner selected", done: true },
              { name: "Sent for signing", done: true },
              { name: "Signed", done: true },
              { name: "Paid", done: true },
            ];
            break;
          }
          //keep
          case AdminLeadStatus.CONTRACT_SENT: {
            milestones = [
              { name: "New", done: true },
              { name: "Contract sent", done: true },
              { name: "Contract signed", done: false },
              { name: "Supplemental assigned", done: false },
              { name: "Supplemental filled", done: false },
            ];
            break;
          }
          case AdminLeadStatus.CONTRACT_SIGNED: {
            milestones = [
              { name: "New", done: true },
              { name: "Contract sent", done: true },
              { name: "Contract signed", done: true },
              { name: "Supplemental assigned", done: false },
              { name: "Supplemental filled", done: false },
            ];
            break;
          }
          case AdminLeadStatus.SUPPLEMENTAL_ASSIGNED: {
            milestones = [
              { name: "New", done: true },
              { name: "Contract sent", done: true },
              { name: "Contract signed", done: true },
              { name: "Supplemental assigned", done: true },
              { name: "Supplemental filled", done: false },
            ];
            break;
          }
          case AdminLeadStatus.DONE: {
            milestones = [
              { name: "New", done: true },
              { name: "Contract sent", done: true },
              { name: "Contract signed", done: true },
              { name: "Supplemental assigned", done: true },
              { name: "Supplemental filled", done: true },
            ];
            break;
          }
        }

        //referral process
        switch (lead.attorneyLeadStatus) {
          case AttorneyLeadStatus.NEW: {
            milestones = [
              { name: "Accept/Reject", done: true },
              { name: "Awaiting GLio confirmation", done: false },
              { name: "Opportunity won", done: false },
              { name: "Pay & Sign", done: false },
            ];
            break;
          }
          case AttorneyLeadStatus.ACCEPTED: {
            milestones = [
              { name: "Accept", done: true },
              { name: "Awaiting GLio confirmation", done: true },
              //{ name: "Introduction", done: true },
              //{ name: "Awaiting GLio introduction confirmation", done: false },
              { name: "Opportunity won", done: false },
              { name: "Pay & Sign", done: false },
            ];
            break;
          }
          case AttorneyLeadStatus.REJECTED: {
            milestones = [{ name: "Reject", done: true }];
            break;
          }
          case AttorneyLeadStatus.LOST: {
            milestones = [
              { name: "Accept", done: true },
              { name: "Awaiting GLio confirmation", done: true },
              { name: "Opportunity lost", done: true },
            ];
            break;
          }
          case AttorneyLeadStatus.WON: {
            milestones = [
              { name: "Accept", done: true },
              { name: "Awaiting GLio confirmation", done: true },
              //{ name: "Introduction", done: true },
              //{ name: "Awaiting GLio introduction confirmation", done: false },
              { name: "Opportunity won", done: true },
              { name: "Pay & Sign", done: !!lead.isPaid },
            ];
            break;
          }
        }
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error retrieving lead data</p>,
        });
      }).finally(() => dispatch(stopSpinner()));
  };

  return (
    <>
      <LeadMenu
        milestones={milestones}
        leadType={leadType}
        userRoles={user.roles}
        details={details}
        handleEditClick={handleEditClick}
      ></LeadMenu>
      <div className="lead-details-holder" id="lead-details">
        <div className="row main-holder">
          <div
            className={
              details.status === AdminLeadStatus.SHARED &&
                (user.roles?.includes(ApplicationRole.ADMIN) || user.roles?.includes(ApplicationRole.REFFERAL_STAFF))
                ? "col-6 row m-0 p-5 details-holder border-right"
                : "col-12 row m-0 p-5 details-holder"
            }
          >
            <h3 className="title">
              {HelpersService.camelToString(details.category, false)} Lead
              Details
            </h3>
            <LeadForm
              details={details}
              isEdit={isEdit}
              leadType={leadType}
            ></LeadForm>
          </div>
          {details.status === AdminLeadStatus.SHARED &&
            (user.roles?.includes(ApplicationRole.ADMIN) || user.roles?.includes(ApplicationRole.REFFERAL_STAFF)) && (
              <div className="col-6 mt-5">
                <h4 className="title">Pick a winner</h4>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 200 }}
                    aria-labelledby="tableTitle"
                    size="medium"
                  >
                    <TableBody>
                      {attorneysData?.sharedAttorneysStatusMapped?.map(
                        (row, index) => (
                          <AttorneysRow
                            leadId={details?.id!}
                            key={index}
                            row={row}
                          />
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
        </div>
      </div>
    </>
  );
}
