/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CSAType {
    PLO = 'PLO',
    STANDARD = 'Standard',
    FORTY_FIVE_TO_FIFTY = 'FortyFiveToFifty',
    FORTY_TO_FORTY_FIVE_FIFTY = 'FortyToFortyFiveFifty',
    CLASS_ACTION = 'ClassAction',
    PAGA = 'PAGA',
}
