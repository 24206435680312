/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthResponse } from '../models/AuthResponse';
import type { CalendarEventViewModel } from '../models/CalendarEventViewModel';
import type { GetUserListRequest } from '../models/GetUserListRequest';
import type { LoginExternalRequest } from '../models/LoginExternalRequest';
import type { LoginRequest } from '../models/LoginRequest';
import type { RegisterLinkRequest } from '../models/RegisterLinkRequest';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { TimeEntryViewModel } from '../models/TimeEntryViewModel';
import type { TimeKeepingReport } from '../models/TimeKeepingReport';
import type { UserCreateRequest } from '../models/UserCreateRequest';
import type { UserNotificationResponse } from '../models/UserNotificationResponse';
import type { UserPushIdRequest } from '../models/UserPushIdRequest';
import type { UserShortResponse } from '../models/UserShortResponse';
import type { UserViewModel } from '../models/UserViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * @param requestBody 
     * @returns AuthResponse Success
     * @throws ApiError
     */
    public static postApiUsersRegister(
requestBody?: UserCreateRequest,
): CancelablePromise<AuthResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/register',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiUsersPing(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/ping',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiUsersInitiateAdmin(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/initiateAdmin',
        });
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiUsersRegisterLink(
requestBody?: RegisterLinkRequest,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/registerLink',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiUsersRequestPasswordReset(
requestBody?: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/requestPasswordReset',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns AuthResponse Success
     * @throws ApiError
     */
    public static postApiUsersResetPassword(
requestBody?: ResetPasswordRequest,
): CancelablePromise<AuthResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/resetPassword',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static putApiUsersRequestChanges(
id: number,
requestBody?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Users/{id}/requestChanges',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns AuthResponse Success
     * @throws ApiError
     */
    public static postApiUsersRegisterByLink(
requestBody?: UserCreateRequest,
): CancelablePromise<AuthResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/registerByLink',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiUsersLogin(
requestBody?: LoginRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/login',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiUsersLoginExternal(
requestBody?: LoginExternalRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/login/external',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns UserShortResponse Success
     * @throws ApiError
     */
    public static postApiUsersGetUsersByRole(
requestBody?: GetUserListRequest,
): CancelablePromise<Array<UserShortResponse>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/getUsersByRole',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @returns UserViewModel Success
     * @throws ApiError
     */
    public static getApiUsers(
id: number,
): CancelablePromise<UserViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns UserViewModel Success
     * @throws ApiError
     */
    public static putApiUsers(
id: number,
requestBody?: UserViewModel,
): CancelablePromise<UserViewModel> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiUsers(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Users/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param shortName 
     * @returns any Success
     * @throws ApiError
     */
    public static putApiUsersUpdateShort(
id: number,
shortName?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Users/{id}/updateShort',
            path: {
                'id': id,
            },
            query: {
                'shortName': shortName,
            },
        });
    }

    /**
     * @param id 
     * @param isPending 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiUsersSetPending(
id: number,
isPending?: boolean,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/{id}/setPending',
            path: {
                'id': id,
            },
            query: {
                'isPending': isPending,
            },
        });
    }

    /**
     * @param id 
     * @param shortName 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiUsersApproveAttorney(
id: number,
shortName?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/{id}/approveAttorney',
            path: {
                'id': id,
            },
            query: {
                'shortName': shortName,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiUsersAddTimeEntry(
requestBody?: TimeEntryViewModel,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/addTimeEntry',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiUsersUpdateTimeEntry(
requestBody?: TimeEntryViewModel,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/updateTimeEntry',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param startDate 
     * @param endDate 
     * @returns TimeKeepingReport Success
     * @throws ApiError
     */
    public static getApiUsersGetTimeEntries(
startDate?: string,
endDate?: string,
): CancelablePromise<Array<TimeKeepingReport>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/getTimeEntries',
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
        });
    }

    /**
     * @returns CalendarEventViewModel Success
     * @throws ApiError
     */
    public static getApiUsersGetCalendarEvents(): CancelablePromise<Array<CalendarEventViewModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/getCalendarEvents',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiUsersAddCalendarEvents(
requestBody?: Array<CalendarEventViewModel>,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/addCalendarEvents',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static putApiUsersUpdateCalendarEvent(
requestBody?: CalendarEventViewModel,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Users/updateCalendarEvent',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiUsersCalendarEvent(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Users/calendarEvent/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static putApiUsersSetReviewer(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Users/{id}/setReviewer',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns UserNotificationResponse Success
     * @throws ApiError
     */
    public static getApiUsersNotifications(): CancelablePromise<Array<UserNotificationResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/notifications',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiUsersNotifications(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/notifications',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiUsersNotificationsPush(
requestBody?: UserPushIdRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/notifications/push',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

}
