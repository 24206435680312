import Button from "@mui/material/Button/Button";
import { useState } from "react";

import "./Profile.scss";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Switch from "@mui/material/Switch/Switch";
import { ApplicationRole, ClioService } from "../../api";
import UserForm from "../Admin/Attorneys/AttorneysDetails/UserForm";
import { useSelector } from "react-redux";

const MySwal = withReactContent(Swal);

export default function ProfileSettings() {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(0);

  const user = useSelector((state: any) => state.user?.user);

  const handleEditClick = () => {
    setIsEdit(!isEdit);
    if (isEdit) {
      UserForm.resetFormWith(user);
    }
  };

  const handleClioClick = () => {
    ClioService.getApiClioInitiateOauth().then((url) => {
      window.location.replace(url);
    });
  }

  const handleTestClick = async () => {
   await ClioService.getApiClioMatters();
  }

  const openSettingsStep = (step: number) => {
    setActiveStep(step);
  };

  const openResetPassword = () => {};

  return (
    <div className="row info-holder">
      <div className="col-12 col-md-9 row m-0 p-5 ">
        {activeStep === 0 && (
          <div>
            <h4 className="title">Profile</h4>
            {
              <UserForm
                user={user}
                isEdit={isEdit}
                isAttorney={
                  user.roles?.includes(ApplicationRole.ATTORNEY) ||
                  user.roles?.includes(ApplicationRole.USER)
                }
                updateStored={true}
              ></UserForm>
            }
          </div>
        )}
        {activeStep === 1 && (
          <div>
            <h4 className="title">Payments</h4>
            <h5>Card information</h5>
          </div>
        )}
        {activeStep === 2 && (
          <div>
            <h4 className="title">Settings</h4>
            <h5>Notifications</h5>
            <div className="row">
              <FormControlLabel
                value="notifications1"
                control={<Switch color="primary" />}
                label="Lead accepted by attorney"
              />
            </div>
            <div className="row">
              <FormControlLabel
                value="notifications2"
                control={<Switch color="primary" />}
                label="New attorney registration"
              />
            </div>
            <div className="row">
              <FormControlLabel
                value="notifications3"
                control={<Switch color="primary" />}
                label="Lead status change"
              />
            </div>
          </div>
        )}
      </div>
      <div className="col-12 col-md-3 side-details ps-3 mt-4">
        {activeStep === 0 && (
          <>
            {!isEdit ? (
              <Button
                variant="contained"
                color="secondary"
                className="full-btn"
                onClick={handleEditClick}
              >
                Edit profile
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                className="full-btn"
                onClick={handleEditClick}
              >
                Cancel edit
              </Button>
            )}
          </>
        )}
        {/* <Button
          variant="contained"
          color="primary"
          className="full-btn mt-5"
          onClick={() => openSettingsStep(0)}
        >
          Profile
        </Button> */}
        {/* <Button
          variant="contained"
          color="secondary"
          className="full-btn"
          onClick={() => openSettingsStep(1)}
        >
          Payments
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className="full-btn"
          onClick={() => openSettingsStep(2)}
        >
          Settings
        </Button> */}
        {/* <Button variant="outlined" onClick={() => openResetPassword()}>Reset password</Button> */}
      </div>
    </div>
  );
}
