import { useEffect, useState } from "react";

import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody/TableBody";
import TableCell from "@mui/material/TableCell/TableCell";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import { UsersService } from "../../../../api/services/UsersService";
import { ApplicationRole, GetUserListRequest, UserShortResponse } from "../../../../api";

const headCells = [
  {
    id: "attorneyName",
    disablePadding: false,
    label: "Attorneys",
  },
];

interface ShareAttorneysTableProps {
  onAttorneySelectionChange: Function;
}

export default function ShareAttorneysTable(props: ShareAttorneysTableProps) {
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [attorneys, setAttorneys] = useState<UserShortResponse[]>([]);

  useEffect(() => {
    UsersService.postApiUsersGetUsersByRole({
      role: ApplicationRole.ATTORNEY
    } as GetUserListRequest
    ).then((attorneys) => {
      setAttorneys(attorneys);
    });
  }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = attorneys.map((n) => n.id!);
      setSelected(newSelected);
      props.onAttorneySelectionChange(newSelected);
      return;
    }
    setSelected([]);
    props.onAttorneySelectionChange([]);
  };

  const handleClick = (row) => {
    const selectedIndex = selected.indexOf(row.id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = selected.concat(row.id);
    } else if (selectedIndex === 0) {
      newSelected = selected.slice(1);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = selected.slice(0, -1);
    } else if (selectedIndex > 0) {
      newSelected = selected
        .slice(0, selectedIndex)
        .concat(selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
    props.onAttorneySelectionChange(newSelected);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  return (
    <TableContainer>
      <Table sx={{ minWidth: 200 }} aria-labelledby="tableTitle" size="medium">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={
                  selected.length > 0 && selected.length < attorneys.length
                }
                checked={attorneys.length > 0 && selected.length === attorneys.length}
                onChange={handleSelectAllClick}
              />
            </TableCell>
            {headCells.map((headCell) => (
              <TableCell key={headCell.id}> {headCell.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {attorneys
            ? attorneys.map((row, index) => {
              const isItemSelected = isSelected(row.id!);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={() => handleClick(row)}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">{row.firstName} {row.lastName}</TableCell>
                </TableRow>
              );
            })
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
