import { useEffect, useMemo, useState } from "react";
import { AssignTaskRequest, ClioService, CustomFields, MatterPreviewResponse, TaskPreviewResponse, UsersModel } from "../../api";
import { Autocomplete, Button, debounce, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
export default function ClioMatterInteraction() {
  const [matterOptions, setMatterOptions] = useState<
    readonly MatterPreviewResponse[]
  >([]);
  const [matterValue, setMatterValue] = useState<MatterPreviewResponse | null>(
    null
  );
  const [matterInputValue, setMatterInputValue] = useState("");
  const [taskInputValue, setTaskInputValue] = useState("");
  const [taskValue, setTaskValue] = useState<TaskPreviewResponse | null>(null);
  const [taskOptions, setTaskOptions] = useState<
    readonly TaskPreviewResponse[]
  >([]);
  const [usersList, setUsersList] = useState<UsersModel[]>([]);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [usersTypesList, setUsersTypesList] = useState<CustomFields[]>([CustomFields.ASSIGNED_PARALEGAL, CustomFields.RESPONSIBLE_ATTORNEY, CustomFields.SUPPORTING_ATTY, CustomFields.SENIOR_MANAGING_ATTY, CustomFields.LEGAL_ASSISTANT]);
  const [selectedUserTypes, setSelectedUserTypes] = useState<string | null>(null);

  useEffect(() => {
    ClioService.getApiClioClioUsers(true).then((users) => {
      setUsersList(users);
    });
  }, []);

  const fetchMatters = useMemo(
    () =>
      debounce((input: string) => {
        ClioService.getApiClioMatters(input).then((response) => {
          let newOptions: readonly MatterPreviewResponse[] = [];
          if (matterValue) {
            newOptions = [matterValue];
          }
          if (response) {
            newOptions = [...newOptions, ...response];
          }

          setMatterOptions(newOptions);
        });
      }, 200),
    []
  );

  useEffect(() => {
    if (matterInputValue === "") {
      setMatterOptions(matterValue ? [matterValue] : []);
      return undefined;
    }

    fetchMatters(matterInputValue);

    return () => { };
  }, [matterValue, matterInputValue, fetchMatters]);

  const fetchTasks = useMemo(
    () =>
      debounce((input: string) => {
        ClioService.getApiClioTaskTypes(input).then((response) => {
          let newOptions: readonly TaskPreviewResponse[] = [];
          if (taskValue) {
            newOptions = [taskValue];
          }
          if (response) {
            newOptions = [...newOptions, ...response];
          }

          setTaskOptions(newOptions);
        });
      }, 200),
    []
  );

  useEffect(() => {
    if (taskInputValue === "") {
      setTaskOptions(taskValue ? [taskValue] : []);
      return undefined;
    }

    fetchTasks(taskInputValue);

    return () => { };
  }, [taskValue, taskInputValue, fetchTasks]);

  const assignTask = () => {
    var request = {
      matterId: +(matterValue!.id!),
      taskTypeId: +(taskValue!.id!),
      assigneeId: (selectedUser != null ? +selectedUser : null),
      assigneeType: selectedUserTypes
    } as AssignTaskRequest;
    ClioService.postApiClioAssignTask(request).then(() => MySwal.fire({
      icon: "success",
      title: (
        <p className="title">
          Task assigned.
        </p>
      ),
    })).catch(() => MySwal.fire({
      icon: "error",
      title: (
        <p className="title">
          Error assigning task.
        </p>
      ),
    }));
  };

  return (
    <>
      <div className="row mt-2">
        <div className="col-6">
          <Autocomplete
            getOptionLabel={(option) =>
              (typeof option === "string" ? option : option.description) as string
            }

            filterOptions={(x) => x}
            options={matterOptions}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={matterValue}
            noOptionsText="Start typing matter"
            onChange={(
              event: any,
              selectedValue: MatterPreviewResponse | null
            ) => {
              setMatterOptions(
                selectedValue ? [selectedValue, ...matterOptions] : matterOptions
              );
              setMatterValue(selectedValue);
            }}
            onInputChange={(event, newInputValue) => {
              setMatterInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Matter" fullWidth />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Typography variant="body2" color="text.secondary">
                      {option.description}
                    </Typography>
                  </Grid>
                </li>
              );
            }}
          />
        </div>
        <div className="col-6">
          <Autocomplete
            getOptionLabel={(option) =>
              (typeof option === "string"
                ? option
                : option.name) as string
            }
            filterOptions={(x) => x}
            options={taskOptions}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={taskValue}
            noOptionsText="Start typing task"
            onChange={(
              event: any,
              selectedValue: TaskPreviewResponse | null
            ) => {
              setTaskOptions(
                selectedValue
                  ? [selectedValue, ...taskOptions]
                  : taskOptions
              );
              setTaskValue(selectedValue);
            }}
            onInputChange={(event, newInputValue) => {
              setTaskInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Task" fullWidth />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Typography variant="body2" color="text.secondary">
                      {option.name}
                    </Typography>
                  </Grid>
                </li>
              );
            }}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-6">
          <FormControl className="w-100">
            <InputLabel id="type-label" required>
              Select User
            </InputLabel>
            <Select
              labelId="type-label"
              className="min-width-200"
              label="Select user*"
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              {usersList.map((user) => {
                return (
                  <MenuItem key={user.id} value={user.id!}>
                    {user.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="col-6">
          <FormControl className="w-100">
            <InputLabel id="type-label" required>
              Select Staff Type
            </InputLabel>
            <Select
              labelId="type-label"
              className="min-width-200"
              label="Select type*"
              value={selectedUserTypes}
              onChange={(e) => setSelectedUserTypes(e.target.value)}
            >
              {usersTypesList
                .map((type) => {
                  return (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="row mt-2">          
        <Button
        variant="contained"
        color="primary"
        type="submit"
        className="submit-btn col-3"
        onClick={() => assignTask()}
      >
        Assign
      </Button>
      </div>
    </>
  );
}
