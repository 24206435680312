import { useEffect, useState } from "react";
import * as yup from "yup";
import { getIn, useFormik } from "formik";
import TextField from "@mui/material/TextField/TextField";
import FormControl from "@mui/material/FormControl/FormControl";
import FormLabel from "@mui/material/FormLabel/FormLabel";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import Button from "@mui/material/Button/Button";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Select from "@mui/material/Select/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Dropzone from "react-dropzone";

import Checkbox from "@mui/material/Checkbox/Checkbox";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import Rating from "@mui/material/Rating/Rating";
import StarIcon from "@mui/icons-material/Star";
import {
  AdminLeadStatus,
  ApplicationRole,
  Category,
  ContactReason,
  EmployeePaidType,
  FileType,
  HarassmentCategory,
  InitialClientDataResponse,
  InjuryType,
  LeadsService,
  LeadViewModel,
  OpenAPI,
  PaidType,
  PaidWithheld,
  ProtectedCategory,
  RadioOptions,
  TerminationType,
} from "../../../../api";
import dayjs from "dayjs";
import {
  clientDataSchema,
  ContactReasonDisplayMapping,
  EmployeePaidDisplayMapping,
  FileModel,
  LeadType,
  maxFileSize,
  PaidTimeDisplayMapping,
  RadioYesOption,
} from "../shared/Constants";
import RadioControl from "../../../../components/basic/RadioControl";
import useNavigateSearch from "../../../../services/useNavigateSearch";
import { HelpersService } from "../../../../services/Helpers";
import InputAdornment from "@mui/material/InputAdornment";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import UnsavedChangesWarning from "../../../../services/UnsavedChangesWarning";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import { FileService } from "../../../../api/services/FileService";
import { useDispatch } from "react-redux";
import {
  startSpinner,
  stopSpinner,
} from "../../../../redux/actions/spinnerActions";
import { MuiTelInput } from "mui-tel-input";
import MapsAutocomplete from "../../../../components/basic/MapsAutocomplete";
const MySwal = withReactContent(Swal);

interface EmploymentProps {
  data?: LeadViewModel;
  isEdit?: boolean;
  isNew?: boolean;
  leadType?: LeadType;
  userRoles?: ApplicationRole[];
}

const emptyData: LeadViewModel = {
  status: undefined,
  category: Category.EMPLOYMENT,
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  birthDate: null,

  employmentDetails: {
    workAddress: "",
    company: "",
    employeesNumber: null,
    jobTitle: "",
    jobDescription: "",
    terminationType: HelpersService.getEnumKeyByEnumValue(
      TerminationType,
      TerminationType.TERMINATED
    ),
    hireDate: null,
    terminationDate: null,
    paidType: undefined,
    hourlyRate: null,
    salary: null,

    severance: undefined,
    severanceAccepted: undefined,
    employed: undefined,
    newSalary: null,
    newJobStartDate: null,
    workSearching: undefined,
    workSearchingEfforts: "",
    filedEeoc: undefined,
    rtsIssued: undefined,
    rtsIssuedWhen: null,
    rtsCopy: undefined,
    arbitration: undefined,
    arbitrationCopy: undefined,

    terminationReasonGiven: "",
    terminationReasonReal: "",
    terminationResponsible: "",
    terminationTimeline: "",
    supervisor: "",
    reprimands: undefined,
    reprimandsDetails: "",

    contactReasons: [],
    protectedCategories: [],
    protectedCategoryDescription: "",
    otherProtectedCategory: "",

    discriminatoryConduct: "",
    discriminatoryConductDates: "",
    discriminatedBy: "",
    discriminatedWrittenComplains: undefined,
    discriminatedWroteTo: "",
    discriminatedWroteWhen: null,
    discriminatedWroteAction: "",
    discriminatedOralComplains: undefined,
    discriminatedOralTo: "",
    discriminatedOralWhen: null,
    discriminatedOralAction: "",
    discriminatedComments: undefined,
    discriminatedCommentsBy: "",
    discriminatedCommentWorst: "",
    discriminatedCommentWorstWhen: null,
    discriminatedCommentLastWhen: null,
    disabilityAccommodationsRequest: "",
    medicalDisabilities: undefined,
    medicalDisabilitiesReported: undefined,
    medicalDisabilitiesActions: undefined,

    harassmentCategories: [],
    harassmentConduct: "",
    harassmentConductDates: "",
    harassmentWrittenComplains: undefined,
    harassmentWroteTo: "",
    harassmentWroteWhen: null,
    harassmentWroteAction: "",
    harassmentOralComplains: undefined,
    harassmentOralTo: "",
    harassmentOralWhen: null,
    harassmentOralAction: "",
    harassmentComments: undefined,
    harassmentCommentsBy: "",
    harassmentCommentWorst: "",
    harassmentCommentWorstWhen: null,
    harassmentCommentLastWhen: null,

    protectedConduct: "",
    protectedConductDates: "",
    retaliatoryConduct: "",
    retaliatoryConductDates: "",
    retaliationBy: "",
    retaliationWrittenComplains: undefined,
    retaliationWroteTo: "",
    retaliationWroteWhen: null,
    retaliationWroteAction: "",
    retaliationOralComplains: undefined,
    retaliationOralTo: "",
    retaliationOralWhen: null,
    retaliationOralAction: "",

    breachWrittenContract: undefined,
    breachOralContract: undefined,
    breachTerms: "",
    breachDetails: "",
    breachDate: "",
    breachParties: "",

    classProperOt: undefined,
    classOtExplain: "",
    classMealPeriod: undefined,
    classMealCanLeave: undefined,
    classRestPeriod: undefined,
    classExplainProblem: "",
    classPaidType: [],
    classPaidOther: "",
    classHoursWorkedWeek: "",
    classDayOff: "",
    classHoursWorkedDay: "",
    classSchedule: "",
    classWorkTravel: undefined,
    classTravelReimbursed: undefined,
    classPaidWithheld: [],
    classPaidWithheldOther: "",
    classReceivedTips: undefined,
    classGratuitiesPool: undefined,
    classManagersInPool: undefined,
    classVacationsCarried: undefined,
    classUnusedVacationPaid: undefined,
    classTerminationDayPaid: undefined,
    classTerminatedWagesPaid: undefined,

    hadInjuries: undefined,
    injuriesDescription: "",
    currentlyRepresented: undefined,
    representedByName: "",
    representedWhyChange: "",
    currentlyRepresentedWC: undefined,
    lookingToChangeWC: undefined,
    interestedInLawyer: undefined,
    employedCA: undefined,
    injuryType: undefined,
    havePain: undefined,
    painDescription: "",
    injuryReported: undefined,
    writtenComplains: undefined,
    writtenTo: "",
    writtenWhen: null,
    oralComplains: undefined,
    oralTo: "",
    oralWhen: null,
    treatmentReceived: undefined,
    treatmentDetails: "",
    hasInsurance: undefined,
    paycheckStubs: undefined,
    workersClaimReprimands: undefined,
    workersClaimReprimandsDetails: "",

    interruptedBreaks: undefined,
    secondBreakForHours: undefined,
    workedOT: undefined,
    reimbursedForOutOfPocket: undefined,
  },
  csNotes: "",
  credibility: null,
};

export default function EmploymentForm(props: EmploymentProps) {
  const [files, setFiles] = useState<FileModel[]>([]);
  const [shrink, setShrink] = useState(false);
  const navigate = useNavigateSearch();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!props.data?.files) {
      const filesMapped = props.data?.files.map((file) => ({
        url: file.url!,
        key: file.name!,
      }));
      setFiles(filesMapped);
    }
  }, [props.data?.files]);

  const handleFilesUpdate = async (acceptedFiles) => {
    dispatch(startSpinner());
    const sameFiles: FileModel[] = files.filter((oldFile) =>
      acceptedFiles.some((newFile) => newFile.name === oldFile.key)
    );
    if (sameFiles.length > 0) {
      MySwal.fire({
        title: <strong>You are trying to add files that already exist</strong>,
        html: (
          <div>
            {sameFiles.map((file) => `${file.key}, `)} will be replaced.
          </div>
        ),
        icon: "info",
        showCancelButton: true,
        cancelButtonText: "No, keep it",
        confirmButtonText: "Yes, replace",
        confirmButtonColor: "#ed633a",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (values.id) {
            await updateFiles(values.id!, acceptedFiles);
          }
          const removeDups = files.filter(
            (file) => !sameFiles.some((sameFile) => file.key === sameFile.key)
          );
          setFiles([...removeDups, ...parseFiles(acceptedFiles)]);
        }
        dispatch(stopSpinner());
      });
    } else {
      if (values.id) {
        await updateFiles(values.id!, acceptedFiles);
      }
      setFiles([...files, ...parseFiles(acceptedFiles)]);
      dispatch(stopSpinner());
    }
  };

  const parseFiles = (acceptedFiles) =>
    acceptedFiles.map((file) => {
      return {
        key: file.name,
        file,
      };
    });

  const handleFileRemove = (key) => {
    dispatch(startSpinner());
    MySwal.fire({
      title: <strong>Confirm file removal</strong>,
      html: <div>{key} will be deleted from this lead.</div>,
      icon: "info",
      showCancelButton: true,
      cancelButtonText: "No, keep it",
      confirmButtonText: "Yes, remove",
      confirmButtonColor: "#ed633a",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setFiles((prevFiles) => prevFiles.filter((file) => file.key !== key));
        await FileService.postApiFileDelete(values.id!, {
          fileNames: [key],
        }).catch(() => {
          MySwal.fire({
            icon: "error",
            title: <p className="title">Error removing files</p>,
          });
        });
      }
      dispatch(stopSpinner());
    });
  };

  const onSubmit = (form) => {
    dispatch(startSpinner());
    var newLead = form as LeadViewModel;
    newLead.isDraft = false;
    newLead.employmentDetails!.terminationType =
      TerminationType[values.employmentDetails!.terminationType!] ??
      TerminationType.TERMINATED;

    newLead.category = Category.EMPLOYMENT;
    newLead.personalInjuryDetails = undefined;
    newLead.workersCompDetails = undefined;
    postLead(newLead);
  }


  const postLead = (lead) => {
    if (values.id) {
      LeadsService.putApiLeads(values.id, lead)
        .then(() => {
          MySwal.fire({
            icon: "success",
            title: <p className="title">Lead updated successfully</p>,
          }).then(() => window.location.reload());
        })
        .catch((e) => {
          MySwal.fire({
            icon: "error",
            title: <p className="title">Error updating lead</p>,
            html: (
              <div>
                Please make sure email and other fields have proper values.
              </div>
            ),
          });
        })
        .finally(() => dispatch(stopSpinner()));
      return;
    }
    LeadsService.postApiLeads(lead)
      .then(async (id) => {
        await updateFiles(id);
        MySwal.fire({
          icon: "success",
          title: <p className="title">Lead created successfully</p>,
        }).then(() => navigate("/new-leads"));
      })
      .catch(() =>
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error creating new lead</p>,
          html: (
            <div>
              Please make sure email and other fields have proper values.
            </div>
          ),
        })
      )
      .finally(() => dispatch(stopSpinner()));
  };

  const updateFiles = async (leadId, filesToUpdate: any = null) => {
    dispatch(startSpinner());
    if (filesToUpdate === null) {
      filesToUpdate = files.map((file) => file.file);
      filesToUpdate = filesToUpdate.filter((file) => file !== undefined);
    }
    if (filesToUpdate.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < filesToUpdate.length; i++) {
        formData.append(`files`, filesToUpdate[i]);
      }
      const url = `${OpenAPI.BASE}/api/File/${leadId}/upload`;
      await axios
        .post(url, formData, {
          params: {
            type: FileType.LEAD_FILE,
          },
        })
        .catch(() => {
          MySwal.fire({
            icon: "error",
            title: <p className="title">Error uploading files.</p>,
          });
        })
        .finally(() => dispatch(stopSpinner()));
    } else {
      dispatch(stopSpinner());
    }
  };

  const handleAddIncomplete = () => {
    dispatch(startSpinner());
    clientDataSchema
      .validate(values, { abortEarly: false })
      .then(() => {
        var newLead = { ...values } as LeadViewModel;
        newLead.isDraft = true;
        newLead.employmentDetails!.terminationType =
          TerminationType[values.employmentDetails!.terminationType!] ??
          TerminationType.TERMINATED;

        newLead.category = Category.EMPLOYMENT;
        newLead.personalInjuryDetails = undefined;
        newLead.workersCompDetails = undefined;
        postLead(newLead);
      });
  };

  const handleCheckboxChange = (value, name: string) => {
    var arrayToUpdate = [] as (typeof value)[];
    let index = -1;

    var details = values.employmentDetails;
    if (!!details) {
      arrayToUpdate = details[name.split(".")[1]];
      index = arrayToUpdate?.indexOf(value);
    }

    if (index >= 0) {
      arrayToUpdate = arrayToUpdate.filter((reason) => reason !== value);
    } else {
      arrayToUpdate = [...arrayToUpdate ?? [], value];
    }

    handleChange({
      target: { value: arrayToUpdate, name: name },
    });
  };

  updateUserData = (data: InitialClientDataResponse) => {
    (Object.keys(data) as (keyof typeof data)[]).forEach((key, index) => {
      handleChange({
        target: { value: data[key], name: key },
      });
    });
  };

  const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    phone: yup.string().required(),
    // .test("is-valid-phone", "Invalid phone", function (value) {
    //   return matchIsValidTel(value);
    // }),
    email: yup.string().email().required(),
    birthDate: yup.mixed().required(),

    employmentDetails: yup.object().shape({
      hireDate: yup.mixed().required(),
      terminationDate: yup
        .mixed()
        .optional()
        .nullable()
        .when("terminationType", {
          is: TerminationType.RESIGNED,
          then: (schema) => schema.required(),
        })
        .when("terminationType", {
          is: TerminationType.TERMINATED,
          then: (schema) => schema.required(),
        }),
        salary: yup
        .mixed()
        .optional()
        .nullable()
        .when("paidType", {
          is: PaidType.SALARY,
          then: (schema) => schema.required(),
        }),
        hourlyRate: yup
        .mixed()
        .optional()
        .nullable()
        .when("paidType", {
          is: PaidType.HOURLY,
          then: (schema) => schema.required(),
        }),
      // .test(
      //   "is-after-hire-date",
      //   "Must be after hire date",
      //   function (value) {
      //     const { hireDate } = this.parent;
      //     var terminationValue =
      //       typeof value === "string" ? dayjs(value) : value;

      //     return (
      //       (!!values.employmentDetails?.terminationType &&
      //         TerminationType[values.employmentDetails?.terminationType] ===
      //         TerminationType.STILL_WORKING) ||
      //       (!!terminationValue &&
      //         !!hireDate &&
      //         terminationValue > dayjs(hireDate))
      //     );
      //   }
      // ),

      severance: yup.mixed().required(),
      severanceAccepted: yup
        .mixed()
        .optional()
        .nullable()
        .when("severance", {
          is: RadioOptions.YES,
          then: (schema) => schema.required(),
        }),
      employed: yup.mixed().required(),

      newJobStartDate: yup
        .mixed()
        .optional()
        .nullable()
        .when("employed", {
          is: RadioOptions.YES,
          then: (schema) => schema.required(),
        }),

      workSearching: yup
        .mixed()
        .optional()
        .nullable()
        .when("employed", {
          is: RadioOptions.NO,
          then: (schema) => schema.required(),
        }),
      currentlyRepresented: yup.mixed().required(),
      filedEeoc: yup.mixed().required(),
      reprimands: yup.mixed().required(),
      contactReasons: yup.array().required().min(1),
      protectedCategories: yup
        .array()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.DISCRIMINATION),
          then: (schema) => schema.required().min(1),
        }),
      discriminatedWrittenComplains: yup
        .mixed()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.DISCRIMINATION),
          then: (schema) => schema.required(),
        }),
      discriminatedOralComplains: yup
        .mixed()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.DISCRIMINATION),
          then: (schema) => schema.required(),
        }),
      discriminatedComments: yup
        .mixed()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.DISCRIMINATION),
          then: (schema) => schema.required(),
        }),
      // discriminatedCommentWorst: yup
      //   .string()
      //   .optional()
      //   .when(["contactReasons", "discriminatedComments"], {
      //     is:
      //       (HelpersService.getEnumKeyByEnumValue(
      //         ContactReason,
      //         ContactReason.DISCRIMINATION
      //       ),
      //       HelpersService.getEnumKeyByEnumValue(RadioOptions, RadioOptions.YES)),
      //     then: (schema) => schema.required(),
      //   }),
      harassmentWrittenComplains: yup
        .mixed()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.HARASSMENT),
          then: (schema) => schema.required(),
        }),
      harassmentOralComplains: yup
        .mixed()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.HARASSMENT),
          then: (schema) => schema.required(),
        }),
      harassmentCategories: yup
        .array()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.HARASSMENT),
          then: (schema) => schema.required().min(1),
        }),
      retaliationWrittenComplains: yup
        .mixed()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.RETALIATION),
          then: (schema) => schema.required(),
        }),
      retaliationOralComplains: yup
        .mixed()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.RETALIATION),
          then: (schema) => schema.required(),
        }),
      classProperOt: yup
        .mixed()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.CLASS_ACTION),
          then: (schema) => schema.required(),
        }),
      classMealPeriod: yup
        .mixed()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.CLASS_ACTION),
          then: (schema) => schema.required(),
        }),
      classRestPeriod: yup
        .mixed()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.CLASS_ACTION),
          then: (schema) => schema.required(),
        }),
      classPaidType: yup
        .array()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.CLASS_ACTION),
          then: (schema) => schema.required().min(1),
        }),
      classWorkTravel: yup
        .mixed()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.CLASS_ACTION),
          then: (schema) => schema.required(),
        }),
      classTravelReimbursed: yup
        .mixed()
        .optional()
        .nullable()
        .when(["contactReasons", "classWorkTravel"], {
          is: (reasons, classWorkTravel) =>
            reasons.includes(ContactReason.CLASS_ACTION) &&
            classWorkTravel === RadioOptions.YES,
          then: (schema) => schema.required(),
        }),
      classPaidWithheld: yup
        .array()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.CLASS_ACTION),
          then: (schema) => schema.required().min(1),
        }),
      classVacationsCarried: yup
        .mixed()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.CLASS_ACTION),
          then: (schema) => schema.required(),
        }),
      classUnusedVacationPaid: yup
        .mixed()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.CLASS_ACTION),
          then: (schema) => schema.required(),
        }),
      classTerminationDayPaid: yup
        .mixed()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.CLASS_ACTION),
          then: (schema) => schema.required(),
        }),
      classTerminatedWagesPaid: yup
        .mixed()
        .optional()
        .nullable()
        .when("contactReasons", {
          is: (reasons) => reasons.includes(ContactReason.CLASS_ACTION),
          then: (schema) => schema.required(),
        }),
      hadInjuries: yup.mixed().required(),
      currentlyRepresentedWC: yup
        .mixed()
        .optional()
        .nullable()
        .when("interestedInLawyer", {
          is: HelpersService.getEnumKeyByEnumValue(
            RadioOptions,
            RadioOptions.YES
          ),
          then: (schema) => schema.required(),
        }),
      injuryType: yup
        .mixed()
        .optional()
        .nullable()
        .when("interestedInLawyer", {
          is: HelpersService.getEnumKeyByEnumValue(
            RadioOptions,
            RadioOptions.YES
          ),
          then: (schema) => schema.required(),
        }),
      hasInsurance: yup
        .mixed()
        .optional()
        .nullable()
        .when("interestedInLawyer", {
          is: HelpersService.getEnumKeyByEnumValue(
            RadioOptions,
            RadioOptions.YES
          ),
          then: (schema) => schema.required(),
        }),
      paycheckStubs: yup
        .mixed()
        .optional()
        .nullable()
        .when("interestedInLawyer", {
          is: HelpersService.getEnumKeyByEnumValue(
            RadioOptions,
            RadioOptions.YES
          ),
          then: (schema) => schema.required(),
        }),
      workersClaimReprimands: yup
        .mixed()
        .optional()
        .nullable()
        .when("interestedInLawyer", {
          is: HelpersService.getEnumKeyByEnumValue(
            RadioOptions,
            RadioOptions.YES
          ),
          then: (schema) => schema.required(),
        }),
    }),
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = useFormik({
    initialValues: props.data ?? emptyData,
    validationSchema: schema,
    onSubmit,
  });
  resetFormWith = (values: LeadViewModel) => {
    values
      ? resetForm({ values: values })
      : resetForm({ values: props.data ?? emptyData });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={!props.isEdit ? "hide-actions" : ""}
    >
      <UnsavedChangesWarning
        hasUnsavedChanges={!props.data?.id && Object.keys(touched).length > 0}
      />
      <fieldset disabled={!props.isEdit}>
        {(!!values.isFullSummary ||
          props.leadType === LeadType.Admin ||
          props.leadType === LeadType.StaffAttorney) && (
            <div className="row mt-3">
              <h5 className="title mb-0 mt-3">Client Info</h5>
              <div className="col-12 mt-3">Full legal name (for CSA)</div>
              <div className="col-6 mt-3">
                <TextField
                  required
                  label="First name"
                  variant="outlined"
                  name="firstName"
                  className={
                    !!errors.firstName && !!touched.firstName ? "error" : ""
                  }
                  value={values.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!errors.firstName && !!touched.firstName && (
                  <FormHelperText className="error-message">
                    Required
                  </FormHelperText>
                )}
              </div>
              <div className="col-6 mt-3">
                <TextField
                  required
                  label="Last name"
                  variant="outlined"
                  name="lastName"
                  className={
                    !!errors.lastName && !!touched.lastName ? "error" : ""
                  }
                  value={values.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!errors.lastName && !!touched.lastName && (
                  <FormHelperText className="error-message">
                    Required
                  </FormHelperText>
                )}
              </div>
              <div className="col-6 mt-3">
                <MuiTelInput
                  required
                  label="Phone"
                  variant="outlined"
                  name="phone"
                  className={!!errors.phone && !!touched.phone ? "error" : ""}
                  onlyCountries={["US"]}
                  defaultCountry={"US"}
                  disableDropdown
                  value={values.phone ?? undefined}
                  onBlur={handleBlur}
                  onChange={(newValue) => {
                    setFieldValue("phone", newValue);
                  }}
                />
                {!!errors.phone && !!touched.phone && (
                  <FormHelperText className="error-message">
                    {errors.phone}
                  </FormHelperText>
                )}
              </div>
              <div className="col-6 mt-3">
                <TextField
                  required
                  label="email"
                  variant="outlined"
                  name="email"
                  className={!!errors.email && !!touched.email ? "error" : ""}
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={(e) => setFieldValue("email", e.target.value.trim())}
                />
                {!!errors.email && !!touched.email && (
                  <FormHelperText className="error-message">
                    {errors.email}
                  </FormHelperText>
                )}
              </div>
              <div className="col-6 mt-3 datepicker-holder">
                <FormControl
                  error={!!errors.birthDate && !!touched.birthDate}
                  required
                >
                  <DatePicker
                    disableFuture
                    label="Birth Date *"
                    value={!!values.birthDate ? dayjs(values.birthDate) : null}
                    onChange={(date) =>
                      handleChange({
                        target: { value: date, name: "birthDate" },
                      })
                    }
                    className={
                      !!errors.birthDate && !!touched.birthDate ? "error" : ""
                    }
                  />
                  {!!errors.birthDate && !!touched.birthDate && (
                    <FormHelperText>Required</FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
          )}
        {!values.isFullSummary &&
          props.leadType === LeadType.Attorney
          && <h5>Client name and contact information will be revealed after you finish payment.</h5>}
        <h5 className="title mb-0 mt-3">Lead Details</h5>
        <div className="row">
          <div className="col-6 mt-3">
            <TextField
              required
              label="Employer Company Full Legal Name (for CSA)"
              variant="outlined"
              name="employmentDetails.company"
              placeholder="Who is on their paystub?"
              value={values.employmentDetails?.company}
              onChange={handleChange}
            />
          </div>
          <div className="col-6 mt-3">
            <TextField
              required
              label="How many employees work in the company? (estimation)"
              variant="outlined"
              name="employmentDetails.employeesNumber"
              value={values.employmentDetails?.employeesNumber}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-3">
            <MapsAutocomplete
              value={values.employmentDetails?.workAddress ?? ""}
              label="Address/​location of lead's former workplace"
              onChange={(value) => setFieldValue("employmentDetails.workAddress", value)}
            />
          </div>
          <div className="col-6 mt-3">
            <TextField
              required
              label="Title/​Position"
              variant="outlined"
              name="employmentDetails.jobTitle"
              value={values.employmentDetails?.jobTitle}
              onChange={handleChange}
            />
          </div>
          <div className="col-6 mt-3">
            <TextField
              label="Brief description of job duties"
              variant="outlined"
              name="employmentDetails.jobDescription"
              value={values.employmentDetails?.jobDescription}
              onChange={handleChange}
            />
          </div>
          <div className="row">
            <div className="col-6 mt-3 dropdown-holder">
              <FormControl
                required
                variant="outlined"
                sx={{ m: 1, minWidth: 120 }}
              >
                <InputLabel id="fired-label">
                  Terminated, Resigned or Still Working?
                </InputLabel>
                <Select
                  disabled={!props.isEdit}
                  labelId="fired-label"
                  label="Terminated, Resigned or Still Working?"
                  id="fired"
                  name="employmentDetails.terminationType"
                  value={values.employmentDetails?.terminationType}
                  onChange={handleChange}
                >
                  {Object.keys(TerminationType).map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {HelpersService.camelToString(TerminationType[key])}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="col-6 mt-3 datepicker-holder">
            <TextField
              required
              label="Date of Hire"
              variant="outlined"
              name="employmentDetails.hireDate"
              value={values.employmentDetails?.hireDate}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <div className="col-6 mt-3 datepicker-holder">
            {values.employmentDetails?.terminationType !==
              HelpersService.getEnumKeyByEnumValue(
                TerminationType,
                TerminationType.STILL_WORKING
              ) && (
                <>
                  <TextField
                    label={
                      values.employmentDetails?.terminationType ===
                        HelpersService.getEnumKeyByEnumValue(
                          TerminationType,
                          TerminationType.TERMINATED
                        )
                        ? "Termination Date*"
                        : "Last working day*"
                    }
                    variant="outlined"
                    name="employmentDetails.terminationDate"
                    value={values.employmentDetails?.terminationDate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </>
              )}
          </div>
          <div className="row m-0 p-0">
            <div className="col-6 mt-3 dropdown-holder">
              <FormControl
                required
                variant="outlined"
                sx={{ m: 1, minWidth: 120 }}
              >
                <InputLabel id="paid-label">
                  Were you paid hourly or salary?
                </InputLabel>
                <Select
                  disabled={!props.isEdit}
                  labelId="paid-label"
                  label="Were you paid hourly or salary?"
                  id="paid"
                  name="employmentDetails.paidType"
                  value={values.employmentDetails?.paidType}
                  onChange={handleChange}
                >
                  {Object.keys(PaidType).map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {PaidType[key]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {values.employmentDetails?.paidType ===
              HelpersService.getEnumKeyByEnumValue(
                PaidType,
                PaidType.SALARY
              ) && (
                <>
                  <div className="col-6 mt-3">
                    <TextField
                      required
                      label="Salary"
                      variant="outlined"
                      name="employmentDetails.salary"
                      value={values.employmentDetails?.salary}
                      onChange={handleChange}
                    />
                  </div>
                  <RadioControl
                    isRequired
                    requiredError={
                      getIn(errors, "employmentDetails.reimbursedForOutOfPocket") &&
                      getIn(touched, "employmentDetails.reimbursedForOutOfPocket")
                    }
                    propertyName="employmentDetails.reimbursedForOutOfPocket"
                    label="Were you reimbursed for out-of-pocket expenses? Ex: Personal cell phone, gas"
                    value={values.employmentDetails?.reimbursedForOutOfPocket}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </>
              )}
            {values.employmentDetails?.paidType ===
              HelpersService.getEnumKeyByEnumValue(
                PaidType,
                PaidType.HOURLY
              ) && (
                <>
                  <div className="col-6 mt-3">
                    <TextField
                      required
                      label="Hourly Rate (if applicable)"
                      variant="outlined"
                      name="employmentDetails.hourlyRate"
                      value={values.employmentDetails?.hourlyRate}
                      onChange={handleChange}
                    />
                  </div>
                  <h5 className="mt-3">Class action Questions</h5>
                  <RadioControl
                    isRequired
                    requiredError={
                      getIn(errors, "employmentDetails.classMealPeriod") &&
                      getIn(touched, "employmentDetails.classMealPeriod")
                    }
                    propertyName="employmentDetails.classMealPeriod"
                    label="Did you receive meal breaks before the 5th hour?"
                    value={values.employmentDetails?.classMealPeriod}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  <RadioControl
                    isRequired
                    requiredError={
                      getIn(errors, "employmentDetails.interruptedBreaks") &&
                      getIn(touched, "employmentDetails.interruptedBreaks")
                    }
                    propertyName="employmentDetails.interruptedBreaks"
                    label="Were your meal breaks interrupted?"
                    value={values.employmentDetails?.interruptedBreaks}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  <RadioControl
                    isRequired
                    requiredError={
                      getIn(errors, "employmentDetails.classMealCanLeave") &&
                      getIn(touched, "employmentDetails.classMealCanLeave")
                    }
                    propertyName="employmentDetails.classMealCanLeave"
                    label="Were you allowed to leave your work location during your meal break?"
                    value={values.employmentDetails?.classMealCanLeave}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  <RadioControl
                    isRequired
                    requiredError={
                      getIn(errors, "employmentDetails.classRestPeriod") &&
                      getIn(touched, "employmentDetails.classRestPeriod")
                    }
                    propertyName="employmentDetails.classRestPeriod"
                    label="Did you receive rest breaks before the 4th hour?"
                    value={values.employmentDetails?.classRestPeriod}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  <RadioControl
                    isRequired
                    requiredError={
                      getIn(errors, "employmentDetails.secondBreakForHours") &&
                      getIn(touched, "employmentDetails.secondBreakForHours")
                    }
                    propertyName="employmentDetails.secondBreakForHours"
                    label="If you worked over 6 hours, did you receive a 2nd rest break?"
                    value={values.employmentDetails?.secondBreakForHours}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  <RadioControl
                    isRequired
                    requiredError={
                      getIn(errors, "employmentDetails.workedOT") &&
                      getIn(touched, "employmentDetails.workedOT")
                    }
                    propertyName="employmentDetails.workedOT"
                    label="Did you ever work OT?"
                    value={values.employmentDetails?.workedOT}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  <RadioControl
                    isRequired
                    requiredError={
                      getIn(errors, "employmentDetails.classProperOt") &&
                      getIn(touched, "employmentDetails.classProperOt")
                    }
                    propertyName="employmentDetails.classProperOt"
                    label="Were you paid time and a half?"
                    value={values.employmentDetails?.classProperOt}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  <RadioControl
                    isRequired
                    requiredError={
                      getIn(errors, "employmentDetails.reimbursedForOutOfPocket") &&
                      getIn(touched, "employmentDetails.reimbursedForOutOfPocket")
                    }
                    propertyName="employmentDetails.reimbursedForOutOfPocket"
                    label="Were you reimbursed for out-of-pocket expenses? Ex: Personal cell phone, gas"
                    value={values.employmentDetails?.reimbursedForOutOfPocket}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                   <h5 className="mt-3 mb-5">End of Class action Questions</h5>
                </>
              )}
          </div>
        </div>
        <div className="row">
          <RadioControl
            isRequired
            requiredError={
              getIn(errors, "employmentDetails.severance") &&
              getIn(touched, "employmentDetails.severance")
            }
            propertyName="employmentDetails.severance"
            label="Were you offered a severance package?"
            value={values.employmentDetails?.severance}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          {values.employmentDetails?.severance ===
            HelpersService.getEnumKeyByEnumValue(
              RadioOptions,
              RadioOptions.YES
            ) && (
              <RadioControl
                isRequired
                requiredError={
                  getIn(errors, "employmentDetails.severanceAccepted") &&
                  getIn(touched, "employmentDetails.severanceAccepted")
                }
                propertyName="employmentDetails.severanceAccepted"
                label="Did you accept the severance package?"
                value={values.employmentDetails?.severanceAccepted}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            )}
          <RadioControl
            isRequired
            requiredError={
              getIn(errors, "employmentDetails.employed") &&
              getIn(touched, "employmentDetails.employed")
            }
            propertyName="employmentDetails.employed"
            label="Did you find any employment after you were terminated?"
            value={values.employmentDetails?.employed}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          {values.employmentDetails?.employed ===
            HelpersService.getEnumKeyByEnumValue(
              RadioOptions,
              RadioOptions.YES
            ) && (
              <>
                <div className="col-6 mt-3">
                  <TextField
                    required
                    label="Rate of pay at new job:"
                    variant="outlined"
                    name="employmentDetails.newSalary"
                    type="number"
                    onFocus={() => setShrink(true)}
                    onBlur={(e) => {
                      !e.target.value && setShrink(false);
                      handleBlur(e);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className="dollar-adornment"
                        >
                          $
                        </InputAdornment>
                      ),
                      inputProps: { step: "any" },
                    }}
                    InputLabelProps={{
                      shrink: !!values.employmentDetails?.newSalary || shrink,
                    }}
                    sx={{
                      "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
                        transform: "translate(35px, 17px)",
                      },
                    }}
                    value={values.employmentDetails?.newSalary}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-6 mt-3 datepicker-holder">
                  <FormControl required>
                    <DatePicker
                      disableFuture
                      label="Date new employment began: *"
                      value={
                        !!values.employmentDetails?.newJobStartDate
                          ? dayjs(values.employmentDetails?.newJobStartDate)
                          : null
                      }
                      onChange={(date) =>
                        handleChange({
                          target: {
                            value: date,
                            name: "employmentDetails.newJobStartDate",
                          },
                        })
                      }
                    />
                    {dayjs(values.employmentDetails?.newJobStartDate) >
                      dayjs() && (
                        <FormHelperText className="error-message">
                          Must be in the past
                        </FormHelperText>
                      )}
                  </FormControl>
                </div>
              </>
            )}
          {values.employmentDetails?.employed ===
            HelpersService.getEnumKeyByEnumValue(
              RadioOptions,
              RadioOptions.NO
            ) && (
              <>
                <RadioControl
                  isRequired
                  requiredError={
                    getIn(errors, "employmentDetails.workSearching") &&
                    getIn(touched, "employmentDetails.workSearching")
                  }
                  propertyName="employmentDetails.workSearching"
                  label="Are you looking for work?"
                  value={values.employmentDetails?.workSearching}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                {values.employmentDetails?.workSearching === RadioYesOption && (
                  <div className="col-12 mt-3">
                    <TextField
                      required
                      label="Describe what efforts you have made to look for another job?"
                      variant="outlined"
                      name="employmentDetails.workSearchingEfforts"
                      value={values.employmentDetails?.workSearchingEfforts}
                      onChange={handleChange}
                    />
                  </div>
                )}
              </>
            )}
          <RadioControl
            isRequired
            requiredError={
              getIn(errors, "employmentDetails.currentlyRepresented") &&
              getIn(touched, "employmentDetails.currentlyRepresented")
            }
            propertyName="employmentDetails.currentlyRepresented"
            label="Are or were you currently represented by another firm?"
            value={values.employmentDetails?.currentlyRepresented}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          {values.employmentDetails?.currentlyRepresented ===
            HelpersService.getEnumKeyByEnumValue(
              RadioOptions,
              RadioOptions.YES
            ) && (
              <>
                <div className="col-6 mt-3">
                  <TextField
                    label="What is firm name?"
                    variant="outlined"
                    name="employmentDetails.representedByName"
                    value={values.employmentDetails?.representedByName}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-6 mt-3">
                  <TextField
                    label="Why do you want to switch?"
                    variant="outlined"
                    name="employmentDetails.representedWhyChange"
                    value={values.employmentDetails?.representedWhyChange}
                    onChange={handleChange}
                  />
                </div>
              </>
            )}
          <RadioControl
            isRequired
            requiredError={
              getIn(errors, "employmentDetails.filedEeoc") &&
              getIn(touched, "employmentDetails.filedEeoc")
            }
            propertyName="employmentDetails.filedEeoc"
            label="Have you filed a claim with EEOC(Equal Employment Opportunity Commission) or DFEH(Department of Fair Employment & Housing)?"
            value={values.employmentDetails?.filedEeoc}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          {values.employmentDetails?.filedEeoc ===
            HelpersService.getEnumKeyByEnumValue(
              RadioOptions,
              RadioOptions.YES
            ) && (
              <>
                <RadioControl
                  propertyName="employmentDetails.rtsIssued"
                  label="Right-to-Sue letter issued?"
                  value={values.employmentDetails?.rtsIssued}
                  class="col-6 mt-3"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                {values.employmentDetails?.rtsIssued ===
                  HelpersService.getEnumKeyByEnumValue(
                    RadioOptions,
                    RadioOptions.YES
                  ) && (
                    <div className="col-6 mt-3 datepicker-holder">
                      <FormControl required>
                        <DatePicker
                          disableFuture
                          label="Right-to-Sue letter issued when?"
                          value={
                            !!values.employmentDetails?.rtsIssuedWhen
                              ? dayjs(values.employmentDetails?.rtsIssuedWhen)
                              : null
                          }
                          onChange={(date) =>
                            handleChange({
                              target: {
                                value: date,
                                name: "employmentDetails.rtsIssuedWhen",
                              },
                            })
                          }
                        />
                        {dayjs(values.employmentDetails?.rtsIssuedWhen) >
                          dayjs() && (
                            <FormHelperText className="error-message">
                              Must be in the past
                            </FormHelperText>
                          )}
                      </FormControl>
                    </div>
                  )}
              </>
            )}
          {values.employmentDetails?.rtsIssued ===
            HelpersService.getEnumKeyByEnumValue(
              RadioOptions,
              RadioOptions.YES
            ) && (
              <RadioControl
                propertyName="employmentDetails.rtsCopy"
                label="Do you have a copy of the Right-to-Sue to provide if requested by the attorney?"
                value={values.employmentDetails?.rtsCopy}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            )}
          <RadioControl
            addNotSure
            propertyName="employmentDetails.arbitration"
            label="Do you recall if you signed an Arbitration Agreement when you were hired?"
            value={values.employmentDetails?.arbitration}
            helperText="A contract or clause in a contract where you agree to resolving a dispute without filing a lawsuit and going to court"
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          {values.employmentDetails?.arbitration ===
            HelpersService.getEnumKeyByEnumValue(
              RadioOptions,
              RadioOptions.YES
            ) && (
              <RadioControl
                propertyName="employmentDetails.arbitrationCopy"
                label="Do you have a copy?"
                value={values.employmentDetails?.arbitrationCopy}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            )}
        </div>
        <div className="row mt-5">
          <h5 className="title mb-3">Wrongful termination</h5>
          <div className="col-6 mt-3">
            <TextField
              required
              label="Reason given for termination"
              variant="outlined"
              name="employmentDetails.terminationReasonGiven"
              multiline
              value={values.employmentDetails?.terminationReasonGiven}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <div className="col-6 mt-3">
            <TextField
              required
              label="What does employee believe/​think is the real reason for the termination?"
              variant="outlined"
              name="employmentDetails.terminationReasonReal"
              multiline
              value={values.employmentDetails?.terminationReasonReal}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <div className="col-6 mt-3">
            <TextField
              label="Person(s) responsible for termination"
              variant="outlined"
              name="employmentDetails.terminationResponsible"
              value={values.employmentDetails?.terminationResponsible}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <div className="col-6 mt-3">
            <TextField
              label="Immediate Supervisor at time of termination"
              variant="outlined"
              name="employmentDetails.supervisor"
              value={values.employmentDetails?.supervisor}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-3">
            <TextField
              required
              label="Description of Termination & Background Information (Note that the lead must create a timeline of events leading up to the termination)"
              variant="outlined"
              name="employmentDetails.terminationTimeline"
              multiline
              value={values.employmentDetails?.terminationTimeline}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <RadioControl
            isRequired
            requiredError={
              getIn(errors, "employmentDetails.reprimands") &&
              getIn(touched, "employmentDetails.reprimands")
            }
            propertyName="employmentDetails.reprimands"
            label="Did you ever receive any write ups or reprimands?"
            value={values.employmentDetails?.reprimands}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          {values.employmentDetails?.reprimands ===
            HelpersService.getEnumKeyByEnumValue(
              RadioOptions,
              RadioOptions.YES
            ) && (
              <div className="col-12 mt-3">
                <TextField
                  required
                  multiline
                  label="Explain"
                  variant="outlined"
                  name="employmentDetails.reprimandsDetails"
                  value={values.employmentDetails?.reprimandsDetails}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
            )}
          <div className="col-12 mt-3">
            <FormControl
              component="fieldset"
              style={{ display: "flex" }}
              required
            >
              <FormLabel
                component="legend"
                required
                className={
                  getIn(errors, "employmentDetails.contactReasons") &&
                    getIn(touched, "employmentDetails.contactReasons")
                    ? "error-message"
                    : ""
                }
              >
                Reason for contacting JLG Lawyers
              </FormLabel>
              <FormGroup className="flex-row">
                {Object.keys(ContactReason).map((reason) => (
                  <div className="col-6" key={reason}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.employmentDetails?.contactReasons?.includes(
                            ContactReason[reason]
                          )}
                          onChange={(event) => {
                            handleCheckboxChange(
                              event.target.value as ContactReason,
                              "employmentDetails.contactReasons"
                            );
                          }}
                          value={ContactReason[reason]}
                        />
                      }
                      label={ContactReasonDisplayMapping[ContactReason[reason]]}
                    />
                  </div>
                ))}
              </FormGroup>

              {getIn(errors, "employmentDetails.contactReasons") &&
                getIn(touched, "employmentDetails.contactReasons") && (
                  <FormHelperText className="error-message">
                    Required
                  </FormHelperText>
                )}
            </FormControl>
          </div>
          {values.employmentDetails?.contactReasons?.includes(
            ContactReason.DISCRIMINATION
          ) && (
              <>
                <h5 className="title mb-2 mt-3">Discrimination</h5>
                <div>Must be based on or motivated by protected category.</div>
                <div className="col-12 mt-3">
                  <FormControl component="fieldset" style={{ display: "flex" }}>
                    <FormLabel
                      component="legend"
                      required
                      className={
                        getIn(errors, "employmentDetails.protectedCategories") &&
                          getIn(touched, "employmentDetails.protectedCategories")
                          ? "error-message"
                          : ""
                      }
                    >
                      Protected Category
                    </FormLabel>
                    <FormGroup className="flex-row">
                      {Object.keys(ProtectedCategory).map((category) => (
                        <div className="col-6" key={category}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.employmentDetails?.protectedCategories?.includes(
                                  ProtectedCategory[category]
                                )}
                                onChange={(event) => {
                                  handleCheckboxChange(
                                    event.target.value as ProtectedCategory,
                                    "employmentDetails.protectedCategories"
                                  );
                                }}
                                value={ProtectedCategory[category]}
                              />
                            }
                            label={HelpersService.camelToString(
                              ProtectedCategory[category],
                              false,
                              true
                            )}
                          />
                        </div>
                      ))}
                      <div className="col-6" key="other">
                        {values.employmentDetails?.protectedCategories?.includes(
                          ProtectedCategory.OTHER
                        ) && (
                            <TextField
                              required
                              label="Describe Other"
                              variant="outlined"
                              name="employmentDetails.otherProtectedCategory"
                              value={
                                values.employmentDetails?.otherProtectedCategory
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          )}
                      </div>
                    </FormGroup>
                    {getIn(errors, "employmentDetails.protectedCategories") &&
                      getIn(touched, "employmentDetails.protectedCategories") && (
                        <FormHelperText className="error-message">
                          Required
                        </FormHelperText>
                      )}
                  </FormControl>
                </div>
                {values.employmentDetails?.protectedCategories &&
                  values.employmentDetails?.protectedCategories?.length > 0 && (
                    <div className="col-12">
                      <TextField
                        required
                        label="Describe protected category"
                        variant="outlined"
                        name="employmentDetails.protectedCategoryDescription"
                        placeholder="E.g. Black, Asian, Gay, Disabled"
                        value={
                          values.employmentDetails?.protectedCategoryDescription
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                <div className="row">
                  <div className="col-6 mt-3">
                    <TextField
                      required
                      label="Describe discriminatory conduct"
                      variant="outlined"
                      name="employmentDetails.discriminatoryConduct"
                      multiline
                      value={values.employmentDetails?.discriminatoryConduct}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <TextField
                      label="Dates"
                      variant="outlined"
                      name="employmentDetails.discriminatoryConductDates"
                      value={values.employmentDetails?.discriminatoryConductDates}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <TextField
                      label="Who discriminated against the employee (name and position)?"
                      variant="outlined"
                      name="employmentDetails.discriminatedBy"
                      multiline
                      value={values.employmentDetails?.discriminatedBy}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <RadioControl
                  isRequired
                  requiredError={
                    getIn(
                      errors,
                      "employmentDetails.discriminatedWrittenComplains"
                    ) &&
                    getIn(
                      touched,
                      "employmentDetails.discriminatedWrittenComplains"
                    )
                  }
                  propertyName="employmentDetails.discriminatedWrittenComplains"
                  label="Did employee submit any written complaints to the employer?"
                  value={values.employmentDetails?.discriminatedWrittenComplains}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                {values.employmentDetails?.discriminatedWrittenComplains ===
                  RadioYesOption && (
                    <div className="row">
                      <div className="col-6 mt-3">
                        <TextField
                          label="To who?"
                          variant="outlined"
                          name="employmentDetails.discriminatedWroteTo"
                          value={values.employmentDetails?.discriminatedWroteTo}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-6 mt-3 datepicker-holder">
                        <TextField
                          label="Date of written complaint"
                          variant="outlined"
                          name="employmentDetails.discriminatedWroteWhen"
                          value={values.employmentDetails?.discriminatedWroteWhen}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <TextField
                          label="What action was taken?"
                          variant="outlined"
                          name="employmentDetails.discriminatedWroteAction"
                          value={values.employmentDetails?.discriminatedWroteAction}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  )}
                <RadioControl
                  isRequired
                  requiredError={
                    getIn(
                      errors,
                      "employmentDetails.discriminatedOralComplains"
                    ) &&
                    getIn(touched, "employmentDetails.discriminatedOralComplains")
                  }
                  propertyName="employmentDetails.discriminatedOralComplains"
                  label="Did employee submit any oral complaints to the employer?"
                  value={values.employmentDetails?.discriminatedOralComplains}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                {values.employmentDetails?.discriminatedOralComplains ===
                  RadioYesOption && (
                    <div className="row">
                      <div className="col-6 mt-3">
                        <TextField
                          label="To who?"
                          variant="outlined"
                          name="employmentDetails.discriminatedOralTo"
                          value={values.employmentDetails?.discriminatedOralTo}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-6 mt-3 datepicker-holder">
                        <TextField
                          label="Date of oral complaint"
                          variant="outlined"
                          name="employmentDetails.discriminatedOralWhen"
                          value={values.employmentDetails?.discriminatedOralWhen}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <TextField
                          label="What action was taken?"
                          variant="outlined"
                          name="employmentDetails.discriminatedOralAction"
                          value={values.employmentDetails?.discriminatedOralAction}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  )}
                <div className="row mt-2">
                  <RadioControl
                    isRequired
                    requiredError={
                      getIn(errors, "employmentDetails.discriminatedComments") &&
                      getIn(touched, "employmentDetails.discriminatedComments")
                    }
                    propertyName="employmentDetails.discriminatedComments"
                    label="Were there discriminatory comments that were made?"
                    value={values.employmentDetails?.discriminatedComments}
                    class="col-6 mt-3"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  {values.employmentDetails?.discriminatedComments ===
                    RadioYesOption && (
                      <>
                        <div className="col-6 mt-3">
                          <TextField
                            label="Who made those comments?"
                            variant="outlined"
                            name="employmentDetails.discriminatedCommentsBy"
                            value={
                              values.employmentDetails?.discriminatedCommentsBy
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-12 mt-3">
                          <TextField
                            label="What was the worst discriminatory comment/​action ever made to or about employee?"
                            variant="outlined"
                            name="employmentDetails.discriminatedCommentWorst"
                            value={
                              values.employmentDetails?.discriminatedCommentWorst
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-6 mt-3 datepicker-holder">
                          <TextField
                            label="When did that occur?"
                            variant="outlined"
                            name="employmentDetails.discriminatedCommentWorstWhen"
                            value={values.employmentDetails?.discriminatedCommentWorstWhen}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-6 mt-3 datepicker-holder">
                          <TextField
                            label="When was the last discriminatory conduct?"
                            variant="outlined"
                            name="employmentDetails.discriminatedCommentLastWhen"
                            value={values.employmentDetails?.discriminatedCommentLastWhen}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      </>
                    )}
                </div>
                {values.employmentDetails?.protectedCategories?.includes(
                  ProtectedCategory.DISABILITY
                ) && (
                    <div className="col-12 mt-3">
                      <TextField
                        label="If disability discrimination, describe requests for reasonable accommodation"
                        variant="outlined"
                        name="employmentDetails.disabilityAccommodationsRequest"
                        multiline
                        value={
                          values.employmentDetails?.disabilityAccommodationsRequest
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <div>
                        Employee needs to prepare a chronology of all discrimination
                        and complaints made.
                      </div>
                    </div>
                  )}
                <RadioControl
                  propertyName="employmentDetails.medicalDisabilities"
                  label="Do you have any medical conditions or Disabilities that affected your work/​ life?"
                  value={values.employmentDetails?.medicalDisabilities}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                <RadioControl
                  propertyName="employmentDetails.medicalDisabilitiesReported"
                  label="Did you report it to your employer?"
                  value={values.employmentDetails?.medicalDisabilitiesReported}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                <RadioControl
                  propertyName="employmentDetails.medicalDisabilitiesActions"
                  label="Were you ever put on medical leave, special accommodation or work restrictions?"
                  value={values.employmentDetails?.medicalDisabilitiesActions}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </>
            )}
          {values.employmentDetails?.contactReasons?.includes(
            ContactReason.HARASSMENT
          ) && (
              <>
                <h5 className="title mb-2 mt-3">Harassment</h5>
                <div>Must be based on or motivated by protected category.</div>
                <div className="col-12 mt-3">
                  <FormControl component="fieldset" style={{ display: "flex" }}>
                    <FormLabel
                      required
                      component="legend"
                      className={
                        getIn(errors, "employmentDetails.harassmentCategories") &&
                          getIn(touched, "employmentDetails.harassmentCategories")
                          ? "error-message"
                          : ""
                      }
                    >
                      Category
                    </FormLabel>
                    <FormGroup className="flex-row">
                      {Object.keys(HarassmentCategory).map((harassment) => (
                        <div className="col-6" key={harassment}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.employmentDetails?.harassmentCategories?.includes(
                                  HarassmentCategory[harassment]
                                )}
                                onChange={(event) => {
                                  handleCheckboxChange(
                                    event.target.value as HarassmentCategory,
                                    "employmentDetails.harassmentCategories"
                                  );
                                }}
                                value={HarassmentCategory[harassment]}
                              />
                            }
                            label={HarassmentCategory[harassment]}
                          />
                        </div>
                      ))}
                    </FormGroup>
                    {getIn(errors, "employmentDetails.harassmentCategories") &&
                      getIn(
                        touched,
                        "employmentDetails.harassmentCategories"
                      ) && (
                        <FormHelperText className="error-message">
                          Required
                        </FormHelperText>
                      )}
                  </FormControl>
                </div>
                <div className="row">
                  <div className="col-6 mt-3">
                    <TextField
                      required
                      label="Describe harassment conduct"
                      variant="outlined"
                      name="employmentDetails.harassmentConduct"
                      multiline
                      value={values.employmentDetails?.harassmentConduct}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <TextField
                      label="Dates"
                      variant="outlined"
                      name="employmentDetails.harassmentConductDates"
                      value={values.employmentDetails?.harassmentConductDates}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <RadioControl
                  isRequired
                  requiredError={
                    getIn(
                      errors,
                      "employmentDetails.harassmentWrittenComplains"
                    ) &&
                    getIn(touched, "employmentDetails.harassmentWrittenComplains")
                  }
                  propertyName="employmentDetails.harassmentWrittenComplains"
                  label="Did employee submit any written complaints to the employer?"
                  value={values.employmentDetails?.harassmentWrittenComplains}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                {values.employmentDetails?.harassmentWrittenComplains ===
                  RadioYesOption && (
                    <div className="row">
                      <div className="col-6 mt-3">
                        <TextField
                          label="To who?"
                          variant="outlined"
                          name="employmentDetails.harassmentWroteTo"
                          value={values.employmentDetails?.harassmentWroteTo}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-6 mt-3 datepicker-holder">
                        <TextField
                          label="Date of written complaint"
                          variant="outlined"
                          name="employmentDetails.harassmentWroteWhen"
                          value={values.employmentDetails?.harassmentWroteWhen}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />

                      </div>
                      <div className="col-12 mt-3">
                        <TextField
                          label="What action was taken?"
                          variant="outlined"
                          name="employmentDetails.harassmentWroteAction"
                          value={values.employmentDetails?.harassmentWroteAction}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  )}
                <RadioControl
                  isRequired
                  requiredError={
                    getIn(errors, "employmentDetails.harassmentOralComplains") &&
                    getIn(touched, "employmentDetails.harassmentOralComplains")
                  }
                  propertyName="employmentDetails.harassmentOralComplains"
                  label="Did employee submit any oral complaints to the employer?"
                  value={values.employmentDetails?.harassmentOralComplains}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                {values.employmentDetails?.harassmentOralComplains ===
                  RadioYesOption && (
                    <div className="row">
                      <div className="col-6 mt-3">
                        <TextField
                          label="To who?"
                          variant="outlined"
                          name="employmentDetails.harassmentOralTo"
                          value={values.employmentDetails?.harassmentOralTo}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-6 mt-3 datepicker-holder">
                        <TextField
                          label="Date of oral complaint"
                          variant="outlined"
                          name="employmentDetails.harassmentOralWhen"
                          value={values.employmentDetails?.harassmentOralWhen}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <TextField
                          label="What action was taken?"
                          variant="outlined"
                          name="employmentDetails.harassmentOralAction"
                          value={values.employmentDetails?.harassmentOralAction}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  )}
                <div className="row mt-2">
                  <RadioControl
                    propertyName="employmentDetails.harassmentComments"
                    label="Were comments made?"
                    value={values.employmentDetails?.harassmentComments}
                    class="col-6 mt-3"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  {values.employmentDetails?.harassmentComments ===
                    RadioYesOption && (
                      <>
                        <div className="col-6 mt-3">
                          <TextField
                            label="By whom?"
                            variant="outlined"
                            name="employmentDetails.harassmentCommentsBy"
                            value={values.employmentDetails?.harassmentCommentsBy}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-12 mt-3">
                          <TextField
                            required
                            label="What was the worst harassing comment/​action ever made to or about employee?"
                            variant="outlined"
                            name="employmentDetails.harassmentCommentWorst"
                            value={values.employmentDetails?.harassmentCommentWorst}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-6 mt-3 datepicker-holder">
                          <TextField
                            label="When did that occur?"
                            variant="outlined"
                            name="employmentDetails.harassmentCommentWorstWhen"
                            value={values.employmentDetails?.harassmentCommentWorstWhen}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />

                        </div>
                        <div className="col-6 mt-3 datepicker-holder">
                          <TextField
                            label="When was the last harassing conducted?"
                            variant="outlined"
                            name="employmentDetails.harassmentCommentLastWhen"
                            value={values.employmentDetails?.harassmentCommentLastWhen}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      </>
                    )}
                </div>
              </>
            )}
          {values.employmentDetails?.contactReasons?.includes(
            ContactReason.RETALIATION
          ) && (
              <>
                <h5 className="title mb-2 mt-3">Retaliation</h5>
                <div>Must be based on or motivated by protected category.</div>
                <div className="row">
                  <div className="col-8 mt-3">
                    <TextField
                      required
                      label="Describe protected conduct"
                      variant="outlined"
                      name="employmentDetails.protectedConduct"
                      placeholder="E.g. Employee complained about health/safety/something illegal"
                      multiline
                      value={values.employmentDetails?.protectedConduct}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-4 mt-3">
                    <TextField
                      label="Dates"
                      variant="outlined"
                      name="employmentDetails.protectedConductDates"
                      value={values.employmentDetails?.protectedConductDates}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-8 mt-3">
                    <TextField
                      required
                      label="Describe retaliatory conduct"
                      variant="outlined"
                      name="employmentDetails.retaliatoryConduct"
                      placeholder="E.g. How did employer retaliate? Did they get fired or reprimanded? Did they lose wages?"
                      multiline
                      value={values.employmentDetails?.retaliatoryConduct}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-4 mt-3">
                    <TextField
                      label="Dates"
                      variant="outlined"
                      name="employmentDetails.retaliatoryConductDates"
                      value={values.employmentDetails?.retaliatoryConductDates}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-3">
                    <TextField
                      required
                      label="Who retaliated against the employee (name and position)?"
                      variant="outlined"
                      name="employmentDetails.retaliationBy"
                      multiline
                      value={values.employmentDetails?.retaliationBy}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <RadioControl
                  isRequired
                  requiredError={
                    getIn(
                      errors,
                      "employmentDetails.retaliationWrittenComplains"
                    ) &&
                    getIn(
                      touched,
                      "employmentDetails.retaliationWrittenComplains"
                    )
                  }
                  propertyName="employmentDetails.retaliationWrittenComplains"
                  label="Did employee submit any written complaints to the employer?"
                  value={values.employmentDetails?.retaliationWrittenComplains}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                {values.employmentDetails?.retaliationWrittenComplains ===
                  RadioYesOption && (
                    <div className="row">
                      <div className="col-6 mt-3">
                        <TextField
                          label="To who?"
                          variant="outlined"
                          name="employmentDetails.retaliationWroteTo"
                          value={values.employmentDetails?.retaliationWroteTo}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-6 mt-3 datepicker-holder">
                        <TextField
                          label="Date of written complaint"
                          variant="outlined"
                          name="employmentDetails.retaliationWroteWhen"
                          value={values.employmentDetails?.retaliationWroteWhen}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <TextField
                          label="What action was taken?"
                          variant="outlined"
                          name="employmentDetails.retaliationWroteAction"
                          value={values.employmentDetails?.retaliationWroteAction}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <div>Employee needs to prepare a chronology</div>
                      </div>
                    </div>
                  )}
                <RadioControl
                  isRequired
                  requiredError={
                    getIn(errors, "employmentDetails.retaliationOralComplains") &&
                    getIn(touched, "employmentDetails.retaliationOralComplains")
                  }
                  propertyName="employmentDetails.retaliationOralComplains"
                  label="Did employee submit any oral complaints to the employer?"
                  value={values.employmentDetails?.retaliationOralComplains}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                {values.employmentDetails?.retaliationOralComplains ===
                  RadioYesOption && (
                    <div className="row">
                      <div className="col-6 mt-3">
                        <TextField
                          label="To who?"
                          variant="outlined"
                          name="employmentDetails.retaliationOralTo"
                          value={values.employmentDetails?.retaliationOralTo}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-6 mt-3 datepicker-holder">
                        <TextField
                          label="Date of oral complaint"
                          variant="outlined"
                          name="employmentDetails.retaliationOralWhen"
                          value={values.employmentDetails?.retaliationOralWhen}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <TextField
                          label="What action was taken?"
                          variant="outlined"
                          name="employmentDetails.retaliationOralAction"
                          value={values.employmentDetails?.retaliationOralAction}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <div>Employee needs to prepare a chronology</div>
                      </div>
                    </div>
                  )}
              </>
            )}
          {values.employmentDetails?.contactReasons?.includes(
            ContactReason.BREACH_OF_CONTRACT
          ) && (
              <>
                <h5 className="title mb-2 mt-3">Breach of Contract</h5>
                <div className="row">
                  <RadioControl
                    propertyName="employmentDetails.breachWrittenContract"
                    label="Written contract?"
                    value={values.employmentDetails?.breachWrittenContract}
                    class="col-6 mt-3"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  <RadioControl
                    propertyName="employmentDetails.breachOralContract"
                    label="Oral contract?"
                    value={values.employmentDetails?.breachOralContract}
                    class="col-6 mt-3"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </div>
                <div>Fax or e-mail a copy</div>
                <div className="row">
                  <div className="col-12 mt-3">
                    <TextField
                      label="Describe the terms"
                      variant="outlined"
                      name="employmentDetails.breachTerms"
                      multiline
                      value={values.employmentDetails?.breachTerms}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <TextField
                      required
                      multiline
                      label="How was the contract breached?"
                      variant="outlined"
                      name="employmentDetails.breachDetails"
                      value={values.employmentDetails?.breachDetails}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6 mt-3 datepicker-holder">
                    <FormControl>
                      <DatePicker
                        disableFuture
                        label="Date"
                        value={
                          !!values.employmentDetails?.breachDate
                            ? dayjs(values.employmentDetails?.breachDate)
                            : null
                        }
                        onChange={(date) =>
                          handleChange({
                            target: {
                              value: date,
                              name: "employmentDetails.breachDate",
                            },
                          })
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="col-12 mt-3">
                    <TextField
                      label="Parties to the contract (Include job titles):"
                      variant="outlined"
                      name="employmentDetails.breachParties"
                      value={values.employmentDetails?.breachParties}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </>
            )}
          {values.employmentDetails?.contactReasons?.includes(
            ContactReason.CLASS_ACTION
          ) && (
              <>
                <h5 className="title mb-2 mt-3">
                  Class Action - Problem with wages/getting breaks
                </h5>
                <div className="row">
                  <RadioControl
                    isRequired
                    requiredError={
                      getIn(errors, "employmentDetails.classProperOt") &&
                      getIn(touched, "employmentDetails.classProperOt")
                    }
                    propertyName="employmentDetails.classProperOt"
                    label="Did you receive proper OT pay?"
                    value={values.employmentDetails?.classProperOt}
                    class="col-6 mt-3"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  {values.employmentDetails?.classProperOt ===
                    HelpersService.getEnumKeyByEnumValue(
                      RadioOptions,
                      RadioOptions.NO
                    ) && (
                      <div className="col-6 mt-3">
                        <TextField
                          label="Explain (OT Pay)"
                          variant="outlined"
                          name="employmentDetails.classOtExplain"
                          multiline
                          value={values.employmentDetails?.classOtExplain}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    )}
                </div>
                <div className="row">
                  <RadioControl
                    isRequired
                    requiredError={
                      getIn(errors, "employmentDetails.classMealPeriod") &&
                      getIn(touched, "employmentDetails.classMealPeriod")
                    }
                    propertyName="employmentDetails.classMealPeriod"
                    label="Did you receive meal breaks before the 5th hour?"
                    value={values.employmentDetails?.classMealPeriod}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  <RadioControl
                    isRequired
                    requiredError={
                      getIn(errors, "employmentDetails.classRestPeriod") &&
                      getIn(touched, "employmentDetails.classRestPeriod")
                    }
                    propertyName="employmentDetails.classRestPeriod"
                    label="Did you receive rest breaks before the 4th hour?"
                    value={values.employmentDetails?.classRestPeriod}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  {((!!values.employmentDetails?.classMealPeriod &&
                    RadioOptions[values.employmentDetails?.classMealPeriod] ===
                    RadioOptions.NO) ||
                    (!!values.employmentDetails?.classMealCanLeave &&
                      RadioOptions[
                      values.employmentDetails?.classMealCanLeave
                      ] === RadioOptions.NO) ||
                    (!!values.employmentDetails?.classRestPeriod &&
                      RadioOptions[values.employmentDetails?.classRestPeriod] ===
                      RadioOptions.NO)) && (
                      <div className="col-12 mt-3">
                        <TextField
                          label="Explain"
                          variant="outlined"
                          name="employmentDetails.classExplainProblem"
                          multiline
                          value={values.employmentDetails?.classExplainProblem}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    )}
                </div>
                <div className="row">
                  <div className="col-12 mt-3">
                    <FormControl component="fieldset" style={{ display: "flex" }}>
                      <FormLabel
                        required
                        component="legend"
                        className={
                          getIn(errors, "employmentDetails.classPaidType") &&
                            getIn(touched, "employmentDetails.classPaidType")
                            ? "error-message"
                            : ""
                        }
                      >
                        How were you paid:
                      </FormLabel>
                      <FormGroup className="flex-row">
                        {Object.keys(EmployeePaidType).map((opt) => (
                          <div className="col-6" key={opt}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.employmentDetails?.classPaidType?.includes(
                                    EmployeePaidType[opt]
                                  )}
                                  onChange={(event) => {
                                    handleCheckboxChange(
                                      event.target.value as EmployeePaidType,
                                      "employmentDetails.classPaidType"
                                    );
                                  }}
                                  value={EmployeePaidType[opt]}
                                />
                              }
                              label={
                                EmployeePaidDisplayMapping[EmployeePaidType[opt]]
                              }
                            />
                          </div>
                        ))}
                        <div className="col-6" key="other">
                          {values.employmentDetails?.classPaidType?.includes(
                            EmployeePaidType.OTHER
                          ) && (
                              <TextField
                                required
                                label="Describe Other"
                                variant="outlined"
                                name="employmentDetails.classPaidOther"
                                value={values.employmentDetails?.classPaidOther}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            )}
                        </div>
                      </FormGroup>
                      {getIn(errors, "employmentDetails.classPaidType") &&
                        getIn(touched, "employmentDetails.classPaidType") && (
                          <FormHelperText className="error-message">
                            Required
                          </FormHelperText>
                        )}
                    </FormControl>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 mt-3">
                    <TextField
                      required
                      label="Hours Typically worked in a week"
                      variant="outlined"
                      name="employmentDetails.classHoursWorkedWeek"
                      value={values.employmentDetails?.classHoursWorkedWeek}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <TextField
                      required
                      label="Regular days off"
                      variant="outlined"
                      name="employmentDetails.classDayOff"
                      value={values.employmentDetails?.classDayOff}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <TextField
                      required
                      label="Hours Typically worked in a day"
                      variant="outlined"
                      name="employmentDetails.classHoursWorkedDay"
                      value={values.employmentDetails?.classHoursWorkedDay}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <TextField
                      required
                      label="Regular schedule"
                      variant="outlined"
                      name="employmentDetails.classSchedule"
                      placeholder="What days per week did you work? Start time, end time?"
                      multiline
                      value={values.employmentDetails?.classSchedule}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                  <RadioControl
                    isRequired
                    requiredError={
                      getIn(errors, "employmentDetails.classWorkTravel") &&
                      getIn(touched, "employmentDetails.classWorkTravel")
                    }
                    propertyName="employmentDetails.classWorkTravel"
                    label="Did you travel for work?"
                    value={values.employmentDetails?.classWorkTravel}
                    class="col-6 mt-3"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  {values.employmentDetails?.classWorkTravel ===
                    RadioYesOption && (
                      <RadioControl
                        isRequired
                        requiredError={
                          getIn(
                            errors,
                            "employmentDetails.classTravelReimbursed"
                          ) &&
                          getIn(touched, "employmentDetails.classTravelReimbursed")
                        }
                        propertyName="employmentDetails.classTravelReimbursed"
                        label="Were you reimbursed for the mileage?"
                        value={values.employmentDetails?.classTravelReimbursed}
                        class="col-6 mt-3"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    )}
                </div>
                <div className="row">
                  <div className="col-12 mt-3">
                    <FormControl component="fieldset" style={{ display: "flex" }}>
                      <FormLabel
                        required
                        component="legend"
                        className={
                          getIn(errors, "employmentDetails.classPaidWithheld") &&
                            getIn(touched, "employmentDetails.classPaidWithheld")
                            ? "error-message"
                            : ""
                        }
                      >
                        Did your company withheld any of these payments from you:
                      </FormLabel>
                      <FormGroup className="flex-row">
                        {Object.keys(PaidWithheld).map((opt) => (
                          <div className="col-6" key={opt}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.employmentDetails?.classPaidWithheld?.includes(
                                    PaidWithheld[opt]
                                  )}
                                  onChange={(event) => {
                                    handleCheckboxChange(
                                      event.target.value as PaidWithheld,
                                      "employmentDetails.classPaidWithheld"
                                    );
                                  }}
                                  value={PaidWithheld[opt]}
                                />
                              }
                              label={PaidTimeDisplayMapping[PaidWithheld[opt]]}
                            />
                          </div>
                        ))}
                        <div className="col-6" key="other">
                          {values.employmentDetails?.classPaidWithheld?.includes(
                            PaidWithheld.OTHER
                          ) && (
                              <TextField
                                required
                                label="Describe Other"
                                variant="outlined"
                                name="employmentDetails.classPaidWithheldOther"
                                value={
                                  values.employmentDetails?.classPaidWithheldOther
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            )}
                        </div>
                      </FormGroup>
                      {getIn(errors, "employmentDetails.classPaidWithheld") &&
                        getIn(touched, "employmentDetails.classPaidWithheld") && (
                          <FormHelperText className="error-message">
                            Required
                          </FormHelperText>
                        )}
                    </FormControl>
                  </div>
                </div>
                <RadioControl
                  propertyName="employmentDetails.classReceivedTips"
                  label="Did you received tips?"
                  value={values.employmentDetails?.classReceivedTips}
                  class="col-6 mt-3"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                {values.employmentDetails?.classReceivedTips ===
                  HelpersService.getEnumKeyByEnumValue(
                    RadioOptions,
                    RadioOptions.YES
                  ) && (
                    <div className="row">
                      <RadioControl
                        propertyName="employmentDetails.classGratuitiesPool"
                        label="Were the gratuities in a tip pool?"
                        value={values.employmentDetails?.classGratuitiesPool}
                        class="col-6 mt-3"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                      <RadioControl
                        propertyName="employmentDetails.classManagersInPool"
                        label="Were the managers a part of the pool?"
                        value={values.employmentDetails?.classManagersInPool}
                        class="col-6 mt-3"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </div>
                  )}
                <div className="row">
                  <RadioControl
                    isRequired
                    requiredError={
                      getIn(errors, "employmentDetails.classVacationsCarried") &&
                      getIn(touched, "employmentDetails.classVacationsCarried")
                    }
                    propertyName="employmentDetails.classVacationsCarried"
                    label="If employer has a vacation plan, is unused vacation carried over year-to-year?"
                    value={values.employmentDetails?.classVacationsCarried}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  <RadioControl
                    isRequired
                    requiredError={
                      getIn(
                        errors,
                        "employmentDetails.classUnusedVacationPaid"
                      ) &&
                      getIn(touched, "employmentDetails.classUnusedVacationPaid")
                    }
                    propertyName="employmentDetails.classUnusedVacationPaid"
                    label="If employee quit or was terminated, were they paid out for all unused vacation time?"
                    value={values.employmentDetails?.classUnusedVacationPaid}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  <RadioControl
                    isRequired
                    requiredError={
                      getIn(
                        errors,
                        "employmentDetails.classTerminationDayPaid"
                      ) &&
                      getIn(touched, "employmentDetails.classTerminationDayPaid")
                    }
                    propertyName="employmentDetails.classTerminationDayPaid"
                    label="If employee was terminated, were they paid all wages on the day of termination?"
                    value={values.employmentDetails?.classTerminationDayPaid}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  <RadioControl
                    isRequired
                    requiredError={
                      getIn(
                        errors,
                        "employmentDetails.classTerminatedWagesPaid"
                      ) &&
                      getIn(touched, "employmentDetails.classTerminatedWagesPaid")
                    }
                    propertyName="employmentDetails.classTerminatedWagesPaid"
                    label="If employee resigned, were they paid all wages within 72 hours of last work day?"
                    value={values.employmentDetails?.classTerminatedWagesPaid}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </div>
              </>
            )}
        </div>

        <div className="row mt-5">
          <h5 className="title mb-2">Workplace Injury</h5>
          <div>
            Fill out below only if the employee ever experienced any workplace
            injury.
          </div>
          <RadioControl
            isRequired
            requiredError={
              getIn(errors, "employmentDetails.hadInjuries") &&
              getIn(touched, "employmentDetails.hadInjuries")
            }
            propertyName="employmentDetails.hadInjuries"
            label="Did you experience any (physical or mental) injuries while employed for this company?"
            value={values.employmentDetails?.hadInjuries}
            handleBlur={handleBlur}
            handleChange={(e) => {
              handleChange(e);
              setFieldValue("employmentDetails.interestedInLawyer", null);
            }}
          />
          {RadioOptions[
            values.employmentDetails?.hadInjuries as RadioOptions
          ] === RadioOptions.YES && (
              <>
                <div className="col-12 mt-3">
                  <TextField
                    label="Explain"
                    variant="outlined"
                    name="employmentDetails.injuriesDescription"
                    value={values.employmentDetails?.injuriesDescription}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <RadioControl
                  isRequired
                  requiredError={
                    getIn(errors, "employmentDetails.currentlyRepresentedWC") &&
                    getIn(touched, "employmentDetails.currentlyRepresentedWC")
                  }
                  propertyName="employmentDetails.currentlyRepresentedWC"
                  label="Are you currently represented by a WC attorney?"
                  value={values.employmentDetails?.currentlyRepresentedWC}
                  handleBlur={handleBlur}
                  handleChange={(e) => {
                    handleChange(e);
                    setFieldValue("employmentDetails.interestedInLawyer", null);
                  }}
                />
                {RadioOptions[
                  values.employmentDetails?.currentlyRepresentedWC as RadioOptions
                ] === RadioOptions.YES && (
                    <RadioControl
                      propertyName="employmentDetails.lookingToChangeWC"
                      label="Are you looking to change your attorney?"
                      value={values.employmentDetails?.lookingToChangeWC}
                      handleBlur={handleBlur}
                      handleChange={(e) => {
                        handleChange(e);
                        setFieldValue("employmentDetails.interestedInLawyer", null);
                      }}
                    />
                  )}
                {(RadioOptions[
                  values.employmentDetails?.currentlyRepresentedWC as RadioOptions
                ] === RadioOptions.NO ||
                  RadioOptions[
                  values.employmentDetails?.lookingToChangeWC as RadioOptions
                  ] === RadioOptions.YES) && (
                    <RadioControl
                      propertyName="employmentDetails.interestedInLawyer"
                      label="Would you be interested in being contacted by our award winning WC Attorney and find out how much your potential compensation is?"
                      value={values.employmentDetails?.interestedInLawyer}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  )}
                {RadioOptions[
                  values.employmentDetails?.interestedInLawyer as RadioOptions
                ] === RadioOptions.YES && (
                    <>
                      <RadioControl
                        propertyName="employmentDetails.hadPreviousCase"
                        label="Did you had previous worker’s compensation cases?"
                        value={values.employmentDetails?.hadPreviousCase}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                      {!!values.employmentDetails?.hadPreviousCase &&
                        RadioOptions[values.employmentDetails?.hadPreviousCase] ===
                        RadioOptions.YES && (
                          <div className="col-12 mt-4">
                            <TextField
                              label="What part of your body was previously injured?"
                              variant="outlined"
                              name="employmentDetails.previousCaseInjury"
                              value={values.employmentDetails?.previousCaseInjury}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                        )}
                      <h5 className="title mb-2 mt-2">WC Questionnaire</h5>
                      <div className="col-12 mt-3">
                        <MapsAutocomplete
                          value={values.employmentDetails?.address ?? ""}
                          label="Lead Address"
                          onChange={(value) => setFieldValue("employmentDetails.address", value)}
                        />
                      </div>
                      <RadioControl
                        propertyName="employmentDetails.employedCA"
                        label="Have you been employed or are you currently employed in the state of CA?"
                        value={values.employmentDetails?.employedCA}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                      <div className="col-12 mt-3">
                        <FormControl required>
                          <FormLabel
                            id="injuryType-label"
                            className={
                              getIn(errors, "employmentDetails.injuryType") &&
                                getIn(touched, "employmentDetails.injuryType")
                                ? "error-message"
                                : ""
                            }
                          >
                            Is your injury physical or mental?
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="injuryType-label"
                            name="employmentDetails.injuryType"
                            value={values.employmentDetails?.injuryType}
                            onChange={handleChange}
                          >
                            {Object.keys(InjuryType).map((key) => {
                              return (
                                <FormControlLabel
                                  key={key}
                                  value={key}
                                  control={<Radio />}
                                  label={InjuryType[key]}
                                />
                              );
                            })}
                          </RadioGroup>
                          {getIn(errors, "employmentDetails.injuryType") &&
                            getIn(touched, "employmentDetails.injuryType") && (
                              <FormHelperText className="error-message">
                                Required
                              </FormHelperText>
                            )}
                        </FormControl>
                      </div>
                      <RadioControl
                        propertyName="employmentDetails.havePain"
                        label="Do you have pain in any part of your body while working at your job?"
                        value={values.employmentDetails?.havePain}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                      {values.employmentDetails?.havePain === RadioYesOption && (
                        <TextField
                          label="Please provide details - describe the pain"
                          variant="outlined"
                          multiline
                          name="employmentDetails.painDescription"
                          value={values.employmentDetails?.painDescription}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      )}
                      <RadioControl
                        propertyName="employmentDetails.injuryReported"
                        label="Have you ever reported your injury to a supervisor?"
                        value={values.employmentDetails?.injuryReported}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                      {values.employmentDetails?.injuryReported ===
                        RadioYesOption && (
                          <div className="row">
                            <RadioControl
                              propertyName="employmentDetails.writtenComplains"
                              label="Did the employee submit any written complaints to the employer?"
                              value={values.employmentDetails?.writtenComplains}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                            />

                            {values.employmentDetails?.writtenComplains ===
                              RadioYesOption && (
                                <div className="row m-0">
                                  <div className="col-6">
                                    <TextField
                                      label="To who?"
                                      variant="outlined"
                                      name="employmentDetails.writtenTo"
                                      value={values.employmentDetails?.writtenTo}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div className="col-6 datepicker-holder">
                                    <TextField
                                      label="Date of written complaint"
                                      variant="outlined"
                                      name="employmentDetails.writtenWhen"
                                      value={values.employmentDetails?.writtenWhen}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                              )}
                            <RadioControl
                              propertyName="employmentDetails.oralComplains"
                              label="Did the employee submit any oral complaints to the employer?"
                              value={values.employmentDetails?.oralComplains}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                            />
                            {values.employmentDetails?.oralComplains ===
                              RadioYesOption && (
                                <div className="row m-0">
                                  <div className="col-6">
                                    <TextField
                                      label="To who?"
                                      variant="outlined"
                                      name="employmentDetails.oralTo"
                                      value={values.employmentDetails?.oralTo}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div className="col-6 datepicker-holder">
                                    <TextField
                                      label="Date of oral complaint"
                                      variant="outlined"
                                      name="employmentDetails.oralWhen"
                                      value={values.employmentDetails?.oralWhen}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                      <RadioControl
                        propertyName="employmentDetails.treatmentReceived"
                        label="Have you ever received medical treatment, through work or by yourself, for a work-related injury?"
                        value={values.employmentDetails?.treatmentReceived}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                      {values.employmentDetails?.treatmentReceived ===
                        RadioYesOption && (
                          <TextField
                            multiline
                            label="Please describe the medical treatment and procedures"
                            variant="outlined"
                            name="employmentDetails.treatmentDetails"
                            value={values.employmentDetails?.treatmentDetails}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        )}
                      <RadioControl
                        isRequired
                        requiredError={
                          getIn(errors, "employmentDetails.hasInsurance") &&
                          getIn(touched, "employmentDetails.hasInsurance")
                        }
                        addNotSure
                        propertyName="employmentDetails.hasInsurance"
                        label="Does your employer have worker's compensation insurance?"
                        value={values.employmentDetails?.hasInsurance}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                      <RadioControl
                        isRequired
                        requiredError={
                          getIn(errors, "employmentDetails.paycheckStubs") &&
                          getIn(touched, "employmentDetails.paycheckStubs")
                        }
                        propertyName="employmentDetails.paycheckStubs"
                        label="Do you receive paycheck stubs?"
                        value={values.employmentDetails?.paycheckStubs}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                      <RadioControl
                        isRequired
                        requiredError={
                          getIn(
                            errors,
                            "employmentDetails.workersClaimReprimands"
                          ) &&
                          getIn(touched, "employmentDetails.workersClaimReprimands")
                        }
                        propertyName="employmentDetails.workersClaimReprimands"
                        label="Have you received any reprimands before you considered filing a worker's compensation claim?"
                        value={values.employmentDetails?.workersClaimReprimands}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                      {values.employmentDetails?.workersClaimReprimands ===
                        RadioYesOption && (
                          <TextField
                            multiline
                            label="Explain"
                            variant="outlined"
                            name="employmentDetails.workersClaimReprimandsDetails"
                            value={
                              values.employmentDetails?.workersClaimReprimandsDetails
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        )}
                    </>
                  )}
              </>
            )}
        </div>
        <legend>
          <div className="row mt-5">
            <h5 className="title">Files</h5>
            {(props.userRoles?.includes(ApplicationRole.ADMIN) ||
              props.userRoles?.includes(ApplicationRole.STAFF)) && (
                <div>
                  Maximum file size is 100MB. Allowed file types are limited, if
                  you cannot upload file that you need, please contact admin.
                </div>
              )}
            <Dropzone
              accept={{
                "application/pdf": [],
                "image/*": [],
                "video/*": [],
                "application/msword": [],
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                  [],
                "application/vnd.ms-excel": [],
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  [],
              }}
              disabled={
                !props.userRoles?.includes(ApplicationRole.ADMIN) &&
                !props.userRoles?.includes(ApplicationRole.STAFF)
              }
              onDrop={(acceptedFiles, fileRejections) =>
                handleFilesUpdate(acceptedFiles)
              }
              minSize={0}
              maxSize={maxFileSize}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
                fileRejections,
              }) => {
                const isFileTooLarge =
                  fileRejections.length > 0 &&
                  fileRejections[0].file.size > maxFileSize;
                return (
                  <section>
                    <>
                      {(props.userRoles?.includes(ApplicationRole.ADMIN) ||
                        props.userRoles?.includes(ApplicationRole.STAFF)) && (
                          <div {...getRootProps()} className="dropzone">
                            <input {...getInputProps()} />
                            {!isDragActive &&
                              "Click to select files or drag and drop"}
                            {isDragActive && !isDragReject && "Drop file"}
                            {isDragReject && "File type not accepted"}
                            {isFileTooLarge && (
                              <div className="text-danger ms-2">
                                File that you tries to upload is too large.
                              </div>
                            )}
                          </div>
                        )}
                      {files.map((fileObj) => {
                        const { key, file, url } = fileObj;
                        return (
                          <div key={key}>
                            {file ? (
                              <div>
                                {file.name}
                                {(props.userRoles?.includes(
                                  ApplicationRole.ADMIN
                                ) ||
                                  props.userRoles?.includes(
                                    ApplicationRole.STAFF
                                  )) && (
                                    <Button
                                      className="ms-2"
                                      variant="contained"
                                      color="error"
                                      size="small"
                                      onClick={() => handleFileRemove(key)}
                                    >
                                      Remove
                                    </Button>
                                  )}
                              </div>
                            ) : (
                              <div className="mt-2">
                                <a
                                  href={url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {key}
                                </a>
                                {(props.userRoles?.includes(
                                  ApplicationRole.ADMIN
                                ) ||
                                  props.userRoles?.includes(
                                    ApplicationRole.STAFF
                                  )) && (
                                    <Button
                                      className="ms-2"
                                      variant="contained"
                                      color="error"
                                      size="small"
                                      onClick={() => handleFileRemove(key)}
                                    >
                                      Remove
                                    </Button>
                                  )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </>
                  </section>
                );
              }}
            </Dropzone>
          </div>
        </legend>
        <div className="row mt-3">
          <h5>Please rate this lead:</h5>
          <span className="width-content">
            How trustworthy/credible did you asses the lead to be?
          </span>
          <Rating
            className="width-content"
            name="credibility"
            value={values.credibility}
            onChange={(e, newValue) =>
              setFieldValue("credibility", !!newValue ? +newValue : 0)
            }
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <h5>Intake Comments</h5>

            <TextField
              label="Comments"
              variant="outlined"
              name="csNotes"
              multiline
              value={values.csNotes}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </div>
      </fieldset>
      <div className="row mt-5 button-holder">
        {!!values.status &&
          props.isEdit &&
          values.status !== AdminLeadStatus.DRAFT && (
            <>
              <Button
                variant={
                  Object.keys(errors).filter((error) =>
                    Object.keys(touched).includes(error)
                  ).length > 0
                    ? "contained"
                    : "outlined"
                }
                color={
                  Object.keys(errors).filter((error) =>
                    Object.keys(touched).includes(error)
                  ).length > 0
                    ? "errorColor"
                    : "primary"
                }
                type="submit"
                className="submit-btn"
                onClick={() => {
                  console.log(errors);
                }}
              >
                Save changes
              </Button>
              {Object.keys(errors).filter((error) =>
                Object.keys(touched).includes(error)
              ).length > 0 && (
                  <div className="error-message">
                    Please fix errors in the fields above
                  </div>
                )}
            </>
          )}
        {(!values.status || values.status === AdminLeadStatus.DRAFT) && (
          <div className="row mb-5">
            <div className="width-content">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  console.log(errors);
                }}
              >
                Submit
              </Button>
              {!!errors && Object.keys(errors).length > 0 && (
                <div className="error-message">
                  Errors in the form above. Click submit to display them.
                </div>
              )}
            </div>
            <div className="width-content">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleAddIncomplete()}
              >
                Incomplete
              </Button>
            </div>
          </div>
        )}
      </div>
    </form>
  );
}

var resetFormWith;
EmploymentForm.resetFormWith = (value?) => {
  resetFormWith(value);
};
var updateUserData;
EmploymentForm.updateUserData = (value) => {
  updateUserData(value);
};
