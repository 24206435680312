/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssignTaskRequest } from '../models/AssignTaskRequest';
import type { ContactData } from '../models/ContactData';
import type { MatterPreviewResponse } from '../models/MatterPreviewResponse';
import type { ReportModel } from '../models/ReportModel';
import type { ReportRequest } from '../models/ReportRequest';
import type { TaskPreviewResponse } from '../models/TaskPreviewResponse';
import type { UsersModel } from '../models/UsersModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ClioService {

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static getApiClioInitiateOauth(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Clio/initiate-oauth',
        });
    }

    /**
     * @param code 
     * @returns any Success
     * @throws ApiError
     */
    public static getApiClioOauthCallback(
code?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Clio/oauth-callback',
            query: {
                'code': code,
            },
        });
    }

    /**
     * @param name 
     * @returns MatterPreviewResponse Success
     * @throws ApiError
     */
    public static getApiClioMatters(
name?: string,
): CancelablePromise<Array<MatterPreviewResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Clio/matters',
            query: {
                'name': name,
            },
        });
    }

    /**
     * @param name 
     * @returns TaskPreviewResponse Success
     * @throws ApiError
     */
    public static getApiClioTaskTypes(
name?: string,
): CancelablePromise<Array<TaskPreviewResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Clio/taskTypes',
            query: {
                'name': name,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiClioAssignTask(
requestBody?: AssignTaskRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Clio/assignTask',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param matterId 
     * @returns TaskPreviewResponse Success
     * @throws ApiError
     */
    public static getApiClioMattersTasks(
matterId: number,
): CancelablePromise<Array<TaskPreviewResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Clio/matters/{matterId}/tasks',
            path: {
                'matterId': matterId,
            },
        });
    }

    /**
     * @param matterId 
     * @returns TaskPreviewResponse Success
     * @throws ApiError
     */
    public static getApiClioMattersCurrentTasks(
matterId: number,
): CancelablePromise<Array<TaskPreviewResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Clio/matters/{matterId}/currentTasks',
            path: {
                'matterId': matterId,
            },
        });
    }

    /**
     * @param matterId 
     * @returns ContactData Success
     * @throws ApiError
     */
    public static getApiClioMattersContacts(
matterId: number,
): CancelablePromise<Array<ContactData>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Clio/matters/{matterId}/contacts',
            path: {
                'matterId': matterId,
            },
        });
    }

    /**
     * @returns ReportModel Success
     * @throws ApiError
     */
    public static getApiClioEmptyMattersReport(): CancelablePromise<Array<ReportModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Clio/emptyMattersReport',
        });
    }

    /**
     * @param requestBody 
     * @returns ReportModel Success
     * @throws ApiError
     */
    public static postApiClioUserReport(
requestBody?: ReportRequest,
): CancelablePromise<Array<ReportModel>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Clio/userReport',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param removeDisabled 
     * @returns UsersModel Success
     * @throws ApiError
     */
    public static getApiClioClioUsers(
removeDisabled?: boolean,
): CancelablePromise<Array<UsersModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Clio/clioUsers',
            query: {
                'removeDisabled': removeDisabled,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getApiClioAddTaskWebhook(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Clio/addTaskWebhook',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getApiClioAddMatterWebhook(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Clio/addMatterWebhook',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getApiClioDeleteWebhooks(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Clio/deleteWebhooks',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiClioWebhookMatterCommunicationCreation(
requestBody?: any,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Clio/webhook/matterCommunicationCreation',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiClioWebhookTaskCompletion(
requestBody?: any,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Clio/webhook/taskCompletion',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiClioWebhookTaskDueChange(
requestBody?: any,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Clio/webhook/taskDueChange',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiClioWebhookTaskAssigneeChange(
requestBody?: any,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Clio/webhook/taskAssigneeChange',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiClioWebhookMatter(
requestBody?: any,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Clio/webhook/matter',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiClioWebhookMatterCustomFieldsUpdated(
requestBody?: any,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Clio/webhook/matterCustomFieldsUpdated',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

}
