export const START_SPINNER = "START_SPINNER";
export const STOP_SPINNER = "STOP_SPINNER";

export const startSpinner = () => {
  return {
    type: START_SPINNER,
  };
};

export const stopSpinner = () => {
  return {
    type: STOP_SPINNER,
  };
};