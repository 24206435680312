import MenuItem from "@mui/material/MenuItem/MenuItem";
import Select from "@mui/material/Select/Select";
import { useEffect, useState } from "react";
import { Category } from "../../../api/models/Category";
import LeadForm from "./LeadDetails/LeadForm";
import "./Leads.scss";
import {
  LeadType,
  ReasonForNAEmpDisplayMapping,
  ReasonForPIEmpDisplayMapping,
} from "./shared/Constants";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { HelpersService } from "../../../services/Helpers";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button/Button";
import {
  AdminLeadStatus,
  ApplicationRole,
  ArchiveRequest,
  ClientSearchRequest,
  HubspotService,
  LeadsService,
  ReasonForNAEmp,
  ReasonForNAPI,
} from "../../../api";
import EmploymentForm from "./LeadDetails/EmploymentForm";
import PersonalInjuryForm from "./LeadDetails/PersonalInjuryForm";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  startSpinner,
  stopSpinner,
} from "../../../redux/actions/spinnerActions";
import WorkersCompForm from "./LeadDetails/WorkersCompForm";
const MySwal = withReactContent(Swal);

export default function AddLead() {
  const [displayedCategories, setDisplayedCategories] = useState<string[]>([
    HelpersService.getEnumKeyByEnumValue(Category, Category.EMPLOYMENT),
    HelpersService.getEnumKeyByEnumValue(Category, Category.PERSONAL_INJURY),
    HelpersService.getEnumKeyByEnumValue(Category, Category.WORKERS_COMPENSATION),
    HelpersService.getEnumKeyByEnumValue(Category, Category.CLASS_ACTION),
  ]);

  const [selectedCategory, setSelectedCategory] = useState<Category>(
    HelpersService.getEnumKeyByEnumValue(Category, Category.EMPLOYMENT)
  );
  const [email, setEmail] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [openNADialog, setOpenNADialog] = useState(false);

  const [selectedEmpReason, setSelectedEmpReason] = useState<ReasonForNAEmp>();
  const [selectedPiReason, setSelectedPiReason] = useState<ReasonForNAPI>();
  const [emailFound, setEmailFound] = useState<boolean>(false);

  const user = useSelector((state: any) => state.user?.user);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const checkIfPageRefreshed = () => {
  //     const navigationEntries = window.performance.getEntriesByType('navigation');
  //     if (navigationEntries.length > 0 && navigationEntries[0] instanceof PerformanceNavigationTiming) {
  //       const navigationType = navigationEntries[0].type;
  //       if (navigationType === 'reload') {
  //         console.log('Page was refreshed');
  //         // Your logic for a page refresh
  //       } else {
  //         console.log('Page was not refreshed');
  //         // Your logic for other navigation types
  //       }
  //     }
  //   };

  //   checkIfPageRefreshed();
  // }, []);
  
  useEffect(() => {
    setSelectedCategory(
      HelpersService.getEnumKeyByEnumValue(Category, Category.EMPLOYMENT)
    );

    // if (user.roles?.includes(ApplicationRole.STAFF)) {
    // switch (user.practiceArea) {
    //   case Category.EMPLOYMENT: {
    //     setDisplayedCategories([
    //       HelpersService.getEnumKeyByEnumValue(Category, Category.EMPLOYMENT),
    //     ]);
    //     setSelectedCategory(
    //       HelpersService.getEnumKeyByEnumValue(Category, Category.EMPLOYMENT)
    //     );
    //     break;
    //   }
    //   case Category.PERSONAL_INJURY: {
    //     setDisplayedCategories([
    //       HelpersService.getEnumKeyByEnumValue(
    //         Category,
    //         Category.PERSONAL_INJURY
    //       ),
    //     ]);
    //     setSelectedCategory(
    //       HelpersService.getEnumKeyByEnumValue(
    //         Category,
    //         Category.PERSONAL_INJURY
    //       )
    //     );
    //     break;
    //   }
    //   case Category.WORKERS_COMPENSATION: {
    //     setDisplayedCategories([
    //       HelpersService.getEnumKeyByEnumValue(
    //         Category,
    //         Category.WORKERS_COMPENSATION
    //       ),
    //     ]);
    //     setSelectedCategory(
    //       HelpersService.getEnumKeyByEnumValue(
    //         Category,
    //         Category.WORKERS_COMPENSATION
    //       )
    //     );
    //     break;
    //   }
    // }
    // }
  }, [user]);

  const handleCategoryChange = (e) => {
    if (selectedCategory) {
      MySwal.fire({
        title: <strong>Confirm category change</strong>,
        html: (
          <div>
            Changing category will delete all data that you added so far.
          </div>
        ),
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "No, go back",
        confirmButtonText: "Yes, change",
        confirmButtonColor: "#ed633a",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setSelectedCategory(e.target.value);
        }
      });
      return;
    }
    setSelectedCategory(e.target.value);
  };

  const handleLookHubspot = () => {
    setErrorMessage("");
    if (!email) {
      MySwal.fire({
        icon: "error",
        title: <strong>Please provide a valid email</strong>,
      }).then();
      return;
    }
    dispatch(startSpinner());
    HubspotService.postApiHubspotSearchClientInHubspot({
      email: email,
    } as ClientSearchRequest)
      .then((res) => {
        setEmailFound(!!res);
        if (!res) {
          setErrorMessage(
            "No contact found with this email. Submitting a new lead will create a new contact on Hubspot."
          );
          return;
        }
        if (res.alreadyHasDeal) {
          setErrorMessage(
            "There is already a deal associated with this client on Hubspot. Please make sure you need a new deal."
          );
        }

        switch (Category[selectedCategory]) {
          case Category.EMPLOYMENT: {
            EmploymentForm.updateUserData(res);
            break;
          }
          case Category.WORKERS_COMPENSATION: {
            WorkersCompForm.updateUserData(res);
            break;
          }
          case Category.PERSONAL_INJURY: {
            PersonalInjuryForm.updateUserData(res);
            break;
          }
        }
      })
      .finally(() => dispatch(stopSpinner()));
  };

  const handleOpenNaDialog = () => {
    if (!emailFound) {
      MySwal.fire({
        icon: "error",
        title: <strong>Please find a valid user in hubspot first</strong>,
      }).then();
      return;
    }

    setOpenNADialog(true);
  };

  const handleNADialogClose = () => {
    let updateRequest = {
      status: AdminLeadStatus.N_A,
      clientEmail: email,
      reasonForNAEmp: selectedEmpReason
        ? ReasonForNAEmp[selectedEmpReason]
        : undefined,
      reasonForNAPI: selectedPiReason
        ? ReasonForNAPI[selectedPiReason]
        : undefined,
    } as ArchiveRequest;

    dispatch(startSpinner());
    LeadsService.postApiLeadsSetAsNa(updateRequest)
      .then(() => {
        MySwal.fire({
          icon: "success",
          title: <p className="title">Client updated!</p>,
        }).then(() => {});
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error updating client</p>,
        });
      })
      .finally(() => {
        dispatch(stopSpinner());
        setOpenNADialog(false);
      });
  };

  return (
    <div className="new-lead-holder">
      <h4 className="title">Add new lead</h4>
      <div className="row main-holder">
        <div className="row mt-3 mb-4 dropdown-holder">
          <h5 className="title mb-2">Practice area</h5>
          <div className="col-12 col-md-6">
            <Select
              labelId="fired-label"
              name="categoryType"
              className="min-width-200"
              value={selectedCategory}
              key={selectedCategory}
              onChange={(e) => handleCategoryChange(e)}
            >
              {displayedCategories.map((key) => {
                return (
                  <MenuItem key={key} value={key}>
                    {HelpersService.camelToString(Category[key])}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className="col-12 col-md-6 row">
            <div className="col-6">
              <TextField
                required
                label="Client email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
              />
            </div>
            <div className="col-6">
              <Button
                variant="contained"
                color="primary"
                onClick={handleLookHubspot}
              >
                Look for client data in Hubspot
              </Button>
            </div>
            {!!errorMessage && <div className="row-12">{errorMessage}</div>}
          </div>
          <div className="col-12 mt-3">
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenNaDialog()}
            >
              Set as N/A
            </Button>
            <div>
              <Dialog
                open={openNADialog}
                onClose={() => setOpenNADialog(false)}
              >
                <DialogContent>
                  <div className="col-12 mt-3">
                    {Category[selectedCategory] === Category.EMPLOYMENT && (
                      <FormControl fullWidth>
                        <InputLabel id="selected-label">
                          Select reason*
                        </InputLabel>
                        <Select
                          labelId="selected-label"
                          label="Select reason"
                          value={selectedEmpReason}
                          key={selectedEmpReason}
                          onChange={(e) =>
                            setSelectedEmpReason(
                              e.target.value as ReasonForNAEmp
                            )
                          }
                        >
                          {Object.keys(ReasonForNAEmp).map((reason) => {
                            return (
                              <MenuItem key={reason} value={reason}>
                                {
                                  ReasonForNAEmpDisplayMapping[
                                    ReasonForNAEmp[reason]
                                  ]
                                }
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                    {Category[selectedCategory] ===
                      Category.PERSONAL_INJURY && (
                      <FormControl fullWidth>
                        <InputLabel id="selected-label">
                          Select reason*
                        </InputLabel>
                        <Select
                          labelId="selected-label"
                          label="Select reason"
                          value={selectedPiReason}
                          key={selectedPiReason}
                          onChange={(e) =>
                            setSelectedPiReason(e.target.value as ReasonForNAPI)
                          }
                        >
                          {Object.keys(ReasonForNAPI).map((reason) => {
                            return (
                              <MenuItem key={reason} value={reason}>
                                {
                                  ReasonForPIEmpDisplayMapping[
                                    ReasonForNAPI[reason]
                                  ]
                                }
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="gray"
                    variant="contained"
                    onClick={() => setOpenNADialog(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!selectedEmpReason && !selectedPiReason}
                    color="primary"
                    variant="contained"
                    onClick={() => handleNADialogClose()}
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
        <LeadForm
          selectedCategory={selectedCategory}
          isEdit={true}
          isNew={true}
          leadType={LeadType.Admin}
        ></LeadForm>
      </div>
    </div>
  );
}
