import { Autocomplete, Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, debounce } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { ClioService, MatterPreviewResponse, TimeEntryViewModel, TimeKeepingReport, UserShortResponse, UsersService } from "../../../api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ReactTabulator, ReactTabulatorOptions } from "react-tabulator";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { HelpersService } from "../../../services/Helpers";
import { AllSelection, Presets } from "../ReportsOptions";
import { useDispatch, useSelector } from "react-redux";
import { startSpinner, stopSpinner } from "../../../redux/actions/spinnerActions";

const MySwal = withReactContent(Swal);

export default function Timekeeping() {
    const [description, setDescription] = useState<string>("");
    const [hours, setHours] = useState<number>();
    const [minutes, setMinutes] = useState<number>();
    const [startDate, setStartDate] = useState<any>(dayjs().startOf('month'));
    const [endDate, setEndDate] = useState<any>(dayjs(new Date()));
    const [userTimes, setUserTimes] = useState<TimeKeepingReport[]>([]);
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const [matterValue, setMatterValue] = useState<MatterPreviewResponse | null>(null);
    const [inputValue, setInputValue] = useState("");
    const [matterOptions, setMatterOptions] = useState<
        readonly MatterPreviewResponse[]
    >([]);
    const [selectedPreset, setSelectedPreset] = useState<any>("Month");
    const [updateId, setUpdateId] = useState<number | null>(null);
    const dispatch = useDispatch();

    const user = useSelector((state: any) => state.user?.user);

    let table;

    useEffect(() => {
        refreshTimeReport();
    }, [])
    useEffect(() => {
        refreshTimeReport();
    }, [startDate, endDate])

    const fetchMatters = useMemo(
        () =>
            debounce((input: string) => {
                ClioService.getApiClioMatters(input).then((response) => {
                    let newOptions: readonly MatterPreviewResponse[] = [];
                    if (matterValue) {
                        newOptions = [matterValue];
                    }
                    if (response) {
                        newOptions = [...newOptions, ...response];
                    }

                    setMatterOptions(newOptions);
                });
            }, 200),
        []
    );

    useEffect(() => {
        if (inputValue === "") {
            setMatterOptions(matterValue ? [matterValue] : []);
            return undefined;
        }

        fetchMatters(inputValue);

        return () => { };
    }, [matterValue, inputValue, fetchMatters]);

    const refreshTimeReport = () => {
        UsersService.getApiUsersGetTimeEntries(formatDateToYYYYMMDD(startDate), formatDateToYYYYMMDD(endDate)).then(times => {
            setUserTimes(times);
        });
    }

    const handleAddTimeEntry = () => {
        var seconds = ((hours ?? 0) * 3600) + ((minutes ?? 0) * 60);
        if (!seconds || seconds < 0 || !description) {
            MySwal.fire({
                icon: "error",
                title: <p className="title">Time and description are required</p>,
            });
            return;
        }

        var desc = description;
        if (!!matterValue) {
            desc = `${matterValue.description} --- ${description}`;
        }
        dispatch(startSpinner());
        if (updateId) {
            UsersService.postApiUsersUpdateTimeEntry({ id: updateId, durationSeconds: seconds, description: desc, entryDate: formatDateToYYYYMMDD(selectedDate ? dayjs(selectedDate) : dayjs()) } as TimeEntryViewModel).then(() => {
                clearData();
                refreshTimeReport();
                MySwal.fire({
                    icon: "success",
                    title: <p className="title">Time entry updated.</p>,
                });
            }).catch(() => {
                MySwal.fire({
                    icon: "error",
                    title: <p className="title">Time entry udpate failed.</p>,
                });
            }).finally(() => dispatch(stopSpinner()));
        }
        else {
            UsersService.postApiUsersAddTimeEntry({ durationSeconds: seconds, description: desc, entryDate: formatDateToYYYYMMDD(selectedDate ? dayjs(selectedDate) : dayjs()) } as TimeEntryViewModel).then(() => {
                clearData();
                refreshTimeReport();
                MySwal.fire({
                    icon: "success",
                    title: <p className="title">Time entry added.</p>,
                });
            }).catch(() => {
                MySwal.fire({
                    icon: "error",
                    title: <p className="title">Time entry failed.</p>,
                });
            }).finally(() => dispatch(stopSpinner()));
        }
    }

    const clearData = () => {
        setSelectedDate(null);
        setDescription("");
        setHours(0);
        setMinutes(0);
        setUpdateId(null);
    }

    const formatDateToYYYYMMDD = (date: dayjs.Dayjs) => {
        if (!date) {
            return undefined;
        }
        const year = date.year();
        const month = date.month() + 1;
        const day = date.date();

        return `${year}-${month}-${day}`;
    };

    const updatePropertyNames = (data) => {
        return data.map((item) => {
            const updatedItem = { ...item };

            //user report
            if (updatedItem.times) {
                updatedItem["_children"] = updatePropertyNames(
                    updatedItem.times
                );
                delete updatedItem.times;
            }
            if (updatedItem.timesByDays) {
                updatedItem["_children"] = updatePropertyNames(
                    updatedItem.timesByDays
                );
                delete updatedItem.timesByDays;
            }
            if (updatedItem.day) {
                updatedItem.day = new Date(updatedItem.day)
                    .toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
            }

            return updatedItem;
        });
    };

    const tableOptions: ReactTabulatorOptions = {
        dataTree: true,
        height: 1000,
    };

    const handlePresetChange = (event) => {
        setSelectedPreset(event.target.value);
        const currentDate = dayjs();
        let selectedDate;
        let endDate = dayjs(new Date());

        switch (event.target.value) {
            case 'LastMonth':
                selectedDate = currentDate.startOf('month').add(-1, "month");
                endDate = currentDate.startOf('month').add(-1, "day");
                break;
            case 'Month':
                selectedDate = currentDate.startOf('month');
                break;
            case 'Week':
                selectedDate = currentDate.startOf('week');
                break;
            case 'Day':
                selectedDate = currentDate.startOf('day');
                break;
            default:
                selectedDate = null;
                break;
        }
        setStartDate(selectedDate);
        setEndDate(endDate);
    };

    const DateRangeSelect = () => {
        return (
            <div className="row">
                <h4>Select date range</h4>
                <div className="col-3 mb-3">
                    <DatePicker
                        disableFuture
                        label="Start date"
                        className="me-3 w-100"
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                    />
                </div>
                <div className="col-3">
                    <DatePicker
                        disableFuture
                        label="End date"
                        className="w-100"
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                    />
                </div>
                <div className="col-3">
                    <FormControl className="w-100">
                        <InputLabel>Select preset</InputLabel>
                        <Select
                            className="min-width-200"
                            value={selectedPreset ?? "All time"}
                            key={selectedPreset}
                            label="Select preset"
                            onChange={handlePresetChange}
                        >
                            <MenuItem key={0} value={AllSelection}>
                                Select All
                            </MenuItem>
                            {Object.keys(Presets)
                                .filter((key) => isNaN(Number(key)))
                                .map((key) => {
                                    return (
                                        <MenuItem key={key} value={key}>
                                            {HelpersService.getEnumKeyByEnumValue(
                                                Presets,
                                                Presets[key]
                                            )}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </div>
            </div>);
    }
    const rowClick = (e: any, row: any) => {
        if (!!row._row.data.id && row._row.data.userId == user.id) {
            setDescription(row._row.data.description);
            var hours = Math.floor(row._row.data.total / 3600);
            var minutes = Math.floor((row._row.data.total - (hours * 3600)) / 60);
            setHours(hours);
            setMinutes(minutes);
            setSelectedDate(dayjs(row._row.data.date));
            setUpdateId(row._row.data.id);
        }
    };
    return (
        <>
            <div className="row col-12">
                <TextField
                    label="Hours"
                    variant="outlined"
                    type="number"
                    className="col-1 m-3"
                    value={hours?.toString()}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">h</InputAdornment>,
                        inputProps: { min: 0 }
                    }}
                    onChange={(e) => setHours(+e.target.value)}
                />
                <TextField
                    label="Minutes"
                    variant="outlined"
                    type="number"
                    className="col-1 m-3"
                    value={minutes?.toString()}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">m</InputAdornment>,
                        inputProps: { min: 0 }
                    }}
                    onChange={(e) => setMinutes(+e.target.value)}
                />
                <DatePicker
                    disableFuture
                    label="Entry date"
                    className="col-4 m-3"
                    value={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                />
                <div className="col-4 mt-3">
                    <Autocomplete
                        getOptionLabel={(option) =>
                            (typeof option === "string"
                                ? option
                                : option.description) as string
                        }
                        filterOptions={(x) => x}
                        options={matterOptions}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        value={matterValue}
                        noOptionsText="No matters"
                        onChange={(
                            event: any,
                            selectedValue: MatterPreviewResponse | null
                        ) => {
                            setMatterOptions(
                                selectedValue
                                    ? [selectedValue, ...matterOptions]
                                    : matterOptions
                            );
                            setMatterValue(selectedValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Matter" fullWidth />
                        )}
                    />
                </div>
                <TextField
                    required
                    multiline
                    label="Description"
                    variant="outlined"
                    className="col-6 m-3"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="submit-btn col-2 mt-3 mb-5"
                    onClick={handleAddTimeEntry}
                > {!!updateId ? "Update" : "Add"} time entry
                </Button>
                {!!updateId && <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    className="submit-btn col-2 m-3 mb-5"
                    onClick={clearData}
                > Cancel
                </Button>}
            </div>

            <h3 className="title m-4">Report</h3>
            <DateRangeSelect></DateRangeSelect>
            <ReactTabulator
                onRef={(ref) => (table = ref)}
                columns={[
                    { title: "Total", field: "totalHours", width: 100 },
                    { title: "Name", field: "userName" },
                    { title: "Date", field: "day" },
                    { title: "Description", field: "description", formatter: "textarea" }]}
                data={updatePropertyNames(userTimes)}
                options={tableOptions}
                events={{
                    rowClick: rowClick,
                }}
            />
        </>
    );
}