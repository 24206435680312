import { useEffect, useState } from "react";
import Button from "@mui/material/Button/Button";
import Drawer from "@mui/material/Drawer/Drawer";

import SendIcon from "@mui/icons-material/Send";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import {
  errorInstructions,
  LeadTableSummary,
  LeadType,
  SummaryType,
} from "../shared/Constants";
import ShareAttorneysTable from "./ShareAttorneysTable";
import ShareLeadsTable from "./ShareLeadsTable";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LeadForm from "../LeadDetails/LeadForm";
import {
  Category,
  GetLeadsByIdsRequest,
  LeadShareRequest,
  LeadsService,
  LeadSummary,
} from "../../../../api";
import { HelpersService } from "../../../../services/Helpers";
import { useParams } from "react-router-dom";
import useNavigateSearch from "../../../../services/useNavigateSearch";
import { InputAdornment, TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { useDispatch } from "react-redux";
import { startSpinner, stopSpinner } from "../../../../redux/actions/spinnerActions";
import FilesDropdown from "../../../../components/basic/FilesDropdown";

const MySwal = withReactContent(Swal);

export default function SendLeads() {
  const [previews, setPreviews] = useState<LeadTableSummary[]>([]);
  const [previewIndex, setPreviewIndex] = useState<number>(0);
  const [attorneysIds, setAttorneysIds] = useState<number[]>([]);
  const [adminFee, setAdminFee] = useState<number>(500);
  const [shrink, setShrink] = useState(false);

  const navigate = useNavigateSearch();
  const dispatch = useDispatch();
  const { ids } = useParams();

  useEffect(() => {
    if (!ids) {
      return;
    }
    dispatch(startSpinner());
    LeadsService.postApiLeadsGetByIds({
      ids: ids.split(",").map((id) => +id),
    } as GetLeadsByIdsRequest).then((leads) => {
      var newPreviews = leads.map((lead) => {
        return {
          lead: HelpersService.parseLeadResponse(lead),
          summaryType: SummaryType.Redacted,
        } as LeadTableSummary;
      });
      setPreviews(newPreviews);
      setPreviewIndex(0);
    }).finally(() => dispatch(stopSpinner()));
  }, [ids]);

  const handlePreviewUpdate = (index) => {
    setPreviewIndex(index);
  };
  const handlePrevNextUpdate = (id) => {
    setPreviewIndex(id);
  };

  const handlePreviewTypeChange = (index: number, value: SummaryType) => {
    let newPreviews = [...previews];
    let preview = { ...newPreviews[index] };
    preview.summaryType = value;
    newPreviews[index] = preview;

    setPreviews(newPreviews);
  };

  const handleShare = () => {
    MySwal.fire({
      title: <strong>Confirm sending</strong>,
      html: (
        <div>
          You are about to send {previews.length} leads to {attorneysIds.length}{" "}
          recipients.
          <br></br>
          Are you sure you want to continue?
        </div>
      ),
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "No, go back",
      confirmButtonText: "Yes, send now",
      confirmButtonColor: "#ed633a",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startSpinner());
        var request = {
          leads: previews.map((preview) => {
            return {
              id: preview.lead?.id,
              isFullSummary: preview.summaryType === SummaryType.Full,
            } as LeadSummary;
          }),
          attorneyIds: attorneysIds,
          adminFee: adminFee,
        } as LeadShareRequest;
        LeadsService.postApiLeadsShareLeads(request)
          .then(() => {
            MySwal.fire({
              icon: "success",
              title: <p className="title">Success!</p>,
              html: (
                <div className="alert-text">
                  All leads have been sent to your recipients. You will get
                  notified when they take action.
                </div>
              ),
            }).then(() => {
              navigate("/referral-leads");
            });
          })
          .catch(() => {
            MySwal.fire({
              icon: "error",
              title: <p className="title">Error sending leads</p>,
              html: <div className="alert-text">{errorInstructions}</div>,
            });
          }).finally(() => dispatch(stopSpinner()));
      }
    });
  };

  const handleAttorneysChange = (ids: number[]) => {
    setAttorneysIds(ids);
  };

  return (
    <div className="row main-holder">
      <div className="col-6 pe-5 pt-3 pb-3 row border-right">
        <h5 className="title">Preview</h5>
        {
          <LeadForm
            details={previews[previewIndex]?.lead}
            isEdit={false}
            leadType={
              previews[previewIndex]?.summaryType === SummaryType.Redacted
                ? LeadType.Attorney
                : LeadType.Admin
            }
          ></LeadForm>
        }
        {previews.length > 0 && (
          <div className="row mt-5 button-holder">
            <Button
              disabled={previewIndex === 0}
              variant="contained"
              color={previewIndex > 0 ? "primary" : "gray"}
              onClick={() => handlePrevNextUpdate(previewIndex - 1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Previous
            </Button>
            <Button
              disabled={previewIndex === previews.length - 1}
              variant="contained"
              color={previewIndex < previews.length - 1 ? "primary" : "gray"}
              onClick={() => handlePrevNextUpdate(previewIndex + 1)}
              endIcon={<ArrowForwardIosIcon />}
            >
              Next
            </Button>
          </div>
        )}
      </div>
      <div className="col-6 ps-5 pt-3">
        <h5 className="title mb-2">
          Send {previews.length > 1 ? "Multiple Leads" : "Lead"}
        </h5>
        <div>
          You have selected the following{" "}
          {previews.length > 1 ? "leads" : "lead"} to share:
          <div className="mt-4">
            <ShareLeadsTable
              leads={previews}
              onPreviewSelect={handlePreviewUpdate}
              onPreviewTypeChange={handlePreviewTypeChange}
            ></ShareLeadsTable>
          </div>
        </div>
        <h5 className="title mt-5 mb-3">Admin fee:</h5>
        <div className="row">
          <NumericFormat
            required
            disabled={previews.some(preview => preview.lead.category === Category.WORKERS_COMPENSATION)}
            thousandSeparator=","
            value={adminFee}
            onFocus={() => setShrink(true)}
            onBlur={(e) => {
              !e.target.value && setShrink(false);
            }}
            onChange={(e) => setAdminFee(+e.target.value.replace(/,/g, ""))}
            customInput={TextField}
            label="Admin fee"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className="dollar-adornment">
                  $
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: !!adminFee || shrink,
            }}
            sx={{
              "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
                transform: "translate(35px, 17px)",
              },
            }}
          />
        </div>
        <h5 className="title mt-5 mb-3">Select recipients:</h5>
        <div className="row">
          <ShareAttorneysTable
            onAttorneySelectionChange={handleAttorneysChange}
          ></ShareAttorneysTable>
        </div>
        {previews[previewIndex]?.lead?.category === Category.WORKERS_COMPENSATION &&
          <div>
            <h5 className="title mt-5 mb-3">Add contract:</h5>
            <FilesDropdown leadData={previews[previewIndex].lead}></FilesDropdown>
          </div>
        }
        <div className="row mt-5 button-holder">
          <Button
            disabled={attorneysIds.length === 0 || !adminFee}
            variant="contained"
            color="primary"
            onClick={handleShare}
            startIcon={<SendIcon />}
          >
            Share now
          </Button>
        </div>
      </div>
    </div>
  );
}
