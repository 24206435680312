import { TableCell, TableBody, TextField, Button } from "@mui/material";
import Table from "@mui/material/Table/Table";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import { useEffect, useState } from "react";
import { WitnessViewModel } from "../../../../api";

interface WitnessFormTableProps {
  initValues: WitnessViewModel[] | null;
  onChange: Function;
}
export default function WitnessFormTable(props: WitnessFormTableProps) {
  const [witnessRows, setWitnessRows] = useState<
    (WitnessViewModel & { rowId: number })[]
  >([]);
  const [disableAddButton, setDisableAddButton] = useState<boolean>(false);

  useEffect(() => {
    if (!props.initValues) {
      return;
    }
    var rows = props.initValues.map((row, index) => {
      var mappedRow = row as WitnessViewModel & { rowId: number };
      mappedRow.rowId = index;
      return mappedRow;
    });
    setWitnessRows(rows);
  }, [props.initValues]);

  const removeRow = (id) => {
    const newRows = witnessRows.filter((row) => row.rowId !== id);
    setWitnessRows(newRows);
    props.onChange(newRows);
  };

  const addNewRow = () => {
    const newRows = [
      ...witnessRows!,
      {
        rowId: witnessRows.length ?? 0,
        name: "",
        phoneNumber: "",
        jobTitle: "",
        canAttestTo: "",
      },
    ];
    setDisableAddButton(true);
    setWitnessRows(newRows);
  };

  const handleChange = (value, id, field: string) => {
    const newRows = witnessRows.map((row) => {
      if (row.rowId === id) {
        row[field] = value;
      }
      return row;
    });
    calculateDisable();
    setWitnessRows(newRows);
    props.onChange(newRows);
  };

  const calculateDisable = () => {
    var unfilledRow = witnessRows.filter(
      (row) => !row.name || !row.canAttestTo
    );
    setDisableAddButton(unfilledRow.length > 0);
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} size="medium">
          <TableHead className="table-head">
            <TableRow>
              <TableCell key="location">Name*</TableCell>
              <TableCell align="left">Phone number</TableCell>
              <TableCell align="left">What is their job title?</TableCell>
              <TableCell align="left">What can they attest to?*</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {witnessRows
              ? witnessRows.map((row) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.rowId}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell align="left">
                        <TextField
                          required
                          variant="outlined"
                          value={row.name}
                          onChange={(event) =>
                            handleChange(event.target.value, row.rowId, "name")
                          }
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          variant="outlined"
                          value={row.phoneNumber}
                          onChange={(event) =>
                            handleChange(
                              event.target.value,
                              row.rowId,
                              "phoneNumber"
                            )
                          }
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          variant="outlined"
                          value={row.jobTitle}
                          onChange={(event) =>
                            handleChange(
                              event.target.value,
                              row.rowId,
                              "jobTitle"
                            )
                          }
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          required
                          variant="outlined"
                          value={row.canAttestTo}
                          multiline
                          onChange={(event) =>
                            handleChange(
                              event.target.value,
                              row.rowId,
                              "canAttestTo"
                            )
                          }
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          variant="contained"
                          color="gray"
                          size="small"
                          onClick={() => removeRow(row.rowId)}
                        >
                          Remove
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        disabled={disableAddButton}
        variant="contained"
        color="secondary"
        onClick={() => addNewRow()}
        className="mt-4"
      >
        Add another witness
      </Button>
    </>
  );
}
