import { useEffect, useState } from "react";
import { ApplicationRole } from "../../../../../api/models/ApplicationRole";
import {
  CSADisplayText,
  errorInstructions,
  LeadType,
  ReasonForDeclinePIDisplayText,
  ReasonForDeclineWCDisplayText,
  ReasonForKeepPIDisplayText,
  ReasonForNAEmpDisplayMapping,
  ReasonForReferPIDisplayText,
} from "../../shared/Constants";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { LeadsService } from "../../../../../api/services/LeadsService";
import Dialog from "@mui/material/Dialog/Dialog";
import { AttorneyApprovalStatus } from "../../../../../api/models/AttorneyApprovalStatus";
import { HelpersService } from "../../../../../services/Helpers";
import Button from "@mui/material/Button/Button";
import { UpdateApprovalStatusRequest } from "../../../../../api/models/UpdateApprovalStatusRequest";
import useNavigateSearch from "../../../../../services/useNavigateSearch";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import { LeadViewModel } from "../../../../../api/models/LeadViewModel";
import {
  Category,
  CSAType,
  LeadCommentRequest,
  OpenAPI,
  ReasonForDeclinePI,
  ReasonForKeepPI,
  ReasonForNAEmp,
  ReasonForNgcWC,
  ReasonForReferPI,
} from "../../../../../api";
import FormControl from "@mui/material/FormControl/FormControl";
import CommentsTab from "./CommentsTab";
import TextField from "@mui/material/TextField/TextField";
import { useDispatch } from "react-redux";
import {
  startSpinner,
  stopSpinner,
} from "../../../../../redux/actions/spinnerActions";

const MySwal = withReactContent(Swal);

interface AttorneyApprovalActionsProps {
  leadType: LeadType;
  userRoles?: ApplicationRole[];
  details?: LeadViewModel;
}

export default function AttorneyApprovalActions(
  props: AttorneyApprovalActionsProps
) {
  const [empRejectReason, setEmpRejectReason] = useState<ReasonForNAEmp>();
  const [selectedCSA, setSelectedCSA] = useState<CSAType>();
  const [CSAComment, setCSAComment] = useState<string>();
  const [openApprovalDialog, setOpenApprovalDialog] = useState(false);
  const [approvalValue, setApprovalValue] = useState<AttorneyApprovalStatus>();
  const [openComments, setOpenComments] = useState<boolean>(false);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const [piRejectReason, setPiRejectReason] = useState<ReasonForDeclinePI>();
  const [otherReasoning, setOtherReasoning] = useState<string>();
  const [piReferReason, setPiReferReason] = useState<ReasonForReferPI>();
  const [piKeepReason, setPiKeepReason] = useState<ReasonForKeepPI>();
  const [autoComment, setAutoComment] = useState<string>();

  const [wcRejectReason, setWcRejectReason] = useState<ReasonForNgcWC>();

  useEffect(() => {
    setIsMobile(HelpersService.checkMobile());
  }, []);

  const navigate = useNavigateSearch();
  const dispatch = useDispatch();

  const triggerCommentsOpen = (value) => {
    value
      ? setApprovalValue(AttorneyApprovalStatus.MORE_INFO_REQUESTED)
      : setApprovalValue(AttorneyApprovalStatus.IN_PROCESS);
    setOpenComments(value);
  };

  const handleApprovalChange = (status: AttorneyApprovalStatus) => {
    setApprovalValue(status);
    setOpenApprovalDialog(true);
  };

  const handleApprovalDialogClose = (success) => {
    if (success) {
      if (!props.details?.id) {
        setOpenApprovalDialog(false);
        return;
      }
      var approvalRequest = {
        attorneyApprovalStatus: approvalValue,
        reasoningDeclineEmp: empRejectReason
          ? ReasonForNAEmp[empRejectReason]
          : null,
        reasoningDeclinePI: piRejectReason
          ? ReasonForDeclinePI[piRejectReason]
          : null,
        otherReasoning: otherReasoning,
        reasoningKeepPI: piKeepReason ? ReasonForKeepPI[piKeepReason] : null,
        reasoningReferPI: piReferReason
          ? ReasonForReferPI[piReferReason]
          : null,
        referOtherPI: autoComment,
        reasoningNgcWC: wcRejectReason ? ReasonForNgcWC[wcRejectReason] : null,
        csaType: selectedCSA ? CSAType[selectedCSA] : null,
      } as UpdateApprovalStatusRequest;

      dispatch(startSpinner());
      LeadsService.postApiLeadsApprovalChange(
        props.details?.id,
        approvalRequest
      )
        .then(() => {
          if (approvalValue === AttorneyApprovalStatus.KEEP && !!selectedCSA) {
            LeadsService.postApiLeadsLeaveComment(props.details!.id!, {
              message:
                "Attorney recommended " + CSADisplayText[CSAType[selectedCSA]],
              sendEmail: false,
              updateStatus: false,
            } as LeadCommentRequest).then();
          }
          if (approvalValue === AttorneyApprovalStatus.KEEP && !!CSAComment) {
            LeadsService.postApiLeadsLeaveComment(props.details!.id!, {
              message: CSAComment,
              sendEmail: false,
              updateStatus: false,
            } as LeadCommentRequest).then();
          }

          if (approvalValue === AttorneyApprovalStatus.KEEP && !!props.details?.classDetails) {
            LeadsService.postApiLeadsLeaveComment(props.details!.id!, {
              message:
                "Attorney approved Class Action",
              sendEmail: false,
              updateStatus: false,
            } as LeadCommentRequest).then();
          }

          MySwal.fire({
            icon: "success",
            title: <p className="title">Success!</p>,
            html: (
              <div className="alert-text">
                {approvalValue === AttorneyApprovalStatus.MORE_INFO_REQUESTED
                  ? "You will be notified when staff answers."
                  : "You have finished review of this lead."}
              </div>
            ),
          }).then(() => {
            navigate(`/approve-leads`);
          });
        })
        .catch(() => {
          MySwal.fire({
            icon: "error",
            title: <p className="title">Error updating client approval</p>,
            html: <div className="alert-text">{errorInstructions}</div>,
          });
        })
        .finally(() => dispatch(stopSpinner()));
    }
    setOpenApprovalDialog(false);
  };

  const generateReasonDetails = (reason: string) => {
    switch (reason) {
      //PI Keep
      case ReasonForKeepPI.AUTO:
        return (
          <div className="row mt-3 text-start">
            <h5>Please make sure these apply:</h5>
            <ul>
              <li>Commercial Insurance - 500K - 2M</li>
              <li>Liability is Clear (Not clients fault)</li>
              <li>No Treatment Gap - Treatment on Day or Week of Accident</li>
              <li>Lead has Auto Insurance</li>
              <li>SOL within 2 years </li>
            </ul>
          </div>
        );
      case ReasonForKeepPI.PREMISES_LIABILITY:
        return (
          <div className="row mt-3 text-start">
            <h5>Please make sure these apply:</h5>
            <ul>
              <li>Liability is Clear (Not clients fault)</li>
              <li>
                Typically good to keep
                <ul>
                  <li>Object falling on Lead</li>
                  <li>Fire or Explosion</li>
                  <li>Anything that has Wrongful Death</li>
                </ul>
              </li>
            </ul>
          </div>
        );
      case ReasonForKeepPI.MED_MAL:
        return (
          <div className="row mt-3 text-start">
            <ul>Brendan reviews and decides</ul>
          </div>
        );
      //PI Refer
      case ReasonForReferPI.NBCAUTO:
        return (
          <div className="row mt-3 text-start">
            <h5>One of these apply:</h5>
            <ul>
              <li>
                Liability is Unclear (Police or adjuster says client caused
                accident)
              </li>
              <li>Gap in Treatment (More than 1 week)</li>
              <li>Lead is Uninsured or Defendant is Uninsured </li>
              <li>Substitution of Attorneys</li>
            </ul>
            <TextField
              required
              label="Auto issue"
              multiline
              variant="outlined"
              value={autoComment}
              onChange={(e) => setAutoComment(e.target.value)}
            />
          </div>
        );
      case ReasonForReferPI.EASY_AUTO:
        return (
          <div className="row mt-3 text-start">
            <h5>Please make sure these apply:</h5>
            <ul>
              <li>Liability is Clear (Not clients fault)</li>
              <li>
                Defendant has insurance or Client has UM/UIM (Uninsured
                Motorist/Underinsured Motorist)
              </li>
              <li>No Treatment Gap - Treatment on Day or Week of Accident</li>
              <li>
                Clients Policy
                <ul>
                  <li>15/30 (15k-30k)</li>
                  <li>25/50 (25k-50k)</li>
                  <li>100/300 (100k) 100 - per person/300 - per accident</li>
                </ul>
              </li>
            </ul>
          </div>
        );
      case ReasonForReferPI.EASY_PREMISES_LIABILITY:
        return (
          <div className="row mt-3 text-start">
            <h5>Please make sure these apply:</h5>
            <ul>
              <li>Has photos of the premises or surveillance</li>
              <li>No Treatment Gap - Treatment on Day or Week of Accident</li>
              <li>Notification was made to the premises </li>
              <li>No Prior Falls</li>
              <li>Typically we will refer out slip and falls </li>
            </ul>
          </div>
        );
      case ReasonForReferPI.CIVIL_RIGHTS:
        return (
          <div className="row mt-3 text-start">
            <h5>Please make sure these apply:</h5>
            <ul>
              <li>Police Brutality</li>
              <li>Government</li>
            </ul>
          </div>
        );
    }
  };
  const generateDeclineReasonDetails = (reason: string) => {
    switch (reason) {
      //PI Reject
      case ReasonForDeclinePI.AUTO:
        return (
          <div className="row mt-3 text-start">
            <h5>One or more apply:</h5>
            <ul>
              <li>Client has no insurance and Defendant has no insurance</li>
              <li>
                Liability disputed (Police or adjuster says client caused
                accident)
              </li>
              <li>Gap in Treatment - Over a month</li>
              <li>SOL over 2 years</li>
              <li>Evidence not strong enough</li>
              <li>Other - enter reason</li>
            </ul>
          </div>
        );
      case ReasonForDeclinePI.PREMISES_LIABILITY:
        return (
          <div className="row mt-3 text-start">
            <h5>One or more apply:</h5>
            <ul>
              <li>Gap in Treatment - Over a month</li>
              <li>No Photos</li>
              <li>No Surveillance</li>
              <li>SOL over 2 years</li>
              <li>Other - enter reason</li>
            </ul>
          </div>
        );
      case ReasonForDeclinePI.OTHER:
        return <div className="row mt-3 text-start">
          <div>Identify the Type of Case</div>
        </div>;
    }
  };

  const downloadClick = () => {
    if (!!props.details?.id) {
      fetch(`${OpenAPI.BASE}/api/Leads/${props.details.id}/pdfIntake`, {
        method: "GET",
        headers: {
          Authorization: (OpenAPI.HEADERS as any)?.Authorization,
        },
      }).then((response: any) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          if (props.details?.attorneyApprovalStatus === AttorneyApprovalStatus.REFER) {
            a.download = `Intake notes.pdf`;
          }
          else {
            a.download = `Intake notes for ${props.details?.firstName} ${props.details?.lastName}.pdf`;
          }
          document.body.appendChild(a);
          a.click();
        });
      });
    }
  }

  const openClassForm = () => {
    navigate(`/lead/${props.details?.id}/class/review`);
  };

  return (
    <>
      {((props.leadType === LeadType.StaffAttorney &&
        (props.userRoles?.includes(ApplicationRole.STAFF_ATTORNEY) || props.userRoles?.includes(ApplicationRole.REFFERAL_STAFF)))
        || (props.userRoles?.includes(ApplicationRole.ADMIN) && props.details?.isCurrentUserReviewing && props.details.attorneyApprovalStatus != AttorneyApprovalStatus.REFER && props.details.attorneyApprovalStatus != AttorneyApprovalStatus.KEEP && props.details.attorneyApprovalStatus != AttorneyApprovalStatus.REJECT)) && (
          <div
            className={
              isMobile
                ? "row sticky-details-top-menu attorney-actions mobile"
                : `row sticky-details-top-menu attorney-actions${props.userRoles?.includes(ApplicationRole.ADMIN) && " move-down"}`
            }
          >
            {isMobile && (
              <Button
                className="lead-menu-icon"
                onClick={() => setMobileOpen(!mobileOpen)}
              >
                {mobileOpen ? "Close actions" : "Open actions"}
              </Button>
            )}
            {(!isMobile || (isMobile && mobileOpen)) && (
              <div className="row justify-content-end">
                {props.details?.category !== Category.WORKERS_COMPENSATION && (
                  <div className="col-12 col-md-3 mt-3">
                    <Button
                      variant="contained"
                      color="primary"
                      className="full-btn"
                      onClick={() =>
                        handleApprovalChange(AttorneyApprovalStatus.KEEP)
                      }
                    >
                      Keep
                    </Button>
                  </div>)}
                <div className="col-12 col-md-3 mt-3">
                  <Button
                    variant="contained"
                    color="primary"
                    className="full-btn"
                    onClick={() =>
                      handleApprovalChange(AttorneyApprovalStatus.REJECT)
                    }
                  >
                    Reject
                  </Button>
                </div>
                {props.details?.category !== Category.EMPLOYMENT && props.details?.category !== Category.CLASS_ACTION && (
                  <div className="col-12 col-md-3 mt-3">
                    <Button
                      variant="contained"
                      color="primary"
                      className="full-btn"
                      onClick={() =>
                        handleApprovalChange(AttorneyApprovalStatus.REFER)
                      }
                    >
                      Refer
                    </Button>
                  </div>
                )}
                {props.details?.attorneyApprovalStatus ===
                  AttorneyApprovalStatus.IN_PROCESS && (
                    <div className="col-12 col-md-3 mt-3">
                      {!openComments ? (
                        <Button
                          variant="contained"
                          color="primary"
                          className="full-btn"
                          onClick={() => triggerCommentsOpen(true)}
                        >
                          More info needed
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="secondary"
                          className="full-btn"
                          onClick={() => triggerCommentsOpen(false)}
                        >
                          Cancel info request
                        </Button>
                      )}
                    </div>
                  )}
                {props.details?.category === Category.PERSONAL_INJURY && (
                  <div className="col-12 col-md-2 mt-3 mb-3">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={downloadClick}
                    >
                      Download Pdf
                    </Button>
                  </div>)
                }
                <div>
                  <Dialog
                    open={openApprovalDialog}
                    onClose={() => handleApprovalDialogClose(false)}
                  >
                    <DialogTitle>
                      Please confirm your choice
                      {(props.details?.category === Category.PERSONAL_INJURY ||
                        approvalValue === AttorneyApprovalStatus.REJECT) &&
                        " and select reason"}
                      {props.details?.category === Category.EMPLOYMENT &&
                        approvalValue === AttorneyApprovalStatus.KEEP &&
                        " and select CSA type"}
                      .
                    </DialogTitle>
                    <DialogContent>
                      {props.details?.category === Category.EMPLOYMENT && (
                        <>
                          {approvalValue === AttorneyApprovalStatus.REJECT && (
                            <div className="col-12 mt-3">
                              <FormControl fullWidth>
                                <InputLabel id="selected-label">
                                  Select reason*
                                </InputLabel>
                                <Select
                                  labelId="selected-label"
                                  name="empRejectReason"
                                  label="Select reason"
                                  value={empRejectReason}
                                  key={empRejectReason}
                                  onChange={(e) =>
                                    setEmpRejectReason(
                                      e.target.value as ReasonForNAEmp
                                    )
                                  }
                                >
                                  {Object.keys(ReasonForNAEmp).map((reason) => {
                                    return (
                                      <MenuItem key={reason} value={reason}>
                                        {
                                          ReasonForNAEmpDisplayMapping[
                                          ReasonForNAEmp[reason]
                                          ]
                                        }
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                <div className="col-12 mt-3">
                                  {empRejectReason &&
                                    ReasonForNAEmp[empRejectReason] ===
                                    ReasonForNAEmp.OTHER && (
                                      <TextField
                                        fullWidth
                                        required
                                        label="Describe Other"
                                        variant="outlined"
                                        value={otherReasoning}
                                        onChange={(e) =>
                                          setOtherReasoning(e.target.value)
                                        }
                                      />
                                    )}
                                </div>
                              </FormControl>
                            </div>
                          )}
                          {approvalValue === AttorneyApprovalStatus.KEEP && (
                            <>
                              <div className="mt-3">
                                <FormControl>
                                  <InputLabel id="selected-label">
                                    Select CSA type*
                                  </InputLabel>
                                  <Select
                                    labelId="selected-label"
                                    name="selectedCSA"
                                    className="min-width-200"
                                    label="Select CSA type"
                                    value={selectedCSA}
                                    key={selectedCSA}
                                    onChange={(e) =>
                                      setSelectedCSA(e.target.value as CSAType)
                                    }
                                  >
                                    {Object.keys(CSAType).map((reason) => {
                                      return (
                                        <MenuItem key={reason} value={reason}>
                                          {CSADisplayText[CSAType[reason]]}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </div>
                              <div className="row mt-3">
                                <TextField
                                  label="Comment"
                                  multiline
                                  variant="outlined"
                                  value={CSAComment}
                                  onChange={(e) =>
                                    setCSAComment(e.target.value)
                                  }
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {props.details?.category === Category.PERSONAL_INJURY && (
                        <>
                          {approvalValue === AttorneyApprovalStatus.KEEP && (
                            <>
                              <div className="mt-3">
                                <FormControl>
                                  <InputLabel id="selected-label">
                                    Select Reason*
                                  </InputLabel>
                                  <Select
                                    labelId="selected-label"
                                    name="piKeepReason"
                                    className="min-width-200"
                                    label="Select reason"
                                    value={piKeepReason}
                                    key={piKeepReason}
                                    onChange={(e) =>
                                      setPiKeepReason(
                                        e.target.value as ReasonForKeepPI
                                      )
                                    }
                                  >
                                    {Object.keys(ReasonForKeepPI).map(
                                      (reason) => {
                                        return (
                                          <MenuItem key={reason} value={reason}>
                                            {
                                              ReasonForKeepPIDisplayText[
                                              ReasonForKeepPI[reason]
                                              ]
                                            }
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                </FormControl>
                              </div>
                              {piKeepReason &&
                                generateReasonDetails(
                                  ReasonForKeepPI[piKeepReason]
                                )}
                            </>
                          )}
                          {approvalValue === AttorneyApprovalStatus.REJECT && (
                            <>
                              <div className="mt-3">
                                <FormControl>
                                  <InputLabel id="selected-label">
                                    Select Reason*
                                  </InputLabel>
                                  <Select
                                    labelId="selected-label"
                                    name="piRejectReason"
                                    className="min-width-200"
                                    label="Select reason"
                                    value={piRejectReason}
                                    key={piRejectReason}
                                    onChange={(e) =>
                                      setPiRejectReason(
                                        e.target.value as ReasonForDeclinePI
                                      )
                                    }
                                  >
                                    {Object.keys(ReasonForDeclinePI).map(
                                      (reason) => {
                                        return (
                                          <MenuItem key={reason} value={reason}>
                                            {
                                              ReasonForDeclinePIDisplayText[
                                              ReasonForDeclinePI[reason]
                                              ]
                                            }
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                </FormControl>
                              </div>
                              {piRejectReason &&
                                generateDeclineReasonDetails(
                                  ReasonForDeclinePI[piRejectReason]
                                )}
                              {piRejectReason
                                && ReasonForDeclinePI[piRejectReason] !== ReasonForDeclinePI.MED_MAL
                                && (
                                  <TextField
                                    className="mt-3"
                                    fullWidth
                                    required
                                    label="Describe Other"
                                    variant="outlined"
                                    value={otherReasoning}
                                    onChange={(e) =>
                                      setOtherReasoning(e.target.value)
                                    }
                                  />
                                )}
                            </>
                          )}
                          {approvalValue === AttorneyApprovalStatus.REFER && (
                            <>
                              <div className="mt-3">
                                <FormControl>
                                  <InputLabel id="selected-label">
                                    Select Reason*
                                  </InputLabel>
                                  <Select
                                    labelId="selected-label"
                                    name="piReferReason"
                                    className="min-width-200"
                                    label="Select reason"
                                    value={piReferReason}
                                    key={piReferReason}
                                    onChange={(e) =>
                                      setPiReferReason(
                                        e.target.value as ReasonForReferPI
                                      )
                                    }
                                  >
                                    {Object.keys(ReasonForReferPI).map(
                                      (reason) => {
                                        return (
                                          <MenuItem key={reason} value={reason}>
                                            {
                                              ReasonForReferPIDisplayText[
                                              ReasonForReferPI[reason]
                                              ]
                                            }
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                </FormControl>
                              </div>
                              {piReferReason &&
                                generateReasonDetails(
                                  ReasonForReferPI[piReferReason]
                                )}
                            </>
                          )}
                        </>
                      )}
                      {props.details?.category ===
                        Category.WORKERS_COMPENSATION && (
                          <>
                            {approvalValue === AttorneyApprovalStatus.REJECT && (
                              <>
                                <div className="mt-3">
                                  <FormControl>
                                    <InputLabel id="selected-label">
                                      Select Reason*
                                    </InputLabel>
                                    <Select
                                      labelId="selected-label"
                                      name="wcRejectReason"
                                      className="min-width-200"
                                      label="Select reason"
                                      value={wcRejectReason}
                                      key={wcRejectReason}
                                      onChange={(e) =>
                                        setWcRejectReason(
                                          e.target.value as ReasonForNgcWC
                                        )
                                      }
                                    >
                                      {Object.keys(ReasonForNgcWC).map(
                                        (reason) => {
                                          return (
                                            <MenuItem key={reason} value={reason}>
                                              {
                                                ReasonForDeclineWCDisplayText[
                                                ReasonForNgcWC[reason]
                                                ]
                                              }
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      {props.details?.category === Category.CLASS_ACTION && (
                        <>
                          {approvalValue === AttorneyApprovalStatus.REJECT && (
                            <div className="col-12 mt-3">
                              <TextField
                                fullWidth
                                required
                                label="Describe Reason"
                                variant="outlined"
                                value={otherReasoning}
                                onChange={(e) =>
                                  setOtherReasoning(e.target.value)
                                }
                              />
                            </div>
                          )}
                          {approvalValue === AttorneyApprovalStatus.KEEP && (
                            <>
                              <div className="row mt-3">
                                <TextField
                                  label="Comment"
                                  multiline
                                  variant="outlined"
                                  value={CSAComment}
                                  onChange={(e) =>
                                    setCSAComment(e.target.value)
                                  }
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button
                        color="gray"
                        variant="contained"
                        onClick={() => handleApprovalDialogClose(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={
                          (approvalValue === AttorneyApprovalStatus.KEEP &&
                            !selectedCSA &&
                            !piKeepReason && props.details?.category !== Category.WORKERS_COMPENSATION && props.details?.category !== Category.CLASS_ACTION) ||
                          (approvalValue === AttorneyApprovalStatus.REJECT &&
                            !empRejectReason &&
                            !piRejectReason && !wcRejectReason && props.details?.category !== Category.CLASS_ACTION) ||
                          (props.details?.category ===
                            Category.PERSONAL_INJURY &&
                            approvalValue === AttorneyApprovalStatus.REFER &&
                            !piReferReason) ||
                          (props.details?.category ===
                            Category.PERSONAL_INJURY &&
                            approvalValue === AttorneyApprovalStatus.REFER &&
                            piReferReason &&
                            ReasonForReferPI[piReferReason] ===
                            ReasonForReferPI.NBCAUTO &&
                            !autoComment) ||
                          (props.details?.category ===
                            Category.CLASS_ACTION &&
                            approvalValue === AttorneyApprovalStatus.REJECT &&
                            !otherReasoning)
                        }
                        color="primary"
                        variant="contained"
                        onClick={() => handleApprovalDialogClose(true)}
                      >
                        {HelpersService.camelToString(approvalValue)}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            )}
          </div>
        )}
      {(props.leadType === LeadType.Admin ||
        props.leadType === LeadType.StaffAttorney) &&
        (props.details?.attorneyApprovalStatus ===
          AttorneyApprovalStatus.MORE_INFO_PROVIDED ||
          props.details?.attorneyApprovalStatus ===
          AttorneyApprovalStatus.MORE_INFO_REQUESTED ||
          props.details?.attorneyApprovalStatus ===
          AttorneyApprovalStatus.KEEP ||
          openComments) && (
          <CommentsTab
            leadId={props.details?.id}
            comments={props.details?.comments}
            isNewRequest={openComments}
            onApprovalChange={handleApprovalDialogClose}
            hideActions={
              props.details?.attorneyApprovalStatus ===
              AttorneyApprovalStatus.KEEP
            }
            lowPlacement={
              props.details?.attorneyApprovalStatus ===
              AttorneyApprovalStatus.KEEP
            }
          ></CommentsTab>
        )}
    </>
  );
}
