import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { LeadsService } from "../../../api/services/LeadsService";
import { PaymentData } from "../../../redux/reducers/paymentReducers";
import { LeadType, errorInstructions } from "../../Admin/LeadsManagement/shared/Constants";
import useNavigateSearch from "../../../services/useNavigateSearch";
import { Button } from "@mui/material";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export default function PaymentDone() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lead_id = queryParams.get("lead_id");
  const paymentInfo: PaymentData = useSelector((state: any) => state.payment);

  const navigate = useNavigateSearch();

  useEffect(() => {
    if (!lead_id || !paymentInfo.intent) {
      return;
    }
    LeadsService.postApiLeadsSavePayment(+lead_id, paymentInfo.intent).then(
      () => { }
    ).catch(() => {
      MySwal.fire({
        icon: "error",
        title: <p className="title">Error confirming payment:</p>,
        html: (
          <div className="alert-text">
            {errorInstructions}
          </div>
        ),
      });
    });
  }, [lead_id, paymentInfo]);

  const goBack = () => {
    navigate(`/lead/${lead_id}`, {
      leadType: LeadType.Attorney,
      isEdit: "false",
    });
  }

  return (
    <div className="text-center">
      <>
        <h4>Payment successful!</h4>
        <div className="mt-3">
          You have successfully paid Admin Fee for this lead. You can now see client contact information.
        </div>
        <div className="mt-3">
          <Button
            variant="contained"
            color="primary"
            onClick={goBack}
          >
            Back to lead
          </Button>
        </div>
      </>
    </div>
  );
}
