import FormControl from "@mui/material/FormControl/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import FormLabel from "@mui/material/FormLabel/FormLabel";
import Radio from "@mui/material/Radio/Radio";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import { RadioOptions } from "../../api/models/RadioOptions";
import { HelpersService } from "../../services/Helpers";

interface RadioControlProps {
  isRequired?: boolean;
  requiredError?: boolean;
  addNotSure?: boolean;
  propertyName: string;
  label: string;
  class?: string;
  helperText?: string;
  value: any;
  handleBlur: Function;
  handleChange: Function;
  hideEmpty?: boolean;
}

const RadioOptionsText = {
  [RadioOptions.YES]: "Yes",
  [RadioOptions.NO]: "No",
  [RadioOptions.DONT_KNOW]: "Don't know",
};

export default function RadioControl(props: RadioControlProps) {
  return (
    <>
      {props.hideEmpty && !props.value ? null : (
        <div
          className={
            (!!props.class ? props.class : "col-12 mt-3") + " radio-control"
          }
        >
          <FormControl required={props.isRequired}>
            <FormLabel
              id={props.propertyName + "-label"}
              className={props.requiredError ? "error-message" : ""}
            >
              {props.label}
            </FormLabel>
            {props.helperText && (
              <FormHelperText>{props.helperText}</FormHelperText>
            )}
            <RadioGroup
              row
              aria-labelledby={props.propertyName + "-label"}
              name={props.propertyName}
              value={props.value}
              onBlur={(e) => props.handleBlur(e)}
              onChange={(e) => props.handleChange(e)}
            >
              {Object.keys(RadioOptions)
                .slice(0, props.addNotSure ? 3 : 2)
                .map((key) => {
                  return (
                    <FormControlLabel
                      key={key}
                      value={key}
                      control={<Radio />}
                      label={RadioOptionsText[RadioOptions[key]]}
                    />
                  );
                })}
            </RadioGroup>
            {props.requiredError && (
              <FormHelperText className="error-message">
                Required
              </FormHelperText>
            )}
          </FormControl>
        </div>
      )}
    </>
  );
}
