/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EdmContainerElementKind {
    NONE = 'None',
    ENTITY_SET = 'EntitySet',
    ACTION_IMPORT = 'ActionImport',
    FUNCTION_IMPORT = 'FunctionImport',
    SINGLETON = 'Singleton',
}
