import { TableFilter, TaskExtensionViewModel, UserShortResponse, ZoomCommunicationViewModel } from "../../../../api";
import { LeadShortResponse } from "../../../../api/models/LeadShortResponse";

export enum LeadsTableType {
  Intake = "Intake",
  ActiveClient = "ActiveClient",
}

export interface HeadCell {
  disablePadding?: boolean;
  disableSorting?: boolean;
  id: HeadCellsType;
  label: string;
}

export interface TableInteractionsProps {
  sortAsc: boolean;
  sortBy: string | undefined;
  filters?: TableFilter[];
  page: number;
  rowsPerPage: number;
  filterValue?: string | null;
}

export type HeadCellsType = keyof LeadShortResponse | keyof UserShortResponse | keyof ZoomCommunicationViewModel | keyof TaskExtensionViewModel;

export const IntakeHeadHead: HeadCell[] = [
  {
    id: "attorneyApprovalStatus",
    disablePadding: false,
    disableSorting: true,
    label: "Status",
  },
  {
    id: "leadName",
    disablePadding: false,
    label: "Lead name",
  },
  {
    id: "category",
    disablePadding: false,
    label: "Category",
  },
  {
    id: "reviewingAttorneyName",
    disablePadding: false,
    label: "Reviewing attorney",
  },
  {
    id: "createdBy",
    disablePadding: false,
    label: "Created by",
  },
  {
    id: "createdAt",
    disablePadding: false,
    label: "Created at",
  },
  {
    id: "updatedAt",
    disablePadding: false,
    label: "Updated at",
  },
];

export const ActiveClientsHead: HeadCell[] = [
  {
    id: "status",
    disablePadding: false,
    label: "Stage",
  },
  {
    id: "leadName",
    disablePadding: false,
    label: "Lead name",
  },
  {
    id: "category",
    disablePadding: false,
    label: "Category",
  },
  {
    id: "reviewingAttorney",
    disablePadding: false,
    label: "Reviewing attorney",
  },
  {
    id: "createdBy",
    disablePadding: false,
    label: "Created by",
  },
  {
    id: "createdAt",
    disablePadding: false,
    label: "Created at",
  },
  {
    id: "contractSentDate",
    disablePadding: false,
    label: "Contract sent",
  },
];
export const ReviewAdminHead: HeadCell[] = [
  {
    id: "attorneyApprovalStatus",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "leadName",
    disablePadding: false,
    label: "Lead name",
  },
  {
    id: "category",
    disablePadding: false,
    label: "Category",
  },
  {
    id: "createdBy",
    disablePadding: false,
    label: "Intake staff",
  },
  {
    id: "reviewingAttorneyName",
    disablePadding: false,
    label: "Reviewer attorney",
  },
  {
    id: "updatedAt",
    disablePadding: false,
    label: "Time from submission",
  },
];

export const ReviewAttorneyHead: HeadCell[] = [
  {
    id: "attorneyApprovalStatus",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "leadName",
    disablePadding: false,
    label: "Lead name",
  },
  {
    id: "category",
    disablePadding: false,
    label: "Category",
  },
  {
    id: "updatedAt",
    disablePadding: false,
    label: "Updated at",
  },
];

export const ArchivedHead: HeadCell[] = [
  {
    id: "status",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "leadName",
    disablePadding: false,
    label: "Lead name",
  },
  {
    id: "category",
    disablePadding: false,
    label: "Category",
  },
  {
    id: "attorney",
    disablePadding: false,
    label: "Attorney",
  },
  {
    id: "archivedReason",
    disablePadding: false,
    label: "Archived reason",
  },
  {
    id: "createdAt",
    disablePadding: false,
    label: "Created at",
  },
];

export const ReferralHead: HeadCell[] = [
  {
    id: "status",
    disablePadding: false,
    label: "Stage",
  },
  {
    id: "leadName",
    disablePadding: false,
    label: "Lead name",
  },
  {
    id: "category",
    disablePadding: false,
    label: "Category",
  },
  {
    id: "attorney",
    disablePadding: false,
    label: "Attorney",
  },
  {
    id: "createdBy",
    disablePadding: false,
    label: "Created by",
  },
  {
    id: "createdAt",
    disablePadding: false,
    label: "Created at",
  },
  {
    id: "updatedAt",
    disablePadding: false,
    label: "Updated at",
  },
];

export const SharedAttorneyHead: HeadCell[] = [
  {
    id: "status",
    disablePadding: false,
    label: "Stage",
  },
  {
    id: "leadName",
    disablePadding: false,
    label: "Lead name",
  },
  {
    id: "category",
    disablePadding: false,
    label: "Category",
  },
  {
    id: "updatedAt",
    disablePadding: false,
    label: "Last update",
  },
];

export const SharedAttorneyHeadMobile: HeadCell[] = [
  {
    id: "status",
    disablePadding: false,
    label: "Stage",
  },
  {
    id: "category",
    disablePadding: false,
    label: "Category",
  },
];

export const ExtensionsAdminHead: HeadCell[] = [
  {
    id: "status",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "requestedByName",
    disablePadding: false,
    disableSorting: true,
    label: "By",
  },
  {
    id: "totalOverdue",
    disablePadding: false,
    label: "Days over due",
  },
  {
    id: "taskName",
    disablePadding: false,
    label: "Task",
  },
  {
    id: "materName",
    disablePadding: false,
    label: "Matter",
  },
  {
    id: "currentDueDate",
    disablePadding: false,
    label: "Initial Due Date",
  },
  {
    id: "requestedDueDate",
    disablePadding: false,
    label: "Requested Due Date",
  },
  {
    id: "updatedDueDate",
    disablePadding: false,
    label: "Clio Due Date",
  },
  {
    id: "requestMessage",
    disablePadding: false,
    label: "Message",
  },
];
export const ExtensionsStaffHead: HeadCell[] = [
  {
    id: "status",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "taskName",
    disablePadding: false,
    label: "Task",
  },
  {
    id: "materName",
    disablePadding: false,
    label: "Matter",
  },
  {
    id: "currentDueDate",
    disablePadding: false,
    label: "Initial Due Date",
  },
  {
    id: "requestedDueDate",
    disablePadding: false,
    label: "Requested Due Date",
  },
  {
    id: "updatedDueDate",
    disablePadding: false,
    label: "Clio Due Date",
  },
  {
    id: "adminMessage",
    disablePadding: false,
    label: "Message",
  },
];


export const DEFAULT_ROWS_PER_PAGE = 10;

export interface Action {
  name: string;
  function: Function;
}

export type LeadData = LeadShortResponse & { actions: Action[] };

export enum LeadAttorneyStatusText {
  IN_PROCESS = "Need attorney approval",
  REFER = "Ready to refer",
  MORE_INFO_PROVIDED = "Additional info provided",
  MORE_INFO_REQUESTED = "Need additional info",
}

export enum AttorneyReviewStatusText {
  IN_PROCESS = "Approval requested",
  MORE_INFO_PROVIDED = "Additional info provided",
  MORE_INFO_REQUESTED = "Need additional info",
}
