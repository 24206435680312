import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationRole, FileService, FileType, LeadViewModel, OpenAPI } from "../../api";
import { FileModel, maxFileSize } from "../../pages/Admin/LeadsManagement/shared/Constants";
import { Button } from "@mui/material";
import { startSpinner, stopSpinner } from "../../redux/actions/spinnerActions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { useEffect, useState } from "react";

const MySwal = withReactContent(Swal);
interface FilesComponentProps {
    leadData?: LeadViewModel;
}

export default function FilesDropdown(props: FilesComponentProps) {
    const [files, setFiles] = useState<any[]>([]);
    const user = useSelector((state: any) => state.user?.user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!!props.leadData?.files) {
            const filesMapped = props.leadData?.files.map((file) => ({
                url: file.url!,
                key: file.name!,
            }));
            setFiles(filesMapped);
        }
    }, [props.leadData?.files]);

    const handleFilesUpdate = async (acceptedFiles) => {
        dispatch(startSpinner());
        const sameFiles: FileModel[] = files.filter((oldFile) =>
            acceptedFiles.some((newFile) => newFile.name === oldFile.key)
        );
        if (sameFiles.length > 0) {
            MySwal.fire({
                title: <strong>You are trying to add files that already exist</strong>,
                html: (
                    <div>
                        {sameFiles.map((file) => `${file.key}, `)} will be replaced.
                    </div>
                ),
                icon: "info",
                showCancelButton: true,
                cancelButtonText: "No, keep it",
                confirmButtonText: "Yes, replace",
                confirmButtonColor: "#ed633a",
                reverseButtons: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (props.leadData?.id) {
                        await updateFiles(props.leadData?.id!, acceptedFiles);
                    }
                    const removeDups = files.filter(
                        (file) => !sameFiles.some((sameFile) => file.key === sameFile.key)
                    );
                    setFiles([...removeDups, ...parseFiles(acceptedFiles)]);
                }
                dispatch(stopSpinner());
            });
        } else {
            if (props.leadData?.id) {
                await updateFiles(props.leadData.id!, acceptedFiles);
            }
            setFiles([...files, ...parseFiles(acceptedFiles)]);
            dispatch(stopSpinner());
        }
    };

    const updateFiles = async (leadId, filesToUpdate: any = null) => {
        dispatch(startSpinner());
        if (filesToUpdate === null) {
            filesToUpdate = files.map((file) => file.file);
            filesToUpdate = filesToUpdate.filter((file) => file !== undefined);
        }
        if (filesToUpdate.length > 0) {
            const formData = new FormData();
            for (let i = 0; i < filesToUpdate.length; i++) {
                formData.append(`files`, filesToUpdate[i]);
            }
            const url = `${OpenAPI.BASE}/api/File/${leadId}/upload`;
            await axios
                .post(url, formData, {
                    params: {
                        type: FileType.LEAD_FILE,
                    },
                })
                .catch(() => {
                    MySwal.fire({
                        icon: "error",
                        title: <p className="title">Error uploading files.</p>,
                    });
                })
                .finally(() => dispatch(stopSpinner()));
        } else {
            dispatch(stopSpinner());
        }
    };

    const parseFiles = (acceptedFiles) =>
        acceptedFiles.map((file) => {
            return {
                key: file.name,
                file,
            };
        });

    const handleFileRemove = (key) => {
        dispatch(startSpinner());
        MySwal.fire({
            title: <strong>Confirm file removal</strong>,
            html: <div>{key} will be deleted from this lead.</div>,
            icon: "info",
            showCancelButton: true,
            cancelButtonText: "No, keep it",
            confirmButtonText: "Yes, remove",
            confirmButtonColor: "#ed633a",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                setFiles((prevFiles) => prevFiles.filter((file) => file.key !== key));
                await FileService.postApiFileDelete(props.leadData?.id!, {
                    fileNames: [key],
                }).catch(() => {
                    MySwal.fire({
                        icon: "error",
                        title: <p className="title">Error removing files</p>,
                    });
                });
            }
            dispatch(stopSpinner());
        });
    };

    return (<div className="row mt-5">
        <h5 className="title">Files</h5>
        {(user.roles?.includes(ApplicationRole.ADMIN) ||
            user.roles?.includes(ApplicationRole.STAFF)) &&
            <div>
                Maximum file size is 100MB. Allowed file types are limited, if
                you cannot upload file that you need, please contact admin.
            </div>}
        <Dropzone
            accept={{
                "application/pdf": [],
                "image/*": [],
                "video/*": [],
                "application/msword": [],
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    [],
                "application/vnd.ms-excel": [],
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    [],
            }}
            disabled={
                !user.roles?.includes(ApplicationRole.ADMIN) &&
                !user.roles?.includes(ApplicationRole.STAFF)
            }
            onDrop={(acceptedFiles, fileRejections) =>
                handleFilesUpdate(acceptedFiles)
            }
            minSize={0}
            maxSize={maxFileSize}
        >
            {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
                fileRejections,
            }) => {
                const isFileTooLarge =
                    fileRejections.length > 0 &&
                    fileRejections[0].file.size > maxFileSize;
                return (
                    <section>
                        <>
                            {(user.roles?.includes(ApplicationRole.ADMIN) ||
                                user.roles?.includes(ApplicationRole.STAFF)) && (
                                    <div {...getRootProps()} className="dropzone">
                                        <input {...getInputProps()} />
                                        {!isDragActive &&
                                            "Click to select files or drag and drop"}
                                        {isDragActive && !isDragReject && "Drop file"}
                                        {isDragReject && "File type not accepted"}
                                        {isFileTooLarge && (
                                            <div className="text-danger ms-2">
                                                File that you tries to upload is too large.
                                            </div>
                                        )}
                                    </div>
                                )}
                            {files.map((fileObj) => {
                                const { key, file, url } = fileObj;
                                return (
                                    <div key={key}>
                                        {file ? (
                                            <div>
                                                {file.name}
                                                {(user.roles?.includes(ApplicationRole.ADMIN) ||
                                                    user.roles?.includes(ApplicationRole.STAFF)) && (
                                                        <Button
                                                            className="ms-2"
                                                            variant="contained"
                                                            color="error"
                                                            size="small"
                                                            onClick={() => handleFileRemove(key)}
                                                        >
                                                            Remove
                                                        </Button>
                                                    )}
                                            </div>
                                        ) : (
                                            <div className="mt-2">
                                                <a
                                                    href={url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {key}
                                                </a>
                                                {(user.roles?.includes(ApplicationRole.ADMIN) ||
                                                    user.roles?.includes(ApplicationRole.STAFF)) && (
                                                        <Button
                                                            className="ms-2"
                                                            variant="contained"
                                                            color="error"
                                                            size="small"
                                                            onClick={() => handleFileRemove(key)}
                                                        >
                                                            Remove
                                                        </Button>
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </>
                    </section>
                );
            }}
        </Dropzone>
    </div>);
}