import { Component } from "react";
import Card from "@mui/material/Card/Card";
import CardContent from "@mui/material/CardContent/CardContent";
import CardActions from "@mui/material/CardActions/CardActions";
import Button from "@mui/material/Button/Button";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { UserShortResponse } from "../../../../api";
import { HelpersService } from "../../../../services/Helpers";

interface CardProps {
  action: Function;
  attorney: UserShortResponse;
}
export function AttorneyCard(props: CardProps) {
  return (
    <div className="col-6">
      <Card className="attorneys-card">
        <CardContent>
          <div className="row">
            <div className="col-4 card-image">
              <img width={"150px"}
                height={"150px"} src={props.attorney?.photo ?? "./DefaultAvatar.svg"} ></img>
            </div>
            <div className="col-8 card-info">
              <h6>{props.attorney.firstName} {props.attorney.lastName}</h6>
              <h6 className="mt-3">PRACTICE AREA:</h6>
              <div>{HelpersService.camelToString(props.attorney.practiceArea)}</div>
            </div>
          </div>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            endIcon={<ArrowForwardIosIcon />}
            onClick={() => props.action(props.attorney.id)}
          >
            View details
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
