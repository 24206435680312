/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AttorneyApprovalStatus {
    IN_PROCESS = 'InProcess',
    REFER = 'Refer',
    REJECT = 'Reject',
    KEEP = 'Keep',
    MORE_INFO_REQUESTED = 'MoreInfoRequested',
    MORE_INFO_PROVIDED = 'MoreInfoProvided',
}
