import { Component } from "react";
import { Container } from "reactstrap";
import NavMenu from "./navigation/NavMenu";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <NavMenu />
        <Container className="root-container">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {this.props.children}
          </LocalizationProvider>
        </Container>
      </div>
    );
  }
}
