import { useEffect, useState } from "react";
import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";

import {
  ClioService,
  EntryType,
  MatterPreviewResponse,
  Path,
  TaskPreviewResponse,
  ZoomCommunicationViewModel,
  ZoomService,
  ZoomTableRequest,
  ZoomUserResponse,
} from "../../api";
import "./ZoomPhone.scss";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import EnhancedTableHead from "../Admin/LeadsManagement/LeadsTables/LeadTableHeader";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DEFAULT_ROWS_PER_PAGE, TableInteractionsProps } from "../Admin/LeadsManagement/LeadsTables/LeadsTableConstants";
import { useDispatch } from "react-redux";
import { startSpinner, stopSpinner } from "../../redux/actions/spinnerActions";
dayjs.extend(utc);
dayjs.extend(timezone);

const MySwal = withReactContent(Swal);

export interface PhoneContact {
  phoneNumber: string;
  name: string;
}

export default function ZoomPhone() {
  const zoomPhoneKey = "ZOOM_PHONE_ID";
  const zoomExtKey = "ZOOM_EXT_ID";

  const [callMatterOptions, setCallMatterOptions] = React.useState<
    readonly MatterPreviewResponse[]
  >([]);
  const [callMatterValue, setCallMatterValue] =
    React.useState<MatterPreviewResponse | null>(null);
  const [callInputValue, setCallInputValue] = React.useState("");
  const [callContactOptions, setCallContactOptions] = React.useState<
    readonly PhoneContact[]
  >([]);
  const [callContactValue, setCallContactValue] =
    React.useState<PhoneContact | null>(null);
  const [callInProgress, setCallInProgress] = React.useState<boolean>(false);

  const callContactValueRef = React.useRef(callContactValue);
  const callMatterValueRef = React.useRef(callMatterValue);

  const [matterValue, setMatterValue] =
    React.useState<MatterPreviewResponse | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [matterOptions, setMatterOptions] = React.useState<
    readonly MatterPreviewResponse[]
  >([]);
  const [taskValue, setTaskValue] = React.useState<TaskPreviewResponse | null>(
    null
  );
  const [taskOptions, setTaskOptions] = React.useState<
    readonly TaskPreviewResponse[]
  >([]);
  const [notesValue, setNotesValue] = React.useState<string | null>(null);

  const [zoomUsers, setZoomUsers] = React.useState<ZoomUserResponse[]>([]);
  const [selectedPhone, setSelectedPhone] = React.useState<string>("");

  const [zoomCommunications, setZoomCommunications] = React.useState<
    ZoomCommunicationViewModel[]
  >([]);
  const [selectedComm, setSelectedComm] =
    React.useState<ZoomCommunicationViewModel | null>(null);

  const [selectUserDialogОpen, setSelectUserDialogОpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const [selectedCalls, setSelectedCalls] = useState<number[]>([]);
  const [assignCallDialogOpen, setAssignCallDialogOpen] = React.useState(false);

  const [tableInteractions, setTableInteractions] =
    useState<TableInteractionsProps>({
      sortAsc: false,
      sortBy: "created",
      filterValue: null,
      page: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    });
  const [totalCount, setTotalCount] = useState(0);
  const [bulkClicked, setBulkClicked] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    loadZoomCommunications();
  }, [tableInteractions]);

  const handleCommunicationClick = (index: number) => {
    setSelectedCalls([]);
    setBulkClicked(false);
    const selectedComm = zoomCommunications[index];
    setSelectedComm(selectedComm);
    if (selectedComm.matterId) {
      setMatterValue({
        id: selectedComm.matterId,
        description: selectedComm.matterName,
      });
      loadTasks(selectedComm.matterId);
    } else {
      setMatterValue(null);
    }
    setMatterOptions([]);

    setTaskOptions([]);
    setTaskValue(null);
    setNotesValue(selectedComm.type == EntryType.TEXT ? (selectedComm.message ?? "") : "");
    setAssignCallDialogOpen(true);
  };

  const handleBulkSave = () => {
    var comms = zoomCommunications.filter(c => selectedCalls.includes(c.id!));
    setSelectedComm(comms[0]);
    const matter = comms.filter(c => !!c.matterId)[0];

    if (!!matter?.matterId) {
      setMatterValue({
        id: matter.matterId,
        description: matter.matterName,
      });
      loadTasks(matter.matterId);
    } else {
      setMatterValue(null);
    }
    setMatterOptions([]);

    setTaskOptions([]);
    setTaskValue(null);

    var combinedMessages = comms.filter(c => !!c.message).sort((a, b) => (a.id ?? 0) - (b.id ?? 0)).map(c => c.message).join("\n \n");
    setNotesValue(combinedMessages);
    setAssignCallDialogOpen(true);
    setBulkClicked(true);
  };

  const handleZoomUserChange = (event: SelectChangeEvent) => {
    setSelectedPhone(event.target.value as string);
  };

  const loadZoomUsers = async () => {
    const response = await ZoomService.getApiZoomUsers();
    setZoomUsers(response);
    setSelectUserDialogОpen(true);
  };

  const handleUserSelected = async () => {
    const user = zoomUsers.find((f) => f.phoneNumber === selectedPhone);
    if (!user) {
      return;
    }
    localStorage.setItem(zoomPhoneKey, user?.phoneNumber as any);
    localStorage.setItem(zoomExtKey, user?.extPhoneNumber as any);
    setSelectUserDialogОpen(false);
    await loadZoomCommunications();
  };

  async function loadZoomCommunications() {
    const phoneId = localStorage.getItem(zoomPhoneKey);
    const extId = localStorage.getItem(zoomExtKey);

    if (!phoneId && !extId) {
      await loadZoomUsers();
      return;
    }
    dispatch(startSpinner());
    var request = {
      phone: phoneId ?? "N/A",
      extPhone: extId ?? "N/A",
      limit: tableInteractions.rowsPerPage,
      offset: tableInteractions.rowsPerPage * tableInteractions.page,
      sortBy: tableInteractions.sortBy,
      sortAscending: tableInteractions.sortAsc,
      filterValue: tableInteractions.filterValue
    } as ZoomTableRequest;

    const response = await ZoomService.postApiZoomPending(request);
    setZoomCommunications(response.communications ?? []);
    setTotalCount(response.totalCount ?? 0);
    dispatch(stopSpinner());
  }

  async function loadTasks(matterId: number) {
    if (!matterId) {
      setTaskOptions([]);
      setTaskValue(null);
      setNotesValue("");
      return;
    }

    var responseOptions = await ClioService.getApiClioMattersTasks(matterId);
    responseOptions.push({ name: "Other", id: undefined });
    responseOptions = [{ name: "Call with client", id: 0 }, { name: "Call with OC", id: 1 }, { name: "Text with client", id: 2 }, { name: "Text with OC", id: 3 }, { name: "Internal call", id: 4 }, { name: "Internal text", id: 5 }, ...responseOptions];
    setTaskOptions(responseOptions);
  }

  async function loadContacts(matterId: number) {
    if (!matterId) {
      setCallContactOptions([]);
      setCallContactValue(null);
      return;
    }

    const responseOptions = await ClioService.getApiClioMattersContacts(
      matterId
    );
    let contacts: PhoneContact[] = [];
    responseOptions.forEach((contact) => {
      const contactPhones = contact.phone_numbers?.map((m) => {
        return {
          name: `${contact.relationship_name}: ${contact.name}`,
          phoneNumber: normalizePhone(m.number),
        };
      });

      contacts = contacts.concat(contactPhones as PhoneContact[]);
    });

    setCallContactOptions(contacts);
  }

  function normalizePhone(phoneNumber: string | null | undefined) {
    if (!phoneNumber) {
      return "";
    }

    if (!phoneNumber.startsWith("+")) {
      phoneNumber = `+1${phoneNumber}`;
    }

    return phoneNumber
      .replace("-", "")
      .replace("-", "")
      .replace("(", "")
      .replace(")", "");
  }

  const fetchMatters = React.useMemo(
    () =>
      debounce((input: string) => {
        ClioService.getApiClioMatters(input).then((response) => {
          let newOptions: readonly MatterPreviewResponse[] = [];
          if (matterValue) {
            newOptions = [matterValue];
          }
          if (response) {
            newOptions = [...newOptions, ...response];
          }

          setMatterOptions(newOptions);
        });
      }, 200),
    []
  );

  const fetchCallMatters = React.useMemo(
    () =>
      debounce((input: string) => {
        ClioService.getApiClioMatters(input).then((response) => {
          let newOptions: readonly MatterPreviewResponse[] = [];
          if (matterValue) {
            newOptions = [matterValue];
          }
          if (response) {
            newOptions = [...newOptions, ...response];
          }

          setCallMatterOptions(newOptions);
        });
      }, 200),
    []
  );

  useEffect(() => {
    if (inputValue === "") {
      setMatterOptions(matterValue ? [matterValue] : []);
      return undefined;
    }

    fetchMatters(inputValue);

    return () => { };
  }, [matterValue, inputValue, fetchMatters]);

  useEffect(() => {
    if (callInputValue === "") {
      setCallMatterOptions(callMatterValue ? [callMatterValue] : []);
      return undefined;
    }

    fetchCallMatters(callInputValue);

    return () => { };
  }, [callMatterValue, callInputValue, fetchCallMatters]);

  useEffect(() => {
    callContactValueRef.current = callContactValue;
  }, [callContactValue]);

  useEffect(() => {
    callMatterValueRef.current = callMatterValue;
  }, [callMatterValue]);

  useEffect(() => {
    window.addEventListener("message", handleZoomEvents);
    loadZoomCommunications();
    return function cleanup() {
      window.removeEventListener("message", handleZoomEvents);
    };
  }, []);

  const handleZoomEvents = async (e) => {
    const data = e.data;
    if (data) {
      // console.log("Message:" + JSON.stringify(data));
      switch (data.type) {
        case "zp-call-ringing-event": {
          setCallInProgress(true);
          break;
        }
        case "zp-call-connected-event": {
          if (callContactValueRef.current) {
            setTimeout(async () => {
              await ZoomService.postApiZoomMatter({
                callId: data.data.callId,
                matterId: callMatterValueRef.current?.id,
                matterName: callMatterValueRef.current?.description,
                clientName: callContactValueRef.current!.name,
              });
            }, 3000);
          }
          setCallInProgress(true);
          break;
        }
        case "zp-call-log-completed-event": {
          setTimeout(async () => {
            if (callContactValueRef.current) {
              await ZoomService.postApiZoomMatter({
                callId: data.data.callId,
                matterId: callMatterValueRef.current?.id,
                matterName: callMatterValueRef.current?.description,
                clientName: callContactValueRef.current!.name,
              });
            }
            setCallMatterOptions([]);
            setCallMatterValue(null);
            setCallContactOptions([]);
            setCallContactValue(null);
            setCallInProgress(false);

            await loadZoomCommunications();
          }, 3000);
          break;
        }
        case "zp-call-voicemail-received-event":
        case "zp-sms-log-event":
          setCallInProgress(false);
          setCallMatterOptions([]);
          setCallMatterValue(null);
          setCallContactOptions([]);
          setCallContactValue(null);
          setTimeout(async () => {
            await loadZoomCommunications();
          }, 3000);
          break;
        default: { break; }
      }
    }
  };

  function handleCall() {
    var phoneNumber = callContactValue?.phoneNumber;
    var zoomUrl = `zoomphonecall://${phoneNumber}`;
    window.location.href = zoomUrl;
  }

  async function onSubmit() {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    setSnackbarOpen(true);
    setSnackbarMessage("Creating Clio record!");

    if (bulkClicked && !!selectedCalls.length) {
      setBulkClicked(false);
      ZoomService.postApiZoomBulk({
        bulkCommIds: selectedCalls,
        callId: selectedComm?.callId,
        matterId: matterValue?.id,
        taskId: taskValue?.id,
        taskName: taskValue?.name,
        notes: notesValue,
      })
        .then(() => {
          const newComms = zoomCommunications.filter(
            (f) => !selectedCalls.includes(+f.id!)
          );
          setZoomCommunications(newComms);
        })
        .finally(() => {
          setSnackbarOpen(false);
          setSelectedComm(null);
          setMatterValue(null);
          setTaskValue(null);
          setSelectedCalls([]);
          setNotesValue("");
          setMatterOptions([]);
          setTaskOptions([]);
          setIsSubmitting(false);
          setAssignCallDialogOpen(false);
        });
    }
    else {
      ZoomService.postApiZoom({
        commId: selectedComm?.id ?? undefined,
        callId: selectedComm?.callId,
        matterId: matterValue?.id,
        taskId: taskValue?.id,
        taskName: taskValue?.name,
        notes: notesValue,
      })
        .then(() => {
          const newComms = zoomCommunications.filter(
            (f) => f.callId !== selectedComm?.callId
          );
          setZoomCommunications(newComms);
        })
        .finally(() => {
          setSnackbarOpen(false);
          setSelectedComm(null);
          setMatterValue(null);
          setTaskValue(null);
          setNotesValue("");
          setMatterOptions([]);
          setTaskOptions([]);
          setIsSubmitting(false);
          setAssignCallDialogOpen(false);
        });
    }
  }

  const handleSelectAllClick = () => {
    if (zoomCommunications.length === selectedCalls.length) {
      setSelectedCalls([]);
      return;
    }
    const newSelected = zoomCommunications.map((n) => n.id!);
    setSelectedCalls(newSelected);
    return;
  };

  const handleDeleteTableComm = async (id, e: any = null) => {
    if (e != null) {
      e.preventDefault();
      e.stopPropagation();
    }
    MySwal.fire({
      title: <strong>Confirm communication deletion</strong>,
      icon: "info",
      showCancelButton: true,
      cancelButtonText: "No, keep it",
      confirmButtonText: "Yes, remove",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await ZoomService.postApiZoomStatusDeleted(id);

        const newComms = zoomCommunications.filter((f) => f.id !== id);
        setZoomCommunications(newComms);
      }
    });
  };

  const handleBulkDelete = () => {
    selectedCalls.forEach(id => handleDeleteTableComm(id));
  }

  const handleRowClick = (e, id: number) => {
    e.preventDefault();
    e.stopPropagation();
    const selectedIndex = selectedCalls.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = selectedCalls.concat(id);
    } else if (selectedIndex === 0) {
      newSelected = selectedCalls.slice(1);
    } else if (selectedIndex === selectedCalls.length - 1) {
      newSelected = selectedCalls.slice(0, -1);
    } else if (selectedIndex > 0) {
      newSelected = selectedCalls
        .slice(0, selectedIndex)
        .concat(selectedCalls.slice(selectedIndex + 1));
    }
    setSelectedCalls(newSelected);
  };

  const generateTableRow = (row: ZoomCommunicationViewModel, index) => {
    const isItemSelected = selectedCalls.indexOf(row.id!) !== -1;
    const labelId = `enhanced-table-checkbox-${index}`;
    return (
      <TableRow
        hover
        onClick={() => handleCommunicationClick(index)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
        className={isItemSelected ? "table-row-accent" : ""}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            onClick={(e) => handleRowClick(e, row.id!)}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell>
        <TableCell align="left">
          {row.path} {row.type}
        </TableCell>
        <TableCell align="left">{row.matterName}</TableCell>
        <TableCell align="left">{row.clientPhone}</TableCell>
        <TableCell align="left">{row.clientName}</TableCell>
        <TableCell align="left" className="limit-rows">{row.message}</TableCell>
        <TableCell align="left">{row.duration}</TableCell>
        <TableCell align="left">{dayjs.utc(row.created).local().format("MM/DD/YYYY h:mmA").toString()}</TableCell>
        <TableCell align="left">
          <IconButton
            onClick={(e) => handleDeleteTableComm(row.id, e)}
            edge="end"
          >
            <DeleteIcon className="delete-icon" />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    newSortBy: keyof ZoomCommunicationViewModel
  ) => {
    const toggledOrder =
      tableInteractions.sortBy !== newSortBy
        ? true
        : !tableInteractions.sortAsc;
    const newTableInteractions = {
      ...tableInteractions,
      sortAsc: toggledOrder,
      sortBy: newSortBy,
    };
    setTableInteractions(newTableInteractions);
  };

  const handleFilterChange = (newFilter) => {
    const newTableInteractions = {
      ...tableInteractions,
      filterValue: newFilter
    };
    setTableInteractions(newTableInteractions);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    const newTableInteractions = { ...tableInteractions, page: newPage };
    setTableInteractions(newTableInteractions);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newTableInteractions = {
      ...tableInteractions,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    };
    setTableInteractions(newTableInteractions);
  };

  return (
    <div className="row zoom-container">
      <div className="col-12 col-md-4">
        <div className="col-12">
          <div className="row mt-2">
            <Autocomplete
              disabled={callInProgress}
              getOptionLabel={(option) =>
                (typeof option === "string"
                  ? option
                  : option.description) as string
              }
              filterOptions={(x) => x}
              options={callMatterOptions}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={callMatterValue}
              noOptionsText="No matters to call"
              onChange={(
                event: any,
                selectedValue: MatterPreviewResponse | null
              ) => {
                setCallMatterOptions(
                  selectedValue
                    ? [selectedValue, ...matterOptions]
                    : matterOptions
                );
                setCallMatterValue(selectedValue);
                loadContacts(selectedValue?.id as number);
              }}
              onInputChange={(event, newInputValue) => {
                setCallInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Matter" fullWidth />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Typography variant="body2" color="text.secondary">
                        {option.description}
                      </Typography>
                    </Grid>
                  </li>
                );
              }}
            />
          </div>
          <div className="col-12 mt-3">
            <Autocomplete
              disablePortal
              disabled={!callMatterValue || callInProgress}
              options={callContactOptions}
              value={callContactValue}
              onChange={(event: any, selectedValue: PhoneContact | null) => {
                setCallContactValue(selectedValue);
              }}
              getOptionLabel={(option) =>
                (typeof option === "string" ? option : option.name) as string
              }
              renderInput={(params) => (
                <TextField {...params} label="Choose a contact to call" />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Typography variant="body2" color="text.secondary">
                        {`${option.name} ${option.phoneNumber}`}
                      </Typography>
                    </Grid>
                  </li>
                );
              }}
            />
          </div>
          <div className="col-12 mt-3">
            <Button
              disabled={!callContactValue || callInProgress}
              variant="contained"
              color="primary"
              type="submit"
              className="col-12"
              onClick={handleCall}
            >
              Call {callContactValue?.phoneNumber}
            </Button>
          </div>
        </div>
        <div className="col-12 p-3">
          <iframe
            id="zoom-embeddable-phone-iframe"
            src="https://applications.zoom.us/integration/phone/embeddablephone/home"
            allow="clipboard-read; clipboard-write https://applications.zoom.us"
          ></iframe>
        </div>
      </div>
      <div className="col-12 col-md-8 row align-content-start">
        <Tooltip title="Only texts could be sent together" arrow>
          <div className="col-6 col-md-3 p-3">
            <Button
              disabled={selectedCalls?.length === 0}
              variant="contained"
              color="primary"
              className=""
              onClick={handleBulkSave}>
              Save selected
            </Button></div>
        </Tooltip>
        <Button
          disabled={selectedCalls?.length === 0}
          variant="contained"
          color="primary"
          className="col-6 col-md-3 m-3"
          onClick={handleBulkDelete}>
          Delete selected
        </Button>
        <div className="col-3 mb-3">
          <TextField
            variant="outlined"
            label="Search in logs"
            className="mt-3"
            value={tableInteractions.filterValue}
            onChange={(e) => handleFilterChange(e.target.value)}
          />
        </div>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size="medium">
            <EnhancedTableHead
              displaySelectAll
              onSelectAllClick={handleSelectAllClick}
              headCells={[
                {
                  id: "path",
                  disablePadding: false,
                  label: "Type",
                },
                {
                  id: "matterName",
                  disablePadding: false,
                  label: "Matter name",
                },
                {
                  id: "clientPhone",
                  disablePadding: false,
                  label: "Callee phone",
                },
                {
                  id: "clientName",
                  disablePadding: false,
                  label: "Callee name",
                },
                {
                  id: "message",
                  disablePadding: false,
                  label: "Text message",
                },
                {
                  id: "duration",
                  disablePadding: false,
                  label: "Duration",
                },
                {
                  id: "created",
                  disablePadding: false,
                  label: "Created at",
                },
              ]}
              numSelected={selectedCalls.length}
              rowCount={zoomCommunications.length}
              removeActions={true}
              sortAsc={tableInteractions.sortAsc}
              sortBy={tableInteractions.sortBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {zoomCommunications
                ? zoomCommunications.map((row, index) => {
                  return generateTableRow(row, index);
                })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={tableInteractions.rowsPerPage}
          page={tableInteractions.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

      <Snackbar open={snackbarOpen} autoHideDuration={4000}>
        <Alert severity="success" sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={selectUserDialogОpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Please select your user from the list
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <Select onChange={handleZoomUserChange} value={selectedPhone}>
              {zoomUsers.map((m) => (
                <MenuItem value={m.phoneNumber as string}>{m.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUserSelected} autoFocus>
            Select user
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={assignCallDialogOpen} className="assign-dialog">
        <DialogContent>
          <div className="col-12">
            {selectedComm !== null && (
              <Card className="p-3 mt-2 sticky-form">
                <div className="row mt-3">
                  <Autocomplete
                    getOptionLabel={(option) =>
                      (typeof option === "string"
                        ? option
                        : option.description) as string
                    }
                    filterOptions={(x) => x}
                    options={matterOptions}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={matterValue}
                    noOptionsText="No matters"
                    onChange={(
                      event: any,
                      selectedValue: MatterPreviewResponse | null
                    ) => {
                      setMatterOptions(
                        selectedValue
                          ? [selectedValue, ...matterOptions]
                          : matterOptions
                      );
                      setMatterValue(selectedValue);
                      loadTasks(selectedValue?.id as number);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Matter" fullWidth />
                    )}
                  />
                </div>
                <div className="row mt-3">
                  <Autocomplete
                    disabled={!matterValue}
                    options={taskOptions}
                    value={taskValue}
                    onChange={(
                      event: any,
                      selectedValue: TaskPreviewResponse | null
                    ) => {
                      setTaskValue(selectedValue);
                    }}
                    getOptionLabel={(option) =>
                      (typeof option === "string"
                        ? option
                        : option.name) as string
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Choose a task" />
                    )}
                  />
                </div>

                <div className="col-12 mt-3">
                  <TextField
                    value={notesValue}
                    onChange={(e) => setNotesValue(e.target.value)}
                    disabled={!matterValue}
                    className="col-12"
                    label="Notes"
                    multiline
                    rows={4}
                  />
                </div>
              </Card>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <div></div>
          <Button
            disabled={!matterValue || !taskValue}
            variant="contained"
            color="primary"
            type="submit"
            className="col-6 mt-3"
            onClick={onSubmit}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className="col-6 mt-3"
            onClick={() => setAssignCallDialogOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
