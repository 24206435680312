import Button from "@mui/material/Button/Button";
import { useEffect, useState } from "react";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Stage, LeadsBlock } from "../../LeadsManagement/shared/Constants";
import { useNavigate, useParams } from "react-router-dom";
import { UserViewModel, UsersService } from "../../../../api";
import UserForm from "./UserForm";
import { useDispatch } from "react-redux";
import {
  startSpinner,
  stopSpinner,
} from "../../../../redux/actions/spinnerActions";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableContainer,
  TextField,
} from "@mui/material";
import AttorneyLeadsRow from "./AttorneyLeadsRow";

const MySwal = withReactContent(Swal);

const rows: LeadsBlock[] = [
  {
    id: "0",
    status: Stage.new,
    leads: [
      { id: "1", leadName: "John Snow" },
      { id: "2", leadName: "Adam Novice" },
      { id: "3", leadName: "Damian Jefferson" },
    ],
  },
  {
    id: "1",
    status: Stage.accepted,
    leads: [
      { id: "4", leadName: "John Snow" },
      { id: "5", leadName: "Adam Novice" },
      { id: "6", leadName: "Damian Jefferson" },
    ],
  },
  {
    id: "2",
    status: Stage.rejected,
    leads: [
      { id: "7", leadName: "John Snow" },
      { id: "8", leadName: "Adam Novice" },
      { id: "9", leadName: "Damian Jefferson" },
    ],
  },
  {
    id: "3",
    status: Stage.won,
    leads: [
      { id: "10", leadName: "John Snow" },
      { id: "11", leadName: "Adam Novice" },
      { id: "12", leadName: "Damian Jefferson" },
    ],
  },
  {
    id: "4",
    status: Stage.lost,
    leads: [
      { id: "13", leadName: "John Snow" },
      { id: "14", leadName: "Adam Novice" },
      { id: "15", leadName: "Damian Jefferson" },
    ],
  },
];

export interface AttorneyDetailsProps {
  isNew?: boolean;
}

export default function AttorneysDetailsView(props: AttorneyDetailsProps) {
  const [user, setUser] = useState<UserViewModel>({} as UserViewModel);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false);
  const [emailMessage, setEmailMessage] = useState<string>();
  const [shortName, setShortName] = useState<string>("");

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsEdit(props.isNew ?? false);
    if (!id) {
      return;
    }
    UsersService.getApiUsers(+id).then((user) => {
      setUser(user);
      setShortName(user.shortCompanyName ?? "");
    });
  }, [id]);

  const handleEditClick = () => {
    setIsEdit(!isEdit);
  };

  const approveAttorney = () => {
    if (!shortName) {
      MySwal.fire({
        title: <strong>Short company name is required.</strong>,
        icon: "error",
      });
      return;
    }
    dispatch(startSpinner());
    MySwal.fire({
      title: <strong>Confirm approval</strong>,
      html: (
        <div>
          You are about to approve Attorney {user.firstName} {user.lastName}.
          <br></br>
          Are you sure you want to continue?
        </div>
      ),
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "No, go back",
      confirmButtonText: "Yes, send now",
      confirmButtonColor: "#ed633a",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startSpinner());
        UsersService.postApiUsersApproveAttorney(user.id!, shortName)
          .then(() => {
            MySwal.fire({
              icon: "success",
              title: <p className="title">Success!</p>,
              html: (
                <div className="alert-text">
                  You have added {user.firstName} {user.lastName} as an
                  attorney.
                </div>
              ),
            }).then(() => {
              navigate("/attorneys");
            });
          })
          .finally(() => dispatch(stopSpinner()));
      }
    });
    dispatch(stopSpinner());
  };

  const handleSendEmailClose = (success) => {
    if (success) {
      dispatch(startSpinner());
      UsersService.putApiUsersRequestChanges(user.id!, emailMessage)
        .then(() =>
          MySwal.fire({
            icon: "success",
            title: <p className="title">Email was sent to attorney!</p>,
          })
        )
        .finally(() => dispatch(stopSpinner()));
    }
    setOpenSendEmailDialog(false);
  };

  const handleUpdateShortCLick = () => {
    if (!shortName) {
      MySwal.fire({
        title: <strong>Short company name is required.</strong>,
        icon: "error",
      });
      return;
    }

    dispatch(startSpinner());
    UsersService.putApiUsersUpdateShort(user.id!, shortName)
      .then(() => {
        MySwal.fire({
          icon: "success",
          title: <p className="title">Success!</p>,
        });
      })
      .finally(() => dispatch(stopSpinner()));
  }

  return (
    <div className="row main-holder">
      <div className="col-12 row m-0 p-5">
        <h4 className="title">Attorney Details</h4>
        {!props.isNew && (
          <div className="row sticky-details-top-menu">
            <div className="col-12 col-md-3 mt-3 mb-3">
              <TextField 
                required
                label="Short company name(for agreement)"
                variant="outlined"
                value={shortName}
                onChange={(e) => setShortName(e.target.value)}
              />
            </div>
            {user.isPending ? (
              <>
                <div className="col-12 col-md-3 mt-3 mb-3">
                  <Button
                    variant="contained"
                    color="primary"
                    className="full-btn"
                    onClick={approveAttorney}
                  >
                    Approve
                  </Button>
                </div>
                <div className="col-12 col-md-3 mt-3 mb-3">
                  <Button
                    variant="contained"
                    color="primary"
                    className="full-btn"
                    onClick={() => setOpenSendEmailDialog(true)}
                  >
                    Request changes
                  </Button>
                </div>
              </>
            ) : (<div className="col-12 col-md-3 mt-3 mb-3">
              <Button
                variant="contained"
                color="primary"
                className="full-btn"
                onClick={handleUpdateShortCLick}
              >
                Update short name
              </Button>
            </div>)}
            {!isEdit ? (
              <div className="col-12 col-md-3 mt-3 mb-3">
                <Button
                  variant="contained"
                  color="secondary"
                  className="full-btn"
                  onClick={handleEditClick}
                >
                  Edit attorney
                </Button>
              </div>
            ) : (
              <div className="col-12 col-md-3 mt-3 mb-3">
                <Button
                  variant="contained"
                  color="primary"
                  className="full-btn"
                  onClick={handleEditClick}
                >
                  Cancel edit
                </Button>
              </div>
            )}
          </div>
        )}
        <div className="row mt-3 mb-3">
          {(user.email || props.isNew) && (
            <UserForm
              user={user}
              isEdit={isEdit}
              isNew={props.isNew}
              isAttorney={true}
              onSave={() => setIsEdit(false)}
            ></UserForm>
          )}
        </div>
        {!user.isPending && (
          <>
            <h4 className="title mt-4">Attorney Cases</h4>
            <Table>
              <TableBody>
                {user.attorneyLeads &&
                  Object.entries(user.attorneyLeads).map(([key, value]) => (
                    <AttorneyLeadsRow key={key} status={key} leads={value} />
                  ))}
              </TableBody>
            </Table>
          </>
        )}
      </div>
      <Dialog
        open={openSendEmailDialog}
        onClose={() => handleSendEmailClose(false)}
      >
        <DialogTitle>
          Fill in message that you wish to send to attorney
        </DialogTitle>
        <DialogContent>
          <div className="row main-holder">
            <div className="col-12 mt-3">
              <TextField
                label="Email message"
                variant="outlined"
                multiline
                className="multiline"
                value={emailMessage}
                onChange={(event) => setEmailMessage(event.target.value)}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="gray"
            variant="contained"
            onClick={() => handleSendEmailClose(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={!emailMessage}
            color="primary"
            variant="contained"
            onClick={() => handleSendEmailClose(true)}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
