/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteRequest } from '../models/DeleteRequest';
import type { FileType } from '../models/FileType';
import type { FileViewModel } from '../models/FileViewModel';
import type { ReportRequest } from '../models/ReportRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FileService {

    /**
     * @param id 
     * @param type 
     * @param formData 
     * @returns FileViewModel Success
     * @throws ApiError
     */
    public static postApiFileUpload(
id: number,
type?: FileType,
formData?: {
files?: Array<Blob>;
},
): CancelablePromise<FileViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/File/{id}/upload',
            path: {
                'id': id,
            },
            query: {
                'type': type,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param leadId 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiFileDelete(
leadId: number,
requestBody?: DeleteRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/File/{leadId}/delete',
            path: {
                'leadId': leadId,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getApiFileDownloadUsersTasksReport(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/File/downloadUsersTasksReport',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getApiFileGetOutsideFunnelTasks(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/File/getOutsideFunnelTasks',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiFileGetAttorneyLeadsReport(
requestBody?: ReportRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/File/getAttorneyLeadsReport',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getApiFileGetNoOcMatters(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/File/getNoOCMatters',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getApiFileGetMattersWithOldChanges(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/File/getMattersWithOldChanges',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getApiFileGetMattersToUpdate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/File/getMattersToUpdate',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiFileGetHoursReport(
requestBody?: ReportRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/File/getHoursReport',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiFileGetKpiReport(
requestBody?: ReportRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/File/getKPIReport',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiFileGetSolesAttyReport(
requestBody?: ReportRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/File/getSolesAttyReport',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiFileGetSolesMonthReport(
requestBody?: ReportRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/File/getSolesMonthReport',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

}
