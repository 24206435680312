import IconButton from "@mui/material/IconButton/IconButton";
import TableCell from "@mui/material/TableCell/TableCell";
import TableRow from "@mui/material/TableRow/TableRow";
import React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse/Collapse";
import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody/TableBody";
import { AttorneysStatusMapped } from "../shared/Constants";
import Button from "@mui/material/Button/Button";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AttorneyStatus } from "../../../../api/models/AttorneyStatus";
import { LeadsService } from "../../../../api";
import useNavigateSearch from "../../../../services/useNavigateSearch";
import { startSpinner, stopSpinner } from "../../../../redux/actions/spinnerActions";
import { useDispatch } from "react-redux";

const MySwal = withReactContent(Swal);
interface AttorneysRowProps {
  leadId: number;
  row: AttorneysStatusMapped;
}

export default function AttorneysRow(props: AttorneysRowProps) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigateSearch();
  const dispatch = useDispatch();

  const selectWinner = (attorney) => {
    MySwal.fire({
      title: <strong>Winner confirmation</strong>,
      html: (
        <div>
          Are you sure you want to select <strong>{attorney.name}</strong> as
          winner for your lead?
        </div>
      ),
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "No, go back",
      confirmButtonText: "Yes, confirm",
      confirmButtonColor: "#ed633a",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed)
        dispatch(startSpinner());
      LeadsService.postApiLeadsWinner(props.leadId, {
        attorneyId: attorney.id,
        initiateIntake: false, //attorney.intakeRequested,
      }).then(() => {
        MySwal.fire({
          icon: "success",
          title: <p className="title">Congratulations!</p>,
          html: (
            <div className="alert-text">
              Winner selected. You can now send agreement fee.
            </div>
          ),
        }).then(() => {
          window.location.reload();
        });
      }).finally(() => dispatch(stopSpinner()));
    });
  };

  const startIntake = (attorney) => {
    //TODO: finish logic
    MySwal.fire({
      title: <strong>Intake initiation</strong>,
      html: (
        <div>
          Are you sure you want to begin client intake with{" "}
          <strong>{attorney.name}</strong>?
        </div>
      ),
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "No, go back",
      confirmButtonText: "Yes, confirm",
      confirmButtonColor: "#ed633a",
      reverseButtons: true,
    }).then((result) => {
      // TODO: sent on success, reload lead details after success
      if (result.isConfirmed)
        MySwal.fire({
          icon: "success",
          title: <p className="title">Intake started</p>,
          html: (
            <div className="alert-text">You can now start intake process.</div>
          ),
        }).then(() => {
          navigate("/referral-leads");
        });
    });
  };

  return (
    <React.Fragment>
      <TableRow className="attorneys-table">
        <TableCell scope="row">
          {props.row.status}({props.row.attorneys.length})
        </TableCell>
        <TableCell sx={{ width: "75px" }}>
          <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className="inner-table">
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          className="inner-row"
          colSpan={2}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table>
              <TableBody>
                {props.row.attorneys.map((attorney) => (
                  <TableRow key={attorney.name}>
                    <TableCell scope="row">{attorney.name}</TableCell>
                    <TableCell scope="row">
                      {Object.entries(attorney.countByStatus!)
                        .map(([key, value]) => `${key}(${value})`)
                        .join("/")}
                    </TableCell>
                    {props.row.status === AttorneyStatus.REJECTED &&
                      <TableCell scope="row">
                        {attorney.message}
                      </TableCell>
                    }
                    {/* <TableCell scope="row">
                      {attorney.intakeRequested ? "Intake requested" : ""}
                    </TableCell> */}
                    <TableCell className="text-right">
                      {props.row.status === AttorneyStatus.ACCEPTED && (
                        <>
                          {!!attorney.intakeRequested ? (
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => startIntake(attorney)}
                            >
                              Start Intake
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => selectWinner(attorney)}
                            >
                              Select Winner
                            </Button>
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
