import IconButton from "@mui/material/IconButton/IconButton";
import TextField from "@mui/material/TextField/TextField";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import { useState } from "react";
import { CommentViewModel } from "../../../../../api/models/CommentViewModel";
import { LeadsService } from "../../../../../api/services/LeadsService";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { LeadCommentRequest } from "../../../../../api/models/LeadCommentRequest";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Button from "@mui/material/Button/Button";
import { errorInstructions } from "../../shared/Constants";

const MySwal = withReactContent(Swal);

interface CommentsActionsProps {
  lowPlacement: boolean;
  leadId?: number | null;
  comments?: Array<CommentViewModel> | null;
  isNewRequest?: boolean;
  hideActions?: boolean;
  onApprovalChange?: Function;
}

export default function CommentsTab(props: CommentsActionsProps) {
  const [commentMessage, setCommentMessage] = useState<string>("");
  const [hideMessages, setHideMessages] = useState<boolean>();

  const sendMessage = () => {
    if (!props.comments || props.comments.length === 0) {
      MySwal.fire({
        title: <strong>Confirm info request</strong>,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "No, go back",
        confirmButtonText: "Yes, send now",
        confirmButtonColor: "#ed633a",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed && !!props.leadId) {
          if (props.onApprovalChange) {
            props.onApprovalChange(true);
          }
          LeadsService.postApiLeadsLeaveComment(props.leadId, {
            message: commentMessage,
            updateStatus: true,
          } as LeadCommentRequest);
        }
      }).catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error sending message</p>,
          html: (
            <div className="alert-text">
              {errorInstructions}
            </div>
          ),
        });
      });
      return;
    }
    if (!!props.leadId) {
      LeadsService.postApiLeadsLeaveComment(props.leadId, {
        message: commentMessage,
        updateStatus: true,
      } as LeadCommentRequest).then(() => {
        window.location.reload();
      }).catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error sending message</p>,
          html: (
            <div className="alert-text">
              {errorInstructions}
            </div>
          ),
        });
      });
    }
  };

  return (
    <div className={!!props.lowPlacement ? "row col-3 sticky-details-side-menu side-menu-low p-0": "row col-3 sticky-details-side-menu p-0"}>
      {((props.comments && props.comments.length > 0) || props.isNewRequest) && (
        <div className="p-4">
          <h5
            className="mt-0 hover-pointer"
            onClick={() => setHideMessages(!hideMessages)}
          >
            Messages
            <IconButton>
              {hideMessages ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          </h5>
          {!hideMessages && (
            <>
              {props.comments?.map((comment, index) => {
                dayjs.extend(utc);
                dayjs.extend(tz);
                const timeZone = dayjs.tz.guess();
                const createdDate = dayjs
                  .utc(comment.createdAt)
                  .tz(timeZone)
                  .format("YYYY-DD-MM hh:mA");
                return (
                  <div className="lead-comment-holder">
                    <div className="send-by" key={index}>
                      Sent by:{" "}
                      {comment.isCurrentOwner ? "You" : comment.createdByName}
                      {" at "}
                      {createdDate}
                    </div>
                    <div>{comment.message}</div>
                  </div>
                );
              })}
            </>
          )}
          {!hideMessages && !props.hideActions && (
            <>
              <TextField
                required
                multiline
                label="Message"
                variant="outlined"
                className="col-12 mt-4"
                value={commentMessage}
                inputProps={{ minLength: 10 }}
                onChange={(e) => setCommentMessage(e.target.value)}
              />
              {commentMessage.length < 10 && (
                <div>Message must be at least 10 characters.</div>
              )}
              <Button
                variant="contained"
                color="primary"
                className="full-btn mt-2"
                disabled={commentMessage.length < 10}
                onClick={() => sendMessage()}
              >
                Send message
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
}
