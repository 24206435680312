/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EdmTypeKind {
    NONE = 'None',
    PRIMITIVE = 'Primitive',
    ENTITY = 'Entity',
    COMPLEX = 'Complex',
    COLLECTION = 'Collection',
    ENTITY_REFERENCE = 'EntityReference',
    ENUM = 'Enum',
    TYPE_DEFINITION = 'TypeDefinition',
    UNTYPED = 'Untyped',
    PATH = 'Path',
}
