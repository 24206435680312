/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EdmSchemaElementKind {
    NONE = 'None',
    TYPE_DEFINITION = 'TypeDefinition',
    TERM = 'Term',
    ACTION = 'Action',
    ENTITY_CONTAINER = 'EntityContainer',
    FUNCTION = 'Function',
}
