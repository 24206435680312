import { useSelector } from "react-redux";
import {
  Category,
  LeadViewModel,
} from "../../../../api";
import { LeadType } from "../shared/Constants";
import EmploymentForm from "./EmploymentForm";
import PersonalInjuryForm from "./PersonalInjuryForm";
import WorkersCompForm from "./WorkersCompForm";
import React from "react";
import { loadScript } from "../../../../services/Helpers";
import ClassActionForm from "./ClassActionForm";

interface LeadFormProps {
  selectedCategory?: Category;
  details?: LeadViewModel;
  isEdit?: boolean;
  isNew?: boolean;
  leadType?: LeadType;
}

export default function LeadForm(props: LeadFormProps) {
  const loaded = React.useRef(false);
  if (typeof window !== 'undefined' && !loaded.current) {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
      document.querySelector('head'),
      'google-maps',
    );

    loaded.current = true;
  }
  const user = useSelector((state: any) => state.user?.user);

  return (
    <div>
      {(props.details?.category === Category.EMPLOYMENT ||
        (props.selectedCategory &&
          Category[props.selectedCategory] === Category.EMPLOYMENT)) && (
          <EmploymentForm
            key={props.details?.id}
            data={props.details}
            isEdit={props.isEdit}
            isNew={props.isNew}
            leadType={props.leadType}
            userRoles={user?.roles}
          ></EmploymentForm>
        )}
      {(props.details?.category === Category.PERSONAL_INJURY ||
        (props.selectedCategory &&
          Category[props.selectedCategory] === Category.PERSONAL_INJURY)) && (
          <PersonalInjuryForm
            key={props.details?.id}
            data={props.details}
            isEdit={props.isEdit}
            isNew={props.isNew}
            leadType={props.leadType}
            userRoles={user?.roles}
          ></PersonalInjuryForm>
        )}
      {(props.details?.category === Category.WORKERS_COMPENSATION ||
        (props.selectedCategory &&
          Category[props.selectedCategory] ===
          Category.WORKERS_COMPENSATION)) && (
          <WorkersCompForm
            key={props.details?.id}
            data={props.details}
            isEdit={props.isEdit}
            leadType={props.leadType}
            userRoles={user?.roles}
          ></WorkersCompForm>
        )}
        {(props.details?.category === Category.CLASS_ACTION ||
        (props.selectedCategory &&
          Category[props.selectedCategory] ===
          Category.CLASS_ACTION)) && (
          <ClassActionForm
            key={props.details?.id}
            data={props.details}
            isNew={props.isNew}
            isEdit={props.isEdit}
            leadType={props.leadType}
            userRoles={user?.roles}
          ></ClassActionForm>
        )}
    </div>
  );
}
