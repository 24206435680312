import { useEffect, useState } from "react";
import * as yup from "yup";
import { getIn, useFormik } from "formik";
import TextField from "@mui/material/TextField/TextField";
import FormControl from "@mui/material/FormControl/FormControl";
import FormLabel from "@mui/material/FormLabel/FormLabel";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import Button from "@mui/material/Button/Button";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Select from "@mui/material/Select/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Rating from "@mui/material/Rating/Rating";
import StarIcon from "@mui/icons-material/Star";
import Dropzone from "react-dropzone";
import dayjs from "dayjs";
import {
  AdminLeadStatus,
  ApplicationRole,
  Category,
  FileService,
  FileType,
  InitialClientDataResponse,
  InjuryType,
  LeadsService,
  LeadViewModel,
  OpenAPI,
  PaidType,
  RadioOptions,
  TerminationType,
} from "../../../../api";
import {
  clientDataSchema,
  FileModel,
  LeadType,
  maxFileSize,
} from "../shared/Constants";
import RadioControl from "../../../../components/basic/RadioControl";
import useNavigateSearch from "../../../../services/useNavigateSearch";
import { HelpersService } from "../../../../services/Helpers";
import { useDispatch } from "react-redux";
import {
  startSpinner,
  stopSpinner,
} from "../../../../redux/actions/spinnerActions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import UnsavedChangesWarning from "../../../../services/UnsavedChangesWarning";
import { MuiTelInput } from "mui-tel-input";
import { NumericFormat } from "react-number-format";
import MapsAutocomplete from "../../../../components/basic/MapsAutocomplete";
const MySwal = withReactContent(Swal);

interface WorkersCompProps {
  data?: LeadViewModel;
  isEdit?: boolean;
  leadType?: LeadType;
  userRoles?: ApplicationRole[];
}

const emptyData: LeadViewModel = {
  status: undefined,
  category: Category.WORKERS_COMPENSATION,
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  birthDate: null,

  workersCompDetails: {
    workAddress: "",
    company: "",
    employeesNumber: null,
    jobTitle: "",
    terminationType: HelpersService.getEnumKeyByEnumValue(
      TerminationType,
      TerminationType.TERMINATED
    ),
    hireDate: null,
    terminationDate: null,
    paidType: HelpersService.getEnumKeyByEnumValue(PaidType, PaidType.HOURLY),

    hadInjuries: undefined,
    injuriesDescription: "",
    currentlyRepresentedWC: undefined,
    employedCA: undefined,
    injuryType: undefined,
    havePain: undefined,
    painDescription: "",
    injuryReported: undefined,
    writtenComplains: undefined,
    writtenTo: "",
    writtenWhen: null,
    oralComplains: undefined,
    oralTo: "",
    oralWhen: null,
    treatmentReceived: undefined,
    treatmentDetails: "",
    hasInsurance: undefined,
    paycheckStubs: undefined,
    workersClaimReprimands: undefined,
    workersClaimReprimandsDetails: "",
  },

  csNotes: "",
  credibility: null,
};
export default function WorkersCompForm(props: WorkersCompProps) {
  const [files, setFiles] = useState<any[]>([]);
  const navigate = useNavigateSearch();
  const dispatch = useDispatch();
  const geocoder = new google.maps.Geocoder();

  useEffect(() => {
    if (props.leadType !== LeadType.Attorney || props.data?.status === AdminLeadStatus.PAID) {
      return
    }
    setFieldValue("workersCompDetails.address", "");
    const cityComponent = "locality";
    const stateComponent = "administrative_area_level_1";
    geocoder
      .geocode({
        address: props.data?.workersCompDetails?.address
      })
      .then((response) => {
        if (response.results[0]) {
          const city = response.results[0].address_components.find(address => address.types.includes(cityComponent))?.long_name ?? "";
          const state = response.results[0].address_components.find(address => address.types.includes(stateComponent))?.short_name ?? "";
          setFieldValue("workersCompDetails.address", `${city}, ${state}`);
        }
        else {
          setFieldValue("workersCompDetails.address", "Hidden");
        }
      })
      .catch(() => { setFieldValue("workersCompDetails.address", "Hidden"); });
  }, [props.data?.workersCompDetails]);

  useEffect(() => {
    if (!!props.data?.files) {
      const filesMapped = props.data?.files.map((file) => ({
        url: file.url!,
        key: file.name!,
      }));
      setFiles(filesMapped);
    }
  }, [props.data?.files]);

  const handleFilesUpdate = async (acceptedFiles) => {
    dispatch(startSpinner());
    const sameFiles: FileModel[] = files.filter((oldFile) =>
      acceptedFiles.some((newFile) => newFile.name === oldFile.key)
    );
    if (sameFiles.length > 0) {
      MySwal.fire({
        title: <strong>You are trying to add files that already exist</strong>,
        html: (
          <div>
            {sameFiles.map((file) => `${file.key}, `)} will be replaced.
          </div>
        ),
        icon: "info",
        showCancelButton: true,
        cancelButtonText: "No, keep it",
        confirmButtonText: "Yes, replace",
        confirmButtonColor: "#ed633a",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (values.id) {
            await updateFiles(values.id!, acceptedFiles);
          }
          const removeDups = files.filter(
            (file) => !sameFiles.some((sameFile) => file.key === sameFile.key)
          );
          setFiles([...removeDups, ...parseFiles(acceptedFiles)]);
        }
        dispatch(stopSpinner());
      });
    } else {
      if (values.id) {
        await updateFiles(values.id!, acceptedFiles);
      }
      setFiles([...files, ...parseFiles(acceptedFiles)]);
      dispatch(stopSpinner());
    }
  };

  const parseFiles = (acceptedFiles) =>
    acceptedFiles.map((file) => {
      return {
        key: file.name,
        file,
      };
    });

  const handleFileRemove = (key) => {
    dispatch(startSpinner());
    MySwal.fire({
      title: <strong>Confirm file removal</strong>,
      html: <div>{key} will be deleted from this lead.</div>,
      icon: "info",
      showCancelButton: true,
      cancelButtonText: "No, keep it",
      confirmButtonText: "Yes, remove",
      confirmButtonColor: "#ed633a",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setFiles((prevFiles) => prevFiles.filter((file) => file.key !== key));
        await FileService.postApiFileDelete(values.id!, {
          fileNames: [key],
        }).catch(() => {
          MySwal.fire({
            icon: "error",
            title: <p className="title">Error removing files</p>,
          });
        });
      }
      dispatch(stopSpinner());
    });
  };

  const onSubmit = async (form) => {
    dispatch(startSpinner());
    var newLead = form as LeadViewModel;
    newLead.isDraft = false;

    newLead.workersCompDetails!.terminationType =
      TerminationType[values.workersCompDetails!.terminationType!];
    newLead.category = Category.WORKERS_COMPENSATION;
    newLead.personalInjuryDetails = undefined;
    newLead.employmentDetails = undefined;

    if (values.id) {
      LeadsService.putApiLeads(values.id, newLead)
        .then(() => {
          MySwal.fire({
            icon: "success",
            title: <p className="title">Lead updated successfully</p>,
          }).then(() => window.location.reload());
        })
        .catch((e) => {
          MySwal.fire({
            icon: "error",
            title: <p className="title">Error updating lead</p>,
            html: (
              <div>
                Please make sure email and other fields have proper values.
              </div>
            ),
          });
        })
        .finally(() => dispatch(stopSpinner()));
      return;
    }
    LeadsService.postApiLeads(newLead)
      .then(async (id) => {
        await updateFiles(id);
        MySwal.fire({
          icon: "success",
          title: <p className="title">Lead created successfully</p>,
        }).then(() => navigate("/new-leads"));
      })
      .catch(() =>
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error creating new lead</p>,
          html: (
            <div>
              Please make sure email and other fields have proper values.
            </div>
          ),
        })
      )
      .finally(() => dispatch(stopSpinner()));
  };

  const updateFiles = async (leadId, filesToUpdate: any = null) => {
    dispatch(startSpinner());
    if (filesToUpdate === null) {
      filesToUpdate = files.map((file) => file.file);
      filesToUpdate = filesToUpdate.filter((file) => file !== undefined);
    }
    if (filesToUpdate.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < filesToUpdate.length; i++) {
        formData.append(`files`, filesToUpdate[i]);
      }
      const url = `${OpenAPI.BASE}/api/File/${leadId}/upload`;
      await axios
        .post(url, formData, {
          params: {
            type: FileType.LEAD_FILE,
          },
        })
        .catch(() => {
          MySwal.fire({
            icon: "error",
            title: <p className="title">Error uploading files.</p>,
          });
        })
        .finally(() => dispatch(stopSpinner()));
    } else {
      dispatch(stopSpinner());
    }
  };

  const handleAddIncomplete = () => {
    dispatch(startSpinner());
    clientDataSchema
      .validate(values, { abortEarly: false })
      .then(() => {
        var newLead = { ...values } as LeadViewModel;
        newLead.isDraft = true;

        newLead.workersCompDetails!.terminationType =
          TerminationType[values.workersCompDetails!.terminationType!];
        newLead.category = Category.WORKERS_COMPENSATION;
        newLead.personalInjuryDetails = undefined;
        newLead.employmentDetails = undefined;

        LeadsService.postApiLeads(newLead)
          .then(async (id) => {
            await updateFiles(id);
            MySwal.fire({
              icon: "success",
              title: <p className="title">Lead created successfully</p>,
            }).then(() => navigate("/new-leads"));
          })
          .catch(() => {
            MySwal.fire({
              icon: "error",
              title: <p className="title">Error creating lead on Hubspot</p>,
              html: (
                <div className="alert-text">
                  Please make sure that all fields in Client Info are valid. If
                  you still get this error, please contact admin.
                </div>
              ),
            });
          })
          .finally(() => dispatch(stopSpinner()));
      })
      .catch(() => {
        setFieldTouched("firstName", true);
        setFieldTouched("lastName", true);
        setFieldTouched("phone", true);
        setFieldTouched("email", true);
        setFieldTouched("birthDate", true);
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error creating incomplete lead</p>,
          html: (
            <div className="alert-text">
              Please make sure that all fields in Client Info are filled.
            </div>
          ),
        });
        dispatch(stopSpinner());
      });
  };

  updateUserData = (data: InitialClientDataResponse) => {
    (Object.keys(data) as (keyof typeof data)[]).forEach((key, index) => {
      handleChange({
        target: { value: data[key], name: key },
      });
    });
  };
  const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    phone: yup.string().required(),
    // .test("is-valid-phone", "Invalid phone", function (value) {
    //   return matchIsValidTel(value);
    // }),
    email: yup.string().email().required(),
    birthDate: yup.mixed().required(),

    workersCompDetails: yup.object().shape({
      hireDate: yup.mixed().required(),
      terminationDate: yup
        .mixed()
        .optional()
        .nullable()
        .when("terminationType", {
          is: TerminationType.RESIGNED,
          then: (schema) => schema.required(),
        })
        .when("terminationType", {
          is: TerminationType.TERMINATED,
          then: (schema) => schema.required(),
        })
    }),
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = useFormik({
    initialValues: props.data ?? emptyData,
    validationSchema: schema,
    onSubmit,
  });

  resetFormWith = (values: LeadViewModel) => {
    values
      ? resetForm({ values: values })
      : resetForm({ values: props.data ?? emptyData });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={!props.isEdit ? "hide-actions" : ""}
    >
      <UnsavedChangesWarning
        hasUnsavedChanges={!props.data?.id && Object.keys(touched).length > 0}
      />
      <fieldset disabled={!props.isEdit}>
        {(!!values.isFullSummary ||
          props.leadType === LeadType.Admin ||
          props.leadType === LeadType.StaffAttorney) && (
            <div className="row mt-4">
              <div className="col-12 mt-4">Full legal name (for CSA)</div>
              <div className="col-6 mt-3">
                <TextField
                  required
                  label="First name"
                  variant="outlined"
                  name="firstName"
                  value={values.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <div className="col-6 mt-3">
                <TextField
                  required
                  label="Last name"
                  variant="outlined"
                  name="lastName"
                  value={values.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <div className="col-6 mt-3">
                <MuiTelInput
                  required
                  label="Phone"
                  variant="outlined"
                  name="phone"
                  className={!!errors.phone && !!touched.phone ? "error" : ""}
                  onlyCountries={["US"]}
                  defaultCountry={"US"}
                  disableDropdown
                  value={values.phone ?? undefined}
                  onBlur={handleBlur}
                  onChange={(newValue) => {
                    setFieldValue("phone", newValue);
                  }}
                />
                {!!errors.phone && !!touched.phone && (
                  <FormHelperText className="error-message">
                    {errors.phone}
                  </FormHelperText>
                )}
              </div>
              <div className="col-6 mt-3">
                <TextField
                  required
                  label="email"
                  variant="outlined"
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={(e) => setFieldValue("email", e.target.value.trim())}
                />
              </div>

              <div className="col-6 mt-3 datepicker-holder">
                <FormControl error={!!errors.birthDate && !!touched.birthDate}>
                  <DatePicker
                    disableFuture
                    label="Birth Date *"
                    value={!!values.birthDate ? dayjs(values.birthDate) : null}
                    onChange={(date) =>
                      handleChange({
                        target: { value: date, name: "birthDate" },
                      })
                    }
                    className={
                      !!errors.birthDate && !!touched.birthDate ? "error" : ""
                    }
                  />
                  {!!errors.birthDate && !!touched.birthDate && (
                    <FormHelperText>Required</FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
          )}
        <div className="row">
          <div className="col-12 mt-3">
            <MapsAutocomplete
              value={values.workersCompDetails?.address ?? ""}
              label="Lead Address"
              onChange={(value) => setFieldValue("workersCompDetails.address", value)}
            />
          </div>
          <div className="col-12 mt-3">
            <MapsAutocomplete
              value={values.workersCompDetails?.workAddress ?? ""}
              label="Address/​location of lead's former workplace"
              onChange={(value) => setFieldValue("workersCompDetails.workAddress", value)}
            />
          </div>
          <div className="col-6 mt-3">
            <TextField
              required
              label="Employer Company Full Legal Name (for CSA)"
              variant="outlined"
              name="workersCompDetails.company"
              value={values.workersCompDetails?.company}
              onChange={handleChange}
            />
          </div>
          <div className="col-6 mt-3">
            <TextField
              required
              label="How many employees work in the company? (estimation)"
              variant="outlined"
              name="workersCompDetails.employeesNumber"
              value={values.workersCompDetails?.employeesNumber}
              onChange={handleChange}
            />
          </div>
          <div className="col-6 mt-3">
            <TextField
              required
              label="Title/​Position"
              variant="outlined"
              name="workersCompDetails.jobTitle"
              value={values.workersCompDetails?.jobTitle}
              onChange={handleChange}
            />
          </div>
          <div className="col-6 mt-3 dropdown-holder">
            <FormControl
              required
              variant="outlined"
              sx={{ m: 1, minWidth: 120 }}
            >
              <InputLabel id="fired-label">Terminated or Resigned?</InputLabel>
              <Select
                disabled={!props.isEdit}
                labelId="fired-label"
                label="Terminated or Resigned?"
                id="fired"
                name="workersCompDetails.terminationType"
                value={values.workersCompDetails?.terminationType}
                onChange={handleChange}
              >
                {Object.keys(TerminationType).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {HelpersService.camelToString(TerminationType[key])}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="col-6 mt-3">
            <TextField
              required
              label="Date of Hire"
              variant="outlined"
              name="workersCompDetails.hireDate"
              value={values.workersCompDetails?.hireDate}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <div className="col-6 mt-3">
            {values.workersCompDetails?.terminationType !==
              HelpersService.getEnumKeyByEnumValue(
                TerminationType,
                TerminationType.STILL_WORKING
              ) && (
                <>
                  <TextField
                    label={
                      values.workersCompDetails?.terminationType ===
                        HelpersService.getEnumKeyByEnumValue(
                          TerminationType,
                          TerminationType.TERMINATED
                        )
                        ? "Termination Date*"
                        : "Last working day*"
                    }
                    variant="outlined"
                    name="workersCompDetails.terminationDate"
                    value={values.workersCompDetails?.terminationDate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </>
              )}
          </div>
          <div className="row m-0 p-0">
            <div className="col-6 mt-3 dropdown-holder">
              <FormControl
                required
                variant="outlined"
                sx={{ m: 1, minWidth: 120 }}
              >
                <InputLabel id="paid-label">
                  Were you paid hourly or salary?
                </InputLabel>
                <Select
                  disabled={!props.isEdit}
                  labelId="paid-label"
                  label="Were you paid hourly or salary?"
                  id="paid"
                  name="workersCompDetails.paidType"
                  value={values.workersCompDetails?.paidType}
                  onChange={handleChange}
                >
                  {Object.keys(PaidType).map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {PaidType[key]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <h5 className="title mb-3">Workplace Injury</h5>
          <div>
            Fill out below only if the employee ever experienced any workplace
            injury.
          </div>
          <RadioControl
            propertyName="workersCompDetails.hadInjuries"
            label="Did you experience any (physical or mental) injuries while employed for this company?"
            value={values.workersCompDetails?.hadInjuries}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          {!!values.workersCompDetails?.hadInjuries &&
            RadioOptions[values.workersCompDetails?.hadInjuries] ===
            RadioOptions.YES && (
              <div className="row">
                <div className="col-12 mt-3">
                  <TextField
                    label="Explain"
                    variant="outlined"
                    name="workersCompDetails.injuriesDescription"
                    value={values.workersCompDetails?.injuriesDescription}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <RadioControl
                  propertyName="workersCompDetails.currentlyRepresentedWC"
                  label="Are you currently represented by a WC attorney?"
                  value={values.workersCompDetails?.currentlyRepresentedWC}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </div>
            )}
          <div className="row">
            <RadioControl
              propertyName="workersCompDetails.filedCase"
              label="Has the employee filed a worker's compensation case?"
              value={values.workersCompDetails?.filedCase}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <RadioControl
              propertyName="workersCompDetails.hadPreviousCase"
              label="Did you had previous worker’s compensation cases?"
              value={values.workersCompDetails?.hadPreviousCase}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {!!values.workersCompDetails?.hadPreviousCase &&
              RadioOptions[values.workersCompDetails?.hadPreviousCase] ===
              RadioOptions.YES && (
                <div className="col-12 mt-4">
                  <TextField
                    label="What part of your body was previously injured?"
                    variant="outlined"
                    name="workersCompDetails.previousCaseInjury"
                    value={values.workersCompDetails?.previousCaseInjury}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
              )}
            <h5 className="title mt-4 mb-3">WC Questionnaire</h5>
            <RadioControl
              propertyName="workersCompDetails.employedCA"
              label="Have you been employed or are you currently employed in the state of CA?"
              value={values.workersCompDetails?.employedCA}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <div className="col-12 mt-4">
              <FormControl>
                <FormLabel id="injuryType-label">
                  Is your injury physical or mental?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="injuryType-label"
                  name="workersCompDetails.injuryType"
                  value={values.workersCompDetails?.injuryType}
                  onChange={handleChange}
                >
                  {Object.keys(InjuryType).map((key) => {
                    return (
                      <FormControlLabel
                        key={key}
                        value={key}
                        control={<Radio />}
                        label={InjuryType[key]}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </div>
            <RadioControl
              propertyName="workersCompDetails.havePain"
              label="Do you have pain in any part of your body while working at your job?"
              value={values.workersCompDetails?.havePain}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {!!values.workersCompDetails?.havePain &&
              RadioOptions[values.workersCompDetails?.havePain] ===
              RadioOptions.YES && (
                <TextField
                  label="Please provide details - describe the pain"
                  variant="outlined"
                  name="workersCompDetails.painDescription"
                  value={values.workersCompDetails?.painDescription}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              )}
            <RadioControl
              propertyName="workersCompDetails.injuryReported"
              label="Have you ever reported your injury to a supervisor?"
              value={values.workersCompDetails?.injuryReported}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {!!values.workersCompDetails?.injuryReported &&
              RadioOptions[values.workersCompDetails?.injuryReported] ===
              RadioOptions.YES && (
                <div className="row">
                  <RadioControl
                    propertyName="workersCompDetails.writtenComplains"
                    label="Did the employee submit any written complaints to the employer?"
                    value={values.workersCompDetails?.writtenComplains}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  {!!values.workersCompDetails?.writtenComplains &&
                    RadioOptions[
                    values.workersCompDetails?.writtenComplains
                    ] === RadioOptions.YES && (
                      <div className="row p-0">
                        <div className="col-6">
                          <TextField
                            label="To who?"
                            variant="outlined"
                            name="workersCompDetails.writtenTo"
                            value={values.workersCompDetails?.writtenTo}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-6">
                          <TextField
                            label="Date of written complaint"
                            variant="outlined"
                            name="workersCompDetails.writtenWhen"
                            value={values.workersCompDetails?.writtenWhen}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    )}
                  <RadioControl
                    propertyName="workersCompDetails.oralComplains"
                    label="Did the employee submit any oral complaints to the employer?"
                    value={values.workersCompDetails?.oralComplains}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  {!!values.workersCompDetails?.oralComplains &&
                    RadioOptions[values.workersCompDetails?.oralComplains] ===
                    RadioOptions.YES && (
                      <div className="row ps-0">
                        <div className="col-6">
                          <TextField
                            label="To who?"
                            variant="outlined"
                            name="workersCompDetails.oralTo"
                            value={values.workersCompDetails?.oralTo}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-6">
                          <TextField
                            label="Date of oral complaint"
                            variant="outlined"
                            name="workersCompDetails.oralWhen"
                            value={values.workersCompDetails?.oralWhen}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    )}
                </div>
              )}
            <RadioControl
              propertyName="workersCompDetails.treatmentReceived"
              label="Have you ever received medical treatment, through work or by yourself, for a work-related injury?"
              value={values.workersCompDetails?.treatmentReceived}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {!!values.workersCompDetails?.treatmentReceived &&
              RadioOptions[values.workersCompDetails?.treatmentReceived] ===
              RadioOptions.YES && (
                <div className="col-12 mt-3">
                  <TextField
                    label="Please describe the medical treatment and procedures"
                    variant="outlined"
                    name="workersCompDetails.treatmentDetails"
                    value={values.workersCompDetails?.treatmentDetails}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
              )}
            <RadioControl
              propertyName="workersCompDetails.hasInsurance"
              label="Does your employer have worker's compensation insurance?"
              value={values.workersCompDetails?.hasInsurance}
              addNotSure={true}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <RadioControl
              propertyName="workersCompDetails.paycheckStubs"
              label="Do you receive paycheck stubs?"
              value={values.workersCompDetails?.paycheckStubs}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <RadioControl
              propertyName="workersCompDetails.workersClaimReprimands"
              label="Have you received any reprimands before you considered filing a worker's compensation claim?"
              value={values.workersCompDetails?.workersClaimReprimands}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {!!values.workersCompDetails?.workersClaimReprimands &&
              RadioOptions[
              values.workersCompDetails?.workersClaimReprimands
              ] === RadioOptions.YES && (
                <div className="col-12 mt-4">
                  <TextField
                    multiline
                    label="Explain"
                    variant="outlined"
                    name="workersCompDetails.workersClaimReprimandsDetails"
                    value={
                      values.workersCompDetails?.workersClaimReprimandsDetails
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
              )}
          </div>
        </div>
        <legend>
          <div className="row mt-5">
            <h5 className="title">Files</h5>
            {(props.userRoles?.includes(ApplicationRole.ADMIN) ||
              props.userRoles?.includes(ApplicationRole.STAFF)) && (
                <div>
                  Maximum file size is 100MB. Allowed file types are limited, if
                  you cannot upload file that you need, please contact admin.
                </div>
              )}
            <Dropzone
              accept={{
                "application/pdf": [],
                "image/*": [],
                "video/*": [],
                "application/msword": [],
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                  [],
                "application/vnd.ms-excel": [],
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  [],
              }}
              disabled={
                !props.userRoles?.includes(ApplicationRole.ADMIN) &&
                !props.userRoles?.includes(ApplicationRole.STAFF)
              }
              onDrop={(acceptedFiles, fileRejections) =>
                handleFilesUpdate(acceptedFiles)
              }
              minSize={0}
              maxSize={maxFileSize}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
                fileRejections,
              }) => {
                const isFileTooLarge =
                  fileRejections.length > 0 &&
                  fileRejections[0].file.size > maxFileSize;
                return (
                  <section>
                    <>
                      {(props.userRoles?.includes(ApplicationRole.ADMIN) ||
                        props.userRoles?.includes(ApplicationRole.STAFF)) && (
                          <div {...getRootProps()} className="dropzone">
                            <input {...getInputProps()} />
                            {!isDragActive &&
                              "Click to select files or drag and drop"}
                            {isDragActive && !isDragReject && "Drop file"}
                            {isDragReject && "File type not accepted"}
                            {isFileTooLarge && (
                              <div className="text-danger ms-2">
                                File that you tries to upload is too large.
                              </div>
                            )}
                          </div>
                        )}
                      {files.map((fileObj) => {
                        const { key, file, url } = fileObj;
                        return (
                          <div key={key}>
                            {file ? (
                              <div>
                                {file.name}
                                {(props.userRoles?.includes(ApplicationRole.ADMIN) ||
                                  props.userRoles?.includes(ApplicationRole.STAFF)) && (
                                    <Button
                                      className="ms-2"
                                      variant="contained"
                                      color="error"
                                      size="small"
                                      onClick={() => handleFileRemove(key)}
                                    >
                                      Remove
                                    </Button>
                                  )}
                              </div>
                            ) : (
                              <div className="mt-2">
                                <a
                                  href={url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {key}
                                </a>
                                {(props.userRoles?.includes(ApplicationRole.ADMIN) ||
                                  props.userRoles?.includes(ApplicationRole.STAFF)) && (
                                    <Button
                                      className="ms-2"
                                      variant="contained"
                                      color="error"
                                      size="small"
                                      onClick={() => handleFileRemove(key)}
                                    >
                                      Remove
                                    </Button>
                                  )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </>
                  </section>
                );
              }}
            </Dropzone>
          </div>
        </legend>
        <div className="row mt-4">
          <h5>Please rate this lead:</h5>
          <span className="width-content">
            How trustworthy/credible did you asses the lead to be?
          </span>
          <Rating
            className="width-content"
            name="credibility"
            value={values.credibility}
            onChange={(e, newValue) =>
              setFieldValue("credibility", !!newValue ? +newValue : 0)
            }
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h5>Intake Comments</h5>

            <TextField
              label="Comments"
              variant="outlined"
              name="csNotes"
              value={values.csNotes}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </div>
      </fieldset>
      <div className="row mt-5 button-holder">
        {!!values.status &&
          props.isEdit &&
          values.status !== AdminLeadStatus.DRAFT && (
            <>
              <Button
                variant={
                  Object.keys(errors).filter((error) =>
                    Object.keys(touched).includes(error)
                  ).length > 0
                    ? "contained"
                    : "outlined"
                }
                color={
                  Object.keys(errors).filter((error) =>
                    Object.keys(touched).includes(error)
                  ).length > 0
                    ? "errorColor"
                    : "primary"
                }
                type="submit"
                className="submit-btn"
              >
                Save changes
              </Button>
              {Object.keys(errors).filter((error) =>
                Object.keys(touched).includes(error)
              ).length > 0 && (
                  <div className="error-message">
                    Please fix errors in the fields above
                  </div>
                )}
            </>
          )}
        {(!values.status || values.status === AdminLeadStatus.DRAFT) && (
          <div className="row mb-5">
            <div className="width-content">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  console.log(errors);
                }}
              >
                Submit
              </Button>
              {!!errors && Object.keys(errors).length > 0 && (
                <div className="error-message">
                  Errors in the form above. Click submit to display them.
                </div>
              )}
            </div>
            <div className="width-content">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleAddIncomplete()}
              >
                Incomplete
              </Button>
            </div>
          </div>
        )}
      </div>
    </form>
  );
}

var resetFormWith;
WorkersCompForm.resetFormWith = (value?) => {
  resetFormWith(value);
};
var updateUserData;
WorkersCompForm.updateUserData = (value) => {
  updateUserData(value);
};
