import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import userReducer from "./reducers/userReducers";
import referAttorneyReducers from "./reducers/referAttorneysReducers";
import spinnerReducers from "./reducers/spinnerReducers";
import paymentReducers from "./reducers/paymentReducers";

const rootReducer = combineReducers({
  user: userReducer,
  attorneys: referAttorneyReducers,
  spinner: spinnerReducers,
  payment: paymentReducers
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
