import Avatar from "@mui/material/Avatar/Avatar";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import Menu from "@mui/material/Menu/Menu";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logoutUser } from "../../redux/actions/userActions";
import { HelpersService } from "../../services/Helpers";
import useNavigateSearch from "../../services/useNavigateSearch";

const settings = [{ name: "Profile", link: "/settings" }]; // TODO: add links

export default function NavMenuAvatar() {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(
    null
  );

  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    setIsMobile(HelpersService.checkMobile());
    if(!user?.roles){
      dispatch(logoutUser());
    }
  }, []);

  const user = useSelector((state: any) => state.user?.user);
  const dispatch = useDispatch();
  const navigate = useNavigateSearch();
  
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const userSignOut = () => {
    dispatch(logoutUser());
    navigate("/login");
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Button
        variant="text"
        color="primary"
        endIcon={<Avatar sx={{ bgcolor: '#ED633A' }} >{!!user?.roles ? user?.roles[0].replace(/[a-z]/g, '') : ""}</Avatar>}
        onClick={handleOpenUserMenu}
      >
        {isMobile ? HelpersService.createInitials(`${user.firstName} ${user.lastName}`) : `${user.firstName} ${user.lastName}`}
      </Button>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
            <Button component={Link} to={setting.link}>
              {setting.name}
            </Button>
          </MenuItem>
        ))}
        <MenuItem key="Logout" onClick={userSignOut}>
          <Button>Logout</Button>
        </MenuItem>
      </Menu>
    </Box>
  );
}
