import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ApplicationRole } from "./api";
import Login from "./components/auth/Login";
import PushSubscriptionTrigger from "./components/basic/PushSubscriptionTrigger";
import LoadingSpinner from "./components/basic/spinner/LoadingSpinner";
import { Layout } from "./components/Layout";
import AppRoutes from "./components/navigation/AppRoutes";
import { GoogleOAuthProvider } from '@react-oauth/google';

import "./custom.css";
import { isEmpty } from "./services/Helpers";

export default function App() {
  const location = useLocation();
  const user = useSelector((state: any) => state.user?.user);

  const generateDisplayedElement = (element, roles) => {
    if (!roles || roles.length === 0 || roles.some(role => user?.roles?.includes(role))) {
      //no limitations or passed
      return element;
    }

    if (!roles.some(role => user?.roles?.includes(role)) && !isEmpty(user)) {
      //logged in but no access to page
      return <Navigate to="/" />;
    }

    return <Login redirectTo={location.pathname}></Login>;
  };

  const getDefaultNav = () => {
    const rolesSet = new Set(user?.roles);

    switch (true) {
      case rolesSet.has(ApplicationRole.STAFF_ATTORNEY):
        return <Navigate to="/approve-leads" />;
      case rolesSet.has(ApplicationRole.ATTORNEY):
        return <Navigate to="/attorney-leads" />;
      case rolesSet.has(ApplicationRole.ADMIN):
      case rolesSet.has(ApplicationRole.STAFF):
        return <Navigate to="/new-leads" />;
      case rolesSet.has(ApplicationRole.USER):
        return <Navigate to="/attorney-info" />;
      case rolesSet.has(ApplicationRole.CLIO_STAFF):
        return <Navigate to="/zoom" />;
      default:
        return <Navigate to="/new-leads" />;
    }
  };

  return (
    <GoogleOAuthProvider clientId="166147532652-omj9krhk7p8dnhgvvdvl14fnpboqvoeb.apps.googleusercontent.com">
      <Layout>
        <LoadingSpinner></LoadingSpinner>
        <Routes>
          <Route path="/" element={getDefaultNav()} />
          {AppRoutes.map((route, index) => {
            const { element, roles, ...rest } = route;
            return (
              <Route
                key={index}
                {...rest}
                element={generateDisplayedElement(element, roles)}
              />
            );
          })}
        </Routes>
        <PushSubscriptionTrigger></PushSubscriptionTrigger>
      </Layout>
    </GoogleOAuthProvider>
  );
}
