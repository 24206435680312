import { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { ApplicationRole } from "../../../api/models/ApplicationRole";
import { UsersService } from "../../../api/services/UsersService";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Button from "@mui/material/Button/Button";
import { CalendarEventViewModel } from "../../../api/models/CalendarEventViewModel";
import dayjs from "dayjs";
import "./Attorneys.scss";
import FormControl from "@mui/material/FormControl/FormControl";
import { DatePicker } from "@mui/x-date-pickers/DatePicker/DatePicker";
import { UserShortResponse } from "../../../api/models/UserShortResponse";
import { useSelector } from "react-redux";
import { isEmpty } from "../../../services/Helpers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Category, GetUserListRequest } from "../../../api";

const eventsColors = [
  "#878787",
  "#A0CFBF",
  "#A294CE",
  "#B6B020",
  "#AE9685",
  "#7488A0",
  "#6CB245",
];

const MySwal = withReactContent(Swal);

export default function ReviewAssignmentCalendar() {
  const [staffEmpAttorneysList, setStaffEmpAttorneysList] = useState<
    UserShortResponse[]
  >([]);
  const [selectedEmpStaffAttorneyId, setSelectedEmpStaffAttorneyId] =
    useState<number>();

  const [staffPIAttorneysList, setStaffPIAttorneysList] = useState<
    UserShortResponse[]
  >([]);
  const [selectedPIStaffAttorneyId, setSelectedPIStaffAttorneyId] =
    useState<number>();

  const [eventEditId, setEventEditId] = useState<number | null>(null);
  const [events, setEvents] = useState<any[]>([]);
  const [dateStart, setDateStart] = useState<string | null>();
  const [dateEnd, setDateEnd] = useState<string | null>();
  const [dateHasEvent, setDateHasEvent] = useState<boolean>(false);

  const user = useSelector((state: any) => state.user?.user);

  useEffect(() => {
    if (isEmpty(user)) {
      return;
    }

    if (user.roles?.includes(ApplicationRole.ADMIN)) {
      UsersService.postApiUsersGetUsersByRole({
        role: ApplicationRole.STAFF_ATTORNEY,
        category: Category.EMPLOYMENT} as GetUserListRequest
      ).then((attorneys) => {
        setStaffEmpAttorneysList(attorneys);
      });

      UsersService.postApiUsersGetUsersByRole({
        role: ApplicationRole.STAFF_ATTORNEY,
        category: Category.PERSONAL_INJURY} as GetUserListRequest
      ).then((attorneys) => {
        setStaffPIAttorneysList(attorneys);
        var current = attorneys.filter(
          (attorney) => attorney.isCurrentReviewing
        );
        if (current.length === 1) {
          setSelectedPIStaffAttorneyId(current[0].id);
        }
      });
    }
  }, []);

  useEffect(() => {
    UsersService.getApiUsersGetCalendarEvents().then((events) => {
      var calendarEvents = events.map((event) => {
        var currentAttorneyIndex = staffEmpAttorneysList.findIndex(
          (attorney) => attorney.id === event.attorneyId
        );
        var color =
          eventsColors[
            currentAttorneyIndex < eventsColors.length
              ? currentAttorneyIndex
              : currentAttorneyIndex - eventsColors.length
          ];
        return {
          id: event.id,
          attorneyId: event.attorneyId,
          title: event.title,
          start: event.start?.slice(0, 10), //remove time from date to have filled background in events
          end: event.end?.slice(0, 10),
          backgroundColor: color,
          borderColor: color,
        };
      });
      setEvents(calendarEvents);
    });
  }, [staffEmpAttorneysList]);

  const handleDateClick = (date) => {
    resetValues();
    setDateStart(date.dateStr);
  };

  const handleDateSelection = (date) => {
    resetValues();
    setDateStart(date.startStr);
    setDateEnd(date.endStr);
  };

  const handleEventSave = () => {
    if (checkDatesExist(dateStart, dateEnd ?? dateStart)) {
      return;
    }
    var currentAttorneyIndex = staffEmpAttorneysList.findIndex(
      (attorney) => attorney.id === selectedEmpStaffAttorneyId
    );
    var color =
      eventsColors[
        currentAttorneyIndex < eventsColors.length
          ? currentAttorneyIndex
          : currentAttorneyIndex - eventsColors.length
      ];
    const event = {
      id:
        eventEditId ??
        Math.max(...events.map((event) => event.id as number)) + 1,
      attorneyId: selectedEmpStaffAttorneyId,
      title: `${staffEmpAttorneysList[currentAttorneyIndex]?.firstName} ${staffEmpAttorneysList[currentAttorneyIndex]?.lastName}`,
      start: dateStart,
      end: dayjs(dateEnd ?? dateStart).add(1, "day").format("YYYY-MM-DD"),
      backgroundColor: color,
      borderColor: color,
    };
    if (!!eventEditId) {
      UsersService.putApiUsersUpdateCalendarEvent({
        ...event,
        end: dayjs(dateEnd ?? dateStart).add(1, "day").format("YYYY-MM-DD"),
      } as CalendarEventViewModel).then(() => {
        var newEvents = [...events];
        let eventIndex = newEvents.findIndex(
          (event) => event.id === eventEditId
        );
        newEvents[eventIndex] = event;
        setEvents(newEvents);
        resetValues();
      });
      return;
    }
    setEvents((prevEvents) => [...prevEvents, event]);
    UsersService.postApiUsersAddCalendarEvents([
      {
        ...event,
        end: dayjs(dateEnd ?? dateStart).add(1, "day").format("YYYY-MM-DD"),
      } as CalendarEventViewModel,
    ])
      .then(() => {
        resetValues();
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error updating schedule</p>,
        });
      });
  };

  const checkDatesExist = (start, end) => {
    var selectedDayEventExist = events.find((event) => {
      var eventEnd =
        event.start !== event.end
          ? dayjs(event.end).subtract(1, "day")
          : dayjs(event.end);
      return (
        event.id !== eventEditId &&
        ((dayjs(event.start) <= dayjs(start) && eventEnd >= dayjs(start)) ||
          (dayjs(event.start) <= dayjs(end) && eventEnd >= dayjs(end)) ||
          (dayjs(event.start) >= dayjs(start) && eventEnd <= dayjs(end)))
      );
    });
    setDateHasEvent(!!selectedDayEventExist);
    return !!selectedDayEventExist;
  };

  const handleEventClick = (event) => {
    setSelectedEmpStaffAttorneyId(event.event._def.extendedProps.attorneyId);
    setDateStart(dayjs(event.event._instance.range.start).format("YYYY-MM-DD"));
    setDateEnd(
      dayjs(event.event._instance.range.end)
        .subtract(1, "day")
        .format("YYYY-MM-DD")
    );
    setEventEditId(+event.event._def.publicId);
    setDateHasEvent(false);
  };

  const handleEventChange = (event) => {
    let eventIndex = events.findIndex(
      (e) => e.id === +event.event._def.publicId
    );
    var oldEvent = events[eventIndex];
    var newEvent = {
      id: +event.event._def.publicId,
      title: oldEvent.title,
      backgroundColor: oldEvent.backgroundColor,
      borderColor: oldEvent.borderColor,
      attorneyId: event.event._def.extendedProps.attorneyId,
      start: dayjs(event.event._instance.range.start).format("YYYY-MM-DD"),
      end:
        dayjs(event.event._instance.range.end).format("YYYY-MM-DD") ??
        dayjs(event.event._instance.range.start).format("YYYY-MM-DD"),
    };
    UsersService.putApiUsersUpdateCalendarEvent({
      ...newEvent,
    } as CalendarEventViewModel)
      .then()
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error updating schedule</p>,
        });
      });
    var newEvents = [...events];

    newEvents[eventIndex] = newEvent;
    setEvents(newEvents);
    resetValues();
    return;
  };

  const handleEventDelete = () => {
    UsersService.deleteApiUsersCalendarEvent(eventEditId!)
      .then(() => {
        let eventIndex = events.findIndex((event) => event.id === eventEditId);
        var newEvents = [...events];
        newEvents.splice(eventIndex, 1);
        setEvents(newEvents);
        resetValues();
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error updating schedule</p>,
        });
      });
  };

  const handleUpdatePIReviewer = (id: number) => {
    setSelectedPIStaffAttorneyId(id);
    UsersService.putApiUsersSetReviewer(id).then(() => {
      MySwal.fire({
        icon: "success",
        title: <p className="title">Updated reviewing attorney</p>,
      });
    });
  };

  const resetValues = () => {
    setEventEditId(null);
    setDateEnd(null);
    setDateStart(null);
    setDateHasEvent(false);
  };

  return (
    <div className="calendar-holder row">
      <div className="col-6 col-md-2">
        <div className="mt-3">
          <h5>
            {!!eventEditId
              ? "Edit Employment attorney schedule"
              : "Create Employment attorney schedule"}
          </h5>
          <FormControl>
            <InputLabel id="selected-label">Select attorney*</InputLabel>
            <Select
              labelId="selected-label"
              className="min-width-200"
              label="Select attorney*"
              value={selectedEmpStaffAttorneyId}
              key={selectedEmpStaffAttorneyId}
              onChange={(e) => setSelectedEmpStaffAttorneyId(+e.target.value)}
            >
              {staffEmpAttorneysList.map((attorney) => {
                return (
                  <MenuItem key={attorney.id} value={attorney.id}>
                    {attorney.firstName} {attorney.lastName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="mt-3">
          <FormControl required>
            <DatePicker
              label="Start date *"
              value={dateStart ? dayjs(dateStart) : null}
              defaultValue={null}
              onChange={(value) =>
                setDateStart(dayjs(value).format("YYYY-MM-DD"))
              }
            />
          </FormControl>
        </div>
        <div className="mt-3">
          <FormControl>
            <DatePicker
              label="End date"
              value={dateEnd ? dayjs(dateEnd) : null}
              onChange={(value) =>
                setDateEnd(dayjs(value).format("YYYY-MM-DD"))
              }
            />
          </FormControl>
        </div>
        <div className="mt-3">
          <Button
            color="primary"
            variant="contained"
            disabled={!selectedEmpStaffAttorneyId}
            onClick={handleEventSave}
          >
            Save
          </Button>
          {dateHasEvent && (
            <p className="error-message">
              Attorney already selected inside this date range
            </p>
          )}
          {(!dateStart || !selectedEmpStaffAttorneyId) && (
            <p className="error-message">
              Start date and attorney are required
            </p>
          )}
        </div>
        <div className="mt-3">
          <Button
            color="secondary"
            variant="contained"
            disabled={!eventEditId}
            onClick={handleEventDelete}
          >
            Delete
          </Button>
        </div>
        <div>
          <div className="mt-5"> <h5>Select current PI attorney for review</h5></div>
          <div className="mt-3">
            <FormControl>
              <InputLabel id="type-label">Select</InputLabel>
              <Select
                labelId="type-label"
                name="role"
                className="min-width-200"
                label="Select"
                value={selectedPIStaffAttorneyId}
                key={selectedPIStaffAttorneyId}
                onChange={(e) => handleUpdatePIReviewer(+e.target.value)}
              >
                {staffPIAttorneysList.map((attorney) => {
                  return (
                    <MenuItem key={attorney.id} value={attorney.id}>
                      {attorney.firstName} {attorney.lastName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="col-10">
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={events}
          selectable
          selectOverlap={false}
          editable
          eventOverlap={false}
          eventStartEditable
          eventResizableFromStart
          dateClick={handleDateClick}
          select={handleDateSelection}
          eventClick={handleEventClick}
          eventDrop={handleEventChange}
          eventResize={handleEventChange}
        />
      </div>
    </div>
  );
}
