/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ProtectedCategory {
    RACE = 'Race',
    ETHNICITY = 'Ethnicity',
    NATIONAL_ORIGIN = 'NationalOrigin',
    GENDER_SEX = 'Gender_Sex',
    AGE = 'Age',
    DISABILITY = 'Disability',
    MARITAL_STATUS = 'MaritalStatus',
    PREGNANCY = 'Pregnancy',
    RELIGION = 'Religion',
    SEXUAL_ORIENTATION = 'SexualOrientation',
    OTHER = 'Other',
}
