import React from "react";

import TableBody from "@mui/material/TableBody/TableBody";
import Table from "@mui/material/Table/Table";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TablePagination from "@mui/material/TablePagination/TablePagination";

import Checkbox from "@mui/material/Checkbox/Checkbox";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button";

import {
  AttorneyLeadData,
  CategoryMapping,LeadType,
  Stage,
  StageColorsMapping,
} from "../../Admin/LeadsManagement/shared/Constants";
import EnhancedTableHead from "../../Admin/LeadsManagement/LeadsTables/LeadTableHeader";
import useNavigateSearch from "../../../services/useNavigateSearch";
import { HeadCell } from "../../Admin/LeadsManagement/LeadsTables/LeadsTableConstants";
import { Category } from "../../../api";

const headCells: HeadCell[] = [
  {
    id: "status",
    disablePadding: false,
    label: "Stage",
  },
  {
    id: "category",
    disablePadding: false,
    label: "Category",
  },
  {
    id: "employer",
    disablePadding: false,
    label: "Employer",
  },
  {
    id: "updatedAt",
    disablePadding: false,
    label: "Last update",
  },
];

export function createData(
  id: string,
  stage: Stage,
  category: Category,
  employer: string,
  lastUpdate: string
): AttorneyLeadData {
  return {
    id,
    stage,
    category,
    employer,
    lastUpdate,
  };
}

const DEFAULT_ORDER = "asc";
const DEFAULT_ORDER_BY = "stage";
const DEFAULT_ROWS_PER_PAGE = 5;

export default function CasesTable() {
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [visibleRows, setVisibleRows] = React.useState<AttorneyLeadData[] | null>(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [paddingHeight, setPaddingHeight] = React.useState(0);
  const navigate = useNavigateSearch();

  // TODO: get rows from BE
  React.useEffect(() => {
    let rowsOnMount = filteredRows;
    rowsOnMount = rowsOnMount.slice(
      0 * DEFAULT_ROWS_PER_PAGE,
      0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE
    );

    setVisibleRows(rowsOnMount);
  }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = filteredRows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (row: AttorneyLeadData) => {
    const selectedIndex = selected.indexOf(row.id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = selected.concat(row.id);
    } else if (selectedIndex === 0) {
      newSelected = selected.slice(1);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = selected.slice(0, -1);
    } else if (selectedIndex > 0) {
      newSelected = selected
        .slice(0, selectedIndex)
        .concat(selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = React.useCallback(
    (event: unknown, newPage: number) => {
      setPage(newPage);

      const sortedRows = filteredRows.slice();
      const updatedRows = sortedRows.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage
      );
      setVisibleRows(updatedRows);

      // Avoid a layout jump when reaching the last page with empty rows.
      const numEmptyRows =
        newPage > 0
          ? Math.max(0, (1 + newPage) * rowsPerPage - filteredRows.length)
          : 0;

      const newPaddingHeight = 53 * numEmptyRows;
      setPaddingHeight(newPaddingHeight);
    },
    [DEFAULT_ORDER, DEFAULT_ORDER_BY, rowsPerPage]
  );

  const handleChangeRowsPerPage = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(updatedRowsPerPage);
      setPage(0);

      const sortedRows = filteredRows.slice();
      const updatedRows = sortedRows.slice(
        0 * updatedRowsPerPage,
        0 * updatedRowsPerPage + updatedRowsPerPage
      );
      setVisibleRows(updatedRows);

      setPaddingHeight(0);
    },
    [DEFAULT_ORDER, DEFAULT_ORDER_BY]
  );

  const handleChangeFilter = (stage: Stage | null = null) => {
    if (!!selected.length) {
      setSelected([]);
    }
    setPage(0);

    let newRows = rows;
    if (!!stage) {
      newRows = rows.slice().filter((row) => row.stage === stage);
    }

    filteredRows = newRows;
    const updatedRows = newRows.slice(0, rowsPerPage);
    setVisibleRows(updatedRows);
    setPaddingHeight(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // TODO: update if needed
  const handleDetailsClick = (e: React.MouseEvent<unknown> | null, row) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    navigate(`/lead/${row.id}`, { leadType: LeadType.Case, isEdit: "false" });
  };

  const rows = [
    createData("1", Stage.new, Category.WORKERS_COMPENSATION, "Apple", "19.03.2023"),
    createData("2", Stage.new, Category.WORKERS_COMPENSATION, "Apple co", "19.03.2023"),
  ];
  let filteredRows = rows;

  return (
    <Box sx={{ width: "100%" }}>
      <div className="row justify-content-between">
        <div className="col-6 col-md-2">
          <h2 className="title">Cases</h2>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12 d-flex table-filters">
          <Button
            variant="contained"
            color="darkGray"
            size="small"
            onClick={() => handleChangeFilter()}
          >
            All
          </Button>
          <Button
            variant="contained"
            color="cyan"
            size="small"
            onClick={() => handleChangeFilter(Stage.new)}
          >
            New
          </Button>
        </div>
      </div>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <EnhancedTableHead
          displaySelectAll={false}
            headCells={headCells}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={() => {}}
            rowCount={filteredRows.length}
          />
          <TableBody>
            {visibleRows
              ? visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={() => handleClick(row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          variant="contained"
                          color={StageColorsMapping[row.stage]}
                          size="small"
                        >
                          {row.stage}
                        </Button>
                      </TableCell>
                      <TableCell align="left">
                        {CategoryMapping[row.category]}
                      </TableCell>                      
                      <TableCell align="left">{row.employer}</TableCell>
                      <TableCell align="left">{row.lastUpdate}</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="left">
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={(e) => handleDetailsClick(e, row)}
                        >
                          View details
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
            {paddingHeight > 0 && (
              // empty rows for rows displayed < rows per page
              <TableRow
                style={{
                  height: paddingHeight,
                }}
              >
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
