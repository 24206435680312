import FormControl from "@mui/material/FormControl/FormControl";
import Rating from "@mui/material/Rating/Rating";
import TextField from "@mui/material/TextField/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker/DatePicker";
import dayjs from "dayjs";
import * as yup from "yup";
import { useFormik, getIn } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  AdminLeadStatus,
  ApplicationRole,
  Category,
  ClassViewModel,
  EmployedTimeType,
  FileService,
  FileType,
  LeadsService,
  LeadViewModel,
  OpenAPI,
  RadioOptions,
} from "../../../../api";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { HelpersService } from "../../../../services/Helpers";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import RadioControl from "../../../../components/basic/RadioControl";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import { FileModel, LeadType, RadioYesOption, maxFileSize } from "../shared/Constants";
import FormLabel from "@mui/material/FormLabel/FormLabel";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import WitnessFormTable from "./WitnessFormTable";
import Button from "@mui/material/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { startSpinner, stopSpinner } from "../../../../redux/actions/spinnerActions";
import { MuiTelInput } from "mui-tel-input";
import axios from "axios";
import Dropzone from "react-dropzone";
import EmploymentForm from "./EmploymentForm";

const MySwal = withReactContent(Swal);

const emptyData: ClassViewModel = {
  arbAgreement: undefined,
  tempAgency: undefined,
  nameTempAgency: "",
  tempWhoSupervised: "",
  terminatedLaidOff: "",
  payStructure: "",
  typicalWorkSchedule: "",
  timekeepingMethod: "",
  waitInLine: undefined,
  howLongClockIn: "",
  warehouseSecurityProcedure: "",
  paidOvertime: undefined,
  paidDoubletime: undefined,
  workBeforeShiftStarts: undefined,
  beforeDuties: "",
  beforeHowOften: "",
  beforeTimeTook: "",
  workAfterShiftStarts: undefined,
  afterDuties: "",
  afterHowOften: "",
  afterTimeTook: "",
  receivedMealOnTime: undefined,
  explainNoMealOnTime: "",
  noMealOnTimeHowOften: "",
  employerDeductedMealTime: "",
  receivedRestOnTime: undefined,
  explainNoRestOnTime: "",
  noRestOnTimeHowOften: "",
  wereInterrupted: undefined,
  interruptedExplain: "",
  interruptedHowOften: "",
  canGoOutside: undefined,
  receivedSecondLunch: undefined,
  signedMealWaiver: undefined,
  paidPremiumForMissedBreask: undefined,
  receivedBonusesOrIncentives: undefined,
  reimbursementIssues: undefined,
  whatNotReinbursed: "",
  usedPersonalVehicle: undefined,
  anyCoworkerToTalk: undefined,
  coworkerDetails: "",
};

interface ClassProps {
  data?: LeadViewModel;
  isEdit?: boolean;
  isNew?: boolean;
  leadType?: LeadType;
  userRoles?: ApplicationRole[];
}

export default function ClassActionForm(props: ClassProps) {
  const [details, setDetails] = useState<LeadViewModel>({} as LeadViewModel);
  const [files, setFiles] = useState<FileModel[]>([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user?.user);

  useEffect(() => {
    if (!id) {
      setDetails(emptyData);
      return;
    }
    LeadsService.getApiLeadsClassDetails(+id)
      .then((lead) => {
        if (!lead.classDetails) {
          lead.classDetails = emptyData;
        }
        var leadData = HelpersService.parseLeadClassResponse(lead);
        leadData = HelpersService.parseLeadResponse(lead);
        setDetails(leadData);
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error retrieving lead data</p>,
        });
      });
  }, []);

  const FormDetails = () => {
    const postLead = (newLead) => {
      newLead.category = Category.CLASS_ACTION;
      if (values.id) {
        LeadsService.putApiLeads(newLead.id, newLead)
          .then(async () => {
            await updateFiles(newLead.id);
            MySwal.fire({
              icon: "success",
              title: <p className="title">Lead updated successfully</p>,
            }).then(() => navigate("/new-leads"))
          })
          .catch(() =>
            MySwal.fire({
              icon: "error",
              title: <p className="title">Error updating new lead</p>,
              html: (
                <div>
                  Please make sure email and other fields have proper values.
                </div>
              ),
            })
          )
          .finally(() => dispatch(stopSpinner()));
        return;
      }
      LeadsService.postApiLeads(newLead)
        .then(async (id) => {
          await updateFiles(id);
          MySwal.fire({
            icon: "success",
            title: <p className="title">Lead created successfully</p>,
          }).then(() => navigate("/new-leads"));
        })
        .catch(() =>
          MySwal.fire({
            icon: "error",
            title: <p className="title">Error creating new lead</p>,
            html: (
              <div>
                Please make sure email and other fields have proper values.
              </div>
            ),
          })
        )
        .finally(() => dispatch(stopSpinner()));
    }

    const onSubmit = (form) => {
      var newLead = form as LeadViewModel;
      newLead.isDraft = false;
      setDetails(form);
      postLead(newLead);
    };

    const saveDraft = () => {
      setDetails(values);
      var newLead = values as LeadViewModel;
      newLead.isDraft = true;
      postLead(newLead);
    };

    const handleFilesUpdate = async (acceptedFiles) => {
      dispatch(startSpinner());
      const sameFiles: FileModel[] = files.filter((oldFile) =>
        acceptedFiles.some((newFile) => newFile.name === oldFile.key)
      );
      if (sameFiles.length > 0) {
        MySwal.fire({
          title: <strong>You are trying to add files that already exist</strong>,
          html: (
            <div>
              {sameFiles.map((file) => `${file.key}, `)} will be replaced.
            </div>
          ),
          icon: "info",
          showCancelButton: true,
          cancelButtonText: "No, keep it",
          confirmButtonText: "Yes, replace",
          confirmButtonColor: "#ed633a",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            if (values.id) {
              await updateFiles(values.id!, acceptedFiles);
            }
            const removeDups = files.filter(
              (file) => !sameFiles.some((sameFile) => file.key === sameFile.key)
            );
            setFiles([...removeDups, ...parseFiles(acceptedFiles)]);
          }
          dispatch(stopSpinner());
        });
      } else {
        if (values.id) {
          await updateFiles(values.id!, acceptedFiles);
        }
        setFiles([...files, ...parseFiles(acceptedFiles)]);
        dispatch(stopSpinner());
      }
    };
    const handleFileRemove = (key) => {
      dispatch(startSpinner());
      MySwal.fire({
        title: <strong>Confirm file removal</strong>,
        html: <div>{key} will be deleted from this lead.</div>,
        icon: "info",
        showCancelButton: true,
        cancelButtonText: "No, keep it",
        confirmButtonText: "Yes, remove",
        confirmButtonColor: "#ed633a",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          setFiles((prevFiles) => prevFiles.filter((file) => file.key !== key));
          await FileService.postApiFileDelete(values.id!, {
            fileNames: [key],
          }).catch(() => {
            MySwal.fire({
              icon: "error",
              title: <p className="title">Error removing files</p>,
            });
          });
        }
        dispatch(stopSpinner());
      });
    };

    const schema = yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().email().required(),

      classDetails: yup.object().shape({
        arbAgreement: yup.mixed().required(),
        tempAgency: yup.mixed().required(),
        nameTempAgency: yup
          .mixed()
          .optional()
          .nullable()
          .when("tempAgency", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
        tempWhoSupervised: yup
          .mixed()
          .optional()
          .nullable()
          .when("tempAgency", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
        terminatedLaidOff: yup.string().required(),
        payStructure: yup.string().required(),
        typicalWorkSchedule: yup.string().required(),
        timekeepingMethod: yup.string().required(),
        waitInLine: yup.mixed().required(),
        howLongClockIn: yup
          .mixed()
          .optional()
          .nullable()
          .when("waitInLine", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
        paidOvertime: yup.mixed().required(),
        noOtExplain: yup
          .mixed()
          .optional()
          .nullable()
          .when("paidOvertime", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
        paidDoubletime: yup.mixed().required(),
        workBeforeShiftStarts: yup.mixed().required(),
        beforeDuties: yup
          .mixed()
          .optional()
          .nullable()
          .when("workBeforeShiftStarts", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
        beforeHowOften: yup
          .mixed()
          .optional()
          .nullable()
          .when("workBeforeShiftStarts", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
        beforeTimeTook: yup
          .mixed()
          .optional()
          .nullable()
          .when("workBeforeShiftStarts", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
        workAfterShiftStarts: yup.mixed().required(),
        afterDuties: yup
          .mixed()
          .optional()
          .nullable()
          .when("workAfterShiftStarts", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
        afterHowOften: yup
          .mixed()
          .optional()
          .nullable()
          .when("workAfterShiftStarts", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
        afterTimeTook: yup
          .mixed()
          .optional()
          .nullable()
          .when("workAfterShiftStarts", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
        receivedMealOnTime: yup.mixed().required(),
        explainNoMealOnTime: yup
          .mixed()
          .optional()
          .nullable()
          .when("receivedMealOnTime", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
        noMealOnTimeHowOften: yup
          .mixed()
          .optional()
          .nullable()
          .when("receivedMealOnTime", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
        employerDeductedMealTime: yup
          .mixed()
          .optional()
          .nullable()
          .when("receivedMealOnTime", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
        receivedRestOnTime: yup.mixed().required(),
        explainNoRestOnTime: yup
          .mixed()
          .optional()
          .nullable()
          .when("receivedRestOnTime", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
        noRestOnTimeHowOften: yup
          .mixed()
          .optional()
          .nullable()
          .when("receivedRestOnTime", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
        wereInterrupted: yup.mixed().required(),
        interruptedExplain: yup
          .mixed()
          .optional()
          .nullable()
          .when("wereInterrupted", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
        interruptedHowOften: yup
          .mixed()
          .optional()
          .nullable()
          .when("wereInterrupted", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
        canGoOutside: yup.mixed().required(),
        receivedSecondLunch: yup.mixed().required(),
        signedMealWaiver: yup.mixed().required(),
        paidPremiumForMissedBreask: yup.mixed().required(),
        receivedBonusesOrIncentives: yup.mixed().required(),
        reimbursementIssues: yup.mixed().required(),
        whatNotReinbursed: yup
          .mixed()
          .optional()
          .nullable()
          .when("reimbursementIssues", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),

        usedPersonalVehicle: yup.mixed().required(),
        anyCoworkerToTalk: yup.mixed().required(),
        coworkerDetails: yup
          .mixed()
          .optional()
          .nullable()
          .when("anyCoworkerToTalk", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
      }),
    });

    const {
      values,
      errors,
      touched,
      isSubmitting,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      setFieldTouched,
      resetForm,
    } = useFormik({
      validationSchema: schema,
      initialValues: details,
      onSubmit,
    });

    return (
      <form onSubmit={handleSubmit} >
        <fieldset disabled={!props.isEdit}>
          <div className="row mt-3">
            <h5 className="title mb-0 mt-3">Client Info</h5>
            <div className="col-12 mt-3">Full legal name (for CSA)</div>
            <div className="col-6 mt-3">
              <TextField
                required
                label="First name"
                variant="outlined"
                name="firstName"
                value={values.firstName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            <div className="col-6 mt-3">
              <TextField
                required
                label="Last name"
                variant="outlined"
                name="lastName"
                value={values.lastName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            <div className="col-6 mt-3">
              <MuiTelInput
                label="Phone"
                variant="outlined"
                name="phone"
                className={!!errors.phone && !!touched.phone ? "error" : ""}
                onlyCountries={["US"]}
                defaultCountry={"US"}
                disableDropdown
                value={values.phone ?? undefined}
                onBlur={handleBlur}
                onChange={(newValue) => {
                  setFieldValue("phone", newValue);
                }}
              />
              {!!errors.phone && !!touched.phone && (
                <FormHelperText className="error-message">
                  {errors.phone}
                </FormHelperText>
              )}
            </div>
            <div className="col-6 mt-3">
              <TextField
                required
                label="email"
                variant="outlined"
                name="email"
                value={values.email}
                className={!!errors.email && !!touched.email ? "error" : ""}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("email", e.target.value.trim())}
              />
              {!!errors.email && !!touched.email && (
                <FormHelperText className="error-message">
                  {errors.email}
                </FormHelperText>
              )}
            </div>
          </div>

          <h5 className="title mb-0 mt-3">Details</h5>
          <div className="row">
            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "classDetails.arbAgreement") &&
                getIn(touched, "classDetails.arbAgreement")
              }
              propertyName="classDetails.arbAgreement"
              label="ARB agreement?"
              class="col-6 mt-3"
              value={values.classDetails?.arbAgreement}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "classDetails.tempAgency") &&
                getIn(touched, "classDetails.tempAgency")
              }
              propertyName="classDetails.tempAgency"
              label="Temp Agency?"
              class="col-6 mt-3"
              value={values.classDetails?.tempAgency}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {values.classDetails?.tempAgency ===
              HelpersService.getEnumKeyByEnumValue(
                RadioOptions,
                RadioOptions.YES
              ) && (
                <>
                  <div className="col-6 mt-3">
                    <TextField
                      required
                      label="Name of the temp agency"
                      variant="outlined"
                      name="classDetails.nameTempAgency"
                      value={values.classDetails?.nameTempAgency}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <TextField
                      required
                      label="If so, who supervised you?"
                      variant="outlined"
                      name="classDetails.tempWhoSupervised"
                      placeholder="Employee from staffing agency or company?"
                      value={values.classDetails?.tempWhoSupervised}
                      onChange={handleChange}
                    />
                  </div>
                </>)}
            <div className="col-12 mt-3">
              <TextField
                required
                label="Terminated or laid off?"
                variant="outlined"
                name="classDetails.terminatedLaidOff"
                value={values.classDetails?.terminatedLaidOff}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 mt-3">
              <TextField
                required
                label="Pay Structure (Important to note if hourly employee v. salaried)"
                variant="outlined"
                name="classDetails.payStructure"
                value={values.classDetails?.payStructure}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 mt-3">
              <TextField
                required
                label="Typical work schedule"
                multiline
                variant="outlined"
                name="classDetails.typicalWorkSchedule"
                value={values.classDetails?.typicalWorkSchedule}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 mt-3">
              <TextField
                required
                label="Timekeeping Method (timeclock, timesheet, etc.)"
                variant="outlined"
                name="classDetails.timekeepingMethod"
                placeholder="Make sure to ask whether they clocked in and out for the day only or if they also clocked out for lunches"
                value={values.classDetails?.timekeepingMethod}
                onChange={handleChange}
              />
            </div>

            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "classDetails.waitInLine") &&
                getIn(touched, "classDetails.waitInLine")
              }
              propertyName="classDetails.waitInLine"
              label="Wait in line?"
              class="col-6 mt-3"
              value={values.classDetails?.waitInLine}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <div className="col-12 mt-3">
              <TextField
                required
                label="How long did it take to clock in?"
                variant="outlined"
                name="classDetails.howLongClockIn"
                value={values.classDetails?.howLongClockIn}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 mt-3">
              <TextField
                label="If warehouse, was there security procedure"
                variant="outlined"
                name="classDetails.warehouseSecurityProcedure"
                value={values.classDetails?.warehouseSecurityProcedure}
                onChange={handleChange}
              />
            </div>
            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "classDetails.paidOvertime") &&
                getIn(touched, "classDetails.paidOvertime")
              }
              propertyName="classDetails.paidOvertime"
              label="Paid overtime?"
              class="col-6 mt-3"
              value={values.classDetails?.paidOvertime}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {values.classDetails?.paidOvertime ===
              HelpersService.getEnumKeyByEnumValue(
                RadioOptions,
                RadioOptions.NO
              ) && (
                <div className="col-12 mt-3">
                  <TextField
                    required
                    label="Explain"
                    variant="outlined"
                    name="classDetails.noOtExplain"
                    value={values.classDetails?.noOtExplain}
                    onChange={handleChange}
                  />
                </div>
              )}
            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "classDetails.paidDoubletime") &&
                getIn(touched, "classDetails.paidDoubletime")
              }
              propertyName="classDetails.paidDoubletime"
              label="Paid double-time?"
              class="col-6 mt-3"
              value={values.classDetails?.paidDoubletime}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />

            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "classDetails.workBeforeShiftStarts") &&
                getIn(touched, "classDetails.workBeforeShiftStarts")
              }
              propertyName="classDetails.workBeforeShiftStarts"
              label="Were there any times you have to do work before your shift starts?"
              class="col-12 mt-3"
              value={values.classDetails?.workBeforeShiftStarts}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {values.classDetails?.workBeforeShiftStarts ===
              HelpersService.getEnumKeyByEnumValue(
                RadioOptions,
                RadioOptions.YES
              ) && (
                <>
                  <div className="col-12 mt-3">
                    <TextField
                      required
                      label="Duties"
                      variant="outlined"
                      multiline
                      name="classDetails.beforeDuties"
                      value={values.classDetails?.beforeDuties}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <TextField
                      required
                      label="How offen did it happen per week"
                      variant="outlined"
                      className="col-4"
                      name="classDetails.beforeHowOften"
                      value={values.classDetails?.beforeHowOften}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <TextField
                      required
                      label="Time it took"
                      variant="outlined"
                      className="col-4"
                      name="classDetails.beforeTimeTook"
                      value={values.classDetails?.beforeTimeTook}
                      onChange={handleChange}
                    />
                  </div>
                </>)}

            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "classDetails.workAfterShiftStarts") &&
                getIn(touched, "classDetails.workAfterShiftStarts")
              }
              propertyName="classDetails.workAfterShiftStarts"
              label="Were there any times you have to do work after your shift was done?"
              class="col-12 mt-3"
              value={values.classDetails?.workAfterShiftStarts}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {values.classDetails?.workAfterShiftStarts ===
              HelpersService.getEnumKeyByEnumValue(
                RadioOptions,
                RadioOptions.YES
              ) && (
                <>
                  <div className="col-12 mt-3">
                    <TextField
                      required
                      label="Duties"
                      variant="outlined"
                      multiline
                      name="classDetails.afterDuties"
                      value={values.classDetails?.afterDuties}
                      onChange={handleChange}
                    /></div>
                  <div className="col-6 mt-3">
                    <TextField
                      required
                      label="How offen did it happen per week"
                      variant="outlined"
                      className="col-4"
                      name="classDetails.afterHowOften"
                      value={values.classDetails?.afterHowOften}
                      onChange={handleChange}
                    /></div>
                  <div className="col-6 mt-3">
                    <TextField
                      required
                      label="Time it took"
                      variant="outlined"
                      className="col-4"
                      name="classDetails.afterTimeTook"
                      value={values.classDetails?.afterTimeTook}
                      onChange={handleChange}
                    />
                  </div>
                </>)}
            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "classDetails.receivedMealOnTime") &&
                getIn(touched, "classDetails.receivedMealOnTime")
              }
              propertyName="classDetails.receivedMealOnTime"
              label="Did you receive your meal breaks on time?"
              class="col-6 mt-3"
              value={values.classDetails?.receivedMealOnTime}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {values.classDetails?.receivedMealOnTime ===
              HelpersService.getEnumKeyByEnumValue(
                RadioOptions,
                RadioOptions.NO
              ) && (
                <>
                  <div className="col-12 mt-3">
                    <TextField
                      required
                      label="Explain"
                      multiline
                      variant="outlined"
                      name="classDetails.explainNoMealOnTime"
                      placeholder="Mealbreaks before 5th hour."
                      value={values.classDetails?.explainNoMealOnTime}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-12 mt-3">
                    <TextField
                      required
                      label="How often (per week)"
                      variant="outlined"
                      name="classDetails.noMealOnTimeHowOften"
                      value={values.classDetails?.noMealOnTimeHowOften}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <TextField
                      required
                      label="Did your employer deduct 30 min. or 1 hour of time to reflect you taking lunches even if you didn’t take them?"
                      variant="outlined"
                      name="classDetails.employerDeductedMealTime"
                      value={values.classDetails?.employerDeductedMealTime}
                      onChange={handleChange}
                    />
                  </div>
                </>)}
            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "classDetails.receivedRestOnTime") &&
                getIn(touched, "classDetails.receivedRestOnTime")
              }
              propertyName="classDetails.receivedRestOnTime"
              label="Did you receive your rest breaks on time?"
              class="col-6 mt-3"
              value={values.classDetails?.receivedRestOnTime}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />

            {values.classDetails?.receivedRestOnTime ===
              HelpersService.getEnumKeyByEnumValue(
                RadioOptions,
                RadioOptions.NO
              ) && (
                <>
                  <div className="col-12 mt-3">
                    <TextField
                      required
                      label="Explain"
                      multiline
                      variant="outlined"
                      name="classDetails.explainNoRestOnTime"
                      placeholder="Restbreak before 4th hour."
                      value={values.classDetails?.explainNoRestOnTime}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <TextField
                      required
                      label="How often (per week)"
                      variant="outlined"
                      name="classDetails.noRestOnTimeHowOften"
                      value={values.classDetails?.noRestOnTimeHowOften}
                      onChange={handleChange}
                    />
                  </div>
                </>)}
            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "classDetails.wereInterrupted") &&
                getIn(touched, "classDetails.wereInterrupted")
              }
              propertyName="classDetails.wereInterrupted"
              label="Were you interrupted?"
              class="col-6 mt-3"
              value={values.classDetails?.wereInterrupted}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {values.classDetails?.wereInterrupted ===
              HelpersService.getEnumKeyByEnumValue(
                RadioOptions,
                RadioOptions.YES
              ) && (
                <>
                  <div className="col-12 mt-3">
                    <TextField
                      required
                      label="Explain"
                      variant="outlined"
                      name="classDetails.interruptedExplain"
                      value={values.classDetails?.interruptedExplain}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <TextField
                      required
                      label="How many times (per week)"
                      variant="outlined"
                      name="classDetails.interruptedHowOften"
                      value={values.classDetails?.interruptedHowOften}
                      onChange={handleChange}
                    />
                  </div>
                </>)}
            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "classDetails.canGoOutside") &&
                getIn(touched, "classDetails.canGoOutside")
              }
              propertyName="classDetails.canGoOutside"
              label="Could you go offsite during breaks/lunches?"
              class="col-6 mt-3"
              value={values.classDetails?.canGoOutside}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />

            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "classDetails.receivedSecondLunch") &&
                getIn(touched, "classDetails.receivedSecondLunch")
              }
              propertyName="classDetails.receivedSecondLunch"
              label="Received a 2nd lunch after 10 hours?"
              class="col-6 mt-3"
              value={values.classDetails?.receivedSecondLunch}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "classDetails.signedMealWaiver") &&
                getIn(touched, "classDetails.signedMealWaiver")
              }
              propertyName="classDetails.signedMealWaiver"
              label="Did you sign a meal waiver form?"
              class="col-6 mt-3"
              value={values.classDetails?.signedMealWaiver}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "classDetails.paidPremiumForMissedBreask") &&
                getIn(touched, "classDetails.paidPremiumForMissedBreask")
              }
              propertyName="classDetails.paidPremiumForMissedBreask"
              label="Paid a premium wage for missed rest breaks and lunches?"
              class="col-6 mt-3"
              value={values.classDetails?.paidPremiumForMissedBreask}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "classDetails.receivedBonusesOrIncentives") &&
                getIn(touched, "classDetails.receivedBonusesOrIncentives")
              }
              propertyName="classDetails.receivedBonusesOrIncentives"
              label="Received bonuses or safety incentives?"
              class="col-6 mt-3"
              value={values.classDetails?.receivedBonusesOrIncentives}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "classDetails.reimbursementIssues") &&
                getIn(touched, "classDetails.reimbursementIssues")
              }
              propertyName="classDetails.reimbursementIssues"
              label="Reimbursement issues (i.e. cell phones, supplies, protective gear, office supplies, mileage)"
              class="col-6 mt-3"
              value={values.classDetails?.reimbursementIssues}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {values.classDetails?.reimbursementIssues ===
              HelpersService.getEnumKeyByEnumValue(
                RadioOptions,
                RadioOptions.YES
              ) && (
                <div className="col-12 mt-3">
                  <TextField required
                    label="What you were not reinbursed for?"
                    variant="outlined"
                    name="classDetails.whatNotReinbursed"
                    value={values.classDetails?.whatNotReinbursed}
                    onChange={handleChange}
                  />
                </div>)}
            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "classDetails.usedPersonalVehicle") &&
                getIn(touched, "classDetails.usedPersonalVehicle")
              }
              propertyName="classDetails.usedPersonalVehicle"
              label="Used personal vehicle for work?"
              class="col-6 mt-3"
              value={values.classDetails?.usedPersonalVehicle}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "classDetails.anyCoworkerToTalk") &&
                getIn(touched, "classDetails.anyCoworkerToTalk")
              }
              propertyName="classDetails.anyCoworkerToTalk"
              label="Any other coworker that we should talk to?"
              class="col-6 mt-3"
              value={values.classDetails?.anyCoworkerToTalk}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {values.classDetails?.anyCoworkerToTalk ===
              HelpersService.getEnumKeyByEnumValue(
                RadioOptions,
                RadioOptions.YES
              ) && (
                <div className="col-12 mt-3">
                  <TextField required
                    label="Contact information"
                    variant="outlined"
                    name="classDetails.coworkerDetails"
                    placeholder="Name, phone, email"
                    value={values.classDetails?.coworkerDetails}
                    onChange={handleChange}
                  />
                </div>)}
          </div>
          <legend>
            <div className="row mt-5">
              <h5 className="title">Files</h5><div>{user.roles?.includes(ApplicationRole.ADMIN)}</div>

              {props.isEdit && (user.roles?.includes(ApplicationRole.ADMIN) ||
                user.roles?.includes(ApplicationRole.STAFF)) && (
                  <div>
                    Maximum file size is 100MB. Allowed file types are limited, if
                    you cannot upload file that you need, please contact admin.
                  </div>
                )}
              <Dropzone
                accept={{
                  "application/pdf": [],
                  "image/*": [],
                  "video/*": [],
                  "application/msword": [],
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    [],
                  "application/vnd.ms-excel": [],
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    [],
                }}
                disabled={(
                  !user.roles?.includes(ApplicationRole.ADMIN) &&
                  !user.roles?.includes(ApplicationRole.STAFF)) || !props.isEdit
                }
                onDrop={(acceptedFiles, fileRejections) =>
                  handleFilesUpdate(acceptedFiles)
                }
                minSize={0}
                maxSize={maxFileSize}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragReject,
                  fileRejections,
                }) => {
                  const isFileTooLarge =
                    fileRejections.length > 0 &&
                    fileRejections[0].file.size > maxFileSize;
                  return (
                    <section>
                      <>
                        {(user.roles?.includes(ApplicationRole.ADMIN) ||
                          user.roles?.includes(ApplicationRole.STAFF)) && (
                            <div {...getRootProps()} className="dropzone">
                              <input {...getInputProps()} />
                              {!isDragActive &&
                                "Click to select files or drag and drop"}
                              {isDragActive && !isDragReject && "Drop file"}
                              {isDragReject && "File type not accepted"}
                              {isFileTooLarge && (
                                <div className="text-danger ms-2">
                                  File that you tries to upload is too large.
                                </div>
                              )}
                            </div>
                          )}
                        {files.map((fileObj) => {
                          const { key, file, url } = fileObj;
                          return (
                            <div key={key}>
                              {file ? (
                                <div>
                                  {file.name}
                                  {(user.roles?.includes(
                                    ApplicationRole.ADMIN
                                  ) ||
                                    user.roles?.includes(
                                      ApplicationRole.STAFF
                                    )) && (
                                      <Button
                                        className="ms-2"
                                        variant="contained"
                                        color="error"
                                        size="small"
                                        onClick={() => handleFileRemove(key)}
                                      >
                                        Remove
                                      </Button>
                                    )}
                                </div>
                              ) : (
                                <div className="mt-2">
                                  <a
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {key}
                                  </a>
                                  {(user.roles?.includes(
                                    ApplicationRole.ADMIN
                                  ) ||
                                    user.roles?.includes(
                                      ApplicationRole.STAFF
                                    )) && (
                                      <Button
                                        className="ms-2"
                                        variant="contained"
                                        color="error"
                                        size="small"
                                        onClick={() => handleFileRemove(key)}
                                      >
                                        Remove
                                      </Button>
                                    )}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </>
                    </section>
                  );
                }}
              </Dropzone>
            </div>
          </legend>
        </fieldset>
        {props.isEdit &&
          <div className="row mt-5 button-holder">
            {!!values.status &&
              props.isEdit &&
              values.status !== AdminLeadStatus.DRAFT && (
                <>
                  <Button
                    variant={
                      Object.keys(errors).filter((error) =>
                        Object.keys(touched).includes(error)
                      ).length > 0
                        ? "contained"
                        : "outlined"
                    }
                    color={
                      Object.keys(errors).filter((error) =>
                        Object.keys(touched).includes(error)
                      ).length > 0
                        ? "errorColor"
                        : "primary"
                    }
                    type="submit"
                    className="submit-btn"
                    onClick={() => {
                      console.log(errors);
                    }}
                  >
                    Save changes
                  </Button>
                  {Object.keys(errors).filter((error) =>
                    Object.keys(touched).includes(error)
                  ).length > 0 && (
                      <div className="error-message">
                        Please fix errors in the fields above
                      </div>
                    )}
                </>
              )}
            {(!values.status || values.status === AdminLeadStatus.DRAFT) && (
              <div className="row mb-5">
                <div className="width-content">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => {
                      console.log(errors);
                    }}
                  >
                    Submit
                  </Button>
                  {!!errors && Object.keys(errors).length > 0 && (
                    <div className="error-message">
                      Errors in the form above. Click submit to display them.
                    </div>
                  )}
                </div>
                <div className="width-content">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={saveDraft}
                  >
                    Incomplete
                  </Button>
                </div>
              </div>
            )}
          </div>}
      </form >
    );
  };

  useEffect(() => {
    if (!!details.files) {
      const filesMapped = details.files.map((file) => ({
        url: file.url!,
        key: file.name!,
      }));
      setFiles(filesMapped);
    }
  }, [details.files]);


  const parseFiles = (acceptedFiles) =>
    acceptedFiles.map((file) => {
      return {
        key: file.name,
        file,
      };
    });

  const updateFiles = async (leadId, filesToUpdate: any = null) => {
    dispatch(startSpinner());
    if (filesToUpdate === null) {
      filesToUpdate = files.map((file) => file.file);
      filesToUpdate = filesToUpdate.filter((file) => file !== undefined);
    }
    if (filesToUpdate.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < filesToUpdate.length; i++) {
        formData.append(`files`, filesToUpdate[i]);
      }
      const url = `${OpenAPI.BASE}/api/File/${leadId}/upload`;
      await axios
        .post(url, formData, {
          params: {
            type: FileType.LEAD_FILE,
          },
        })
        .catch(() => {
          MySwal.fire({
            icon: "error",
            title: <p className="title">Error uploading files.</p>,
          });
        })
        .finally(() => dispatch(stopSpinner()));
    } else {
      dispatch(stopSpinner());
    }
  };

  const onGoBack = () => {
    navigate(-1);
  };
  const downloadClick = () => {
    dispatch(startSpinner());
    fetch(`${OpenAPI.BASE}/api/Leads/${details.id}/pdfClass`, {
      method: "GET",
      headers: {
        Authorization: (OpenAPI.HEADERS as any)?.Authorization,
      },
    }).then((response: any) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `Colass notes for ${details?.firstName} ${details?.lastName}.pdf`;

        document.body.appendChild(a);
        a.click();
      });
    }).finally(() => dispatch(stopSpinner()));
  }


  return (
    <div className="row main-holder">
      {!!details?.id && user.roles?.includes(ApplicationRole.ADMIN) &&
        <Button
          variant="contained"
          color="primary"
          className="col-3 mb-3 ms-3"
          onClick={downloadClick}
        >
          Download Class Action
        </Button>}
      {!!details && !!user && <FormDetails></FormDetails>}
      {!!details.employmentDetails && <>
      <h3 className="title mt-5">Employment data for this client(cannot edit)</h3>
        <EmploymentForm key={details?.id}
          data={{ ...details, status: AdminLeadStatus.NEW }}
          isEdit={false}
          isNew={false}
          leadType={props.leadType}
          userRoles={user?.roles}></EmploymentForm>
      </>}
    </div>
  );
}
