import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UsersService } from "../../api";

export default function PushSubscriptionTrigger() {
  const [pushNotified, setPushNotified] = useState<boolean>(true);
  const [iosCanPush, setIosCanPush] = useState<boolean>(false);
  const user = useSelector((state: any) => state.user?.user);

  useEffect(() => {
    setPushNotified(!!localStorage.getItem("userSeenPushNotification"));
    var userAgent = navigator.userAgent;

    if (!("standalone" in window.navigator && window.navigator["standalone"])) {
      setIosCanPush(false);
      return;
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      var match = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
      var majorVersion;
      var minorVersion;

      if (match !== null && match.length > 1) {
        majorVersion = parseInt(match[1], 10);
        minorVersion = parseInt(match[2], 10);
        setIosCanPush(
          majorVersion > 16 || (majorVersion === 16 && minorVersion >= 4)
        );
      }
    }
  }, []);

  function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const handleSubscribe = async () => {
    try {
      const result = await window.Notification.requestPermission();
      if (result === "granted") {
        await navigator.serviceWorker.register("serviceworker.js", {
          scope: "./",
        });

        navigator.serviceWorker.ready.then(function (registration) {
          registration.pushManager
            .subscribe({
              applicationServerKey: urlBase64ToUint8Array(
                "BBQe61vY32FLD_aKO3WAQ_flk5idCIwinxFRvrVddQdxWHHDWyeXNJa2dwpaPgJiBFo6Um2GT2J-gFIiGI7ZlRE"
              ),
              userVisibleOnly: true,
            })
            .then((subscription) => {
              const endpoint = subscription.endpoint;
              const p256dh = arrayBufferToBase64(subscription.getKey("p256dh"));
              const auth = arrayBufferToBase64(subscription.getKey("auth"));
              UsersService.postApiUsersNotificationsPush({
                endpoint,
                p256dh,
                auth,
              }).then((response) => {});
            })
            .catch((ex) => {
              window.alert(ex);
            });
        });
      }
      localStorage.setItem("userSeenPushNotification", "true");
      setPushNotified(true);
    } catch (ex) {
      window.alert(ex);
    }
  };

  const handleReject = () => {
    localStorage.setItem("userSeenPushNotification", "true");
    setPushNotified(true);
  };

  return (
    <>
      {!pushNotified && !!user && iosCanPush && (
        <div className="justify-content-end sticky-details-bottom-menu">
          <div className="row my-auto p-4 pb-0">
            <span>
              Allow us to send notifications through your browser. You will only
              receive notifications about your leads.
            </span>
          </div>
          <div className="row text-center p-3">
            <div className="col-6">
              <Button
                variant="contained"
                color="primary"
                className="full-btn"
                onClick={handleSubscribe}
              >
                Allow
              </Button>
            </div>
            <div className="col-6">
              <Button
                variant="contained"
                color="secondary"
                className="full-btn"
                onClick={handleReject}
              >
                Don't allow
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
