import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import Tab from "@mui/material/Tab/Tab";
import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody/TableBody";
import TableCell from "@mui/material/TableCell/TableCell";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import Tabs from "@mui/material/Tabs/Tabs";
import React, { ReactNode, SyntheticEvent, useEffect, useState } from "react";
import { AttorneyCard } from "./AttorneysCard";
import "../Attorneys.scss";
import {
  ApplicationRole,
  GetUserListRequest,
  UserShortResponse,
  UsersService,
} from "../../../../api";
import useNavigateSearch from "../../../../services/useNavigateSearch";
import { TabsHelpers } from "../../../../components/basic/TabsHelpers";

interface HeadCell {
  id: string;
  label: string;
}
const headCells: readonly HeadCell[] = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "leads",
    label: "Leads",
  },
];

export default function AttorneysTable() {
  const [value, setValue] = useState<number>(0);
  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [approvedAttorneys, setApprovedAttorneys] = useState<
    UserShortResponse[]
  >([]);
  const [pendingAttorneys, setPendingAttorneys] = useState<UserShortResponse[]>(
    []
  );

  const navigate = useNavigateSearch();

  useEffect(() => {
    UsersService.postApiUsersGetUsersByRole({
      role: ApplicationRole.ATTORNEY
    } as GetUserListRequest
    ).then(
      (users) => {
        setApprovedAttorneys(users);
      }
    );
    UsersService.postApiUsersGetUsersByRole({
      role: ApplicationRole.USER
    } as GetUserListRequest
    ).then(
      (users) => {
        setPendingAttorneys(users.filter((user) => user.isPending));
      }
    );
  }, []);

  const handleDetailsClick = (id: number) => {
    navigate(`/attorney/${id}`);
  };

  const handleCreateRegistrationLink = () => {
    navigate("/generateLink");
  };

  const handleCreateAttorney = () => {
    navigate("/attorney/new");
  };

  return (
    <Box sx={{ width: "100%" }}>
      <div className="row justify-content-between">
        <div className="col-12 row">
          <div className="col-6">
            <h2 className="title">Attorneys</h2>
          </div>
          <div className="col-3 d-flex justify-content-end mb-4">
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateAttorney}
            >
              Add new attorney
            </Button>
          </div>
          <div className="col-3 d-flex justify-content-end mb-4">
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateRegistrationLink}
            >
              Create registration link
            </Button>
          </div>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleTabChange}>
              <Tab label="ACTIVE ATTORNEYS" {...TabsHelpers.parseProps(0)} />
              <Tab label="PENDING APPROVAL" {...TabsHelpers.parseProps(1)} />
            </Tabs>
          </Box>
          <TabsHelpers.TabPanel value={value} index={0}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium"
              >
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id}>{headCell.label}</TableCell>
                    ))}
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {approvedAttorneys
                    ? approvedAttorneys.map((row, index) => {
                      return (
                        <TableRow tabIndex={-1} key={row.id}>
                          <TableCell align="left">
                            {row.firstName} {row.lastName}
                          </TableCell>
                          <TableCell align="left">
                            <div className="col-12 d-flex table-filters">
                              <Button
                                variant="contained"
                                color="darkGray"
                                size="small"
                              >
                                All(
                                {Object.values(
                                  row.countByLeadStatus ?? {}
                                ).reduce((acc, count) => acc! + count!, 0)}
                                )
                              </Button>
                              <Button
                                variant="contained"
                                color="cyan"
                                size="small"
                              >
                                New({row.countByLeadStatus?.new ?? 0})
                              </Button>
                              <Button
                                variant="contained"
                                color="purple"
                                size="small"
                              >
                                Accepted(
                                {row.countByLeadStatus?.accepted ?? 0})
                              </Button>
                              <Button
                                variant="contained"
                                color="dirtyYellow"
                                size="small"
                              >
                                Rejected(
                                {row.countByLeadStatus?.Rejected ?? 0})
                              </Button>
                              <Button
                                variant="contained"
                                color="brown"
                                size="small"
                              >
                                Lost({row.countByLeadStatus?.lost ?? 0})
                              </Button>
                              <Button
                                variant="contained"
                                color="dirtyBlue"
                                size="small"
                              >
                                Won({(row.countByLeadStatus?.won ?? 0) + (row.countByLeadStatus?.signed ?? 0) + (row.countByLeadStatus?.paid ?? 0)})
                              </Button>
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={() => handleDetailsClick(row.id!)}
                            >
                              View details
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </TabsHelpers.TabPanel>
          <TabsHelpers.TabPanel value={value} index={1}>
            <div className="row">
              {pendingAttorneys.length > 0 ? (
                pendingAttorneys.map((row) => (
                  <AttorneyCard
                    action={handleDetailsClick}
                    attorney={row}
                    key={row.id}
                  ></AttorneyCard>
                ))
              ) : (
                <div className="text-center caption-text mt-3">
                  No attorneys pending review.
                </div>
              )}
            </div>
          </TabsHelpers.TabPanel>
        </div>
      </div>
    </Box>
  );
}
