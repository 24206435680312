/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IntentResponse } from '../models/IntentResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentsService {

    /**
     * @param leadId 
     * @returns IntentResponse Success
     * @throws ApiError
     */
    public static postApiPaymentsSecret(
leadId: number,
): CancelablePromise<IntentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Payments/{leadId}/secret',
            path: {
                'leadId': leadId,
            },
        });
    }

    /**
     * @param leadId 
     * @param requestBody 
     * @returns IntentResponse Success
     * @throws ApiError
     */
    public static postApiPaymentsLogError(
leadId: number,
requestBody?: string,
): CancelablePromise<IntentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Payments/{leadId}/logError',
            path: {
                'leadId': leadId,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

}
