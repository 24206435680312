/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Category {
    PERSONAL_INJURY = 'PersonalInjury',
    WORKERS_COMPENSATION = 'WorkersCompensation',
    EMPLOYMENT = 'Employment',
    CLASS_ACTION = 'ClassAction',
}
