import jwt from "jwt-decode";
import { OpenAPI } from "../../api";
import { HelpersService } from "../../services/Helpers";
import {
  SET_USER,
  LOGOUT_USER,
  UPDATE_USER_DATA,
  UPDATE_USER_PHOTO,
} from "../actions/userActions";

const isTokenValid = (token) => {
  if (!token) return false;

  const decodedToken = jwt(token);
  if (typeof decodedToken !== "object" || decodedToken === null) return false;

  return decodedToken["exp"] * 1000 >= Date.now();
};

var userInfo =
  localStorage.getItem("user_info") &&
  JSON.parse(window.atob(localStorage.getItem("user_info") ?? ""));

if (!userInfo?.token || !isTokenValid(userInfo.token)) {
  localStorage.removeItem("user_info");
  userInfo = null;
  OpenAPI.HEADERS = HelpersService.authHeader();
} else {
  OpenAPI.HEADERS = HelpersService.authHeader(userInfo.token);
}
const initialUserState: any = { user: userInfo };
const userReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case SET_USER:
      if (!isTokenValid(action.payload.token)) {
        localStorage.removeItem("user_info");
        return {
          ...state,
          user: null,
        };
      }
      localStorage.setItem(
        "user_info",
        window.btoa(JSON.stringify(action.payload).replace(/[^\x00-\x7F]/g, ""))
      );
      OpenAPI.HEADERS = HelpersService.authHeader(action.payload.token);
      return {
        ...state,
        user: action.payload,
      };
    case UPDATE_USER_DATA:
      action.payload.token = state.user.token;
      localStorage.setItem(
        "user_info",
        window.btoa(JSON.stringify(action.payload).replace(/[^\x00-\x7F]/g, ""))
      );
      return {
        ...state,
        user: action.payload,
      };
    case UPDATE_USER_PHOTO:
      var updatedUser = state.user;
      updatedUser.photo = action.payload;
      localStorage.setItem(
        "user_info",
        window.btoa(JSON.stringify(updatedUser).replace(/[^\x00-\x7F]/g, ""))
      );
      return {
        ...state,
        user: updatedUser,
      };
    case LOGOUT_USER:
      localStorage.removeItem("user_info");
      localStorage.removeItem("userPicture");
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

//TODO: add as another reducer when needed
export const refreshUser = () => {
  return (dispatch, getState) => {
    const { token } = getState().user.token;

    if (!isTokenValid(token)) {
      localStorage.removeItem("user_info");
      return Promise.reject("Token is not valid");
    }

    // TODO: Add the logic to refresh the user data using the token
    // For example, you can call an API endpoint to get the updated user data
    // and dispatch the setUser action with the new data.
    // You can use Axios or any other HTTP library for this.

    return Promise.resolve(); // Replace this with the actual API call
  };
};

export default userReducer;
