import { SET_ATTORNEYS } from "../actions/attorneyReferActions";

const initialAttorneyState: any = null
const referAttorneyReducers = (state = initialAttorneyState, action) => {
  switch (action.type) {
    case SET_ATTORNEYS:
      return {
        ...state,
        attorneys: action.payload,
      };
    default:
      return state;
  }
};

export default referAttorneyReducers;