/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientSearchRequest } from '../models/ClientSearchRequest';
import type { HubspotWebhookResponse } from '../models/HubspotWebhookResponse';
import type { InitialClientDataResponse } from '../models/InitialClientDataResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HubspotService {

    /**
     * @param code 
     * @returns any Success
     * @throws ApiError
     */
    public static getApiHubspotOauthCallback(
code?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Hubspot/oauth-callback',
            query: {
                'code': code,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiHubspotUpdateWebhook(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Hubspot/updateWebhook',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiHubspotWebhook(
requestBody?: Array<HubspotWebhookResponse>,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Hubspot/webhook',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns InitialClientDataResponse Success
     * @throws ApiError
     */
    public static postApiHubspotSearchClientInHubspot(
requestBody?: ClientSearchRequest,
): CancelablePromise<InitialClientDataResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Hubspot/searchClientInHubspot',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiHubspotUpdateHubspotProperties(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Hubspot/updateHubspotProperties',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiHubspotInitiate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Hubspot/initiate',
        });
    }

}
