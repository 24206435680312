/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ReasonForDeclinePI {
    AUTO = 'Auto',
    PREMISES_LIABILITY = 'PremisesLiability',
    MED_MAL = 'MedMal',
    OTHER = 'Other',
}
