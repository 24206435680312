/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum HurtType {
    AUTO = 'Auto',
    TRUCK_SEMI = 'TruckSemi',
    RIDESHARE = 'Rideshare',
    BUS = 'Bus',
    RAIL = 'Rail',
    DANGEROUS_ROAD = 'DangerousRoad',
    MEDICAL_MALPRACTICE = 'MedicalMalpractice',
    SLIP_FALL = 'SlipFall',
    PREMISES_LIABILITY = 'PremisesLiability',
    PEDESTRIAN = 'Pedestrian',
}
