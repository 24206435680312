/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CustomFields {
    TERMINATION_DATE = 'TerminationDate',
    CONVERSION_DATE = 'ConversionDate',
    SETTLEMENT_AMOUNT = 'SettlementAmount',
    EARLIEST_SOLE = 'EarliestSOLE',
    SENIOR_MANAGING_ATTY_OLD = 'SeniorManagingAttyOld',
    RESPONSIBLE_ATTORNEY_OLD = 'ResponsibleAttorneyOld',
    SUPPORTING_ATTY_OLD = 'SupportingAttyOld',
    ASSIGNED_PARALEGAL_OLD = 'AssignedParalegalOld',
    LEGAL_ASSISTANT_OLD = 'LegalAssistantOld',
    STATUS_ARBITRATION_AGREEMENT = 'StatusArbitrationAgreement',
    RATING_COA = 'RatingCOA',
    RATED_BY_COA = 'RatedByCOA',
    RATING_NEGOTIATIONS = 'RatingNegotiations',
    RATED_BY_NEGOTIATIONS = 'RatedByNegotiations',
    CASE_TYPE = 'CaseType',
    DLRESPONSE_RECEIVED = 'DLResponseReceived',
    OCCONTACT_INFO = 'OCContactInfo',
    DEFENDANT_AGENT = 'DefendantAgent',
    HANDLING_ATTORNEYS = 'HandlingAttorneys',
    OPPOSING_COUNSEL_FIRM = 'OpposingCounselFirm',
    DEFENDANTS = 'Defendants',
    ASSIGNED_PARALEGAL = 'AssignedParalegal',
    RESPONSIBLE_ATTORNEY = 'ResponsibleAttorney',
    RATING_CORRESPONDENCE = 'RatingCorrespondence',
    RATED_BY_CORRESPONDENCE = 'RatedByCorrespondence',
    CASE_IS_LITIGATION = 'CaseIsLitigation',
    JLGFEES = 'JLGFees',
    SUPPORTING_ATTY = 'SupportingAtty',
    SENIOR_MANAGING_ATTY = 'SeniorManagingAtty',
    LEGAL_ASSISTANT = 'LegalAssistant',
    PAYMENT_RECEIVED_AT = 'PaymentReceivedAt',
    WRONGFUL_TERMINATION_SOLE = 'WrongfulTerminationSOLE',
    FEHASOLE = 'FEHASOLE',
    WHSOLE = 'WHSOLE',
    UCLSOLE = 'UCLSOLE',
    SOLENOTES = 'SOLENotes',
}
