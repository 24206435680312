import IconButton from "@mui/material/IconButton/IconButton";
import TableCell from "@mui/material/TableCell/TableCell";
import TableRow from "@mui/material/TableRow/TableRow";
import React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse/Collapse";
import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody/TableBody";

import { LeadType } from "../../LeadsManagement/shared/Constants";
import useNavigateSearch from "../../../../services/useNavigateSearch";
import { AttorneyLeadsResponse } from "../../../../api";
import { HelpersService } from "../../../../services/Helpers";

interface AttorneysRowProps {
  status: string;
  leads: AttorneyLeadsResponse[] | null;
}

export default function AttorneyLeadsRow(props: AttorneysRowProps) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigateSearch();
  const handleDetailsOpen = (id) => {
    navigate(`/lead/${id}`, { leadType: LeadType.Admin, isEdit: "false" });
  };

    return (
      <React.Fragment>
        <TableRow className="attorneys-table">
          <TableCell scope="row">{HelpersService.camelToString(props.status)}</TableCell>
          <TableCell sx={{ width: "75px" }}>
            <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className="inner-table">
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            className="inner-row"
            colSpan={2}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table>
                <TableBody>
                  {props.leads?.map((lead) => (
                    <TableRow
                      hover
                      key={lead.id}
                      onClick={() => handleDetailsOpen(lead.id)}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell scope="row">{lead.firstName} {lead.lastName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
}
