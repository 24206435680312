import { getIn, useFormik } from "formik";
import TextField from "@mui/material/TextField/TextField";
import FormControl from "@mui/material/FormControl/FormControl";
import FormLabel from "@mui/material/FormLabel/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Button from "@mui/material/Button/Button";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Select from "@mui/material/Select/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Checkbox from "@mui/material/Checkbox/Checkbox";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import { useEffect, useState } from "react";
import Rating from "@mui/material/Rating/Rating";
import StarIcon from "@mui/icons-material/Star";
import Dropzone from "react-dropzone";
import IconButton from "@mui/material/IconButton/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import RadioControl from "../../../../components/basic/RadioControl";
import {
  AdminLeadStatus,
  ApplicationRole,
  Category,
  FileService,
  FileType,
  HurtType,
  InitialClientDataResponse,
  Injuries,
  LeadsService,
  LeadViewModel,
  OpenAPI,
  RadioOptions,
  VehicleType,
} from "../../../../api";
import * as yup from "yup";
import {
  clientDataSchema,
  FileModel,
  InjuriesDisplayMapping,
  LeadType,
  maxFileSize,
} from "../shared/Constants";
import useNavigateSearch from "../../../../services/useNavigateSearch";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { HelpersService } from "../../../../services/Helpers";
import { MuiTelInput } from "mui-tel-input";
import { useDispatch } from "react-redux";
import {
  startSpinner,
  stopSpinner,
} from "../../../../redux/actions/spinnerActions";
import TextControl from "../../../../components/basic/TextControl";
import UnsavedChangesWarning from "../../../../services/UnsavedChangesWarning";
import MapsAutocomplete from "../../../../components/basic/MapsAutocomplete";

const MySwal = withReactContent(Swal);
interface PersonalInjuryProps {
  data?: LeadViewModel;
  isEdit?: boolean;
  isNew?: boolean;
  leadType?: LeadType;
  userRoles?: ApplicationRole[];
}

const emptyData: LeadViewModel = {
  status: undefined,
  category: Category.PERSONAL_INJURY,
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  birthDate: null,

  personalInjuryDetails: {
    autoInsurance: undefined,
    medicalInsurance: undefined,

    address: "",
    hurtType: undefined,
    accidentDate: undefined,
    accidentLocation: "",
    accidentDetails: "",
    vehiclePassengers: "",
    policeInvestigated: undefined,
    accidentReportObtained: undefined,
    incidentNumber: "",
    statementGiven: undefined,
    statementGivenTo: "",
    ticketIssued: undefined,
    tickedIssuedTo: "",
    tickedIssuedNumber: "",

    attorneySpoken: undefined,
    attorneySigned: undefined,
    otherAttorneyName: "",
    caseNumber: "",

    defendantsFirstName: "",
    defendantsLastName: "",
    defendantsPhone: "",
    defendantsEmail: "",
    defendantsDriverLicense: "",
    defendantVehicle: undefined,

    injuries: [],
    otherInjuries: "",
    requireHospital: undefined,
    hospitalDetails: "",
    requireTreatment: undefined,
    treatmentDetails: "",
    priorInjuries: "",
    priorConditions: "",

    vehicleDamageCollected: undefined,
    propertyDamageEstimate: undefined,
    vehiclePictures: undefined,

    missedJob: undefined,
    jobTitle: "",
    employer: "",
    wagesLostEstimated: null,
    rateOfPay: null,
    howOftenPayed: "",

    defendantsInsuranceCompany: "",
    defendantsInsuranceActive: undefined,
    defendantsInsuranceNumber: "",
  },
  csNotes: "",
  credibility: null,
};

export default function PersonalInjuryForm(props: PersonalInjuryProps) {
  const [files, setFiles] = useState<FileModel[]>([]);
  const [hideEmpty, setHideEmpty] = useState(false);
  const navigate = useNavigateSearch();
  const dispatch = useDispatch();
  const geocoder = new google.maps.Geocoder();

  useEffect(() => {
    if (props.leadType !== LeadType.Attorney || props.data?.status === AdminLeadStatus.PAID) {
      return
    }
    setFieldValue("personalInjuryDetails.address", "");
    const cityComponent = "locality";
    const stateComponent = "administrative_area_level_1";
    geocoder
      .geocode({
        address: props.data?.personalInjuryDetails?.address
      })
      .then((response) => {
        if (response.results[0]) {
          const city = response.results[0].address_components.find(address => address.types.includes(cityComponent))?.long_name ?? "";
          const state = response.results[0].address_components.find(address => address.types.includes(stateComponent))?.short_name ?? "";
          setFieldValue("personalInjuryDetails.address", `${city}, ${state}`);
        }
        else {
          setFieldValue("personalInjuryDetails.address", "Hidden");
        }
      })
      .catch(() => { setFieldValue("personalInjuryDetails.address", "Hidden"); });
  }, [props.data?.personalInjuryDetails]);

  useEffect(() => {
    if (!!props.data?.files) {
      const filesMapped = props.data?.files.map((file) => ({
        url: file.url!,
        key: file.name!,
      }));
      setFiles(filesMapped);
    }
  }, [props.data?.files]);

  useEffect(() => {
    setHideEmpty(props.leadType === LeadType.Attorney);
  }, [props.leadType]);

  const handleFilesUpdate = async (acceptedFiles) => {
    const sameFiles: FileModel[] = files.filter((oldFile) =>
      acceptedFiles.some((newFile) => newFile.name === oldFile.key)
    );
    if (sameFiles.length > 0) {
      MySwal.fire({
        title: <strong>You are trying to add files that already exist</strong>,
        html: (
          <div>
            {sameFiles.map((file) => `${file.key}, `)} will be replaced.
          </div>
        ),
        icon: "info",
        showCancelButton: true,
        cancelButtonText: "No, keep it",
        confirmButtonText: "Yes, replace",
        confirmButtonColor: "#ed633a",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (values.id) {
            await updateFiles(values.id!, acceptedFiles);
          }
          const removeDups = files.filter(
            (file) => !sameFiles.some((sameFile) => file.key === sameFile.key)
          );
          setFiles([...removeDups, ...parseFiles(acceptedFiles)]);
        }
      });
    } else {
      if (values.id) {
        await updateFiles(values.id!, acceptedFiles);
      }
      setFiles([...files, ...parseFiles(acceptedFiles)]);
    }
  };

  const parseFiles = (acceptedFiles) =>
    acceptedFiles.map((file) => {
      return {
        key: file.name,
        file,
      };
    });

  const handleFileRemove = (key) => {
    MySwal.fire({
      title: <strong>Confirm file removal</strong>,
      html: <div>{key} will be deleted from this lead.</div>,
      icon: "info",
      showCancelButton: true,
      cancelButtonText: "No, keep it",
      confirmButtonText: "Yes, remove",
      confirmButtonColor: "#ed633a",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setFiles((prevFiles) => prevFiles.filter((file) => file.key !== key));
        if (!!values.id) {
          await FileService.postApiFileDelete(values.id!, {
            fileNames: [key],
          }).catch(() => {
            MySwal.fire({
              icon: "error",
              title: <p className="title">Error removing files</p>,
            });
          });
        }
      }
    });
  };

  const onSubmit = async (form) => {
    var newLead = form as LeadViewModel;
    newLead.isDraft = false;
    if (values.personalInjuryDetails!.hurtType) {
      newLead.personalInjuryDetails!.hurtType =
        HurtType[values.personalInjuryDetails!.hurtType];
    }
    if (values.personalInjuryDetails!.defendantVehicle) {
      newLead.personalInjuryDetails!.defendantVehicle =
        VehicleType[values.personalInjuryDetails!.defendantVehicle];
    }

    newLead.category = Category.PERSONAL_INJURY;
    newLead.employmentDetails = undefined;
    newLead.workersCompDetails = undefined;

    dispatch(startSpinner());

    if (values.id) {
      LeadsService.putApiLeads(values.id, newLead)
        .then(async () => {
          MySwal.fire({
            icon: "success",
            title: <p className="title">Lead updated successfully</p>,
          }).then(() => window.location.reload());
        })
        .catch((e) => {
          MySwal.fire({
            icon: "error",
            title: <p className="title">Error updating lead</p>,
            html: (
              <div>
                Please make sure email and other fields have proper values.
              </div>
            ),
          });
        })
        .finally(() => dispatch(stopSpinner()));
      return;
    }

    LeadsService.postApiLeads(newLead)
      .then(async (id) => {
        await updateFiles(id);
        MySwal.fire({
          icon: "success",
          title: <p className="title">Lead created successfully</p>,
        }).then(() => navigate("/new-leads"));
      })
      .catch(() =>
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error creating new lead</p>,
          html: (
            <div>
              Please make sure email and other fields have proper values.
            </div>
          ),
        })
      )
      .finally(() => dispatch(stopSpinner()));
  };

  const updateFiles = async (leadId, filesToUpdate: any = null) => {
    dispatch(startSpinner());
    if (filesToUpdate === null) {
      filesToUpdate = files.map((file) => file.file);
      filesToUpdate = filesToUpdate.filter((file) => file !== undefined);
    }
    if (filesToUpdate.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < filesToUpdate.length; i++) {
        formData.append(`files`, filesToUpdate[i]);
      }
      const url = `${OpenAPI.BASE}/api/File/${leadId}/upload`;
      await axios
        .post(url, formData, {
          params: {
            type: FileType.LEAD_FILE,
          },
        })
        .catch(() => {
          MySwal.fire({
            icon: "error",
            title: <p className="title">Error uploading files.</p>,
            html: <div>Please try again later. Your notes are saved.</div>,
          });
        })
        .finally(() => dispatch(stopSpinner()));
    } else {
      dispatch(stopSpinner());
    }
  };

  const handleAddIncomplete = () => {
    dispatch(startSpinner());
    //TODO: move to redux?, it is same for all forms, keep it separate from other components
    clientDataSchema
      .validate(values, { abortEarly: false })
      .then(() => {
        var newLead = { ...values } as LeadViewModel;
        newLead.isDraft = true;
        newLead.personalInjuryDetails!.hurtType =
          HurtType[values.personalInjuryDetails!.hurtType!];
        newLead.personalInjuryDetails!.defendantVehicle =
          VehicleType[values.personalInjuryDetails!.defendantVehicle!];

        newLead.category = Category.PERSONAL_INJURY;
        newLead.employmentDetails = undefined;
        newLead.workersCompDetails = undefined;

        if (values.id) {
          LeadsService.putApiLeads(values.id, newLead)
            .then(() =>
              MySwal.fire({
                icon: "success",
                title: <p className="title">Lead updated successfully</p>,
              }).then(() => window.location.reload())
            )
            .catch(() => {
              MySwal.fire({
                icon: "error",
                title: <p className="title">Error updating lead on Hubspot</p>,
                html: (
                  <div className="alert-text">
                    Please make sure that all fields in Client Info are valid.
                    If you still get this error, please contact admin.
                  </div>
                ),
              });
            })
            .finally(() => dispatch(stopSpinner()));
          return;
        }
        LeadsService.postApiLeads(newLead)
          .then(() => navigate("/new-leads"))
          .catch(() => {
            MySwal.fire({
              icon: "error",
              title: <p className="title">Error creating lead on Hubspot</p>,
              html: (
                <div className="alert-text">
                  Please make sure that all fields in Client Info are valid. If
                  you still get this error, please contact admin.
                </div>
              ),
            });
          })
          .finally(() => dispatch(stopSpinner()));
      })
      .catch(() => {
        setFieldTouched("firstName", true);
        setFieldTouched("lastName", true);
        setFieldTouched("phone", true);
        setFieldTouched("email", true);
        setFieldTouched("birthDate", true);
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error creating incomplete lead</p>,
          html: (
            <div className="alert-text">
              Please make sure that all fields in Client Info are filled.
            </div>
          ),
        });
        dispatch(stopSpinner());
      });
  };

  const handleCheckboxChange = (value, name: string) => {
    var arrayToUpdate = [] as (typeof value)[];
    let index = -1;

    var details = values.personalInjuryDetails;
    if (!!details) {
      arrayToUpdate = details[name.split(".")[1]];
      index = arrayToUpdate?.indexOf(value);
    }

    if (index === -1) {
      arrayToUpdate = [...arrayToUpdate, value];
    } else {
      arrayToUpdate = arrayToUpdate.filter((reason) => reason !== value);
    }

    handleChange({
      target: { value: arrayToUpdate, name: name },
    });
  };

  updateUserData = (data: InitialClientDataResponse) => {
    (Object.keys(data) as (keyof typeof data)[]).forEach((key, index) => {
      handleChange({
        target: { value: data[key], name: key },
      });
    });
  };

  const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    phone: yup.string().required(),
    // .test("is-valid-phone", "Invalid phone", function (value) {
    //   return matchIsValidTel(value);
    // }),
    email: yup.string().email().required(),
    birthDate: yup.mixed().required(),

    personalInjuryDetails: yup.object().shape({
      autoInsurance: yup.mixed().required(),
      medicalInsurance: yup.mixed().required(),
      attorneySpoken: yup.mixed().required(),
      attorneySigned: yup
        .mixed()
        .optional()
        .nullable()
        .when("attorneySpoken", {
          is: RadioOptions.YES,
          then: (schema) => schema.required(),
        }),
      // defendantsPhone: yup
      //   .string()
      //   .optional()
      //   .test("is-valid-defendant-phone", "Invalid phone", function (value) {
      //     return !value || matchIsValidTel(value);
      //   }),
    }),
  });
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = useFormik({
    initialValues: props.data ?? emptyData,
    validationSchema: schema,
    onSubmit,
  });

  resetFormWith = (values: LeadViewModel) => {
    values
      ? resetForm({ values: values })
      : resetForm({ values: props.data ?? emptyData });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={!props.isEdit ? "hide-actions" : ""}
    >
      <UnsavedChangesWarning
        hasUnsavedChanges={!props.data?.id && Object.keys(touched).length > 0}
      />
      <fieldset disabled={!props.isEdit}>
        {(!!values.isFullSummary ||
          props.leadType === LeadType.Admin ||
          props.leadType === LeadType.StaffAttorney) && (
          <div className="row mt-3">
            <div className="col-12 mt-3">Full legal name</div>
            <div className="col-12 col-md-6 mt-3">
              <TextField
                required
                label="First name"
                variant="outlined"
                name="firstName"
                value={values.firstName}
                className={
                  !!errors.firstName && !!touched.firstName ? "error" : ""
                }
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!errors.firstName && !!touched.firstName && (
                <FormHelperText className="error-message">
                  Required
                </FormHelperText>
              )}
            </div>
            <div className="col-12 col-md-6 mt-3">
              <TextField
                required
                label="Last name"
                variant="outlined"
                name="lastName"
                className={
                  !!errors.lastName && !!touched.lastName ? "error" : ""
                }
                value={values.lastName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!errors.lastName && !!touched.lastName && (
                <FormHelperText className="error-message">
                  Required
                </FormHelperText>
              )}
            </div>
            <div className="col-12 col-md-6 mt-3">
              <MuiTelInput
                required
                label="Phone"
                variant="outlined"
                name="phone"
                className={!!errors.phone && !!touched.phone ? "error" : ""}
                onlyCountries={["US"]}
                defaultCountry={"US"}
                disableDropdown
                value={values.phone ?? undefined}
                onBlur={handleBlur}
                onChange={(newValue) => {
                  setFieldValue("phone", newValue);
                }}
              />
              {!!errors.phone && !!touched.phone && (
                <FormHelperText className="error-message">
                  {errors.phone}
                </FormHelperText>
              )}
            </div>
            <div className="col-12 col-md-6 mt-3">
              <TextField
                required
                label="email"
                variant="outlined"
                name="email"
                className={!!errors.email && !!touched.email ? "error" : ""}
                value={values.email}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("email", e.target.value.trim())}
              />
              {!!errors.email && !!touched.email && (
                <FormHelperText className="error-message">
                  {errors.email}
                </FormHelperText>
              )}
            </div>
            <div className="col-12 col-md-6 mt-3 datepicker-holder">
              <FormControl
                error={!!errors.birthDate && !!touched.birthDate}
                required
              >
                <DatePicker
                  disableFuture
                  label="Birth Date *"
                  value={!!values.birthDate ? dayjs(values.birthDate) : null}
                  onChange={(date) =>
                    handleChange({
                      target: { value: date, name: "birthDate" },
                    })
                  }
                  className={
                    !!errors.birthDate && !!touched.birthDate ? "error" : ""
                  }
                />
                {!!errors.birthDate && !!touched.birthDate && (
                  <FormHelperText>Required</FormHelperText>
                )}
              </FormControl>
            </div>
          </div>
        )}
        <div className="col-12 mt-3">
        <MapsAutocomplete
              value={values.personalInjuryDetails?.address ?? ""}
              label="Address"
              onChange={(value) => setFieldValue("personalInjuryDetails.address", value)}
            />
        </div>

        <div className="col-12 col-md-6 mt-3">
          <FormControl
            variant="outlined"
            sx={{ m: 1, minWidth: 120 }}
            hidden={hideEmpty && !values.personalInjuryDetails?.hurtType}
          >
            <InputLabel id="hurt-label">How were you hurt?</InputLabel>
            <Select
              disabled={!props.isEdit}
              labelId="hurt-label"
              label="How were you hurt?"
              id="hurt"
              name="personalInjuryDetails.hurtType"
              value={values.personalInjuryDetails?.hurtType}
              onChange={handleChange}
            >
              {Object.keys(HurtType).map((key) => {
                return (
                  <MenuItem key={key} value={key}>
                    {HelpersService.camelToString(HurtType[key])}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <h5 className="title mt-3 mb-3">Automobile Insurance Information</h5>
        <div className="row mt-3">
          <RadioControl
            isRequired
            hideEmpty={hideEmpty}
            requiredError={
              getIn(errors, "personalInjuryDetails.autoInsurance") &&
              getIn(touched, "personalInjuryDetails.autoInsurance")
            }
            propertyName="personalInjuryDetails.autoInsurance"
            label="Do you have automobile Insurance?"
            value={values.personalInjuryDetails?.autoInsurance}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          {!!values.personalInjuryDetails?.autoInsurance &&
            RadioOptions[values.personalInjuryDetails?.autoInsurance] ===
              RadioOptions.YES && (
              <>
                <RadioControl
                  hideEmpty={hideEmpty}
                  propertyName="personalInjuryDetails.autoInsuranceActive"
                  label="Insurance active?"
                  value={values.personalInjuryDetails?.autoInsuranceActive}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                <TextControl
                  hideEmpty={hideEmpty}
                  class="col-12 mt-3"
                  label="Insurance Company"
                  name="personalInjuryDetails.autoInsuranceCompany"
                  value={values.personalInjuryDetails?.autoInsuranceCompany}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!values.personalInjuryDetails?.autoInsuranceActive &&
                  RadioOptions[
                    values.personalInjuryDetails?.autoInsuranceActive
                  ] === RadioOptions.YES && (
                    <>
                      <TextControl
                        hideEmpty={hideEmpty}
                        class="col-12 mt-3"
                        multiline
                        label="What is your primary coverage?"
                        name="personalInjuryDetails.autoInsuranceCoverage"
                        value={
                          values.personalInjuryDetails?.autoInsuranceCoverage
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <TextControl
                        hideEmpty={hideEmpty}
                        class="col-12 mt-3"
                        multiline
                        label="What is your uninsured motorists coverage?"
                        name="personalInjuryDetails.autoMotoristsCoverage"
                        value={
                          values.personalInjuryDetails?.autoMotoristsCoverage
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </>
                  )}
              </>
            )}

          <h5 className="title mb-3 mt-3">Medical Insurance Information</h5>
          <RadioControl
            isRequired
            hideEmpty={hideEmpty}
            requiredError={
              getIn(errors, "personalInjuryDetails.medicalInsurance") &&
              getIn(touched, "personalInjuryDetails.medicalInsurance")
            }
            propertyName="personalInjuryDetails.medicalInsurance"
            label="Do you have medical Insurance?"
            value={values.personalInjuryDetails?.medicalInsurance}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          {!!values.personalInjuryDetails?.medicalInsurance &&
            RadioOptions[values.personalInjuryDetails?.medicalInsurance] ===
              RadioOptions.YES && (
              <>
                <RadioControl
                  hideEmpty={hideEmpty}
                  propertyName="personalInjuryDetails.medicalInsuranceActive"
                  label="Insurance active?"
                  value={values.personalInjuryDetails?.medicalInsuranceActive}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                <TextControl
                  hideEmpty={hideEmpty}
                  class="col-12 mt-3"
                  label="Insurance Company"
                  name="personalInjuryDetails.medicalInsuranceCompany"
                  value={values.personalInjuryDetails?.medicalInsuranceCompany}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!values.personalInjuryDetails?.medicalInsuranceActive &&
                  RadioOptions[
                    values.personalInjuryDetails?.medicalInsuranceActive
                  ] === RadioOptions.YES && (
                    <TextControl
                      hideEmpty={hideEmpty}
                      class="col-12 mt-3"
                      label="What is your coverage?"
                      name="personalInjuryDetails.medicalInsuranceCoverage"
                      value={
                        values.personalInjuryDetails?.medicalInsuranceCoverage
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  )}
              </>
            )}
        </div>
        <div className="row mt-3">
          <h5 className="title mb-3">Accident Information</h5>
          <div className="col-12 col-md-6 mt-3 dropdown-holder">
            <FormControl
              hidden={hideEmpty && !values.personalInjuryDetails?.accidentDate}
            >
              <DatePicker
                disableFuture
                label="Date of accident"
                value={
                  !!values.personalInjuryDetails?.accidentDate
                    ? dayjs(values.personalInjuryDetails?.accidentDate)
                    : null
                }
                onChange={(date) =>
                  handleChange({
                    target: {
                      value: date,
                      name: "personalInjuryDetails.accidentDate",
                    },
                  })
                }
              />
            </FormControl>
          </div>
          <div className="col-12 col-md-6 mt-3">
          <MapsAutocomplete
              value={values.personalInjuryDetails?.accidentLocation ?? ""}
              label="Location"
              onChange={(value) => setFieldValue("personalInjuryDetails.accidentLocation", value)}
            />
          </div>
          <TextControl
            multiline
            class="col-12 mt-3"
            hideEmpty={hideEmpty}
            placeholder="Where were you coming from? Where were you going?"
            name="personalInjuryDetails.accidentDetails"
            label="How did the accident happen?"
            value={values.personalInjuryDetails?.accidentDetails}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <RadioControl
            hideEmpty={hideEmpty}
            propertyName="personalInjuryDetails.onClock"
            label="Was the defendant on the clock at the time of the accident?"
            value={values.personalInjuryDetails?.onClock}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <TextControl
            hideEmpty={hideEmpty}
            name="personalInjuryDetails.vehiclePassengers"
            label="Passengers in vehicle?"
            value={values.personalInjuryDetails?.vehiclePassengers}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <div className="col-12 mt-3 row">
            <RadioControl
              hideEmpty={hideEmpty}
              propertyName="personalInjuryDetails.policeInvestigated"
              label="Was accident investigated by a police officer?"
              value={values.personalInjuryDetails?.policeInvestigated}
              class="col-12 col-md-6 mt-3"
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <RadioControl
              hideEmpty={hideEmpty}
              propertyName="personalInjuryDetails.accidentReportObtained"
              label="Was there an Accident/​Collision report?"
              value={values.personalInjuryDetails?.accidentReportObtained}
              class="col-12 col-md-6 mt-3"
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
          </div>
          {!!values.personalInjuryDetails?.accidentReportObtained &&
            RadioOptions[
              values.personalInjuryDetails?.accidentReportObtained
            ] === RadioOptions.YES && (
              <TextControl
                class="col-12"
                hideEmpty={hideEmpty}
                name="personalInjuryDetails.incidentNumber"
                label="Report Number"
                value={values.personalInjuryDetails?.incidentNumber}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            )}
          <div className="col-12 mt-3 row">
            <RadioControl
              hideEmpty={hideEmpty}
              propertyName="personalInjuryDetails.statementGiven"
              label="Statements given to anyone"
              value={values.personalInjuryDetails?.statementGiven}
              class="col-12 col-md-6 mt-3"
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {!!values.personalInjuryDetails?.statementGiven &&
              RadioOptions[values.personalInjuryDetails?.statementGiven] ===
                RadioOptions.YES && (
                <TextControl
                  hideEmpty={hideEmpty}
                  required
                  label="To whom?"
                  name="personalInjuryDetails.statementGivenTo"
                  value={values.personalInjuryDetails?.statementGivenTo}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              )}
          </div>
          <div className="col-12 mt-3 row">
            <RadioControl
              hideEmpty={hideEmpty}
              propertyName="personalInjuryDetails.ticketIssued"
              label="Tickets or Citations issued?"
              value={values.personalInjuryDetails?.ticketIssued}
              class="col-12 col-md-6 mt-3"
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {!!values.personalInjuryDetails?.ticketIssued &&
              RadioOptions[values.personalInjuryDetails?.ticketIssued] ===
                RadioOptions.YES && (
                <>
                  <TextControl
                    hideEmpty={hideEmpty}
                    label="To Which Party?"
                    name="personalInjuryDetails.tickedIssuedTo"
                    value={values.personalInjuryDetails?.tickedIssuedTo}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <TextControl
                    hideEmpty={hideEmpty}
                    label="Ticket or Citation Number"
                    name="personalInjuryDetails.tickedIssuedNumber"
                    value={values.personalInjuryDetails?.tickedIssuedNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </>
              )}
          </div>
        </div>
        <div className="row mt-3">
          <h5 className="title mb-3">Prior Counsel</h5>
          <RadioControl
            hideEmpty={hideEmpty}
            isRequired
            requiredError={
              getIn(errors, "personalInjuryDetails.attorneySpoken") &&
              getIn(touched, "personalInjuryDetails.attorneySpoken")
            }
            propertyName="personalInjuryDetails.attorneySpoken"
            label="Have you spoken to any attorneys?"
            value={values.personalInjuryDetails?.attorneySpoken}
            class="col-12 col-md-6 mt-3"
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          {!!values.personalInjuryDetails?.attorneySpoken &&
            RadioOptions[values.personalInjuryDetails?.attorneySpoken] ===
              RadioOptions.YES && (
              <>
                <RadioControl
                  hideEmpty={hideEmpty}
                  isRequired
                  requiredError={
                    getIn(errors, "personalInjuryDetails.attorneySigned") &&
                    getIn(touched, "personalInjuryDetails.attorneySigned")
                  }
                  propertyName="personalInjuryDetails.attorneySigned"
                  label="Have you signed any contracts with any other attorneys?"
                  value={values.personalInjuryDetails?.attorneySigned}
                  class="col-12 col-md-6 mt-3"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                {!!values.personalInjuryDetails?.attorneySigned &&
                  RadioOptions[values.personalInjuryDetails?.attorneySigned] ===
                    RadioOptions.YES && (
                    <>
                      <TextControl
                        hideEmpty={hideEmpty}
                        label="Attorney name"
                        name="personalInjuryDetails.otherAttorneyName"
                        value={values.personalInjuryDetails?.otherAttorneyName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <TextControl
                        hideEmpty={hideEmpty}
                        label="What is the case number?"
                        name="personalInjuryDetails.caseNumber"
                        value={values.personalInjuryDetails?.caseNumber}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </>
                  )}
              </>
            )}
        </div>
        <div className="row mt-3">
          <h5 className="title mb-3">Defendant's Information</h5>
          <TextControl
            hideEmpty={hideEmpty}
            label="First name"
            name="personalInjuryDetails.defendantsFirstName"
            value={values.personalInjuryDetails?.defendantsFirstName}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <TextControl
            hideEmpty={hideEmpty}
            label="Last name"
            name="personalInjuryDetails.defendantsLastName"
            value={values.personalInjuryDetails?.defendantsLastName}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {hideEmpty &&
          !values.personalInjuryDetails?.defendantsPhone ? null : (
            <div className="col-12 col-md-6 mt-3">
              <MuiTelInput
                label="Phone"
                variant="outlined"
                name="personalInjuryDetails.defendantsPhone"
                className={
                  getIn(errors, "personalInjuryDetails.defendantsPhone") &&
                  getIn(touched, "personalInjuryDetails.defendantsPhone")
                    ? "error"
                    : ""
                }
                onlyCountries={["US"]}
                defaultCountry={"US"}
                disableDropdown
                value={
                  values.personalInjuryDetails?.defendantsPhone ?? undefined
                }
                onBlur={handleBlur}
                onChange={(newValue) => {
                  setFieldValue(
                    "personalInjuryDetails.defendantsPhone",
                    newValue
                  );
                }}
              />
              {getIn(errors, "personalInjuryDetails.defendantsPhone") &&
                getIn(touched, "personalInjuryDetails.defendantsPhone") && (
                  <FormHelperText className="error-message">
                    Invalid phone
                  </FormHelperText>
                )}
            </div>
          )}
          <TextControl
            hideEmpty={hideEmpty}
            label="Email"
            name="personalInjuryDetails.defendantsEmail"
            value={values.personalInjuryDetails?.defendantsEmail}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <TextControl
            hideEmpty={hideEmpty}
            label="Driver's License Number"
            name="personalInjuryDetails.defendantsDriverLicense"
            value={values.personalInjuryDetails?.defendantsDriverLicense}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <div className="col-12 col-md-6 mt-3">
            <FormControl
              variant="outlined"
              sx={{ m: 1, minWidth: 120 }}
              hidden={
                hideEmpty && !values.personalInjuryDetails?.defendantVehicle
              }
            >
              <InputLabel id="vehicle-label">Type of Vehicle</InputLabel>
              <Select
                disabled={!props.isEdit}
                labelId="vehicle-label"
                label="Type of Vehicle"
                name="personalInjuryDetails.defendantVehicle"
                value={values.personalInjuryDetails?.defendantVehicle}
                onChange={handleChange}
              >
                {Object.keys(VehicleType).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {VehicleType[key]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="row mt-3">
          <h5 className="title mb-3">Injuries</h5>
          <div className="col-12 mt-3">
            <FormControl
              component="fieldset"
              style={{ display: "flex" }}
              hidden={hideEmpty && !values.personalInjuryDetails?.injuries}
            >
              <FormLabel component="legend">
                What types of injuries were sustained?
              </FormLabel>
              <FormGroup className="flex-row">
                {Object.keys(Injuries).map((key) => (
                  <div className="col-12 col-md-6" key={key}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.personalInjuryDetails?.injuries?.includes(
                            Injuries[key]
                          )}
                          onChange={(event) => {
                            handleCheckboxChange(
                              event.target.value as Injuries,
                              "personalInjuryDetails.injuries"
                            );
                          }}
                          value={Injuries[key]}
                        />
                      }
                      label={InjuriesDisplayMapping[Injuries[key]]}
                    />
                  </div>
                ))}
                {values.personalInjuryDetails?.injuries?.includes(
                  Injuries.OTHER
                ) && (
                  <TextControl
                    hideEmpty={hideEmpty}
                    label="Describe Other"
                    name="personalInjuryDetails.otherInjuries"
                    value={values.personalInjuryDetails?.otherInjuries}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                )}
              </FormGroup>
            </FormControl>
          </div>
          <RadioControl
            hideEmpty={hideEmpty}
            propertyName="personalInjuryDetails.requireHospital"
            label="Did your injuries require hospitalization?"
            value={values.personalInjuryDetails?.requireHospital}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          {!!values.personalInjuryDetails?.requireHospital &&
            RadioOptions[values.personalInjuryDetails?.requireHospital] ===
              RadioOptions.YES && (
              <TextControl
                class="col-12 mt-3"
                hideEmpty={hideEmpty}
                multiline
                placeholder="What hospital? Where?"
                label="Details"
                name="personalInjuryDetails.hospitalDetails"
                value={values.personalInjuryDetails?.hospitalDetails}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            )}
          <RadioControl
            hideEmpty={hideEmpty}
            propertyName="personalInjuryDetails.requireTreatment"
            label="Did your injuries require medical treatment or surgery?"
            value={values.personalInjuryDetails?.requireTreatment}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          {!!values.personalInjuryDetails?.requireTreatment &&
            RadioOptions[values.personalInjuryDetails?.requireTreatment] ===
              RadioOptions.YES && (
              <TextControl
                hideEmpty={hideEmpty}
                class="col-12 mt-3"
                multiline
                label="Details"
                name="personalInjuryDetails.treatmentDetails"
                value={values.personalInjuryDetails?.treatmentDetails}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            )}
          <TextControl
            hideEmpty={hideEmpty}
            class="col-12 mt-3"
            label="Did you had prior injuries?"
            placeholder="Describe injuries"
            name="personalInjuryDetails.priorInjuries"
            value={values.personalInjuryDetails?.priorInjuries}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <TextControl
            hideEmpty={hideEmpty}
            class="col-12 mt-3"
            label="Did you had Pre-Existing Conditions?"
            placeholder="Describe conditions"
            name="personalInjuryDetails.priorConditions"
            value={values.personalInjuryDetails?.priorConditions}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
        <div className="row mt-3">
          <h5 className="title mb-3">Property Damage Information</h5>
          <RadioControl
            hideEmpty={hideEmpty}
            propertyName="personalInjuryDetails.vehiclePictures"
            label="Do You have Pictures of Your Vehicle?"
            value={values.personalInjuryDetails?.vehiclePictures}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <RadioControl
            hideEmpty={hideEmpty}
            propertyName="personalInjuryDetails.propertyDamageEstimate"
            label="Do You have an Estimate for Property Damage?"
            value={values.personalInjuryDetails?.propertyDamageEstimate}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <RadioControl
            hideEmpty={hideEmpty}
            propertyName="personalInjuryDetails.vehicleDamageCollected"
            label="Property damage already collected on your vehicle?"
            value={values.personalInjuryDetails?.vehicleDamageCollected}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </div>
        <div className="row mt-3">
          <h5 className="title mb-3">Lost Wages</h5>
          <RadioControl
            hideEmpty={hideEmpty}
            propertyName="personalInjuryDetails.missedJob"
            label="Did you Miss Work as a Result of this Accident?"
            value={values.personalInjuryDetails?.missedJob}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          {!!values.personalInjuryDetails?.missedJob &&
            RadioOptions[values.personalInjuryDetails?.missedJob] ===
              RadioOptions.YES && (
              <>
                <TextControl
                  hideEmpty={hideEmpty}
                  label="Occupation/​Job Title"
                  name="personalInjuryDetails.jobTitle"
                  value={values.personalInjuryDetails?.jobTitle}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextControl
                  hideEmpty={hideEmpty}
                  label="Employer"
                  name="personalInjuryDetails.employer"
                  value={values.personalInjuryDetails?.employer}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="col-12 mt-3">
                  <TextControl
                    hideEmpty={hideEmpty}
                    label="What is the estimated amount of the lost wages?"
                    name="personalInjuryDetails.wagesLostEstimated"
                    value={values.personalInjuryDetails?.wagesLostEstimated}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <TextControl
                    hideEmpty={hideEmpty}
                    label="Rate of pay"
                    name="personalInjuryDetails.rateOfPay"
                    value={values.personalInjuryDetails?.rateOfPay}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <TextControl
                  hideEmpty={hideEmpty}
                  label="Paid how often?"
                  name="personalInjuryDetails.howOftenPayed"
                  value={values.personalInjuryDetails?.howOftenPayed}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </>
            )}
        </div>
        <div className="row mt-3">
          <h5 className="title mb-3">Defendant's Insurance Information</h5>

          <div className="col-12 mt-3 row">
            <RadioControl
              hideEmpty={hideEmpty}
              propertyName="personalInjuryDetails.defendantsInsuranceActive"
              label="Insurance Active?"
              value={values.personalInjuryDetails?.defendantsInsuranceActive}
              class="col-12 col-md-6 mt-3"
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {!!values.personalInjuryDetails?.defendantsInsuranceActive &&
              RadioOptions[
                values.personalInjuryDetails?.defendantsInsuranceActive
              ] === RadioOptions.YES && (
                <TextControl
                  hideEmpty={hideEmpty}
                  label="Policy number, if known"
                  name="personalInjuryDetails.defendantsInsuranceNumber"
                  value={
                    values.personalInjuryDetails?.defendantsInsuranceNumber
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              )}
          </div>
          <TextControl
            hideEmpty={hideEmpty}
            class="col-12 mt-3"
            label="Insurance Company"
            name="personalInjuryDetails.defendantsInsuranceCompany"
            value={values.personalInjuryDetails?.defendantsInsuranceCompany}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
        <legend>
          <div className="row mt-5">
            <h5 className="title">Files</h5>
            {(props.userRoles?.includes(ApplicationRole.ADMIN) ||
              props.userRoles?.includes(ApplicationRole.STAFF)) && (
              <div>
                Maximum file size is 100MB. Allowed file types are limited, if
                you cannot upload file that you need, please contact admin.
              </div>
            )}
            <Dropzone
              accept={{
                "application/pdf": [],
                "image/*": [],
                "video/*": [],
                "application/msword": [],
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                  [],
                "application/vnd.ms-excel": [],
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  [],
              }}
              disabled={
                !props.userRoles?.includes(ApplicationRole.ADMIN) &&
                !props.userRoles?.includes(ApplicationRole.STAFF)
              }
              onDrop={(acceptedFiles) => handleFilesUpdate(acceptedFiles)}
              minSize={0}
              maxSize={maxFileSize}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
                fileRejections,
              }) => {
                const isFileTooLarge =
                  fileRejections.length > 0 &&
                  fileRejections[0].file.size > maxFileSize;
                return (
                  <section>
                    <>
                      {(props.userRoles?.includes(ApplicationRole.ADMIN) ||
                        props.userRoles?.includes(ApplicationRole.STAFF)) && (
                        <div {...getRootProps()} className="dropzone">
                          <input {...getInputProps()} />
                          {!isDragActive &&
                            "Click to select files or drag and drop"}
                          {isDragActive && !isDragReject && "Drop file"}
                          {isDragReject && "File type not accepted"}
                          {isFileTooLarge && (
                            <div className="text-danger ms-2">
                              File that you tries to upload is too large.
                            </div>
                          )}
                        </div>
                      )}
                      {files.length > 0 ? (
                        files.map((fileObj) => {
                          const { key, file, url } = fileObj;
                          return (
                            <div key={key}>
                              {file ? (
                                <div>
                                  {file.name}
                                  {(props.userRoles?.includes(
                                    ApplicationRole.ADMIN
                                  ) ||
                                    props.userRoles?.includes(
                                      ApplicationRole.STAFF
                                    )) && (
                                    <Button
                                      className="ms-2"
                                      variant="contained"
                                      color="error"
                                      size="small"
                                      onClick={() => handleFileRemove(key)}
                                    >
                                      Remove
                                    </Button>
                                  )}
                                </div>
                              ) : (
                                <div className="mt-2">
                                  <a
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {key}
                                  </a>
                                  {(props.userRoles?.includes(
                                    ApplicationRole.ADMIN
                                  ) ||
                                    props.userRoles?.includes(
                                      ApplicationRole.STAFF
                                    )) && (
                                    <Button
                                      className="ms-2"
                                      variant="contained"
                                      color="error"
                                      size="small"
                                      onClick={() => handleFileRemove(key)}
                                    >
                                      Remove
                                    </Button>
                                  )}
                                </div>
                              )}
                            </div>
                          );
                        })
                      ) : (
                        <div>No files</div>
                      )}
                    </>
                  </section>
                );
              }}
            </Dropzone>
          </div>
        </legend>
        {hideEmpty && !values.credibility ? null : (
          <div className="row mt-3">
            <h5>Please rate this lead:</h5>
            <span className="width-content">
              How trustworthy/credible did you asses the lead to be?
            </span>
            <Rating
              className="width-content"
              name="credibility"
              value={values.credibility}
              onChange={(e, newValue) =>
                setFieldValue("credibility", !!newValue ? +newValue : 0)
              }
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
          </div>
        )}
        {hideEmpty && !values.comments ? null : (
          <div className="row mt-3">
            <div className="col-12">
              <h5>Intake Comments</h5>

              <TextField
                label="Comments"
                variant="outlined"
                name="csNotes"
                value={values.csNotes}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
          </div>
        )}
        <div className="row mt-5 button-holder">
          {!!values.status &&
            props.isEdit &&
            values.status !== AdminLeadStatus.DRAFT && (
              <>
                <Button
                  variant={
                    Object.keys(errors).filter((error) =>
                      Object.keys(touched).includes(error)
                    ).length > 0
                      ? "contained"
                      : "outlined"
                  }
                  color={
                    Object.keys(errors).filter((error) =>
                      Object.keys(touched).includes(error)
                    ).length > 0
                      ? "errorColor"
                      : "primary"
                  }
                  type="submit"
                  className="submit-btn"
                >
                  Save changes
                </Button>
                {Object.keys(errors).filter((error) =>
                  Object.keys(touched).includes(error)
                ).length > 0 && (
                  <div className="error-message">
                    Please fix errors in the fields above
                  </div>
                )}
              </>
            )}
          {(!values.status || values.status === AdminLeadStatus.DRAFT) && (
            <div className="row mb-5">
              <div className="width-content">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    console.log(errors);
                  }}
                >
                  Submit
                </Button>
                {!!errors && Object.keys(errors).length > 0 && (
                  <div className="error-message">
                    Errors in the form above. Click submit to display them.
                  </div>
                )}
              </div>
              <div className="width-content">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleAddIncomplete()}
                >
                  Incomplete
                </Button>
              </div>
            </div>
          )}
        </div>
      </fieldset>
    </form>
  );
}

var resetFormWith;
PersonalInjuryForm.resetFormWith = (value?) => {
  resetFormWith(value);
};

var updateUserData;
PersonalInjuryForm.updateUserData = (value) => {
  updateUserData(value);
};
