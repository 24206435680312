import * as yup from "yup";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button/Button";
import {
  ApplicationRole,
  Category,
  UserCreateRequest,
  UsersService,
} from "../../api";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import IconButton from "@mui/material/IconButton/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import FormControl from "@mui/material/FormControl/FormControl";
import { useLocation } from "react-router-dom";
import useNavigateSearch from "../../services/useNavigateSearch";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { HelpersService } from "../../services/Helpers";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/actions/userActions";

const MySwal = withReactContent(Swal);

const emptyData: UserCreateRequest = {
  firstName: "",
  lastName: "",
  phoneNumber: null,
  roles: [ApplicationRole.ATTORNEY],
  practiceArea: Category.EMPLOYMENT,
  password: "",
  confirmPassword: "",
};
const schema = yup.object().shape({
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), undefined], "Passwords must match"),
});

const fullSchema = yup.object().shape({
  firstName: yup.string().required("firstName"),
  lastName: yup.string().required("lastName"),
  phoneNumber: yup
    .string()
    .required("phone")
    .test("is-valid-phone", "Invalid phone", function (value) {
      return matchIsValidTel(value);
    }),
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), undefined], "Passwords must match"),
});
interface RegisterProps {
  isLink?: boolean;
  isStaff?: boolean;
}

export default function Register(props: RegisterProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigateSearch();

  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const onSubmit = (value) => {
    var createRequest = value as UserCreateRequest;
    value.username = value.email;
    if (!props.isLink) {
      UsersService.postApiUsersRegister(createRequest)
        .then((result) => {
          if (result.success) {
            MySwal.fire({
              icon: "success",
              title: <p className="title">User created!</p>,
            });
            return;
          }
          MySwal.fire({
            icon: "error",
            title: <p className="title">Error creating user!</p>,
            html: <div className="alert-text">{result.message}</div>,
          });
        })
        .catch(() => {
          MySwal.fire({
            title: <p className="title">Error creating user!</p>,
            html: (
              <div className="alert-text">
                Password must be at least 6 letters long and contain upper case.
              </div>
            ),
          });
        });
    } else {
      if (!token) {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Invalid link!</p>,
        });
        return;
      }
      createRequest.token = token;
      UsersService.postApiUsersRegisterByLink(createRequest)
        .then((result) => {
          if (result.success) {
            MySwal.fire({
              icon: "success",
              title: <p className="title">Success!</p>,
            }).then(() => {
              if (!!result.userEmail) {
                UsersService.postApiUsersLogin({
                  username: result.userEmail,
                  password: createRequest.password,
                })
                  .then((data) => {
                    if (!data.error) {
                      dispatch(setUser(data));
                    }
                  })
                  .finally(() => navigate("/"));
              } else {
                navigate("/login");
              }
            });
            return;
          }
          MySwal.fire({
            icon: "error",
            title: <p className="title">Error registering!</p>,
            html: <div className="alert-text">{result.message}</div>,
          });
        })
        .catch(() => {
          MySwal.fire({
            icon: "error",
            title: <p className="title">Error creating user!</p>,
            html: (
              <div className="alert-text">
                Password must be at least 6 letters long and contain upper case.
              </div>
            ),
          });
        });
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: emptyData,
    validationSchema: props.isStaff ? schema : fullSchema,
    onSubmit,
  });

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        {!props.isLink && (
          <>
            <div className="col-12">
              <FormControl>
                <InputLabel id="type-label">Roles</InputLabel>
                <Select
                  labelId="type-label"
                  name="roles"
                  className="min-width-200"
                  label="Roles"
                  multiple
                  value={values.roles}
                  onChange={handleChange}
                >
                  {Object.keys(ApplicationRole).map((key) => {
                    return (
                      <MenuItem key={key} value={ApplicationRole[key]}>
                        {ApplicationRole[key]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 mt-3">
              <FormControl>
                <InputLabel id="type-label">Practice area</InputLabel>
                <Select
                  labelId="type-label"
                  name="practiceArea"
                  className="min-width-200"
                  label="Practice area"
                  value={values.practiceArea}
                  key={values.practiceArea}
                  onChange={handleChange}
                >
                  {Object.keys(Category).map((key) => {
                    return (
                      <MenuItem key={key} value={Category[key]}>
                        {HelpersService.camelToString(Category[key])}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </>
        )}
        {!props.isStaff && (
          <>
            <div className="col-12 mt-3">
              <TextField
                required
                label="First name"
                variant="outlined"
                name="firstName"
                value={values.firstName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 mt-3">
              <TextField
                required
                label="Last name"
                variant="outlined"
                name="lastName"
                value={values.lastName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 mt-3">
              <MuiTelInput
                required
                label="Phone"
                variant="outlined"
                name="phoneNumber"
                className={
                  !!errors.phoneNumber && !!touched.phoneNumber ? "error" : ""
                }
                onlyCountries={["US"]}
                defaultCountry={"US"}
                disableDropdown
                value={values.phoneNumber ?? undefined}
                onBlur={handleBlur}
                onChange={(newValue) => {
                  setFieldValue("phoneNumber", newValue);
                }}
              />
              {!!errors.phoneNumber && !!touched.phoneNumber && (
                <FormHelperText className="error-message">
                  {errors.phoneNumber}
                </FormHelperText>
              )}
            </div>
          </>
        )}
        <div className="col-12 mt-3">
          <TextField
            required
            label="Password"
            variant="outlined"
            name="password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={values.password}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 mt-3">
          <TextField
            required
            label="Confirm password"
            variant="outlined"
            name="confirmPassword"
            type={showConfirmPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={values.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        className="submit-btn mt-3"
        onClick={() => console.log(errors)}
      >
        Register
      </Button>
      {!!errors.confirmPassword && !!touched.confirmPassword && (
        <FormHelperText className="error-message">
          Password and Confirm password don't match
        </FormHelperText>
      )}
    </form>
  );
}
