import Button from "@mui/material/Button/Button";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Select from "@mui/material/Select/Select";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import {
  ApplicationRole,
  ClientRepresentationRequest,
  CSAType,
  DeclinedAction,
  GetUserListRequest,
  LeadCommentRequest,
  LeadsService,
  LeadViewModel,
  SendLeadRequest,
  UserShortResponse,
  UsersService,
} from "../../../../../api";
import { AdminLeadStatus } from "../../../../../api/models/AdminLeadStatus";
import { HelpersService } from "../../../../../services/Helpers";
import { CSADisplayText, errorInstructions } from "../../shared/Constants";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import useNavigateSearch from "../../../../../services/useNavigateSearch";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import TextField from "@mui/material/TextField/TextField";
import { useDispatch } from "react-redux";
import {
  startSpinner,
  stopSpinner,
} from "../../../../../redux/actions/spinnerActions";

const MySwal = withReactContent(Swal);

interface KeepActionsProps {
  details?: LeadViewModel;
  userRoles?: ApplicationRole[];
}

interface ConfirmCSAFields {
  firstName: string;
  lastName: string;
  company: string;
}

export default function KeepProcessActions(props: KeepActionsProps) {
  const [selectedCSA, setSelectedCSA] = useState<CSAType>();
  const [staffUsers, setStaffUsers] = useState<UserShortResponse[]>([]);
  const [selectedStaff, setSelectedStaff] = useState<number>();
  const [btnPressed, setBtnPressed] = useState<boolean>();
  const [openSendCSADialog, setOpenSendCSADialog] = useState(false);
  const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false);
  const [confirmFields, setConfirmFields] = useState<ConfirmCSAFields>({
    firstName: "",
    lastName: "",
    company: "",
  });
  const [receiverEmail, setReceiverEmail] = useState<string>();
  const [emailMessage, setEmailMessage] = useState<string>();

  const [openDeclinedDialog, setOpenDeclinedlDialog] = useState(false);
  const [selectedDeclineAction, setSelectedDeclineAction] = useState<DeclinedAction>();

  const navigate = useNavigateSearch();
  const dispatch = useDispatch();

  useEffect(() => {
  }, []);

  useEffect(() => {
    setSelectedCSA(
      HelpersService.getEnumKeyByEnumValue(CSAType, props.details?.csaType)
    );

    if (props.userRoles?.includes(ApplicationRole.ADMIN)) {
      UsersService.postApiUsersGetUsersByRole({
        role: ApplicationRole.STAFF
      } as GetUserListRequest
      ).then(
        (users) => {
          setStaffUsers(users);
        }
      );
    }

    if (props.details?.supplementalAssignedTo) {
      setSelectedStaff(props.details?.supplementalAssignedTo);
    }
    setConfirmFields({
      firstName: props.details?.firstName ?? "",
      lastName: props.details?.lastName ?? "",
      company: props.details?.employmentDetails?.company ?? "",
    });
  }, [props.details?.csaType]);

  const handleSendCSAClose = (success) => {
    if (success) {
      dispatch(startSpinner());
      var request = {
        success: true,
        csaType: CSAType[selectedCSA!],
        firstName: confirmFields.firstName,
        lastName: confirmFields.lastName,
        company: confirmFields.company,
      } as ClientRepresentationRequest;
      LeadsService.postApiLeadsCsaAction(props.details!.id!, request)
        .then(() => {
          MySwal.fire({
            icon: "success",
            title: <p className="title">CSA was sent successfully.</p>,
          }).then(() => window.location.reload());
        })
        .catch(() => {
          MySwal.fire({
            icon: "error",
            title: <p className="title">Error updating CSA.</p>,
            html: <div className="alert-text">{errorInstructions}</div>,
          });
        })
        .finally(() => dispatch(stopSpinner()));
    }
    setBtnPressed(false);
    setOpenSendCSADialog(false);
  };

  const handleSendEmailClose = (success) => {
    if (success) {
      handleSendEmail(receiverEmail, emailMessage, false);
      LeadsService.postApiLeadsLeaveComment(props.details?.id!, {
        message: "Lead data was shared",
        sendEmail: false,
        updateStatus: false,
      } as LeadCommentRequest).then(() =>
        MySwal.fire({
          icon: "success",
          title: <p className="title">Email was sent.</p>,
        }).then(() => window.location.reload())
      );
    }
    setOpenSendEmailDialog(false);
  };

  const handleSendCSA = () => {
    setBtnPressed(true);
    if (!selectedCSA) {
      MySwal.fire({
        icon: "error",
        title: <p className="title">Please select CSA type</p>,
      });
      return;
    }
    setOpenSendCSADialog(true);
    setBtnPressed(false);
  };

  const handleClientDenial = () => {
    MySwal.fire({
      title: <strong>Action confirmation</strong>,
      html: <div>Are you sure you want to decline this client?</div>,
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "No, go back",
      confirmButtonText: "Yes, confirm",
      confirmButtonColor: "#ed633a",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed && !!selectedDeclineAction) {
        dispatch(startSpinner());
        var request = {
          success: false,
          declinedAction: DeclinedAction[selectedDeclineAction],
        } as ClientRepresentationRequest;
        LeadsService.postApiLeadsCsaAction(props.details!.id!, request)
          .then(() => {
            MySwal.fire({
              icon: "success",
              title: <p className="title">Client declined successfully.</p>,
            }).then(() => navigate("/active-clients"));
          })
          .catch(() => {
            MySwal.fire({
              icon: "error",
              title: <p className="title">Error declining client.</p>,
              html: <div className="alert-text">{errorInstructions}</div>,
            });
          })
          .finally(() => dispatch(stopSpinner()));
      }
    });
  };

  const handleConfirmSign = () => {
    LeadsService.putApiLeadsConfirmCsaSigned(props.details!.id!).then(() => {
      MySwal.fire({
        icon: "success",
        title: <p className="title">CSA signing confirmed</p>,
      }).then(() => window.location.reload());
    });
  };

  const openSupplementalForm = () => {
    navigate(`/lead/${props.details?.id}/supplemental`);
  };

  const assignSupplementalForm = () => {
    LeadsService.postApiLeadsAssignStaffToSupplemental(
      props.details!.id!,
      selectedStaff
    )
      .then(() => {
        MySwal.fire({
          icon: "success",
          title: (
            <p className="title">
              You've successfully assigned supplemental to a staff.
            </p>
          ),
          html: <div>You will be notified when it is filled.</div>,
        }).then(() => window.location.reload());
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error assigning supplemental.</p>,
          html: <div className="alert-text">{errorInstructions}</div>,
        });
      });
  };

  const handleSendEmail = (
    email: string | undefined,
    emailMessage: string | undefined,
    withSupplemental: boolean
  ) => {
    // TODO: Update when doing PI
    dispatch(startSpinner());
    LeadsService.putApiLeadsSendEmail(props.details!.id!, {
      receiverEmail: email,
      saveAsFile: !email,
      message: emailMessage,
      withSupplemental: withSupplemental,
    } as SendLeadRequest)
      .then(() => {
        MySwal.fire({
          icon: "success",
          title: (
            <p className="title">Email with lead data was sent successfully</p>
          ),
          html: <div>A copy was sent to you.</div>,
        }).then();
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error declining client.</p>,
          html: <div className="alert-text">{errorInstructions}</div>,
        });
      })
      .finally(() => dispatch(stopSpinner()));
  };

  return (
    <>
      {props.userRoles?.includes(ApplicationRole.ADMIN) && (
        <>
          {props.details?.status === AdminLeadStatus.NEW && (
            <>
              <div className="col-12 col-md-2 mt-3 mb-3">
                <FormControl fullWidth>
                  <InputLabel id="selected-label">
                    Select CSA type(initially selected by reviewing attorney)
                  </InputLabel>
                  <Select
                    labelId="selected-label"
                    className="min-width-200"
                    label="Select CSA type(initially selected by reviewing attorney)"
                    value={selectedCSA}
                    key={selectedCSA}
                    onChange={(e) => setSelectedCSA(e.target.value as CSAType)}
                  >
                    {Object.keys(CSAType).map((reason) => {
                      return (
                        <MenuItem key={reason} value={reason}>
                          {CSADisplayText[CSAType[reason]]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-md-2 mt-3 mb-3">
                <Button
                  disabled={btnPressed}
                  variant="contained"
                  color="primary"
                  className="full-btn"
                  onClick={handleSendCSA}
                >
                  Send CSA
                </Button>
              </div>
              <div className="col-12 col-md-2 mt-3 mb-3">
                <Button
                  variant="contained"
                  color="primary"
                  className="full-btn"
                  onClick={() => setOpenDeclinedlDialog(true)}
                >
                  Declined
                </Button>
              </div>
              <div className="col-12 col-md-3 mt-3 mb-3">
                <Button
                  variant="contained"
                  color="primary"
                  className="full-btn"
                  onClick={() => setOpenSendEmailDialog(true)}
                >
                  Send lead data as email
                </Button>
              </div>
              <Dialog
                open={openSendCSADialog}
                onClose={() => handleSendCSAClose(false)}
              >
                <DialogTitle>
                  Please confirm company and lead names.
                </DialogTitle>
                <DialogContent>
                  <div className="row main-holder">
                    <div className="col-12 mt-3">
                      <TextField
                        required
                        label="Company name"
                        variant="outlined"
                        value={confirmFields.company}
                        onChange={(event) =>
                          setConfirmFields({
                            ...confirmFields,
                            company: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <TextField
                        required
                        label="First name"
                        variant="outlined"
                        value={confirmFields.firstName}
                        onChange={(event) =>
                          setConfirmFields({
                            ...confirmFields,
                            firstName: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <TextField
                        required
                        label="Last name"
                        variant="outlined"
                        value={confirmFields.lastName}
                        onChange={(event) =>
                          setConfirmFields({
                            ...confirmFields,
                            lastName: event.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="gray"
                    variant="contained"
                    onClick={() => handleSendCSAClose(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!selectedCSA}
                    color="primary"
                    variant="contained"
                    onClick={() => handleSendCSAClose(true)}
                  >
                    Send CSA
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={openSendEmailDialog}
                onClose={() => handleSendEmailClose(false)}
              >
                <DialogContent>
                  <div className="row main-holder">
                    <div className="col-12 mt-3 mb-3">
                      <TextField
                        required
                        fullWidth
                        name="email"
                        label="Receiver email"
                        variant="outlined"
                        value={receiverEmail}
                        onChange={(e) =>
                          setReceiverEmail(e.target.value.trim())
                        }
                      />
                    </div>
                    <div className="col-12 mt-3">
                      <TextField
                        label="Email message"
                        variant="outlined"
                        multiline
                        className="multiline"
                        value={emailMessage}
                        onChange={(event) =>
                          setEmailMessage(event.target.value)
                        }
                      />
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="gray"
                    variant="contained"
                    onClick={() => handleSendEmailClose(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!receiverEmail}
                    color="primary"
                    variant="contained"
                    onClick={() => handleSendEmailClose(true)}
                  >
                    Send
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={openDeclinedDialog}
                onClose={() => setOpenDeclinedlDialog(false)}
              >
                <DialogTitle>
                  Select reason.
                </DialogTitle>
                <DialogContent>
                  <div className="row main-holder">
                    <FormControl fullWidth>
                      <InputLabel id="selected-label">Select reason*</InputLabel>
                      <Select
                        labelId="selected-label"
                        label="Select reason"
                        value={selectedDeclineAction}
                        key={selectedDeclineAction}
                        onChange={(e) => setSelectedDeclineAction(e.target.value as DeclinedAction)}
                      >
                        {Object.keys(DeclinedAction).map((reason) => {
                          return (
                            <MenuItem key={reason} value={reason}>
                              {HelpersService.camelToString(DeclinedAction[reason])}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="gray"
                    variant="contained"
                    onClick={() => setOpenDeclinedlDialog(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!selectedDeclineAction}
                    color="primary"
                    variant="contained"
                    onClick={() => handleClientDenial()}
                  >
                    Confirm Decline
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
          {props.details?.status === AdminLeadStatus.CONTRACT_SENT && (
            <>
              <div className="col-12 col-md-3 mt-3 mb-3">
                <Button
                  variant="contained"
                  color="primary"
                  className="full-btn"
                  onClick={handleConfirmSign}
                >
                  Confirm contract signed
                </Button>
              </div>
            </>
          )}
          {(props.details?.status === AdminLeadStatus.CONTRACT_SIGNED ||
            props.details?.status ===
            AdminLeadStatus.SUPPLEMENTAL_ASSIGNED) && (
              <>
                <div className="col-12 col-md-3 mt-3 mb-3">
                  <FormControl fullWidth>
                    <InputLabel id="staff-label">
                      Select staff to assign supplemental form
                    </InputLabel>
                    <Select
                      labelId="staff-label"
                      className="min-width-200"
                      label="Select staff to assign supplemental form"
                      value={selectedStaff}
                      key={selectedStaff}
                      onChange={(e) => setSelectedStaff(e.target.value as number)}
                    >
                      {staffUsers.map((staff) => {
                        return (
                          <MenuItem key={staff.id} value={staff.id}>
                            {staff.firstName} {staff.lastName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-12 col-md-3 mt-3 mb-3">
                  <Button
                    disabled={!selectedStaff}
                    variant="contained"
                    color="primary"
                    className="full-btn"
                    onClick={assignSupplementalForm}
                  >
                    {!!props.details?.supplementalAssignedTo
                      ? "Reassign supplemental form"
                      : "Assign supplemental form"}
                  </Button>
                </div>
                <div className="col-12 col-md-3 mt-3 mb-3">
                  <Button
                    variant="contained"
                    color="primary"
                    className="full-btn"
                    onClick={openSupplementalForm}
                  >
                    Fill supplemental form
                  </Button>
                </div>
              </>
            )}
          {props.details?.status === AdminLeadStatus.DONE && (
            <>
              <div className="col-12 col-md-3 mt-3 mb-3">
                <Button
                  disabled={btnPressed}
                  variant="contained"
                  color="primary"
                  className="full-btn"
                  onClick={() => handleSendEmail(undefined, undefined, true)}
                >
                  Send
                </Button>
              </div>
              <div className="col-12 col-md-3 mt-3 mb-3">
                <Button
                  variant="contained"
                  color="primary"
                  className="full-btn"
                  onClick={openSupplementalForm}
                >
                  Open supplemental form
                </Button>
              </div>
            </>
          )}
        </>
      )}
      {props.userRoles?.includes(ApplicationRole.STAFF) && (
        <>
          {props.details?.status === AdminLeadStatus.SUPPLEMENTAL_ASSIGNED && (
            <div className="col-12 col-md-3 mt-3 mb-3">
              <Button
                variant="contained"
                color="primary"
                className="full-btn"
                onClick={openSupplementalForm}
              >
                Fill supplemental form
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
}
