import { useSelector } from "react-redux";
import PacmanLoader from "react-spinners/PacmanLoader";
import "./Spinner.scss";

export default function LoadingSpinner() {
  const spinnerActive = useSelector((state: any) => state.spinner);

  return (
    <>
      {spinnerActive && (
        <div className="loading-overlay">
          <div className="loading-spinner">
            <PacmanLoader color="#df8e57" size="20"/>
          </div>
        </div>
      )}
    </>
  );
}
