import Button from "@mui/material/Button/Button";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import useNavigateSearch from "../../../services/useNavigateSearch";
import "./Stripe.scss";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { PaymentsService } from "../../../api";
import { useState } from "react";

const MySwal = withReactContent(Swal);

const CheckoutForm = () => {
  const [clicked, setClicked] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigateSearch();
  const { id } = useParams();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setClicked(true);
    const result = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: 'https://office.greatlawyers.io/paymentDone?lead_id=${id}',
      },
    });

    if (result.error) {
      MySwal.fire({
        icon: "error",
        title: <p className="title">Error completing payment:</p>,
        html: (
          <div className="alert-text">
            {result.error.message}
          </div>
        ),
      });
      var errorMessage = `Code: ${result.error.code} \r\n DocUrl: ${result.error.doc_url} \r\n Message: ${result.error.message} \r\n PaymentIntentId: ${result.error.payment_intent?.id} \r\n RequestUrl: ${(result.error as any).request_log_url ?? ""} \r\n `;
      await PaymentsService.postApiPaymentsLogError(parseInt(id!), errorMessage);
      setClicked(false);
      return;
    } 
    if(result.paymentIntent.last_payment_error){
      MySwal.fire({
        icon: "error",
        title: <p className="title">Error completing payment:</p>,
        html: (
          <div className="alert-text">
            {result.paymentIntent.last_payment_error.message}
          </div>
        ),
      });
      setClicked(false);
      return;
    } 

    if (result.paymentIntent.status === "succeeded") {
      setClicked(false);
      navigate(`/paymentDone?lead_id=${id}`);
    }
    setClicked(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        className="mt-3"
        variant="contained"
        color="primary"
        disabled={!stripe || clicked}
        type="submit"
      >
        Pay
      </Button>
      <div className="stripe-info-holder">
        <a href="https://stripe.com/"><img src="./PoweredByStripe-blurple.svg"></img></a>
      </div>
    </form>
  );
};

export default CheckoutForm;
