/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TaskExtentionStatus {
    REQUESTED = 'Requested',
    APPROVED = 'Approved',
    REJECTED = 'Rejected',
    UNAUTHORIZED = 'Unauthorized',
    AUTHORIZED = 'Authorized',
    TASK_REMOVED = 'TaskRemoved',
    TASK_COMPLETED = 'TaskCompleted',
}
