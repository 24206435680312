/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArchiveRequest } from '../models/ArchiveRequest';
import type { ClientRepresentationRequest } from '../models/ClientRepresentationRequest';
import type { GetLeadsByIdsRequest } from '../models/GetLeadsByIdsRequest';
import type { LeadAttorneysResponse } from '../models/LeadAttorneysResponse';
import type { LeadCommentRequest } from '../models/LeadCommentRequest';
import type { LeadShareRequest } from '../models/LeadShareRequest';
import type { LeadsReport } from '../models/LeadsReport';
import type { LeadViewModel } from '../models/LeadViewModel';
import type { LeadWinnerRequest } from '../models/LeadWinnerRequest';
import type { ReportRequest } from '../models/ReportRequest';
import type { SendLeadRequest } from '../models/SendLeadRequest';
import type { StatusUpdateRequest } from '../models/StatusUpdateRequest';
import type { TableLeadsRequest } from '../models/TableLeadsRequest';
import type { TableLeadsResponse } from '../models/TableLeadsResponse';
import type { UpdateApprovalStatusRequest } from '../models/UpdateApprovalStatusRequest';
import type { UserShortResponse } from '../models/UserShortResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LeadsService {

    /**
     * @param requestBody 
     * @returns TableLeadsResponse Success
     * @throws ApiError
     */
    public static postApiLeadsLeads(
requestBody?: TableLeadsRequest,
): CancelablePromise<TableLeadsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/leads',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @returns LeadViewModel Success
     * @throws ApiError
     */
    public static getApiLeads(
id: number,
): CancelablePromise<LeadViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Leads/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static putApiLeads(
id: number,
requestBody?: LeadViewModel,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Leads/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiLeads(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Leads/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns LeadViewModel Success
     * @throws ApiError
     */
    public static postApiLeadsGetByIds(
requestBody?: GetLeadsByIdsRequest,
): CancelablePromise<Array<LeadViewModel>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/getByIds',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns number Success
     * @throws ApiError
     */
    public static postApiLeads(
requestBody?: LeadViewModel,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsApprovalChange(
id: number,
requestBody?: UpdateApprovalStatusRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/approvalChange',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsUnassign(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/Unassign',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsAssign(
id: number,
requestBody?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/Assign',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsLeaveComment(
id: number,
requestBody?: LeadCommentRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/leaveComment',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsArchive(
id: number,
requestBody?: ArchiveRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/archive',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsSetAsNa(
requestBody?: ArchiveRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/setAsNA',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsCsaAction(
id: number,
requestBody?: ClientRepresentationRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/CSAAction',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsAssignStaffToSupplemental(
id: number,
requestBody?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/AssignStaffToSupplemental',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @returns LeadViewModel Success
     * @throws ApiError
     */
    public static getApiLeadsSupplementalDetails(
id: number,
): CancelablePromise<LeadViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Leads/{id}/supplementalDetails',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns LeadViewModel Success
     * @throws ApiError
     */
    public static getApiLeadsClassDetails(
id: number,
): CancelablePromise<LeadViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Leads/{id}/classDetails',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static putApiLeadsUpdateSupplemental(
id: number,
requestBody?: LeadViewModel,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Leads/{id}/updateSupplemental',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @returns string Success
     * @throws ApiError
     */
    public static putApiLeadsConvertToClass(
id: number,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Leads/{id}/convertToClass',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static putApiLeadsUpdateClass(
id: number,
requestBody?: LeadViewModel,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Leads/{id}/updateClass',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static putApiLeadsConfirmCsaSigned(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Leads/{id}/confirmCSASigned',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static putApiLeadsSendEmail(
id: number,
requestBody?: SendLeadRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Leads/{id}/sendEmail',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static getApiLeadsPdfIntake(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Leads/{id}/pdfIntake',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static getApiLeadsPdfClass(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Leads/{id}/pdfClass',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static getApiLeadsPdfSupplemental(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Leads/{id}/pdfSupplemental',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsRestore(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/restore',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsShareLeads(
requestBody?: LeadShareRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/shareLeads',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @returns LeadAttorneysResponse Success
     * @throws ApiError
     */
    public static getApiLeadsAttorneys(
id: number,
): CancelablePromise<Array<LeadAttorneysResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Leads/{id}/attorneys',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsWinner(
id: number,
requestBody?: LeadWinnerRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/winner',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @returns UserShortResponse Success
     * @throws ApiError
     */
    public static getApiLeadsWinner(
id: number,
): CancelablePromise<UserShortResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Leads/{id}/winner',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsConfirmIntake(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/confirmIntake',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsRejectIntake(
id: number,
requestBody?: StatusUpdateRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/rejectIntake',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsAgreementSend(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/agreement/send',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiLeadsAgreementSignurl(
id: number,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/agreement/signurl',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsAgreementSign(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/agreement/sign',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsSetOpen(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/setOpen',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiLeadsAcceptLead(
id: number,
requestBody?: StatusUpdateRequest,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/acceptLead',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsRejectLead(
id: number,
requestBody?: StatusUpdateRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/rejectLead',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsSavePayment(
id: number,
requestBody?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/savePayment',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiLeadsStartCase(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/{id}/startCase',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns LeadsReport Success
     * @throws ApiError
     */
    public static postApiLeadsLeadsReport(
requestBody?: ReportRequest,
): CancelablePromise<LeadsReport> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Leads/leadsReport',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

}
