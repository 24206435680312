import Button from "@mui/material/Button/Button";
import Step from "@mui/material/Step/Step";
import StepLabel from "@mui/material/StepLabel/StepLabel";
import Stepper from "@mui/material/Stepper/Stepper";
import { useEffect, useState } from "react";
import {
  AdminLeadStatus,
  AttorneyApprovalStatus,
  Category,
  LeadsService,
  OpenAPI,
} from "../../../../../api";
import { ApplicationRole } from "../../../../../api/models/ApplicationRole";
import { LeadViewModel } from "../../../../../api/models/LeadViewModel";
import { HelpersService } from "../../../../../services/Helpers";
import { LeadType, Milestone, errorInstructions } from "../../shared/Constants";
import KeepProcessActions from "./KeepProcessActions";
import ReferralProcessActions from "./ReferralProcessActions";
import { useDispatch } from "react-redux";
import { startSpinner, stopSpinner } from "../../../../../redux/actions/spinnerActions";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import useNavigateSearch from "../../../../../services/useNavigateSearch";
const MySwal = withReactContent(Swal);

interface AdminActionsProps {
  milestones?: Milestone[];
  leadType: LeadType;
  userRoles?: ApplicationRole[];
  details?: LeadViewModel;
  handleEditClick: Function;
}

export default function AdminActions(props: AdminActionsProps) {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const navigate = useNavigateSearch();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsMobile(HelpersService.checkMobile());
  }, []);

  const editClick = () => {
    setIsEdit(!isEdit);
    props.handleEditClick();
  };

  const downloadClick = () => {
    if (!!props.details?.id) {
      fetch(`${OpenAPI.BASE}/api/Leads/${props.details.id}/pdfIntake`, {
        method: "GET",
        headers: {
          Authorization: (OpenAPI.HEADERS as any)?.Authorization,
        },
      }).then((response: any) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          if (props.details?.attorneyApprovalStatus === AttorneyApprovalStatus.REFER) {
            a.download = `Intake notes.pdf`;
          }
          else {
            a.download = `Intake notes for ${props.details?.firstName} ${props.details?.lastName}.pdf`;
          }
          document.body.appendChild(a);
          a.click();
        });
      });
    }

    if (!!props.details?.id && props.details.status === AdminLeadStatus.DONE) {
      fetch(`${OpenAPI.BASE}/api/Leads/${props.details.id}/pdfSupplemental`, {
        method: "GET",
        headers: {
          Authorization: (OpenAPI.HEADERS as any)?.Authorization,
        },
      }).then((response: any) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `Supplemental notes for ${props.details?.firstName} ${props.details?.lastName}.pdf`;
          document.body.appendChild(a);
          a.click();
        });
      });
    }
  };

  const convertClassForm = () => {
    dispatch(startSpinner());
    LeadsService.putApiLeadsConvertToClass(props.details!.id!).then(url => {
      navigate(`${url}?isEdit=true`);
    }).catch(() => MySwal.fire({
      icon: "error",
      title: <p className="title">Error converting to Class action.</p>,
      html: <div className="alert-text">{errorInstructions}</div>,
    })).finally(() => dispatch(stopSpinner()));
  };

  return (
    <>
      <div
        className={
          isMobile
            ? "row sticky-details-top-menu mobile"
            : "row sticky-details-top-menu"
        }
      >
        {isMobile &&
          ((props.userRoles?.includes(ApplicationRole.ADMIN) &&
            props.details?.status !== AdminLeadStatus.DRAFT) ||
            (!!props.milestones && props.milestones.length > 0)) && (
            <Button
              className="lead-menu-icon"
              onClick={() => setMobileOpen(!mobileOpen)}
            >
              {mobileOpen ? "Close actions" : "Open actions"}
            </Button>
          )}
        {(!isMobile || (isMobile && mobileOpen)) && (
          <div className="row">
            {props.leadType !== LeadType.StaffAttorney &&
              !!props.milestones?.length && (
                <div className="col-md-9 col-4 mt-3 mb-3">
                  <h5 className="">Milestones</h5>
                  <Stepper
                    activeStep={
                      !!props.milestones.find((m) => !!m.done)
                        ? props.milestones.indexOf(
                          props.milestones.find((m) => !!m.done) as Milestone
                        )
                        : 0
                    }
                    orientation={isMobile ? "vertical" : "horizontal"}
                  >
                    {props.milestones.map((m) => (
                      <Step key={m.name} completed={m.done}>
                        <StepLabel color="inherit">{m.name}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              )}
            {props.leadType === LeadType.Admin && (
              <>
                <div className="col-12 col-md-2 mt-3 mb-3">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={downloadClick}
                  >
                    Download Pdf
                  </Button>
                </div>
                {props.details?.status !== AdminLeadStatus.DRAFT && (
                  <>
                    {(props.userRoles?.includes(ApplicationRole.ADMIN) ||
                      props.userRoles?.includes(ApplicationRole.STAFF)) && (
                        <>
                          <div className="col-md-3 col-12 mt-3 mb-3">
                            {!isEdit ? (
                              <Button
                                variant="contained"
                                color="secondary"
                                className="full-btn"
                                onClick={editClick}
                              >
                                Edit lead
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                className="full-btn"
                                onClick={editClick}
                              >
                                Cancel edit
                              </Button>
                            )}
                          </div>
                          {props.details?.category == Category.EMPLOYMENT && 
                          <div className="col-12 col-md-3 mt-3 mb-3">
                            <Button
                              variant="contained"
                              color="primary"
                              className="full-btn"
                              onClick={convertClassForm}
                            >
                              Convert to Class Action
                            </Button>
                          </div>}
                        </>
                      )}
                  </>
                )}
              </>
            )}
            {props.leadType === LeadType.Admin &&
              props.details?.attorneyApprovalStatus ===
              AttorneyApprovalStatus.KEEP && (
                <KeepProcessActions
                  details={props.details}
                  userRoles={props.userRoles}
                ></KeepProcessActions>
              )}
            {props.details?.attorneyApprovalStatus ===
              AttorneyApprovalStatus.REFER && (
                <ReferralProcessActions
                  leadType={props.leadType}
                  userRoles={props.userRoles}
                  details={props.details}
                ></ReferralProcessActions>
              )}
          </div>
        )}
      </div>
    </>
  );
}
