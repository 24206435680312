/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExtensionDetails } from '../models/ExtensionDetails';
import type { ExtensionsTableRequest } from '../models/ExtensionsTableRequest';
import type { TableExtensionsResponse } from '../models/TableExtensionsResponse';
import type { TaskExtensionBulkRequest } from '../models/TaskExtensionBulkRequest';
import type { TaskExtensionViewModel } from '../models/TaskExtensionViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExtensionsService {

    /**
     * @param requestBody 
     * @returns TableExtensionsResponse Success
     * @throws ApiError
     */
    public static postApiExtensionsGetExtensions(
requestBody?: ExtensionsTableRequest,
): CancelablePromise<TableExtensionsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Extensions/getExtensions',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiExtensionsExtensionRequest(
requestBody?: TaskExtensionViewModel,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Extensions/extension/request',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiExtensionsExtensionUpdate(
id: number,
requestBody?: TaskExtensionViewModel,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Extensions/extension/{id}/update',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiExtensionsTaskExtentionApproval(
id: number,
requestBody?: TaskExtensionViewModel,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Extensions/{id}/taskExtentionApproval',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @returns ExtensionDetails Success
     * @throws ApiError
     */
    public static getApiExtensionsDetails(
id: number,
): CancelablePromise<ExtensionDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Extensions/{id}/details',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiExtensionsBulkTaskExtentionApproval(
requestBody?: TaskExtensionBulkRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Extensions/bulkTaskExtentionApproval',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

}
