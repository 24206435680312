/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ReasonForNgcWC {
    MENTAL = 'Mental',
    SOL = 'SOL',
    GLUCK_DECLINED = 'GluckDeclined',
    RATTO_DECLINED = 'RattoDeclined',
    LEAD_DECLINED = 'LeadDeclined',
    OUT_OF_STATE = 'OutOfState',
    NOT_WC = 'NotWC',
    NO_PAYSTUBS = 'NoPaystubs',
    DID_NOT_REPORT = 'DidNotReport',
    ALREADY_HAS_SETTLEMENT = 'AlreadyHasSettlement',
    ALREADY_REPRESENTED = 'AlreadyRepresented',
    NO_WCINSURANCE = 'NoWCInsurance',
    REPRIMANDED = 'Reprimanded',
    CREDIBILITY = 'Credibility',
    NO_INJURIES = 'NoInjuries',
    GOVERNMENT_ENTITY = 'GovernmentEntity',
}
