import { blue } from "@mui/material/colors";
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { OpenAPI } from "./api/core/OpenAPI";

declare module "@mui/material/styles" {
  interface Palette {
    gray: Palette["primary"];
    errorColor: Palette["primary"];
    darkGray: Palette["primary"];
    cyan: Palette["primary"];
    purple: Palette["primary"];
    dirtyYellow: Palette["primary"];
    brown: Palette["primary"];
    dirtyBlue: Palette["primary"];
    dirtyGreen: Palette["primary"];
  }

  interface PaletteOptions {
    gray: PaletteOptions["primary"];
    errorColor: PaletteOptions["primary"];
    darkGray: PaletteOptions["primary"];
    cyan: PaletteOptions["primary"];
    purple: PaletteOptions["primary"];
    dirtyYellow: PaletteOptions["primary"];
    brown: PaletteOptions["primary"];
    dirtyBlue: PaletteOptions["primary"];
    dirtyGreen: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    gray: true;
    errorColor: true;
    darkGray: true;
    cyan: true;
    purple: true;
    dirtyYellow: true;
    brown: true;
    dirtyBlue: true;
    dirtyGreen: true;
  }
}

let theme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "20px",
          height: "50px",
          minWidth: "120px",
          borderRadius: "10px",
          whiteSpace: "nowrap",
          fontSize: "1rem",
          fontWeight: "600",
        },
        sizeSmall: {
          height: "35px",
          padding: "15px",
        },
      },
    },
    // navigation
    MuiAppBar: {
      styleOverrides: {
        root: { backgroundColor: "#FFFFFF", color: "#333355" },
      },
    },
    // attorneys card
    MuiCard: {
      styleOverrides: {
        root: {
          marginTop: "30px",
          borderRadius: "20px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: "center",
          backgroundColor: "#F9F9F9",
        },
      },
    },
    // popup dialog
    MuiDialog: {
      styleOverrides: {
        root: {
          textAlign: "center",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: "center",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#ED633A",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FBEADE",
      light: "#FDC293",
      dark: "#FCDEC5",
      contrastText: "#DF8E57",
    },
    gray: {
      main: "#E4E4E4",
      light: "#F0F0F0",
      dark: "#C9C9C9",
      contrastText: "#717171",
    },
    errorColor: {
      main: "#D32F2F",
      light: "#E03131",
      dark: "#BA2929",
      contrastText: "#FFFFFF",
    },
    darkGray: {
      main: "#878787",
      light: "#949494",
      dark: "#6E6E6E",
      contrastText: "#FFFFFF",
    },
    cyan: {
      main: "#A0CFBF",
      light: "#A9DBCA",
      dark: "#8BB5A7",
      contrastText: "#FFFFFF",
    },
    purple: {
      main: "#A294CE",
      light: "#AC9EDB",
      dark: "#70678F",
      contrastText: "#FFFFFF",
    },
    dirtyYellow: {
      main: "#B6B020",
      light: "#C1BE5D",
      dark: "#9C971C",
      contrastText: "#FFFFFF",
    },
    brown: {
      main: "#AE9685",
      light: "#BAA08D",
      dark: "#947F70",
      contrastText: "#FFFFFF",
    },
    dirtyBlue: {
      main: "#7488A0",
      light: "#7D93AD",
      dark: "#617287",
      contrastText: "#FFFFFF",
    },
    dirtyGreen: {
      main: "#6CB245",
      light: "#73BF4B",
      dark: "#5C993C",
      contrastText: "#FFFFFF",
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.gray.main,
        },
      },
    },
  },
});

export default function ThemeManager(props) {
  const { children } = props;
  
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
