import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScheduleSelector from "react-schedule-selector";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  AdminLeadStatus,
  ApplicationRole,
  AttorneyApprovalStatus,
  AttorneyLeadStatus,
  Category,
  IntentResponse,
  LeadsService,
  LeadViewModel,
  OpenAPI,
  PaymentsService,
  UserShortResponse,
} from "../../../../../api";
import {
  setPaymentAmount,
  setPaymentIntent,
  setPaymentSecret,
} from "../../../../../redux/actions/paymentActions";
import {
  startSpinner,
  stopSpinner,
} from "../../../../../redux/actions/spinnerActions";
import { HelpersService } from "../../../../../services/Helpers";
import useNavigateSearch from "../../../../../services/useNavigateSearch";
import StripeCheckout from "../../../../Integrations/Stripe/StripeCheckout";
import {
  errorInstructions,
  IntakeStatus,
  LeadType,
} from "../../shared/Constants";
import AttorneysRow from "../AttorneysTableBlock";

const MySwal = withReactContent(Swal);

interface ReferralProcessActionsProps {
  leadType: LeadType;
  userRoles?: ApplicationRole[];
  details?: LeadViewModel;
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ReferralProcessActions(
  props: ReferralProcessActionsProps
) {
  const [isSendAgreement, setIsSendAgreement] = useState<boolean>(false);
  const [selectWinner, setSelectWinner] = useState<boolean>(false);
  const [initiateIntake, setInitiateIntake] = useState<boolean>(false);

  const [schedule, setSchedule] = useState<Date[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [intakeStatus, setIntakeStatus] = useState<number>(0);

  const [winningAttorney, setWinningAttorney] = useState<UserShortResponse>();
  const [initiateStripe, setInitiateStripe] = useState<boolean>(false);
  const [signUrl, setSignUrl] = useState<string | null>(null);

  const navigate = useNavigateSearch();
  const dispatch = useDispatch();
  useEffect(() => {
    window.addEventListener("message", handleDocumentEvent);
    return function cleanup() {
      window.removeEventListener("message", handleDocumentEvent);
    };
  }, []);

  const handleDocumentEvent = (e) => {
    const type = e.data && e.data.type;
    const payload = e.data && e.data.payload;

    switch (type) {
      case "session_view.document.loaded":
        console.log("Session view is loaded");
        break;
      case "session_view.document.completed":
        LeadsService.postApiLeadsAgreementSign(props.details!.id!)
          .then(() => {
            MySwal.fire({
              icon: "success",
              title: <p className="title">Agreement successfully signed.</p>,
            });
            window.location.reload();
          })
          .catch(() =>
            MySwal.fire({
              icon: "error",
              title: <p className="title">Error signing agreement.</p>,
              html: <div className="alert-text">{errorInstructions}</div>,
            })
          );

        setSignUrl(null);
        console.log("Document is completed");
        console.log(payload);
        break;
      case "session_view.document.exception":
        console.log("Exception during document finalization");
        console.log(payload);
    }
  };

  useEffect(() => {
    if (
      props.details?.status === AdminLeadStatus.WINNER_SELECTED &&
      (props.userRoles?.includes(ApplicationRole.ADMIN) || props.userRoles?.includes(ApplicationRole.REFFERAL_STAFF))
    ) {
      LeadsService.getApiLeadsWinner(props.details?.id!).then((winner) =>
        setWinningAttorney(winner)
      );
    }
  }, [props.details]);

  const handleShareClick = () => {
    navigate(`/lead/send/${props.details?.id}`);
  };

  const handleIntakeReject = () => {
    MySwal.fire({
      title: <strong>Please provide a reason for failure</strong>,
      input: "text",
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "Back",
      confirmButtonText: "Reject intake",
      confirmButtonColor: "#ed633a",
      reverseButtons: true,
    })
      .then((result) => {
        // TODO: sent on success
        if (result.isConfirmed)
          MySwal.fire({
            icon: "success",
            title: <p className="title">Intake rejected!</p>,
            html: (
              <div className="alert-text">
                You can select a new winner for this lead now.
              </div>
            ),
          }).then(() => {
            window.location.reload();
          });
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error rejecting lead.</p>,
          html: <div className="alert-text">{errorInstructions}</div>,
        });
      });
  };
  const handleConfirmIntake = () => {
    MySwal.fire({
      title: <strong>Confirm intake</strong>,
      html: (
        <div>
          You are about to confirm {winningAttorney?.firstName}{" "}
          {winningAttorney?.lastName} intake of this lead. Are you sure you want
          to continue?
        </div>
      ),
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "No, go back",
      confirmButtonText: "Yes, send now",
      confirmButtonColor: "#ed633a",
      reverseButtons: true,
    })
      .then((result) => {
        // TODO: sent on success
        if (result.isConfirmed) {
          MySwal.fire({
            icon: "success",
            title: <p className="title">Intake confirmed</p>,
            html: (
              <div className="alert-text">
                You can now send agreement to {winningAttorney?.firstName}{" "}
                {winningAttorney?.lastName}.
              </div>
            ),
          }).then(() => {
            window.location.reload();
          });
          handleIntakeStatusChange(true);
        }
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error confirming intake.</p>,
          html: <div className="alert-text">{errorInstructions}</div>,
        });
      });
  };
  const handleIntakeStatusChange = (value) => {
    //TODO: on reject send lead back to shared status, on approve - allow agreement send
    setIsSendAgreement(value);
  };

  const handleLeadAccept = () => {
    if (!!initiateIntake || intakeStatus === 1) {
      setOpenModal(true);
      return;
    }

    MySwal.fire({
      title: <strong>Confirm accept</strong>,
      html: (
        <>
          <div>
            You are about to accept this lead. Please confirm your action.
          </div>
        </>
      ),
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "No, go back",
      confirmButtonText: "Yes, accept",
      confirmButtonColor: "#ed633a",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startSpinner());
        LeadsService.postApiLeadsAcceptLead(props.details?.id!, {})/* {single ? "Lead Accepted. Please proceed with next steps." : "Please wait for GLio confirmation. You will be notified of the final decision."} */
          .then((single) => {
            MySwal.fire({
              icon: "info",
              title: (
                <p className="title">
                  Please wait for GLio confirmation. You will be notified of the final decision.
                </p>
              ),
            }).then(() => {
              // if (single) {
              //   window.location.reload();
              // }
              // else {
              navigate(`/attorney-leads`);
              //}
            });
          })
          .finally(() => dispatch(stopSpinner()));
      }
    }).catch(() => {
      MySwal.fire({
        icon: "error",
        title: <p className="title">Error accepting lead</p>,
        html: (
          <div className="alert-text">
            {errorInstructions}
          </div>
        ),
      });
    });
  };

  const handleAcceptConfirm = () => {
    MySwal.fire({
      icon: "success",
      title: <p className="title">Lead accepted</p>,
    }).then(() => {
      setOpenModal(false);
      schedule.forEach((sch) => {
        var dat = new Date(sch);
        console.log(dat.toLocaleString());
      });
      //TODO: redirect to table
    });
  };

  const handleLeadReject = () => {
    MySwal.fire({
      title: <strong>Confirm rejection</strong>,
      html: (
        <div>
          Please provide a reason for rejection. You cannot undo this action.
          Are you sure you want to continue?
        </div>
      ),
      input: "text",
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "No, go back",
      confirmButtonText: "Yes, reject",
      confirmButtonColor: "#ed633a",
      reverseButtons: true,
    })
      .then((result) => {
        LeadsService.postApiLeadsRejectLead(props.details?.id!, {
          reason: result.value,
        }).then(() => {
          MySwal.fire({
            icon: "success",
            title: <p className="title">Lead rejected</p>,
          }).then(() => {
            navigate(`/attorney-leads`);
          });
        });
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error rejecting lead.</p>,
          html: <div className="alert-text">{errorInstructions}</div>,
        });
      });
  };

  const handlePay = () => {
    if (!props.details?.totalFee) {
      MySwal.fire({
        icon: "error",
        title: <p className="title">Error inititating payment.</p>,
        html: <div className="alert-text">{errorInstructions}</div>,
      });
      return;
    }
    PaymentsService.postApiPaymentsSecret(
      props.details!.id!
    ).then((response: IntentResponse) => {
      dispatch(setPaymentAmount({ adminFee: props.details?.adminFee, totalFee: props.details?.totalFee, stripeFee: props.details?.stripeFee }));
      dispatch(setPaymentSecret(response.secret));
      dispatch(setPaymentIntent(response.intent));
      navigate(`/checkout/${props.details?.id}`);
    });
  };

  const handleSign = () => {
    LeadsService.postApiLeadsAgreementSignurl(props.details!.id!).then(
      (url) => {
        setSignUrl(url);
      }
    );
  };

  const triggerSelectWinner = () => {
    setSelectWinner(!selectWinner);
  };

  const handleSendAgreement = () => {
    MySwal.fire({
      title: <strong>Confirm approval</strong>,
      html: (
        <div>
          You are about to send agreement to {winningAttorney?.firstName}{" "}
          {winningAttorney?.lastName}. Are you sure you want to continue?
        </div>
      ),
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "No, go back",
      confirmButtonText: "Yes, send now",
      confirmButtonColor: "#ed633a",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        LeadsService.postApiLeadsAgreementSend(props.details!.id!)
          .then((res) => {
            console.log(res);
            MySwal.fire({
              icon: "success",
              title: <p className="title">Success!</p>,
              html: (
                <div className="alert-text">
                  Agreement was sent, you will be notified when it is signed.
                </div>
              ),
            }).then(() => {
              navigate("/referral-leads");
            });
          })

          .catch(() => {
            MySwal.fire({
              icon: "error",
              title: <p className="title">Error sending agreement.</p>,
              html: <div className="alert-text">{errorInstructions}</div>,
            });
          });
      }
    });
  };

  const downloadClick = () => {
    if (!!props.details?.id) {
      fetch(`${OpenAPI.BASE}/api/Leads/${props.details.id}/pdfIntake`, {
        method: "GET",
        headers: {
          Authorization: (OpenAPI.HEADERS as any)?.Authorization,
        },
      }).then((response: any) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          if (props.details?.status != AdminLeadStatus.PAID) {
            a.download = `Intake notes.pdf`;
          }
          else {
            a.download = `Intake notes for ${props.details?.firstName} ${props.details?.lastName}.pdf`;
          }
          document.body.appendChild(a);
          a.click();
        });
      });
    }
  };

  return (
    <>
      {props.leadType === LeadType.Admin && (
        <>
          {props.details?.status === AdminLeadStatus.NEW &&
            props.details.attorneyApprovalStatus ===
            AttorneyApprovalStatus.REFER && (
              <div className="col-12 col-md-3 mt-3 mb-3">
                <Button
                  variant="contained"
                  color="primary"
                  className="full-btn"
                  onClick={handleShareClick}
                >
                  Share
                </Button>
              </div>
            )}
          {props.details?.status === AdminLeadStatus.INTRODUCTION &&
            !isSendAgreement && (
              <div className="col-12 col-md-3 mt-3 mb-3">
                <Button
                  variant="contained"
                  color="primary"
                  className="full-btn"
                  onClick={handleConfirmIntake}
                >
                  Intake successful
                </Button>
                <Button
                  variant="contained"
                  color="gray"
                  className="full-btn"
                  onClick={() => handleIntakeReject()}
                >
                  Intake failed
                </Button>
              </div>
            )}
        </>
      )}
      {props.details?.status !== AdminLeadStatus.DRAFT &&
        !props.details?.isArchived && (
          <>
            {props.leadType === LeadType.Attorney && (
              <>
                {props.details?.attorneyLeadStatus ===
                  AttorneyLeadStatus.NEW && (
                    <>
                      <div className="flex-break"></div>
                      <div className="col-12 col-md-3 text-right">
                        <h5 className="mt-2">
                          Admin fee:
                          {props.details.adminFee
                            ? " $" +
                            props.details.adminFee.toLocaleString("en-US")
                            : ""}
                        </h5>
                      </div>
                      <div className="col-12 col-md-3">
                        <Button
                          variant="contained"
                          color="primary"
                          className="full-btn"
                          onClick={handleLeadAccept}
                        >
                          Accept
                        </Button>
                        {/* {intakeStatus === 0 && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value={initiateIntake}
                              onChange={(e) =>
                                setInitiateIntake(e.target.checked)
                              }
                            />
                          }
                          label="Request intake before taking on the lead?"
                        />
                      )}
                      {intakeStatus === 1 && (
                        <div>*Intake is required for this case</div>
                      )}
                      {intakeStatus === 2 && (
                        <div>*Intake is disabled for this case</div>
                      )} */}
                      </div>
                      <div className="col-12 col-md-3 mb-4">
                        <Button
                          variant="contained"
                          color="secondary"
                          className="full-btn"
                          onClick={handleLeadReject}
                        >
                          Reject
                        </Button>
                      </div>
                    </>
                  )}
                {props.details?.attorneyLeadStatus ===
                  AttorneyLeadStatus.ACCEPTED && (
                    <div className="col-12 col-md-3 mt-3 mb-3">
                      <h4 className="title mt-4 mb-3">
                        Lead progress:{" "}
                        <span className="text-main">
                          Awaiting GL.io confirmation
                        </span>
                      </h4>
                    </div>
                  )}
                {props.details?.attorneyLeadStatus ===
                  AttorneyLeadStatus.WON && (
                    <>
                      {props.details.status ===
                        AdminLeadStatus.SENT_FOR_SIGNING &&
                        !signUrl && (
                          <>
                            <div className="col-12 col-md-3 mt-3 mb-3">
                              <Button
                                variant="contained"
                                color="primary"
                                className="full-btn"
                                onClick={handleSign}
                              >
                                Sign
                              </Button>
                            </div>
                          </>
                        )}
                    </>
                  )}
                {(props.details?.attorneyLeadStatus === AttorneyLeadStatus.SIGNED) && (
                  <>
                    <div className="flex-break"></div>
                    <div className="col-12 col-md-3 text-right">
                      <h5 className="mt-2">
                        Admin fee:
                        {props.details.adminFee
                          ? " $" +
                          props.details.adminFee.toLocaleString("en-US")
                          : ""}
                      </h5>
                    </div>
                    <div className="col-12 col-md-3 mt-3 mb-3">
                      <Button
                        variant="contained"
                        color="primary"
                        className="full-btn"
                        onClick={handlePay}
                      >
                        Pay
                      </Button>
                    </div>
                  </>
                )}

                {(props.details?.attorneyLeadStatus === AttorneyLeadStatus.PAID) && (
                  <div className="col-12 col-md-3 mt-3 mb-3"><Button
                    variant="contained"
                    color="primary"
                    onClick={downloadClick}
                  >
                    Download Pdf
                  </Button></div>
                )}
              </>
            )}
          </>
        )}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <h5 id="modal-title" className="title text-center">
            {
              // TODO: update text when required by admin
            }
            You requested intake for this lead, please select at least 3 times
            that you are available and we will notify you once intake is
            confirmed.
          </h5>
          <div id="modal-description">
            <ScheduleSelector
              selection={schedule}
              numDays={5}
              minTime={8}
              maxTime={19}
              hourlyChunks={1}
              onChange={setSchedule}
            ></ScheduleSelector>
          </div>
          <div className="row mt-5 justify-content-between">
            <Button
              variant="contained"
              color="secondary"
              className="col-6 col-md-2"
              onClick={() => setOpenModal(false)}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="col-6 col-md-2"
              onClick={handleAcceptConfirm}
            >
              Confirm
            </Button>
          </div>
        </Box>
      </Modal>

      <div className="row col-3 sticky-details-side-menu p-0">
        {props.details?.status === AdminLeadStatus.WINNER_SELECTED &&
          (props.userRoles?.includes(ApplicationRole.ADMIN) || props.userRoles?.includes(ApplicationRole.REFFERAL_STAFF)) && (
            <div className="col-12 lead-additional-info">
              <h5 className="title">
                Winning Attorney: {winningAttorney?.firstName}{" "}
                {winningAttorney?.lastName}
              </h5>
              <Button
                variant="contained"
                color="primary"
                className="ms-4 mb-4"
                onClick={handleSendAgreement}
              >
                Send agreement
              </Button>
            </div>
          )}
        {props.leadType === LeadType.Admin && (
          <>
            {props.details?.status === AdminLeadStatus.INTRODUCTION && (
              <div className="col-4 lead-additional-info">
                <h5 className="title">
                  Intake Attorney: {winningAttorney?.firstName}{" "}
                  {winningAttorney?.lastName}
                </h5>
                <div>Phone: 12345 Email: brendan@gmail.com</div>
                <div>Available times:</div>
                <div>4/28 12PM</div>
                <div>4/28 2PM</div>
                <div>4/28 3PM</div>
                <div>4/29 11AM</div>
                <div>4/29 12PM</div>
                <div>4/30 12PM</div>
              </div>
            )}

            {props.details?.status === AdminLeadStatus.SENT_FOR_SIGNING && (
              <div className="col-4 lead-additional-info">
                <h5 className="title">
                  Winning Attorney: {winningAttorney?.firstName}{" "}
                  {winningAttorney?.lastName}
                </h5>
                <div>Agreement status: Waiting for signature</div>
              </div>
            )}

            {props.details?.status === AdminLeadStatus.SIGNED && (
              <div className="col-4 lead-additional-info">
                <h5 className="title">
                  Winning Attorney: {winningAttorney?.firstName}{" "}
                  {winningAttorney?.lastName}
                </h5>
                <div>Agreement status: Signed, awaiting payment </div>
              </div>
            )}
          </>
        )}
      </div>
      {!!signUrl && (
        <div className="row">
          <iframe
            title="signing"
            src={`https://app.pandadoc.com/s/${signUrl}`}
            width="800"
            height="800"
          ></iframe>
        </div>
      )}
    </>
  );
}
