/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum LeadTableType {
    INTAKE = 'Intake',
    ACTIVE_CLIENT = 'ActiveClient',
    REVIEW = 'Review',
    ARCHIVE = 'Archive',
    REFERRAL = 'Referral',
    SHARED_ATTORNEY = 'SharedAttorney',
}
