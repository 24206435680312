export const SET_PAYMENT_AMOUNT = "SET_PAYMENT_AMOUNT";
export const SET_PAYMENT_SECRET = "SET_PAYMENT_SECRET";
export const SET_PAYMENT_INTENT = "SET_PAYMENT_INTENT";

export const setPaymentAmount = (amount) => {
  return {
    type: SET_PAYMENT_AMOUNT,
    payload: amount,
  };
}

  export const setPaymentSecret = (secret) => {
    return {
      type: SET_PAYMENT_SECRET,
      payload: secret,
    };
  }

  export const setPaymentIntent = (intent) => {
    return {
      type: SET_PAYMENT_INTENT,
      payload: intent,
    };
  }
  
  
