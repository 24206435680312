import { ApplicationRole } from "../../../../../api/models/ApplicationRole";
import { LeadType, Milestone } from "../../shared/Constants";

import AttorneyApprovalActions from "./AttorneyApprovalActions";
import { LeadViewModel } from "../../../../../api";
import AdminActions from "./AdminActions";

interface LeadMenuProps {
  milestones?: Milestone[];
  leadType: LeadType;
  userRoles?: ApplicationRole[];
  handleEditClick: Function;
  details?: LeadViewModel;
}

export default function LeadMenu(props: LeadMenuProps) {
  return (
    <>
      <AdminActions
        leadType={props.leadType}
        userRoles={props.userRoles}
        details={props.details}
        milestones={props.milestones}
        handleEditClick={props.handleEditClick}
      ></AdminActions>
      <AttorneyApprovalActions
        leadType={props.leadType}
        userRoles={props.userRoles}
        details={props.details}
      ></AttorneyApprovalActions>
    </>
  );
}
