import { TextField } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";

interface TextControlProps {
  required?: boolean;
  multiline?: boolean;
  placeholder?: string;
  error?: boolean;
  name: string;
  label: string;
  class?: string;
  helperText?: string;
  value: any;
  onBlur: Function;
  onChange: Function;
  hideEmpty?: boolean;
}

export default function TextControl(props: TextControlProps) {
  return (
    <>
      {props.hideEmpty && !props.value ? null : (
        <div className={!!props.class ? props.class : "col-12 col-md-6 mt-3"}>
          <TextField
            multiline={props.multiline}
            required={props.required}
            label={props.label}
            variant="outlined"
            placeholder={props.placeholder}
            name={props.name}
            className={!!props.error ? "error" : ""}
            value={props.value}
            onBlur={(e) => props.onBlur(e)}
            onChange={(e) => props.onChange(e)}
          />
          {!!props.error && (
            <FormHelperText className="error-message">
              Required
            </FormHelperText>
          )}
        </div>
      )}
    </>
  );
}
