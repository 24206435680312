import dayjs, { Dayjs } from "dayjs";
import {
  Category,
  EmployedTimeType,
  HurtType,
  UsersService,
  VehicleType,
  AdminLeadStatus,
  ApplicationRole,
  ArchiveRequest,
  AttorneyApprovalStatus,
  AttorneyLeadStatus,
  LeadShortResponse,
  LeadTableType,
  ReasonForNAEmp,
  ReasonForNAPI,
  StatusUpdateRequest,
} from "../api";
import { InjuryType } from "../api/models/InjuryType";
import { LeadViewModel } from "../api/models/LeadViewModel";
import { PaidType } from "../api/models/PaidType";
import { RadioOptions } from "../api/models/RadioOptions";
import { TerminationType } from "../api/models/TerminationType";

export class HelpersService {
  public static getEnumKeyByEnumValue(myEnum, enumValue) {
    if (Object.keys(myEnum).includes(enumValue)) {
      return enumValue;
    }
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : null;
  }

  public static getEnumPosition<T>(enumType: T, enumValue: T[keyof T]): number | undefined {
    if (enumType) {
      const enumValues = Object.values(enumType);
      const valueIndex = enumValues.indexOf(enumValue);
      if (valueIndex !== -1) {
        return valueIndex;
      }
    }

    return undefined;
  }

  public static parseLeadClassResponse(lead: LeadViewModel) {
    Object.entries(lead.classDetails ?? {}).forEach(([key, value]) => {
      if (typeof value === "string" && lead.classDetails) {
        if (Object.values(RadioOptions).includes(value as RadioOptions)) {
          lead.classDetails[key] = HelpersService.getEnumKeyByEnumValue(
            RadioOptions,
            value
          );
        }
      }
    });
    return lead;
  }

  public static parseLeadSupplementalResponse(lead: LeadViewModel) {
    Object.entries(lead.supplementalDetails ?? {}).forEach(([key, value]) => {
      if (typeof value === "string" && lead.supplementalDetails) {
        if (Object.values(RadioOptions).includes(value as RadioOptions)) {
          lead.supplementalDetails[key] = HelpersService.getEnumKeyByEnumValue(
            RadioOptions,
            value
          );
        }
        if (
          Object.values(EmployedTimeType).includes(value as EmployedTimeType)
        ) {
          lead.supplementalDetails[key] = HelpersService.getEnumKeyByEnumValue(
            EmployedTimeType,
            value
          );
        }
      }
    });
    return lead;
  }

  public static parseLeadResponse(lead: LeadViewModel) {
    switch (lead.category) {
      case Category.EMPLOYMENT:
      case Category.CLASS_ACTION: {
        Object.entries(lead.employmentDetails ?? {}).forEach(([key, value]) => {
          if (typeof value === "string" && lead.employmentDetails) {
            if (Object.values(RadioOptions).includes(value as RadioOptions)) {
              lead.employmentDetails[key] =
                HelpersService.getEnumKeyByEnumValue(RadioOptions, value);
            }
            if (Object.values(InjuryType).includes(value as InjuryType)) {
              lead.employmentDetails[key] =
                HelpersService.getEnumKeyByEnumValue(InjuryType, value);
            }
            if (Object.values(PaidType).includes(value as PaidType)) {
              lead.employmentDetails[key] =
                HelpersService.getEnumKeyByEnumValue(PaidType, value);
            }
            if (
              Object.values(TerminationType).includes(value as TerminationType)
            ) {
              lead.employmentDetails[key] =
                HelpersService.getEnumKeyByEnumValue(TerminationType, value);
            }
          }
        });
        break;
      }
      case Category.PERSONAL_INJURY: {
        Object.entries(lead.personalInjuryDetails ?? {}).forEach(
          ([key, value]) => {
            if (typeof value === "string" && lead.personalInjuryDetails) {
              if (Object.values(RadioOptions).includes(value as RadioOptions)) {
                lead.personalInjuryDetails[key] =
                  HelpersService.getEnumKeyByEnumValue(RadioOptions, value);
              }
              if (Object.values(HurtType).includes(value as HurtType)) {
                lead.personalInjuryDetails[key] =
                  HelpersService.getEnumKeyByEnumValue(HurtType, value);
              }
              if (Object.values(VehicleType).includes(value as VehicleType)) {
                lead.personalInjuryDetails[key] =
                  HelpersService.getEnumKeyByEnumValue(VehicleType, value);
              }
            }
          }
        );
        break;
      }
      case Category.WORKERS_COMPENSATION: {
        Object.entries(lead.workersCompDetails ?? {}).forEach(([key, value]) => {
          if (typeof value === "string" && lead.workersCompDetails) {
            if (Object.values(RadioOptions).includes(value as RadioOptions)) {
              lead.workersCompDetails[key] =
                HelpersService.getEnumKeyByEnumValue(RadioOptions, value);
            }
            if (Object.values(InjuryType).includes(value as InjuryType)) {
              lead.workersCompDetails[key] =
                HelpersService.getEnumKeyByEnumValue(InjuryType, value);
            }
            if (Object.values(PaidType).includes(value as PaidType)) {
              lead.workersCompDetails[key] =
                HelpersService.getEnumKeyByEnumValue(PaidType, value);
            }
            if (
              Object.values(TerminationType).includes(value as TerminationType)
            ) {
              lead.workersCompDetails[key] =
                HelpersService.getEnumKeyByEnumValue(TerminationType, value);
            }
          }
        });
        break;
      }
    }
    return lead;
  }

  public static camelToString(
    camelCase?: string | null,
    toLowerCase: boolean = true,
    addSlash = false
  ) {
    if (!camelCase) {
      return "";
    }
    var sentence = camelCase.replace(/([A-Z])/g, " $1");
    sentence = toLowerCase
      ? sentence
        .trim()
        .split(" ")
        .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
        .join(" ")
      : sentence.trim();

    sentence = sentence.replace(/_ /g, addSlash ? "/" : " ");

    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  }

  public static authHeader(
    token: string | null = null
  ): Record<string, string> {
    if (!!token) {
      return {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "*/*",
      };
    } else {
      return { "Content-Type": "application/json", Accept: "*/*" };
    }
  }

  public static UTCToDate(stringDate: string) {
    const year = parseInt(stringDate.substring(0, 4));
    const month = parseInt(stringDate.substring(5, 7)) - 1;
    const day = parseInt(stringDate.substring(8, 10));
    const hour = parseInt(stringDate.substring(11, 13));
    const minute = parseInt(stringDate.substring(14, 16));
    const second = parseInt(stringDate.substring(17, 19));
    const millisecond = parseInt(stringDate.substring(20, 23));

    return new Date(Date.UTC(year, month, day, hour, minute, second, millisecond));
  }

  public static calcDatesDifference(start: Dayjs, end: Dayjs = dayjs()) {
    let result = "";

    var Difference_In_Time = end.diff(start);
    if (Difference_In_Time < 1000 * 60) {
      let amount = Math.ceil(Difference_In_Time / 1000);
      result = `${amount} ${amount === 1 ? "second" : "seconds"}`;
    } else if (Difference_In_Time < 1000 * 3600) {
      let amount = Math.ceil(Difference_In_Time / (1000 * 60));
      result = `${amount} ${amount === 1 ? "minute" : "minutes"}`;
    } else if (Difference_In_Time < 1000 * 3600 * 24) {
      let amount = Math.ceil(Difference_In_Time / (1000 * 3600));
      result = `${amount} ${amount === 1 ? "hour" : "hours"}`;
    } else {
      let amount = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));
      result = `${amount} ${amount === 1 ? "day" : "days"}`;
    }
    return `${result} ago`;
  }

  public static checkMobile() {
    return window.innerWidth <= 767;
  }

  public static createInitials(value: string): string {
    const words = value.split(" ");
    const initials = words.map((word) => word[0].toUpperCase());
    return initials.join(" ");
  }
}

export function isEmpty(obj: Record<string, any>): boolean {
  return !obj || Object.keys(obj).length === 0;
}

export async function openPushPrompt() {
  try {
    const result = await window.Notification.requestPermission();
    if (result === "granted") {
      await navigator.serviceWorker.register("serviceworker.js", {
        scope: "./",
      });

      navigator.serviceWorker.ready.then(function (registration) {
        registration.pushManager
          .subscribe({
            applicationServerKey: urlBase64ToUint8Array(
              "BBQe61vY32FLD_aKO3WAQ_flk5idCIwinxFRvrVddQdxWHHDWyeXNJa2dwpaPgJiBFo6Um2GT2J-gFIiGI7ZlRE"
            ),
            userVisibleOnly: true,
          })
          .then((subscription) => {
            const endpoint = subscription.endpoint;
            const p256dh = arrayBufferToBase64(subscription.getKey("p256dh"));
            const auth = arrayBufferToBase64(subscription.getKey("auth"));
            UsersService.postApiUsersNotificationsPush({
              endpoint,
              p256dh,
              auth,
            }).then((response) => {
              localStorage.setItem("userSeenPushNotification", "true");
            });
          })
          .catch((ex) => {
            window.alert(ex);
          });
      });
    }
  } catch (ex) {
    window.alert(ex);
  }
}
function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!document.querySelector('#google-maps')) {
    if (!position) {
      return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
  }
}
