import React from "react";

import Box from "@mui/material/Box/Box";
import IconButton from "@mui/material/IconButton/IconButton";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu/Menu";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import SendLeads from "../SendLeads/SendLeads";
import { Action } from "./LeadsTableConstants";

interface TableActionMenuProps {
  row: any;
  actions: Action[];
}

export default function TableActionMenu(props: TableActionMenuProps) {
  const rowId = props.row;
  const [actionsAnchor, setActionsAnchor] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenActionsMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setActionsAnchor(e.currentTarget);
  };

  const handleCloseActionsMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActionsAnchor(null);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <IconButton onClick={handleOpenActionsMenu} sx={{ padding: 0 }}>
        <MoreVertIcon color="primary" sx={{ fontSize: "35px" }}></MoreVertIcon>
      </IconButton>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={actionsAnchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(actionsAnchor)}
        onClose={handleCloseActionsMenu}
      >
        {props.actions.map((action) => (
          <MenuItem
            key={action.name}
            onClick={(e) => {
              action.function(props.row);
              handleCloseActionsMenu(e);
            }}
          >
            {action.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
