/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EmployeePaidType {
    MULTIPLE = 'Multiple',
    SALARY = 'Salary',
    COMMISSION = 'Commission',
    PIECE_RATE = 'PieceRate',
    BONUSES = 'Bonuses',
    TIPS = 'Tips',
    HOURLY = 'Hourly',
    OTHER = 'Other',
}
