import { ColumnDefinition, ReactTabulatorOptions } from "react-tabulator";
import { ReportTypes } from "../../api";

export enum Presets {
  LastMonth = 0,
  Month = 30,
  Week = 7,
  Day = 1,
};

export enum ReportSource {
  Leads = 0,
  Clio = 1
}

export interface ReportDetails {
  type: ReportTypes;
  description: string;
  header: string;
  columns: ColumnDefinition[];
  showDateRange: boolean;
}

export const AllSelection = "Select all";

export const ReportsData: ReportDetails[] = [{
  header: "Leads by Staff",
  description: "Shows intakes made by staff and what stage they are currently in.",
  type: ReportTypes.LEADS_STAFF,
  columns: [
    { title: "Total", field: "total", width: 100 },
    { title: "Name", field: "userName" },
    { title: "Practice area", field: "practiceArea" },
    { title: "Lead status", field: "status" },
    { title: "Lead name", field: "leadName" },
    {
      title: "Created at",
      field: "createdAt",
      sorter: "date",
      sorterParams: {
        format: "MM-dd-yyyy",
        alignEmptyValues: "bottom",
      },
    },
  ],
  showDateRange: true
}, {
  header: "Leads by Attorney",
  description: `Shows intakes reviewed by Attorneys and what decision was made. Referred leads are included in Approval rate. 
Lead review time tracking started on 06/13/24. Leads before will not appear as a part of average response time.`,
  type: ReportTypes.LEADS_ATTORNEY,
  columns: [
    { title: "Total", field: "total", width: 100 },
    { title: "Name", field: "userName" },
    { title: "Approval rate", field: "percentage" },
    { title: "Lead status", field: "status" },
    { title: "Lead name", field: "leadName" },
    {
      title: "Created at",
      field: "createdAt",
      sorter: "date",
      sorterParams: {
        format: "MM-dd-yyyy",
        alignEmptyValues: "bottom",
      },
    },
    { title: "Average response time", field: "average", width: 150 },
  ],
  showDateRange: true
}, {
  header: "Intake overview",
  description: "Shows how many intakes were made per day and what's the outcome for them.",
  type: ReportTypes.LEADS_DATE,
  columns: [
    { title: "Total", field: "total", width: 100 },
    { title: "Completed", field: "completed", width: 120 },
    {
      title: "Date",
      field: "date",
      sorter: "date",
      sorterParams: {
        format: "MM-dd-yy",
        alignEmptyValues: "bottom",
      },
    },
    { title: "Practice area", field: "practiceArea" },
    { title: "Lead status", field: "status" },
    { title: "Lead name", field: "leadName" },
  ],
  showDateRange: true
}, {
  header: "Staff Matters Overview",
  description: `Shows how many matters staff have assigned to them and who else works on their matters. 
As an example, if Attorney is selected, this report will show how many total matters they have and how many they have with each Paralegal.`,
  type: ReportTypes.OVERVIEW,
  columns: [
    { title: "Total", field: "total", width: 100 },
    { title: "Task Assignee", field: "descriptionLevel1" },
    { title: "Funnel", field: "descriptionLevel2" },
    { title: "Sub-Funnel", field: "descriptionLevel3", width: 150 },
    { title: "Matter Stage", field: "descriptionLevel4" },
    { title: "Matter Name", field: "descriptionLevel5", width: 300 },
    { title: "Task Name", field: "descriptionLevel6" },
  ],
  showDateRange: false
}, {
  header: "Staff Pending Tasks",
  description: "Shows how many tasks are currently assigned to staff.",
  type: ReportTypes.TASKS,
  columns: [
    { title: "Total", field: "total", width: 120 },
    { title: "Task Assignee", field: "descriptionLevel1" },
    { title: "Funnel", field: "descriptionLevel2", width: 100 },
    { title: "Status", field: "descriptionLevel3", width: 100 },
    { title: "Task Type/Name", field: "descriptionLevel4" },
    { title: "Matter Name", field: "descriptionLevel5", width: 290 },
    { title: "Due Date", field: "level5Data3", width: 100 },
    { title: "Overdue", field: "level5Data4" },
    { title: "SOLE", field: "level5Data2", width: 100 },
    { title: "Conversion", field: "level5Data1", width: 100 },
  ],
  showDateRange: true
}, {
  header: "Staff Hours",
  description: `Shows time tracked by staff for funnels, grouped by tasks and activities. 
On Clio time is rounded up, this can cause significant discrepancy for big date ranges. It is shown in report like this: "Real hours || Clio time".`,
  type: ReportTypes.HOURS,
  columns: [
    { title: "Hours || Clio", field: "totalHours", width: 150 },
    { title: "Name", field: "descriptionLevel1", width: 200 },
    { title: "Funnel", field: "descriptionLevel2", width: 100 },
    { title: "Task Name", field: "descriptionLevel3" },
    { title: "Matter Name", field: "level4Data1", width: 200 },
    { title: "Details", field: "descriptionLevel4", width: 400 },
  ],
  showDateRange: true
}, {
  header: "Staff Tasks Extensions",
  description: `Shows how many extensions staff have and if they were approved. Status explanation:
  Requested - Extension requested through Glio.
  Unauthorized - Due date was changed on Clio without requesting extension.
  Approved or Authorized/Rejected - Action was decided by Admin and due date was automatically updated.
  Task Removed - Extension was requested or Unauthorized, now this task is Deleted.`,
  type: ReportTypes.EXTENSIONS,
  columns: [
    { title: "Total", field: "total", width: 100 },
    { title: "Name", field: "descriptionLevel1", width: 150 },
    { title: "Status", field: "descriptionLevel2", width: 150 },
    { title: "Task Name", field: "descriptionLevel3", width: 250 },
    { title: "Matter Name", field: "descriptionLevel4", width: 250 },
    { title: "Original", field: "level3Data1", width: 100 },
    { title: "Request", field: "level3Data2", width: 100 },
    { title: "Message", field: "level3Data3", formatter: "textarea", width: 200 },
  ],
  showDateRange: true
}, {
  header: "Matter Stage Change",
  description: "Shows manual changes to matter stage that were made by staff.",
  type: ReportTypes.MATTER_STAGE_CHANGE,
  columns: [
    { title: "Total", field: "total", width: 100 },
    { title: "Name", field: "descriptionLevel1", width: 200 },
    { title: "Type", field: "descriptionLevel2", width: 100 },
    { title: "Matter Name", field: "descriptionLevel3", width: 400 },
    { title: "Changes", field: "descriptionLevel4", width: 500 },
  ],
  showDateRange: true
}, {
  header: "Empty Matters",
  description: "Shows matters that currently have no pending tasks assigned. This might only be expected in weekly FU stages.",
  type: ReportTypes.EMPTY_MATTERS,
  columns: [
    { title: "Total", field: "total", width: 100 },
    { title: "Funnel", field: "descriptionLevel1", width: 200 },
    { title: "Attorney", field: "descriptionLevel2", width: 200 },
    { title: "SA", field: "level3Data2", width: 100 },
    { title: "Matter Name", field: "descriptionLevel3", width: 250 },
    { title: "Matter Stage", field: "level3Data1", width: 250 },
    { title: "Last Update", field: "level3Data3", width: 200 },
  ],
  showDateRange: false
}, {
  header: "Task Assignee Changes",
  description: "Shows how tasks assignee were changed.",
  type: ReportTypes.TASK_ASSIGNEE_CHANGE,
  columns: [
    { title: "Total", field: "total", width: 100 },
    { title: "User name", field: "descriptionLevel1", width: 150 },
    { title: "Changes", field: "level2Data1", width: 300 },
    { title: "Task Name", field: "level2Data2", width: 350 },
    { title: "Matter Name", field: "descriptionLevel2", width: 400 },
  ],
  showDateRange: true
}, {
  header: "Pre-lit Settlement overview",
  description: "Shows settlement matters that came form Pre-lit funnels to Settlement.",
  type: ReportTypes.SETTLEMENT_PRELIT,
  columns: [
    { title: "Total", field: "total", width: 100 },
    { title: "Total Settlement", field: "totalClio", width: 150 },
    { title: "Attorney", field: "descriptionLevel1", width: 150 },
    { title: "Status", field: "descriptionLevel2", width: 150 },
    { title: "Matter Stage", field: "descriptionLevel3", width: 250 },
    { title: "Matter Name", field: "descriptionLevel4", width: 250 },
    { title: "Pmt. rec.", field: "level4Data1", width: 100 },
    { title: "Attorney's Fee", field: "level4Data2", width: 150 },
  ],
  showDateRange: true
},
{
  header: "Litigation Settlement overview",
  description: "Shows settlement matters that came from Litigation to Settlement. Separation to Lit started on 06/18/2024. Matters before that date will appear in Pre-lit settlement report.",
  type: ReportTypes.SETTLEMENT_LIT,
  columns: [
    { title: "Total", field: "total", width: 70 },
    { title: "Total Settlement", field: "totalClio", width: 150 },
    { title: "Attorney", field: "descriptionLevel1", width: 150 },
    { title: "Status", field: "descriptionLevel2", width: 130 },
    { title: "Matter Stage", field: "descriptionLevel3", width: 150 },
    { title: "Team", field: "level4Data2", width: 210, formatter: "textarea" },
    { title: "Matter Name", field: "descriptionLevel4", width: 220 },
    { title: "Pmt. rec.", field: "level4Data1", width: 100 },
    { title: "Attorney's Fee", field: "level4Data2", width: 120 },
  ],
  showDateRange: true
},
{
  header: "Pre-lit KPI",
  description: `Demand, Complaint, Warning and Termination Stages are being tracked from 07/02/2024. 
Month groupings show when was the last change to matter Status. For example, when matter was moved to Settlement(Pending) or when Settlement was completed(Completed).`,
  type: ReportTypes.KPI,
  columns: [
    { title: "Total", field: "total", width: 100 },
    { title: "Month", field: "descriptionLevel1", width: 150 },
    { title: "Stage", field: "descriptionLevel2", width: 200 },
    { title: "Completion", field: "descriptionLevel3", width: 200 },
    { title: "Matter Name", field: "descriptionLevel4" },
    { title: "Completed at", field: "level4Data2", width: 200 }
  ],
  showDateRange: true
},
{
  header: "Negotiation outcomes",
  description: `Where and when matter went after Negotiations. Date searched by when matter left Negotiation.`,
  type: ReportTypes.NEGOTIATION_RESULT,
  columns: [
    { title: "Total", field: "total", width: 100 },
    { title: "Month", field: "descriptionLevel1", width: 200 },
    { title: "Funnel", field: "descriptionLevel2", width: 200 },
    { title: "Stage", field: "level3Data1", width: 200 },
    { title: "Days in Negotiations", field: "level3Data2", width: 200 },
    { title: "Matter", field: "descriptionLevel3" },
  ],
  showDateRange: true
},
{
  header: "SOLES",
  description: `This report provides a comprehensive overview of the Statutes of Limitations Expiration (SOLES) for all active pre-litigation cases within our firm. 
The purpose of this report is to ensure that all critical deadlines are clearly identified, monitored, and managed effectively to prevent any lapse in legal action.`,
  type: ReportTypes.SOLES,
  columns: [
    { title: "Total", field: "total", width: 100 },
    { title: "Month", field: "descriptionLevel1", width: 100 },
    { title: "Attorney", field: "descriptionLevel2", width: 150 },
    { title: "Matter", field: "descriptionLevel3", width: 150, formatter: "textarea" },
    { title: "Termination", field: "level3Data7", width: 70 },
    { title: "Conversion", field: "level3Data8", width: 70 },
    { title: "Team", field: "level3Data1", width: 150, formatter: "textarea" },
    { title: "Earliest", field: "level3Data2", width: 70 },
    { title: "WTVPP", field: "level3Data3", width: 70 },
    { title: "FEHA", field: "level3Data4", width: 70 },
    { title: "W&H", field: "level3Data5", width: 70 },
    { title: "UCL", field: "level3Data6", width: 70 },
    { title: "Notes", field: "level3Data9" },
  ],
  showDateRange: true
},
];