/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AttorneyStatus {
    NOT_OPEN = 'NotOpen',
    NO_ACTION = 'NoAction',
    ACCEPTED = 'Accepted',
    REJECTED = 'Rejected',
}
