import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody/TableBody";
import TableCell from "@mui/material/TableCell/TableCell";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import { LeadTableSummary, SummaryType, SummaryTypeText } from "../shared/Constants";
import Button from "@mui/material/Button/Button";
import FormControl from "@mui/material/FormControl/FormControl";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import { HelpersService } from "../../../../services/Helpers";

const headCells = [
  {
    id: "leadName",
    disablePadding: false,
    label: "Lead name",
  },
  {
    id: "category",
    disablePadding: false,
    label: "Category",
  },
  {
    id: "summaryType",
    disablePadding: false,
    label: "Summary type",
  },
];

interface ShareLeadsTableProps {
  leads: LeadTableSummary[];
  onPreviewSelect: Function;
  onPreviewTypeChange: Function;
}

export default function ShareLeadsTable(props: ShareLeadsTableProps) {
  const generateSingleRow = () => {

    return (
      <TableRow
        hover
        tabIndex={-1}
        key={props.leads[0].lead.id}
      >
        <TableCell align="left">
          {props.leads[0].lead.firstName}{" "}
          {props.leads[0].lead.lastName}
        </TableCell>
        <TableCell align="left">{HelpersService.camelToString(props.leads[0].lead.category)}</TableCell>
        <TableCell align="left">
          <FormControl required variant="outlined" sx={{ m: 1, minWidth: 120 }}>
            <Select
              name="terminationType"
              value={props.leads[0].summaryType}
              onChange={(e) => props.onPreviewTypeChange(0, e.target.value)}
            >
              {Object.keys(SummaryType).map((key) => {
                return (
                  <MenuItem key={key} value={key}>
                    {SummaryTypeText[key]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell align="left">
          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.onPreviewSelect(0);
            }}
          >
            Preview
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 600 }} aria-labelledby="tableTitle" size="medium">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell key={headCell.id}> {headCell.label}</TableCell>
            ))}
            <TableCell align="left" className="ps-5">
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            {props.leads &&
              props.leads.length > 1 &&
              props.leads.map((row, index) => {

                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.lead.id}
                  >
                    <TableCell align="left">
                      {row.lead.firstName} {row.lead.lastName}
                    </TableCell>
                    <TableCell align="left">{row.lead.category}</TableCell>
                    <TableCell align="left">
                      <FormControl
                        required
                        variant="outlined"
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <Select
                          name="terminationType"
                          value={row.summaryType}
                          onChange={(e) =>
                            props.onPreviewTypeChange(index, e.target.value)
                          }
                        >
                          {Object.keys(SummaryType).map((key) => {
                            return (
                              <MenuItem key={key} value={key}>
                                {SummaryTypeText[key]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        variant="text"
                        color="primary"
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          props.onPreviewSelect(index);
                        }}
                      >
                        Preview
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}

            {props.leads && props.leads.length === 1 && (
              <>{generateSingleRow()}</>
            )}
          </>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
