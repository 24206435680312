/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ReasonForNAEmp {
    EVIDENCE_NOT_STRONG = 'EvidenceNotStrong',
    NOT_EXAUSTED_UNION = 'NotExaustedUnion',
    NOT_ILLEGAL = 'NotIllegal',
    CREDIBILITY = 'Credibility',
    INSUFFICIENT_FUNDS = 'InsufficientFunds',
    REFER_TO_LABOR = 'ReferToLabor',
    GIG_OR_GOVERNMENT = 'GigOrGovernment',
    SOL_EXPIRED = 'SolExpired',
    REPRESENTED = 'Represented',
    SIGNED_SEVERANCE = 'SignedSeverance',
    OUT_OF_STATE = 'OutOfState',
    NOT_PLAINTIFF_CLAIM = 'NotPlaintiffClaim',
    REFER_WC = 'ReferWC',
    NON_RESPONSIVE = 'NonResponsive',
    OTHER = 'Other',
}
