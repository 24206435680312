/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ReasonForDecline {
    SIGNED_WITH_ANOTHER_FIRM = 'SignedWithAnotherFirm',
    SIGNED_SEVERANCE = 'SignedSeverance',
    LEAD_CHANGED_THEIR_MIND = 'LeadChangedTheirMind',
    ATTORNEY_WITHDREW_OFFER = 'AttorneyWithdrewOffer',
    CONTINGENCY_FEE = 'ContingencyFee',
    NON_RESPONSIVE = 'NonResponsive',
    CREDIBILITY = 'Credibility',
    OTHER = 'Other',
}
