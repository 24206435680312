/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ZoomSetMatterRequest } from '../models/ZoomSetMatterRequest';
import type { ZoomTableRequest } from '../models/ZoomTableRequest';
import type { ZoomTableResponse } from '../models/ZoomTableResponse';
import type { ZoomUserResponse } from '../models/ZoomUserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ZoomService {

    /**
     * @returns ZoomUserResponse Success
     * @throws ApiError
     */
    public static getApiZoomUsers(): CancelablePromise<Array<ZoomUserResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Zoom/users',
        });
    }

    /**
     * @param requestBody 
     * @returns ZoomTableResponse Success
     * @throws ApiError
     */
    public static postApiZoomPending(
requestBody?: ZoomTableRequest,
): CancelablePromise<ZoomTableResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Zoom/pending',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiZoomMatter(
requestBody?: ZoomSetMatterRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Zoom/matter',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiZoom(
requestBody?: ZoomSetMatterRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Zoom',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiZoomBulk(
requestBody?: ZoomSetMatterRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Zoom/bulk',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiZoomStatusDeleted(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Zoom/{id}/status/deleted',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiZoomMeetingsWebhook(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Zoom/meetingsWebhook',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiZoomWebhook(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Zoom/webhook',
        });
    }

}
