/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ReasonForReferPI {
    EASY_AUTO = 'EasyAuto',
    NBCAUTO = 'NBCAuto',
    EASY_PREMISES_LIABILITY = 'EasyPremisesLiability',
    CIVIL_RIGHTS = 'CivilRights',
}
