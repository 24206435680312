import { START_SPINNER, STOP_SPINNER } from "../actions/spinnerActions";

const initialSpinnerState: boolean = false;
const spinnerReducers = (state = initialSpinnerState, action) => {
  switch (action.type) {
    case STOP_SPINNER:
        return state = false;
      case START_SPINNER:
      return state = true;
      default: return state;
  }
};

export default spinnerReducers;