/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Injuries {
    NECK_AND_BACK = 'NeckAndBack',
    SOFT_TISSUE = 'SoftTissue',
    BROKEN_BONES = 'BrokenBones',
    LOST_LIMB = 'LostLimb',
    SPINAL_CORD = 'SpinalCord',
    BRAIN_INJURY_CONCUSSION = 'BrainInjury_Concussion',
    LOSS_OF_LIFE = 'LossOfLife',
    TOOTH = 'Tooth',
    OTHER = 'Other',
}
