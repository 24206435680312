/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ReasonForNAPI {
    SUSPECTED_COMPETITOR = 'SuspectedCompetitor',
    NO_INJURIES = 'NoInjuries',
    NOT_PI = 'NotPI',
    DISCONNECT = 'Disconnect',
    NO_INSURANCE = 'NoInsurance',
    CREDIBILITY = 'Credibility',
    SOL_EXPIRED = 'SolExpired',
    NON_RESPONSIVE = 'NonResponsive',
    EVIDENCE_NOT_STRONG = 'EvidenceNotStrong',
    RECEIVED_SETTLEMENT = 'ReceivedSettlement',
    ALREADY_REPRESENTED = 'AlreadyRepresented',
    OUT_OF_STATE = 'OutOfState',
    CHANGED_MIND = 'ChangedMind',
    OTHER = 'Other',
}
