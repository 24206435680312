import Box from "@mui/material/Box/Box";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import { alignProperty } from "@mui/material/styles/cssUtils";
import TableCell from "@mui/material/TableCell/TableCell";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { UserShortResponse } from "../../../../api";
import { LeadShortResponse } from "../../../../api/models/LeadShortResponse";
import { HeadCell, HeadCellsType } from "./LeadsTableConstants";

interface EnhancedTableProps {
  headCells: HeadCell[];
  numSelected?: number;
  onRequestSort?: (event: React.MouseEvent<unknown>, newOrderBy: any) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  displaySelectAll: boolean;
  sortAsc?: boolean;
  sortBy?: string;
  rowCount?: number;
  removeActions?: boolean;
}

export default function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    sortAsc,
    sortBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (newOrderBy: HeadCellsType) =>
    (event: React.MouseEvent<unknown>) => {
      if (!!onRequestSort) onRequestSort(event, newOrderBy);
    };

  return (
    <TableHead>
      <TableRow>
        {props.displaySelectAll && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={
                !!numSelected &&
                numSelected > 0 &&
                numSelected < (rowCount ?? 0)
              }
              checked={(rowCount ?? 0) > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {props.headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.disableSorting ? (
              <>{headCell.label}</>
            ) : (
              <TableSortLabel
                active={sortBy === headCell.id}
                direction={
                  sortBy === headCell.id ? (sortAsc ? "asc" : "desc") : "asc"
                }
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {sortBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sortAsc ? "sorted ascending" : "sorted descending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
        {!props.removeActions && (
          <>
            <TableCell align="left"></TableCell>
            <TableCell align="left">Actions</TableCell>
          </>
        )}
      </TableRow>
    </TableHead>
  );
}
