import { useEffect, useState } from "react";

import TableBody from "@mui/material/TableBody/TableBody";
import Table from "@mui/material/Table/Table";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TablePagination from "@mui/material/TablePagination/TablePagination";

import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";

import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button";

import "../Leads.scss";
import TableActionMenu from "./LeadActionsMenu";
import {
  CategoryMapping,
  errorInstructions,
  LeadType,
  ReasonForNAEmpDisplayMapping,
  ReasonForPIEmpDisplayMapping,
  StageColorsMapping,
} from "../shared/Constants";
import EnhancedTableHead from "./LeadTableHeader";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { LeadsService } from "../../../../api/services/LeadsService";
import {
  AdminLeadStatus,
  ApplicationRole,
  ArchiveRequest,
  AttorneyApprovalStatus,
  AttorneyLeadStatus,
  Category,
  ClientRepresentationRequest,
  DeclinedAction,
  GetUserListRequest,
  LeadShortResponse,
  LeadTableType,
  ReasonForNAEmp,
  ReasonForNAPI,
  TableFilter,
  TableLeadsRequest,
  UserShortResponse,
  UsersService,
} from "../../../../api";
import useNavigateSearch from "../../../../services/useNavigateSearch";
import { HelpersService } from "../../../../services/Helpers";
import { useLocation } from "react-router-dom";
import {
  Action,
  ActiveClientsHead,
  ArchivedHead,
  AttorneyReviewStatusText,
  DEFAULT_ROWS_PER_PAGE,
  IntakeHeadHead,
  LeadAttorneyStatusText,
  LeadData,
  ReferralHead,
  ReviewAdminHead,
  ReviewAttorneyHead,
  SharedAttorneyHead,
  SharedAttorneyHeadMobile,
  TableInteractionsProps,
} from "./LeadsTableConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { startSpinner, stopSpinner } from "../../../../redux/actions/spinnerActions";
import dayjs from "dayjs";

const MySwal = withReactContent(Swal);
interface LeadsTableProps {
  tableType: LeadTableType;
}

export default function LeadsTable(props: LeadsTableProps) {
  const [locationPath, setLocationPath] = useState<string>();
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [visibleRows, setVisibleRows] = useState<LeadData[]>([]);
  const [leadsCount, setLeadsCount] = useState<Record<string, number> | null>();
  const [totalCount, setTotalCount] = useState(0);

  const [bulkEnabled, setBulkEnabled] = useState(false);
  const [openArchivedDialog, setOpenArchivedDialog] = useState(false);
  const [selectedArchiveType, setSelectedArchiveType] =
    useState<AdminLeadStatus>();

  const [archivedComment, setArchivedComment] = useState<string>();
  const [naReason, setNaReason] = useState<ReasonForNAEmp | ReasonForNAPI>();
  const [declinedReason, setDeclinedReason] = useState<string>();
  const [actionRow, setActionRow] = useState<LeadData>();

  const [tableInteractions, setTableInteractions] =
    useState<TableInteractionsProps>({
      sortAsc: false,
      sortBy: undefined,
      filters: [],
      page: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    });
  const [nameSearchValue, setNameSearchValue] = useState<string>();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [selectedAttorneyStage, setSelectedAttorneyStage] =
    useState<AttorneyLeadStatus>();
  const [openReassignDialog, setOpenReassignDialog] = useState(false);
  const [staffAttorneysList, setStaffAttorneysList] = useState<
    UserShortResponse[]
  >([]);
  const [selectedStaffAttorneyId, setSelectedStaffAttorneyId] =
    useState<number>();

  const location = useLocation();
  const navigate = useNavigateSearch();
  const user = useSelector((state: any) => state.user?.user);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsMobile(HelpersService.checkMobile());
    if (user.roles.includes(ApplicationRole.ADMIN)) {
      UsersService.postApiUsersGetUsersByRole({
        role: ApplicationRole.STAFF_ATTORNEY,
        category: Category.EMPLOYMENT,
        addAdmins: true
      } as GetUserListRequest
      ).then((attorneys) => {
        setStaffAttorneysList(attorneys);
      });
    }
  }, []);

  useEffect(() => {
    if (location.pathname === locationPath) {
      return;
    }
    setLocationPath(location.pathname);
    setTableInteractions({
      sortAsc: false,
      sortBy: undefined,
      filters: [],
      page: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    });
    setVisibleRows([]);
  }, [location]);

  const initiateLeads = async () => {
    dispatch(startSpinner());

    var request = {
      type: props.tableType,
      limit: tableInteractions.rowsPerPage,
      offset: tableInteractions.rowsPerPage * tableInteractions.page,
      sortBy: tableInteractions.sortBy,
      sortAscending: tableInteractions.sortAsc,
      filters: tableInteractions.filters
    } as TableLeadsRequest;
    LeadsService.postApiLeadsLeads(request).then(response => {
      let rowsOnMount = [] as LeadData[];
      if (!!response.leads) {
        rowsOnMount = response.leads.map((row) => {
          let action = { actions: getActions(row) };
          return { ...(row as LeadData), ...action };
        });
      }
      setVisibleRows(rowsOnMount);
      setTotalCount(response.totalCount ?? 0);
      setLeadsCount(response.countByStatus);
    }).finally(() => dispatch(stopSpinner()));
  };

  useEffect(() => {
    initiateLeads();
  }, [tableInteractions]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    newSortBy: keyof LeadShortResponse
  ) => {
    const toggledOrder =
      tableInteractions.sortBy !== newSortBy
        ? true
        : !tableInteractions.sortAsc;
    const newTableInteractions = {
      ...tableInteractions,
      sortAsc: toggledOrder,
      sortBy: newSortBy,
      page: 0,
    };
    setTableInteractions(newTableInteractions);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    const newTableInteractions = { ...tableInteractions, page: newPage };
    setTableInteractions(newTableInteractions);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newTableInteractions = {
      ...tableInteractions,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    };
    setTableInteractions(newTableInteractions);
  };

  const handleChangeFilter = (
    value: string | null = null,
    field: string = "Status",
    isEnum: boolean = true
  ) => {
    var oldFilters = tableInteractions.filters ?? [];
    if (field !== "Status") {
      oldFilters = oldFilters.filter((f) => f.filterField !== "Status");
    }
    const existingFilterIndex = oldFilters.findIndex(
      (filter) => filter.filterField === field
    );

    if (existingFilterIndex !== -1) {
      const updatedFilters = [...oldFilters];
      updatedFilters[existingFilterIndex] = {
        filterField: field,
        filterValue: value ?? "",
        isEnum: isEnum
      } as TableFilter;
      setTableInteractions((prevState) => ({
        ...prevState,
        filters: updatedFilters,
        page: 0,
      }));
    } else {
      setTableInteractions((prevState) => ({
        ...prevState,
        filters: [
          ...oldFilters,
          {
            filterField: field,
            filterValue: value ?? "",
            isEnum: isEnum,
          } as TableFilter,
        ],
        page: 0,
      }));
    }
  };

  const updateSearch = (value: string) => {
    setNameSearchValue(value);
    if (value.length > 2 || value.length === 0) {
      handleChangeFilter(value, "LeadName", false);
    }
  };

  const isSelected = (index: number) => selected.indexOf(index) !== -1;
  const handleRowClick = (row: LeadData) => {
    const selectedIndex = selected.indexOf(row.id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = selected.concat(row.id);
    } else if (selectedIndex === 0) {
      newSelected = selected.slice(1);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = selected.slice(0, -1);
    } else if (selectedIndex > 0) {
      newSelected = selected
        .slice(0, selectedIndex)
        .concat(selected.slice(selectedIndex + 1));
    }

    calculateBulkActionState(newSelected);
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBulkEnabled(false);
    if (event.target.checked) {
      const newSelected = visibleRows.map((n) => n.id);
      setSelected(newSelected);
      calculateBulkActionState(newSelected ?? []);
      return;
    }
    setSelected([]);
  };

  const calculateBulkActionState = (newSelected: number[]) => {
    const selectedRows = visibleRows.filter((row) =>
      newSelected.includes(row.id)
    );
    setBulkEnabled(
      selectedRows.length > 0 &&
      selectedRows.filter(
        (row) =>
          row.status === AdminLeadStatus.NEW &&
          row.attorneyApprovalStatus === AttorneyApprovalStatus.REFER
      ).length === selectedRows.length
    );
  };

  const handleAddNewLead = () => {
    navigate(`/lead/new`);
  };

  const handleDetailsClick = (
    e: React.MouseEvent<unknown> | null,
    row: LeadData
  ) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (props.tableType === LeadTableType.SHARED_ATTORNEY) {
      navigate(`/lead/${row.leadId}`, {
        leadType: LeadType.Attorney,
        isEdit: "false",
      });
      return;
    }
    if (props.tableType === LeadTableType.REVIEW && (user.roles.includes(ApplicationRole.STAFF_ATTORNEY) || user.roles.includes(ApplicationRole.REFFERAL_STAFF))) {
      navigate(`/lead/${row.id}`, {
        leadType: LeadType.StaffAttorney,
        isEdit: "false",
      });
      return;
    }
    navigate(`/lead/${row.id}`, {
      isEdit: row.status === AdminLeadStatus.DRAFT,
    });
  };

  const setSelectWinner = (row: LeadData) => {
    navigate(`/lead/${row.id}`);
  };

  const handleArchiveLead = (row: LeadData) => {
    setActionRow(row);
    setOpenArchivedDialog(true);
  };

  const handleRestoreLead = (id: number) => {
    LeadsService.postApiLeadsRestore(id)
      .then(() => {
        MySwal.fire({
          icon: "success",
          title: <p className="title">Success!</p>,
          html: (
            <div className="alert-text">
              Lead has been restored. You can find it back in main leads table.
            </div>
          ),
        }).then(() => {
          initiateLeads();
        });
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error restoring lead</p>,
          html: <div className="alert-text">{errorInstructions}</div>,
        });
      });
  };

  const handleDeleteIncomplete = (row: LeadData) => {
    MySwal.fire({
      title: <strong>Confirm lead deletion</strong>,
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "No, go back",
      confirmButtonText: "Yes, delete",
      confirmButtonColor: "#ed633a",
      reverseButtons: true,
    })
      .then((result) => {
        if (result.isConfirmed)
          LeadsService.deleteApiLeads(row.id).then(() => {
            MySwal.fire({
              icon: "success",
              title: <p className="title">Lead deleted successfully!</p>,
            }).then(() => {
              initiateLeads();
            });
          });
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error deleting lead</p>,
          html: <div className="alert-text">{errorInstructions}</div>,
        });
      });
  };

  const handleUnassign = (row: LeadData) => {
    MySwal.fire({
      title: <strong>Confirm unassignment</strong>,
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "No, go back",
      confirmButtonText: "Yes, remove",
      confirmButtonColor: "#ed633a",
      reverseButtons: true,
    })
      .then((result) => {
        if (result.isConfirmed)
          LeadsService.postApiLeadsUnassign(row.id).then(() => {
            MySwal.fire({
              icon: "success",
              title: <p className="title">Lead unassigned successfully!</p>,
            }).then(() => {
              initiateLeads();
            });
          });
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error unassigning lead</p>,
          html: <div className="alert-text">{errorInstructions}</div>,
        });
      });
  };

  const handleReassignLead = (row: LeadData) => {
    setActionRow(row);
    setOpenReassignDialog(true);
  };

  const sendLead = (row: LeadData) => {
    navigate(`/lead/send/${row.id}`);
  };

  const getActions = (row: LeadShortResponse): Action[] => {
    var actions: Action[] = [];
    if (props.tableType === LeadTableType.REVIEW) {
      actions = [{ name: "Unassign", function: handleUnassign }];
      if (user?.roles?.includes(ApplicationRole.ADMIN)) {
        if (row.attorneyApprovalStatus === AttorneyApprovalStatus.IN_PROCESS || row.attorneyApprovalStatus === AttorneyApprovalStatus.MORE_INFO_PROVIDED || row.attorneyApprovalStatus === AttorneyApprovalStatus.MORE_INFO_REQUESTED) {
          actions = [...actions, { name: "Reassign", function: handleReassignLead }];
        }
        actions = [...actions, { name: "Archive", function: handleArchiveLead }];
      }
      return actions;
    }

    switch (row.status) {
      //Intake process
      case AdminLeadStatus.DRAFT: {
        if (user?.roles?.includes(ApplicationRole.ADMIN)) {
          return [{ name: "Delete", function: handleDeleteIncomplete }];
        }
        break;
      }
      //Referral process
      case AdminLeadStatus.NEW: {
        if (row.attorneyApprovalStatus === AttorneyApprovalStatus.REFER) {
          actions = [{ name: "Send lead", function: sendLead }];
        }
        if (row.attorneyApprovalStatus === AttorneyApprovalStatus.KEEP) {
          actions = [{ name: "Move to Refer", function: handleClientDenial }];
        }
        break;
      }
      case AdminLeadStatus.SHARED: {
        actions = [{ name: "Select winner", function: setSelectWinner }];
        break;
      }
      case AdminLeadStatus.INTRODUCTION: {
        actions = [
          { name: "Accept intake", function: () => { } },
          { name: "Reject intake", function: () => { } },
        ];
        break;
      }
      case AdminLeadStatus.WINNER_SELECTED: {
        actions = [{ name: "Reject", function: () => { } }];
        break;
      }
      case AdminLeadStatus.NGC:
      case AdminLeadStatus.N_A:
      case AdminLeadStatus.CSADECLINED:
      case AdminLeadStatus.CLIENT_REFERED:
      case AdminLeadStatus.CLIENT_RETAINED: {
        if (props.tableType === LeadTableType.ARCHIVE) {
          return [
            {
              name: "Restore lead",
              function: () => {
                handleRestoreLead(row.id);
              },
            },
          ];
        }
        return [];
      }
    }
    if (user?.roles?.includes(ApplicationRole.ADMIN)) {
      if (row.attorneyApprovalStatus === AttorneyApprovalStatus.IN_PROCESS || row.attorneyApprovalStatus === AttorneyApprovalStatus.MORE_INFO_PROVIDED || row.attorneyApprovalStatus === AttorneyApprovalStatus.MORE_INFO_REQUESTED) {
        actions = [...actions, { name: "Reassign", function: handleReassignLead }];
      }
      actions = [...actions, { name: "Archive", function: handleArchiveLead }];
    }
    return actions;
  };

  const generateTableHeadCells = () => {
    switch (props.tableType) {
      case LeadTableType.ACTIVE_CLIENT: {
        return ActiveClientsHead;
      }
      case LeadTableType.INTAKE: {
        return IntakeHeadHead;
      }
      case LeadTableType.REVIEW: {
        return user?.roles?.includes(ApplicationRole.ADMIN)
          ? ReviewAdminHead
          : ReviewAttorneyHead;
      }
      case LeadTableType.ARCHIVE: {
        return ArchivedHead;
      }
      case LeadTableType.REFERRAL: {
        return ReferralHead;
      }
      case LeadTableType.SHARED_ATTORNEY: {
        return isMobile ? SharedAttorneyHeadMobile : SharedAttorneyHead;
      }
    }
    return [];
  };

  const generateTableRow = (row: LeadData, index) => {
    let createdDate = HelpersService.calcDatesDifference(
      dayjs.utc(row.createdAt ?? "")
    );

    let sharedCells = (
      <>
        <TableCell align="left">{row.leadName}</TableCell>
        <TableCell align="left">{CategoryMapping[row.category]}</TableCell>
      </>
    );
    let intakeSharedCells = (
      <>
        <TableCell align="left">{row.reviewingAttorneyName}</TableCell>
        <TableCell align="left">{row.creatorName}</TableCell>
        <TableCell align="left">{createdDate}</TableCell>
      </>
    );

    let actionsCells = (
      <>
        <TableCell align="left">
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={(e) => handleDetailsClick(e, row)}
          >
            View details
          </Button>
        </TableCell>
        {!user?.roles?.includes(ApplicationRole.ATTORNEY) && (
          <TableCell align="left">
            {row.actions.length > 0 && (
              <TableActionMenu
                row={row}
                actions={row.actions}
              ></TableActionMenu>
            )}
          </TableCell>
        )}
      </>
    );

    switch (props.tableType) {
      case LeadTableType.ACTIVE_CLIENT: {
        let dateSinceContractSent = HelpersService.calcDatesDifference(
          dayjs.utc(row.contractSentDate ?? "")
        );

        return (
          <TableRow
            hover
            tabIndex={-1}
            key={row.id}
            className={row.highlight ? "table-row-accent" : ""}
          >
            <TableCell align="left">
              <Button
                variant="contained"
                color={StageColorsMapping[row.status ?? AdminLeadStatus.NEW]}
                size="small"
              >
                {HelpersService.camelToString(
                  row.status === AdminLeadStatus.NEW ? "Approved" : row.status
                )}
              </Button>
            </TableCell>
            {sharedCells}
            {intakeSharedCells}
            <TableCell align="left">
              {row.contractSentDate ? dateSinceContractSent : "Not sent yet"}
            </TableCell>
            {actionsCells}
          </TableRow>
        );
      }
      case LeadTableType.INTAKE: {
        let updatedDate = HelpersService.calcDatesDifference(dayjs.utc(row.updatedAt ?? ""));

        return (
          <TableRow
            hover
            tabIndex={-1}
            key={row.id}
            className={
              row.attorneyApprovalStatus ===
                AttorneyApprovalStatus.MORE_INFO_REQUESTED
                ? "table-row-accent"
                : ""
            }
            sx={{ cursor: "pointer" }}
          >
            {row.status === AdminLeadStatus.DRAFT ? (
              <TableCell align="left">
                <Button
                  variant="contained"
                  color={StageColorsMapping[AdminLeadStatus.DRAFT]}
                  size="small"
                >
                  Incomplete
                </Button>
              </TableCell>
            ) : (
              <TableCell align="left">
                <Button
                  variant="contained"
                  color={
                    StageColorsMapping[
                    row.attorneyApprovalStatus ?? AdminLeadStatus.NEW
                    ]
                  }
                  size="small"
                >
                  {
                    LeadAttorneyStatusText[
                    HelpersService.getEnumKeyByEnumValue(
                      AttorneyApprovalStatus,
                      row.attorneyApprovalStatus
                    )
                    ]
                  }
                </Button>
              </TableCell>
            )}
            {sharedCells}
            {intakeSharedCells}
            <TableCell align="left">{updatedDate}</TableCell>
            {actionsCells}
          </TableRow>
        );
      }
      case LeadTableType.REVIEW: {
        return (
          <TableRow
            className={
              row.attorneyApprovalStatus ===
                AttorneyApprovalStatus.MORE_INFO_PROVIDED
                ? "table-row-accent"
                : ""
            }
            tabIndex={-1}
            key={row.id}
          >
            <TableCell align="left">
              <Button
                variant="contained"
                color={
                  StageColorsMapping[
                  row.attorneyApprovalStatus ?? AdminLeadStatus.NEW
                  ]
                }
                size="small"
              >
                {
                  AttorneyReviewStatusText[
                  HelpersService.getEnumKeyByEnumValue(
                    AttorneyApprovalStatus,
                    row.attorneyApprovalStatus
                  )
                  ]
                }
              </Button>
            </TableCell>
            {sharedCells}
            {user?.roles?.includes(ApplicationRole.ADMIN) && (
              <>
                <TableCell align="left">{row.creatorName}</TableCell>
                <TableCell align="left">{row.reviewingAttorneyName}</TableCell>
              </>
            )}
            <TableCell align="left">{createdDate}</TableCell>
            {actionsCells}
          </TableRow>
        );
      }
      case LeadTableType.ARCHIVE: {
        return (
          <TableRow tabIndex={-1} key={row.id}>
            <TableCell align="left">
              <Button
                variant="contained"
                color={StageColorsMapping[row.status]}
                size="small"
              >
                {HelpersService.camelToString(row.status)}
              </Button>
            </TableCell>
            {sharedCells}
            <TableCell align="left">{row.attorney}</TableCell>
            <TableCell align="left">
              {HelpersService.camelToString(row.archivedReason)}
            </TableCell>
            <TableCell align="left">{createdDate}</TableCell>
            {actionsCells}
          </TableRow>
        );
      }
      case LeadTableType.REFERRAL: {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        let updatedDate = HelpersService.calcDatesDifference(
          dayjs.utc(row.updatedAt ?? "")
        );
        return (
          <TableRow
            hover
            onClick={() => handleRowClick(row)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
            className={row.hasActivity ? "table-row-accent" : ""}
          >
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={isItemSelected}
                inputProps={{
                  "aria-labelledby": labelId,
                }}
              />
            </TableCell>
            <TableCell align="left" className="table-cell">
              <Button
                variant="contained"
                color={StageColorsMapping[row.status ?? AdminLeadStatus.NEW]}
                size="small"
                className={
                  (row.status === AdminLeadStatus.WINNER_SELECTED && !!row.isPaid)
                    ? "table-btn-higher"
                    : ""
                }
              >
                {HelpersService.camelToString(row.status)}
                {row.hasActivity && row.status === AdminLeadStatus.SHARED
                  && " (accepted)"}
              </Button>
            </TableCell>
            {sharedCells}
            <TableCell align="left">{row.attorney}</TableCell>
            <TableCell align="left">{row.creatorName}</TableCell>
            <TableCell align="left">{createdDate}</TableCell>
            <TableCell align="left">{updatedDate}</TableCell>
            {actionsCells}
          </TableRow>
        );
      }
      case LeadTableType.SHARED_ATTORNEY: {
        return (
          <TableRow tabIndex={-1} key={row.id}>
            <TableCell
              align="left"
              onClick={(e) => handleDetailsClick(e, row)}
              className="table-cell"
            >
              <Button
                variant="contained"
                color={StageColorsMapping[row.attorneyStatus!]}
                size="small"
                className={
                  generateAttorneyAction(row.attorneyStatus).length > 0
                    ? "table-btn-higher"
                    : ""
                }
              >
                {row.attorneyStatus}{generateAttorneyAction(row.attorneyStatus)}
              </Button>
            </TableCell>
            <TableCell align="left">{row.leadName ?? "Hidden"}</TableCell>
            <TableCell align="left" onClick={(e) => handleDetailsClick(e, row)}>
              {HelpersService.camelToString(row.category)}
            </TableCell>
            {!isMobile && (
              <>
                <TableCell align="left">
                  {HelpersService.calcDatesDifference(dayjs.utc(row.updatedAt ?? "")
                  )}
                </TableCell>
                <TableCell></TableCell>
                {actionsCells}
              </>
            )}
          </TableRow>
        );
      }
    }
  };

  const generateAttorneyAction = (status) => {
    switch (status) {
      case AttorneyLeadStatus.ACCEPTED: return " (await GLio confirmation)";
      case AttorneyLeadStatus.WON: return " (sign agreement)";
      case AttorneyLeadStatus.SIGNED: return " (pay fee)";
    }
    return "";
  }

  const generateTitle = () => {
    switch (props.tableType) {
      case LeadTableType.ACTIVE_CLIENT: {
        return "Active clients";
      }
      case LeadTableType.INTAKE: {
        return "New leads";
      }
      case LeadTableType.REVIEW: {
        return "Leads to approve";
      }
      case LeadTableType.ARCHIVE: {
        return "Archived leads";
      }
      case LeadTableType.REFERRAL: {
        return "Referral leads";
      }
      case LeadTableType.SHARED_ATTORNEY: {
        return "Leads";
      }
    }
  };

  const generateTableActions = () => {
    switch (props.tableType) {
      case LeadTableType.ACTIVE_CLIENT: {
        return (
          <div className="row mb-5">
            <div className="col-12 d-flex table-filters flex-wrap">
              <Button
                variant="contained"
                color="darkGray"
                size="small"
                onClick={() => handleChangeFilter()}
              >
                All(
                {Object.values(leadsCount ?? {}).reduce(
                  (acc, count) => acc + count,
                  0
                )}
                )
              </Button>
              <Button
                variant="contained"
                color={StageColorsMapping[AdminLeadStatus.NEW]}
                size="small"
                onClick={() => handleChangeFilter(AdminLeadStatus.NEW)}
              >
                Approved(
                {leadsCount?.new ?? 0})
              </Button>
              <Button
                variant="contained"
                color={StageColorsMapping[AdminLeadStatus.CONTRACT_SENT]}
                size="small"
                onClick={() =>
                  handleChangeFilter(AdminLeadStatus.CONTRACT_SENT)
                }
              >
                Contract sent(
                {leadsCount?.contractSent ?? 0})
              </Button>
              <Button
                variant="contained"
                color={StageColorsMapping[AdminLeadStatus.CONTRACT_SIGNED]}
                size="small"
                onClick={() =>
                  handleChangeFilter(AdminLeadStatus.CONTRACT_SIGNED)
                }
              >
                Contract signed(
                {leadsCount?.contractSigned ?? 0})
              </Button>
              {(user.roles?.includes(ApplicationRole.ADMIN) ||
                (user.roles?.includes(ApplicationRole.STAFF) &&
                  user.practiceArea === Category.EMPLOYMENT)) && (
                  <Button
                    variant="contained"
                    color={
                      StageColorsMapping[AdminLeadStatus.SUPPLEMENTAL_ASSIGNED]
                    }
                    size="small"
                    onClick={() =>
                      handleChangeFilter(AdminLeadStatus.SUPPLEMENTAL_ASSIGNED)
                    }
                  >
                    Supplemental assigned(
                    {leadsCount?.supplementalAssigned ?? 0})
                  </Button>
                )}
              <Button
                variant="contained"
                color={StageColorsMapping[AdminLeadStatus.DONE]}
                size="small"
                onClick={() => handleChangeFilter(AdminLeadStatus.DONE)}
              >
                Done(
                {leadsCount?.Done ?? 0})
              </Button>
            </div>
          </div>
        );
      }
      case LeadTableType.ARCHIVE: {
        return (
          <div className="row mb-5">
            <div className="col-12 d-flex table-filters flex-wrap">
              <Button
                variant="contained"
                color="darkGray"
                size="small"
                onClick={() => handleChangeFilter()}
              >
                All(
                {Object.values(leadsCount ?? {}).reduce(
                  (acc, count) => acc + count,
                  0
                )}
                )
              </Button>
              <Button
                variant="contained"
                color={StageColorsMapping[AdminLeadStatus.CLIENT_RETAINED]}
                size="small"
                onClick={() =>
                  handleChangeFilter(AdminLeadStatus.CLIENT_RETAINED)
                }
              >
                Client retained(
                {leadsCount?.clientRetained ?? 0})
              </Button>
              <Button
                variant="contained"
                color={StageColorsMapping[AdminLeadStatus.CLIENT_REFERED]}
                size="small"
                onClick={() =>
                  handleChangeFilter(AdminLeadStatus.CLIENT_REFERED)
                }
              >
                Client referred(
                {leadsCount?.clientRefered ?? 0})
              </Button>
              <Button
                variant="contained"
                color={StageColorsMapping[AdminLeadStatus.DECLINED]}
                size="small"
                onClick={() => handleChangeFilter(AdminLeadStatus.DECLINED)}
              >
                Declined(
                {leadsCount?.csaDeclined ?? 0})
              </Button>
              <Button
                variant="contained"
                color={StageColorsMapping[AdminLeadStatus.NGC]}
                size="small"
                onClick={() => handleChangeFilter(AdminLeadStatus.NGC)}
              >
                NGC(
                {leadsCount?.ngc ?? 0})
              </Button>
              <Button
                variant="contained"
                color={StageColorsMapping[AdminLeadStatus.N_A]}
                size="small"
                onClick={() => handleChangeFilter(AdminLeadStatus.N_A)}
              >
                N/A(
                {leadsCount?.n_A ?? 0})
              </Button>
              <Button
                variant="contained"
                color={StageColorsMapping[AdminLeadStatus.CSADECLINED]}
                size="small"
                onClick={() => handleChangeFilter(AdminLeadStatus.CSADECLINED)}
              >
                CSA Declined(
                {leadsCount?.csaDeclined ?? 0})
              </Button>
            </div>
          </div>
        );
      }
      case LeadTableType.REFERRAL: {
        return (
          <div className="row mb-5">
            <div className="col-12 d-flex table-filters flex-wrap">
              <Button
                variant="contained"
                color="darkGray"
                size="small"
                onClick={() => handleChangeFilter()}
              >
                All(
                {Object.values(leadsCount ?? {}).reduce(
                  (acc, count) => acc + count,
                  0
                )}
                )
              </Button>
              <Button
                variant="contained"
                color={StageColorsMapping[AdminLeadStatus.NEW]}
                size="small"
                onClick={() => handleChangeFilter(AdminLeadStatus.NEW)}
              >
                New({leadsCount?.new ?? 0})
              </Button>
              <Button
                variant="contained"
                color={StageColorsMapping[AdminLeadStatus.SHARED]}
                size="small"
                onClick={() => handleChangeFilter(AdminLeadStatus.SHARED)}
              >
                Shared(
                {leadsCount?.shared ?? 0})
              </Button>
              {/* <Button
                variant="contained"
                color={StageColorsMapping[AdminLeadStatus.INTRODUCTION]}
                size="small"
                onClick={() => handleChangeFilter(AdminLeadStatus.INTRODUCTION)}
              >
                Intake(
                {leadsCount?.intake ?? 0})
              </Button> */}
              <Button
                variant="contained"
                color={StageColorsMapping[AdminLeadStatus.WINNER_SELECTED]}
                size="small"
                onClick={() =>
                  handleChangeFilter(AdminLeadStatus.WINNER_SELECTED)
                }
              >
                Winner selected(
                {leadsCount?.winnerSelected ?? 0})
              </Button>
              <Button
                variant="contained"
                color={StageColorsMapping[AdminLeadStatus.SENT_FOR_SIGNING]}
                size="small"
                onClick={() =>
                  handleChangeFilter(AdminLeadStatus.SENT_FOR_SIGNING)
                }
              >
                Sent for signing(
                {leadsCount?.sentForSigning ?? 0})
              </Button>
              <Button
                variant="contained"
                color={StageColorsMapping[AdminLeadStatus.SIGNED]}
                size="small"
                onClick={() => handleChangeFilter(AdminLeadStatus.SIGNED)}
              >
                Signed(
                {leadsCount?.signed ?? 0})
              </Button>
              <Button
                variant="contained"
                color={StageColorsMapping[AdminLeadStatus.PAID]}
                size="small"
                onClick={() => handleChangeFilter(AdminLeadStatus.PAID)}
              >
                Paid(
                {leadsCount?.paid ?? 0})
              </Button>
            </div>
          </div>
        );
      }
      case LeadTableType.SHARED_ATTORNEY: {
        return (
          <div className="row mb-5">
            {isMobile ? (
              <div className="col-12 m-3">
                <FormControl>
                  <InputLabel id="type-label">Stage</InputLabel>
                  <Select
                    labelId="type-label"
                    name="role"
                    className="min-width-200"
                    label="Stage"
                    value={selectedAttorneyStage}
                    key={selectedAttorneyStage}
                    onChange={(e) => {
                      handleChangeFilter(
                        +e.target.value === 999 ? null : e.target.value,
                        "AttorneyStatus"
                      );
                    }}
                  >
                    <MenuItem key={999} value={999}>
                      All(
                      {Object.values(leadsCount ?? {}).reduce(
                        (acc, count) => acc + count,
                        0
                      )}
                      )
                    </MenuItem>
                    <MenuItem
                      key={AttorneyLeadStatus.NEW}
                      value={AttorneyLeadStatus.NEW}
                    >
                      New({leadsCount?.new ?? 0})
                    </MenuItem>
                    <MenuItem
                      key={AttorneyLeadStatus.ACCEPTED}
                      value={AttorneyLeadStatus.ACCEPTED}
                    >
                      Accepted({leadsCount?.accepted ?? 0})
                    </MenuItem>
                    <MenuItem
                      key={AttorneyLeadStatus.REJECTED}
                      value={AttorneyLeadStatus.REJECTED}
                    >
                      Rejected({leadsCount?.rejected ?? 0})
                    </MenuItem>
                    <MenuItem
                      key={AttorneyLeadStatus.LOST}
                      value={AttorneyLeadStatus.LOST}
                    >
                      Opportunities lost({leadsCount?.lost ?? 0})
                    </MenuItem>
                    <MenuItem
                      key={AttorneyLeadStatus.WON}
                      value={AttorneyLeadStatus.WON}
                    >
                      Opportunities won({leadsCount?.won ?? 0})
                    </MenuItem>
                    <MenuItem
                      key={AttorneyLeadStatus.SIGNED}
                      value={AttorneyLeadStatus.SIGNED}
                    >
                      Signed({leadsCount?.signed ?? 0})
                    </MenuItem>
                    <MenuItem
                      key={AttorneyLeadStatus.PAID}
                      value={AttorneyLeadStatus.PAID}
                    >
                      Paid({leadsCount?.paid ?? 0})
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            ) : (
              <div className="col-12 d-flex table-filters flex-wrap">
                <Button
                  variant="contained"
                  color="darkGray"
                  size="small"
                  onClick={() => handleChangeFilter(null, "AttorneyStatus")}
                >
                  All(
                  {Object.values(leadsCount ?? {}).reduce(
                    (acc, count) => acc + count,
                    0
                  )}
                  )
                </Button>
                <Button
                  variant="contained"
                  color={StageColorsMapping[AttorneyLeadStatus.NEW]}
                  size="small"
                  onClick={() =>
                    handleChangeFilter(AttorneyLeadStatus.NEW, "AttorneyStatus")
                  }
                >
                  New({leadsCount?.new ?? 0})
                </Button>
                <Button
                  variant="contained"
                  color={StageColorsMapping[AttorneyLeadStatus.ACCEPTED]}
                  size="small"
                  onClick={() =>
                    handleChangeFilter(
                      AttorneyLeadStatus.ACCEPTED,
                      "AttorneyStatus"
                    )
                  }
                >
                  Accepted({leadsCount?.accepted ?? 0})
                </Button>
                <Button
                  variant="contained"
                  color={StageColorsMapping[AttorneyLeadStatus.REJECTED]}
                  size="small"
                  onClick={() =>
                    handleChangeFilter(
                      AttorneyLeadStatus.REJECTED,
                      "AttorneyStatus"
                    )
                  }
                >
                  Rejected({leadsCount?.rejected ?? 0})
                </Button>
                {/* <Button
                variant="contained"
                color={StageColorsMapping[AttorneyLeadStatus.INTRODUCTION]}
                size="small"
                onClick={() =>
                  handleChangeFilter(
                    AttorneyLeadStatus.INTRODUCTION,
                    "AttorneyStatus"
                  )
                }
              >
                INTRODUCTION({leadsCount?.introduction ?? 0})
              </Button> */}
                <Button
                  variant="contained"
                  color={StageColorsMapping[AttorneyLeadStatus.LOST]}
                  size="small"
                  onClick={() =>
                    handleChangeFilter(
                      AttorneyLeadStatus.LOST,
                      "AttorneyStatus"
                    )
                  }
                >
                  Opportunities lost({leadsCount?.lost ?? 0})
                </Button>
                <Button
                  variant="contained"
                  color={StageColorsMapping[AttorneyLeadStatus.WON]}
                  size="small"
                  onClick={() =>
                    handleChangeFilter(AttorneyLeadStatus.WON, "AttorneyStatus")
                  }
                >
                  Opportunities won({leadsCount?.won ?? 0})
                </Button>
                <Button
                  variant="contained"
                  color={StageColorsMapping[AttorneyLeadStatus.SIGNED]}
                  size="small"
                  onClick={() =>
                    handleChangeFilter(AttorneyLeadStatus.SIGNED, "AttorneyStatus")
                  }
                >
                  Signed({leadsCount?.signed ?? 0})
                </Button>
                <Button
                  variant="contained"
                  color={StageColorsMapping[AttorneyLeadStatus.PAID]}
                  size="small"
                  onClick={() =>
                    handleChangeFilter(AttorneyLeadStatus.PAID, "AttorneyStatus")
                  }
                >
                  Paid({leadsCount?.paid ?? 0})
                </Button>
              </div>
            )}
          </div>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  const generateTitleAction = () => {
    switch (props.tableType) {
      case LeadTableType.INTAKE: {
        return (
          <div className="col-12 col-md-6 d-flex justify-content-end mb-4">
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddNewLead}
            >
              Add new lead
            </Button>
          </div>
        );
      }
      case LeadTableType.REFERRAL: {
        return (
          <div className="col-12 col-md-6 d-flex justify-content-end mb-4">
            <Button
              variant="contained"
              color="primary"
              startIcon={<SendIcon />}
              className="me-2"
              disabled={!bulkEnabled}
              onClick={() => handleSendMultipleClick(selected)}
            >
              {selected.length > 1 ? "Send Multiple Leads" : "Send Lead"}
            </Button>
          </div>
        );
      }
      default:
        return <></>;
    }
  };

  function handleSendMultipleClick(selectedRows: readonly number[]) {
    navigate(`/lead/send/${selectedRows}`);
  }

  const handleArchiveDialogClose = (success) => {
    if (!success || !actionRow) {
      setOpenArchivedDialog(false);
      return;
    }
    dispatch(startSpinner());
    let updateRequest = {
      status: selectedArchiveType,
      comment: archivedComment,
      reasonForNAEmp:
        actionRow.category === Category.EMPLOYMENT && naReason
          ? ReasonForNAEmp[naReason]
          : null,
      reasonForNAPI:
        actionRow.category === Category.PERSONAL_INJURY && naReason
          ? ReasonForNAPI[naReason]
          : null,
    } as ArchiveRequest;
    LeadsService.postApiLeadsArchive(actionRow.id, updateRequest)
      .then(() => {
        initiateLeads();
        MySwal.fire({
          icon: "success",
          title: <p className="title">Lead archived!</p>,
        }).then(() => { });
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error archiving lead</p>,
          html: <div className="alert-text">{errorInstructions}</div>,
        });
      })
      .finally(() => {
        setOpenArchivedDialog(false);
        dispatch(stopSpinner());
      });
  };

  const renderArchivedReasons = (category) => {
    switch (selectedArchiveType) {
      case AdminLeadStatus.N_A:
      case AdminLeadStatus.NGC: {
        return category === Category.EMPLOYMENT ? (
          <FormControl fullWidth>
            <InputLabel id="selected-label">Select reason*</InputLabel>
            <Select
              labelId="selected-label"
              label="Select reason"
              value={naReason}
              key={naReason}
              onChange={(e) => setNaReason(e.target.value as ReasonForNAEmp)}
            >
              {Object.keys(ReasonForNAEmp).map((reason) => {
                return (
                  <MenuItem key={reason} value={reason}>
                    {ReasonForNAEmpDisplayMapping[ReasonForNAEmp[reason]]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : (
          <FormControl fullWidth>
            <InputLabel id="selected-label">Select reason*</InputLabel>
            <Select
              labelId="selected-label"
              label="Select reason"
              value={naReason}
              key={naReason}
              onChange={(e) => setNaReason(e.target.value as ReasonForNAPI)}
            >
              {Object.keys(ReasonForNAPI).map((reason) => {
                return (
                  <MenuItem key={reason} value={reason}>
                    {ReasonForPIEmpDisplayMapping[ReasonForNAPI[reason]]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      }

      case AdminLeadStatus.CSADECLINED:
        return (
          <div className="col-12 mt-3">
            <TextField
              required
              fullWidth
              label="Comment"
              variant="outlined"
              value={archivedComment}
              onChange={(e) => setArchivedComment(e.target.value)}
            />
          </div>
        );
    }
  };

  const handleClientDenial = (row: LeadData) => {
    MySwal.fire({
      title: <strong>Action confirmation</strong>,
      html: <div>Are you sure you want to refer this client?</div>,
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "No, go back",
      confirmButtonText: "Yes, confirm",
      confirmButtonColor: "#ed633a",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startSpinner());
        var request = {
          success: false,
          declinedAction: DeclinedAction.REFER,
        } as ClientRepresentationRequest;
        LeadsService.postApiLeadsCsaAction(row.id, request)
          .then(() => {
            MySwal.fire({
              icon: "success",
              title: <p className="title">Client referred successfully.</p>,
            }).then(() => navigate("/active-clients"));
          })
          .catch(() => {
            MySwal.fire({
              icon: "error",
              title: <p className="title">Error referring client.</p>,
              html: <div className="alert-text">{errorInstructions}</div>,
            });
          })
          .finally(() => dispatch(stopSpinner()));
      }
    });
  };

  const handleReassignDialogClose = (success) => {
    if (!success || !actionRow) {
      setOpenReassignDialog(false);
      return;
    }

    dispatch(startSpinner()); LeadsService.postApiLeadsAssign(actionRow.id, selectedStaffAttorneyId)
      .then(() => {
        initiateLeads();
        MySwal.fire({
          icon: "success",
          title: <p className="title">Lead reassigned!</p>,
        }).then(() => { });
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error reassigning lead</p>,
          html: <div className="alert-text">{errorInstructions}</div>,
        });
      })
      .finally(() => { setOpenArchivedDialog(false); dispatch(stopSpinner()); });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <div className="row justify-content-between">
        <div className="col-12 col-md-3">
          <h2 className="title">{generateTitle()}</h2>
        </div>
        {generateTitleAction()}
      </div>
      {generateTableActions()}
      {user.roles?.includes(ApplicationRole.ADMIN) && (
        <div className="col-12 mt-3 mb-3">
          <FormControl>
            <InputLabel id="type-label">Practice area</InputLabel>
            <Select
              labelId="type-label"
              name="practiceArea"
              className="min-width-200"
              label="Practice area"
              onChange={(e) =>
                handleChangeFilter(e.target.value as string, "Category")
              }
            >
              {[Object.keys(Category)[2], Object.keys(Category)[0], Object.keys(Category)[1], Object.keys(Category)[3]].map(
                (key) => {
                  return (
                    <MenuItem key={key} value={Category[key]}>
                      {HelpersService.camelToString(Category[key])}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </FormControl>
        </div>
      )}
      {props.tableType !== LeadTableType.SHARED_ATTORNEY && (
        <div className="col-12 mt-3 mb-3">
          <TextField
            required
            variant="outlined"
            label="Search by name"
            className="mt-3"
            value={nameSearchValue}
            onChange={(e) => updateSearch(e.target.value)}
          />
        </div>
      )}
      <TableContainer>
        <Table aria-labelledby="tableTitle" size="medium">
          {visibleRows.length === 0 && (
            <caption>
              <div className="text-center caption-text">No leads</div>
            </caption>
          )}
          <EnhancedTableHead
            displaySelectAll={props.tableType === LeadTableType.REFERRAL}
            onSelectAllClick={handleSelectAllClick}
            headCells={generateTableHeadCells()}
            numSelected={selected.length}
            sortAsc={tableInteractions.sortAsc}
            sortBy={tableInteractions.sortBy}
            onRequestSort={handleRequestSort}
            rowCount={visibleRows.length}
            removeActions={
              props.tableType === LeadTableType.SHARED_ATTORNEY && isMobile
            }
          />
          <TableBody>
            {visibleRows
              ? visibleRows.map((row, index) => {
                return generateTableRow(row, index);
              })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={tableInteractions.rowsPerPage}
        page={tableInteractions.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        open={openArchivedDialog}
        onClose={() => handleArchiveDialogClose(false)}
      >
        <DialogContent>
          <div className="col-12 mt-3 mb-3">
            <FormControl fullWidth>
              <InputLabel id="selected-label">Select type*</InputLabel>
              <Select
                labelId="selected-label"
                name="selectedReason"
                label="Select reason"
                value={selectedArchiveType}
                key={selectedArchiveType}
                onChange={(e) =>
                  setSelectedArchiveType(e.target.value as AdminLeadStatus)
                }
              >
                <MenuItem key={AdminLeadStatus.NGC} value={AdminLeadStatus.NGC}>
                  NGC
                </MenuItem>
                <MenuItem key={AdminLeadStatus.N_A} value={AdminLeadStatus.N_A}>
                  N/A
                </MenuItem>
                <MenuItem
                  key={AdminLeadStatus.CSADECLINED}
                  value={AdminLeadStatus.CSADECLINED}
                >
                  CSA Declined
                </MenuItem>
                <MenuItem
                  key={AdminLeadStatus.CLIENT_RETAINED}
                  value={AdminLeadStatus.CLIENT_RETAINED}
                >
                  Client Retained
                </MenuItem>
              </Select>
              {!!actionRow && renderArchivedReasons(actionRow.category)}
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="gray"
            variant="contained"
            onClick={() => handleArchiveDialogClose(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={!selectedArchiveType || (selectedArchiveType !== AdminLeadStatus.CLIENT_RETAINED && !archivedComment && !naReason)}
            color="primary"
            variant="contained"
            onClick={() => handleArchiveDialogClose(true)}
          >
            Archive
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openReassignDialog}
        onClose={() => handleReassignDialogClose(false)}
      >
        <DialogContent>
          <div className="col-12 mt-3">
            <FormControl>
              <InputLabel id="selected-label">Select attorney*</InputLabel>
              <Select
                labelId="selected-label"
                className="min-width-200"
                label="Select attorney*"
                value={selectedStaffAttorneyId}
                key={selectedStaffAttorneyId}
                onChange={(e) => setSelectedStaffAttorneyId(+e.target.value)}
              >
                {staffAttorneysList.map((attorney) => {
                  return (
                    <MenuItem key={attorney.id} value={attorney.id}>
                      {attorney.firstName} {attorney.lastName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="gray"
            variant="contained"
            onClick={() => handleReassignDialogClose(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={!selectedStaffAttorneyId}
            color="primary"
            variant="contained"
            onClick={() => handleReassignDialogClose(true)}
          >
            Reassign
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
