export const SET_USER = "SET_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_USER_PHOTO = "UPDATE_USER_PHOTO";

export const updateUserPhoto = (photo) => {
  return {
    type: UPDATE_USER_PHOTO,
    payload: photo,
  };
};

export const updateUserData = (user) => {
  return {
    type: UPDATE_USER_DATA,
    payload: user,
  };
};


export const setUser = (user) => {
  return {
    type: SET_USER,
    payload: user,
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};