import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ApplicationRole, Category, RegisterLinkRequest, UsersService } from "../../api";
import { startSpinner, stopSpinner } from "../../redux/actions/spinnerActions";

import * as yup from "yup";
import { useFormik } from "formik";
import { HelpersService } from "../../services/Helpers";

const MySwal = withReactContent(Swal);
const emptyData: RegisterLinkRequest = {
  firstName: "",
  lastName: "",
  userEmail: "",
  phoneNumber: "",
  practiceArea: undefined,
  role: ApplicationRole.STAFF,
};

const schema = yup.object().shape({
  firstName: yup.string().required("firstName"),
  lastName: yup.string().required("lastName"),
  phoneNumber: yup
    .string()
    .optional()
    .test("is-valid-phone", "Invalid phone", function (value) {
      return !value || matchIsValidTel(value);
    }),
});

export default function GenerateLink() {
  const [userEmail, setUserEmail] = useState<string>();
  const [role, setRole] = useState<ApplicationRole>(ApplicationRole.USER);
  const [link, setLink] = useState<string>();
  const [useAllRoles, setUseAllRoles] = useState<boolean>();

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setUseAllRoles(queryParams.get("setAny") === "true");
  }, [location]);

  const handleLinkGenerate = () => {
    sendLink({
      userEmail: userEmail,
      role: ApplicationRole.USER,
    });
  };

  const onSubmit = (value) => {
    dispatch(startSpinner());
    sendLink({
      userEmail: userEmail,
      firstName: value.firstName,
      lastName: value.lastName,
      phoneNumber: value.phoneNumber ?? null,
      practiceArea: value.practiceArea ?? null,
      role: role,
    });
  };

  const sendLink = (request: RegisterLinkRequest) => {
    UsersService.postApiUsersRegisterLink(request)
      .then((link) => {
        setLink(link);
        MySwal.fire({
          icon: "success",
          title: <p className="title">Link was sent via email!</p>,
          html: (
            <div className="alert-text">
              You can share it through other media.
            </div>
          ),
        });
      })
      .finally(() => dispatch(stopSpinner()));
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: emptyData,
    validationSchema: schema,
    onSubmit,
  });

  return (
    <div className="row main-holder">
      <h4>User will be created with information that you provided here.</h4>
      <div className="col-6 mt-3">
        <TextField
          required
          label="Email"
          variant="outlined"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value.trim())}
        />
      </div>

      {useAllRoles ? (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-6 mt-3">
              <TextField
                required
                label="First name"
                variant="outlined"
                name="firstName"
                value={values.firstName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            <div className="col-6 mt-3">
              <TextField
                required
                label="Last name"
                variant="outlined"
                name="lastName"
                value={values.lastName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            <div className="col-6 mt-3">
              <MuiTelInput
                label="Phone"
                variant="outlined"
                name="phoneNumber"
                className={
                  !!errors.phoneNumber && !!touched.phoneNumber ? "error" : ""
                }
                onlyCountries={["US"]}
                defaultCountry={"US"}
                disableDropdown
                value={values.phoneNumber ?? undefined}
                onBlur={handleBlur}
                onChange={(newValue) => {
                  setFieldValue("phoneNumber", newValue);
                }}
              />
              {!!errors.phoneNumber && !!touched.phoneNumber && (
                <FormHelperText className="error-message">
                  {errors.phoneNumber}
                </FormHelperText>
              )}
            </div>
            <div className="col-6 mt-3">
              <FormControl>
                <InputLabel id="type-label">Role</InputLabel>
                <Select
                  labelId="type-label"
                  name="role"
                  className="min-width-200"
                  label="Role"
                  value={role}
                  onChange={(e) => setRole(e.target.value as ApplicationRole)}
                >
                  {[
                    HelpersService.getEnumKeyByEnumValue(ApplicationRole,ApplicationRole.ADMIN),
                    HelpersService.getEnumKeyByEnumValue(ApplicationRole,ApplicationRole.STAFF),
                    HelpersService.getEnumKeyByEnumValue(ApplicationRole,ApplicationRole.STAFF_ATTORNEY),
                    HelpersService.getEnumKeyByEnumValue(ApplicationRole,ApplicationRole.CLIO_STAFF),
                    HelpersService.getEnumKeyByEnumValue(ApplicationRole,ApplicationRole.CLIO_ADMIN),
                  ].map((key) => {
                    return (
                      <MenuItem key={key} value={ApplicationRole[key]}>
                        {ApplicationRole[key]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 mt-3">
              <FormControl>
                <InputLabel id="type-label">Practice area</InputLabel>
                <Select
                  labelId="type-label"
                  name="practiceArea"
                  className="min-width-200"
                  label="Practice area"
                  value={values.practiceArea}
                  key={values.practiceArea}
                  onChange={handleChange}
                >
                  {Object.keys(Category).map((key) => {
                    return (
                      <MenuItem key={key} value={Category[key]}>
                        {HelpersService.camelToString(Category[key])}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="col-4 mt-3">
            <Button
              disabled={!userEmail}
              variant="contained"
              color="primary"
              className="full-btn"
              type="submit"
            >
              Generate and send link
            </Button>
          </div>
        </form>
      ) : (
        <div className="col-4 mt-3">
          <Button
            disabled={!userEmail}
            variant="contained"
            color="primary"
            className="full-btn"
            onClick={handleLinkGenerate}
          >
            Generate and send link
          </Button>
        </div>
      )}
      <div className="col-10 mt-3">
        <TextField
          label="Link"
          variant="outlined"
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />
      </div>
    </div>
  );
}
