/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ContactReason {
    DISCRIMINATION = 'Discrimination',
    HARASSMENT = 'Harassment',
    RETALIATION = 'Retaliation',
    BREACH_OF_CONTRACT = 'BreachOfContract',
    CLASS_ACTION = 'ClassAction',
}
