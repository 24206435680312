/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PaidWithheld {
    WEEKLY_OVERTIME = 'WeeklyOvertime',
    DAILY_OVERTIME = 'DailyOvertime',
    DOUBLE_TIME = 'DoubleTime',
    TRAVEL_TIME = 'TravelTime',
    REPORTING_TIME = 'ReportingTime',
    STANDBY_TIME = 'StandbyTime',
    PREP_TIME = 'PrepTime',
    TRAINING_TIME = 'TrainingTime',
    ON_CALL_TIME = 'OnCallTime',
    BONUS = 'Bonus',
    UNUSED_VACATION = 'UnusedVacation',
    SICK_LEAVE = 'SickLeave',
    GRATUITIES = 'Gratuities',
    OTHER = 'Other',
}
