/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AdminLeadStatus {
    NEW = 'New',
    SHARED = 'Shared',
    INTRODUCTION = 'Introduction',
    WINNER_SELECTED = 'WinnerSelected',
    SENT_FOR_SIGNING = 'SentForSigning',
    SIGNED = 'Signed',
    CLIENT_REFERED = 'ClientRefered',
    DECLINED = 'Declined',
    NGC = 'NGC',
    CONTRACT_SENT = 'ContractSent',
    CONTRACT_SIGNED = 'ContractSigned',
    SUPPLEMENTAL_ASSIGNED = 'SupplementalAssigned',
    DONE = 'Done',
    CLIENT_RETAINED = 'ClientRetained',
    DRAFT = 'Draft',
    N_A = 'N_A',
    CSADECLINED = 'CSADeclined',
    PAID = 'Paid',
}
