import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import {
  ApplicationRole,
  Category,
  FileType,
  OpenAPI,
  UserCreateRequest,
  UsersService,
  UserViewModel,
} from "../../../../api";
import EducationTable from "../../../../components/basic/EducationTable";
import { HelpersService } from "../../../../services/Helpers";

import * as yup from "yup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { useDispatch } from "react-redux";
import {
  startSpinner,
  stopSpinner,
} from "../../../../redux/actions/spinnerActions";
import axios from "axios";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import {
  updateUserData,
  updateUserPhoto,
} from "../../../../redux/actions/userActions";

const MySwal = withReactContent(Swal);

interface UserFormProps {
  user?: UserViewModel;
  isEdit?: boolean;
  onSave?: Function;
  isNew?: boolean;
  isAttorney?: boolean;
  updateStored?: boolean;
}
const emptyData: UserViewModel = {
  firstName: "Brendan",
  lastName: "Way",
  phoneNumber: "+123456",
  city: "",
  state: "",
  email: "brendan@way.com",
  bio: "Mr. Shahabi is the Senior Managing Attorney at JLG Lawyers and has spent his entire legal career of over 20 years as an employment law attorney.  Mr. Shahabi’s practice is primarily dedicated to the representation of employees in workplace disputes, and he represents employees in wrongful termination, discrimination, and harassment actions, as well as individual and class wage and hour matters.",
  companyName: "Law Group",
  address: "San Francisco, CA",
  practiceArea: Category.EMPLOYMENT,
  awardsAchievements: ["Top 10 2015"],
  barAdmissionYear: 2000,
  barAndTradeOrgs: [
    "California Employment Lawyers Association, Member",
    "Consumer Attorneys Association of Los Angeles, Member",
  ],
  yearsOfPractice: 20,
};
const attorneySchema = yup.object().shape({
  firstName: yup.string().required("firstName"),
  lastName: yup.string().required("lastName"),
  email: yup.string().email().required("email"),
  phoneNumber: yup
    .string()
    .required("phone")
    .test("is-valid-phone", "Invalid phone", function (value) {
      return matchIsValidTel(value);
    }),
  companyName: yup.string().required(),
  practiceArea: yup.string().required(),
  state: yup.string().required(),
  bio: yup.string().required(),
  barAdmissionYear: yup.number().required(),
  education: yup.array().min(1, "at least 1").required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), undefined], "Passwords must match"),
});

const userSchema = yup.object().shape({
  firstName: yup.string().required("firstName"),
  lastName: yup.string().required("lastName"),
  email: yup.string().email().required("email"),
  phoneNumber: yup
    .string()
    .required("phone")
    .test("is-valid-phone", "Invalid phone", function (value) {
      return matchIsValidTel(value);
    }),
});

const barAndTradeOrgs = ["Law organization", "South Trade Organization"];

export default function UserForm(props: UserFormProps) {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [initImage, setInitImage] = useState<string>("./DefaultAvatar.svg");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const inputFileRef = useRef<HTMLInputElement>(null);
  const inputAwardRef = useRef<HTMLInputElement>(null);
  const inputOrgsRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!props.user?.photo?.url) {
      setInitImage(props.user?.photo?.url);
    }
  }, [props.user?.photo]);

  const onSubmit = async (user) => {
    dispatch(startSpinner());
    if (!user.id) {
      user.roles = [ApplicationRole.ATTORNEY];
      UsersService.postApiUsersRegister(user as UserCreateRequest)
        .then(async (response) => {
          if (response.success) {
            if (!!selectedImage) {
              const formData = new FormData();
              formData.append(`files`, selectedImage);
              const url = `${OpenAPI.BASE}/api/File/${response.userId}/upload`;
              await axios
                .post(url, formData, {
                  params: {
                    type: FileType.ACCOUNT_PHOTO,
                  },
                })
                .catch(() => {
                  MySwal.fire({
                    icon: "error",
                    title: <p className="title">Error uploading file.</p>,
                  });
                });
            }
            MySwal.fire({
              icon: "success",
              title: <p className="title">Attorney created</p>,
            }).then(() => navigate("/attorneys"));
          } else {
            MySwal.fire({
              icon: "error",
              title: <p className="title">Error creating attorney</p>,
              html: <div className="alert-text">{response.message}</div>,
            });
          }
        })
        .finally(() => dispatch(stopSpinner()));
      return;
    }
    UsersService.putApiUsers(user.id, user as UserViewModel)
      .then((updatedUser) => {
        if (props.updateStored) {
          dispatch(updateUserData(updatedUser));
        }
        MySwal.fire({
          icon: "success",
          title: <p className="title">User data updated</p>,
        });
        if (props.onSave != null) {
          props.onSave();
        }
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error updating user data</p>,
          html: (
            <div className="alert-text">
              Please make sure that all fields are valid. If error remains,
              please contact us.
            </div>
          ),
        });
      })
      .finally(() => {
        dispatch(stopSpinner());
      });

    if (!!selectedImage) {
      dispatch(startSpinner());
      const formData = new FormData();
      formData.append(`files`, selectedImage);
      const url = `${OpenAPI.BASE}/api/File/${user.id}/upload`;
      await axios
        .post(url, formData, {
          params: {
            type: FileType.ACCOUNT_PHOTO,
          },
        })
        .then((response) => {
          if (props.updateStored) {
            dispatch(updateUserPhoto(response.data));
          }
        })
        .catch(() => {
          MySwal.fire({
            icon: "error",
            title: <p className="title">Error uploading file.</p>,
          });
        })
        .finally(() => dispatch(stopSpinner()));
    }
  };

  const onFileClick = () => {
    inputFileRef.current?.click();
  };

  const educationUpdate = (educationRows) => {
    handleChange({
      target: {
        value: educationRows,
        name: "education",
      },
    });
  };

  const handlePhotoUpdate = async (file: File | null) => {
    setSelectedImage(file);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = useFormik({
    initialValues: { ...props.user, password: "", confirmPassword: "" } ?? {
      ...emptyData,
      password: "",
      confirmPassword: "",
    },
    validationSchema: props.isAttorney ? attorneySchema : userSchema,
    onSubmit,
  });

  resetFormWith = (values: UserViewModel) => {
    resetForm({ values: { ...values, password: "", confirmPassword: "" } });
  };

  return (
    <form onSubmit={handleSubmit}>
      <fieldset disabled={!props.isEdit}>
        <div className="attorney-picture-holder col-12">
          <div className="attorney-picture">
            <div>
              <img
                alt="not found"
                width={"250px"}
                height={"250px"}
                src={
                  selectedImage ? URL.createObjectURL(selectedImage) : initImage
                }
              />
              <br />
              {selectedImage && (
                <Button
                  variant="contained"
                  color="secondary"
                  className="full-btn"
                  onClick={() => setSelectedImage(null)}
                >
                  Remove
                </Button>
              )}
            </div>

            <input
              type="file"
              name="picture"
              ref={inputFileRef}
              onChange={(event) => {
                handlePhotoUpdate(
                  event?.target?.files ? event.target.files[0] : null
                );
              }}
            />
            {props.isEdit && (
              <Button
                variant="contained"
                color="primary"
                className="full-btn"
                onClick={onFileClick}
              >
                Choose file
              </Button>
            )}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6 col-12 mt-3">
            <TextField
              required
              disabled={
                props.updateStored && props.user?.roles?.includes(ApplicationRole.STAFF)
              }
              label="First name"
              variant="outlined"
              name="firstName"
              inputProps={{
                form: {
                  autocomplete: "off",
                },
              }}
              value={values.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 col-12 mt-3">
            <TextField
              required
              disabled={
                props.updateStored && props.user?.roles?.includes(ApplicationRole.STAFF)
              }
              label="Last name"
              variant="outlined"
              name="lastName"
              inputProps={{
                form: {
                  autocomplete: "off",
                },
              }}
              value={values.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6 col-12 mt-3">
            <MuiTelInput
              required
              disabled={
                props.updateStored && props.user?.roles?.includes(ApplicationRole.STAFF)
              }
              label="Phone"
              variant="outlined"
              name="phoneNumber"
              className={
                !!errors.phoneNumber && !!touched.phoneNumber ? "error" : ""
              }
              inputProps={{
                form: {
                  autocomplete: "off",
                },
              }}
              onlyCountries={["US"]}
              defaultCountry={"US"}
              disableDropdown
              value={values.phoneNumber ?? undefined}
              onBlur={handleBlur}
              onChange={(newValue) => {
                setFieldValue("phoneNumber", newValue);
              }}
            />
            {!!errors.phoneNumber && !!touched.phoneNumber && (
              <FormHelperText className="error-message">
                {errors.phoneNumber.toString()}
              </FormHelperText>
            )}
          </div>
          <div className="col-md-6 col-12 mt-3">
            <TextField
              required
              disabled={props.updateStored}
              label="Email"
              variant="outlined"
              name="email"
              value={values.email}
              inputProps={{
                form: {
                  autocomplete: "off",
                },
              }}
              onBlur={handleBlur}
              onChange={(e) => setFieldValue("email", e.target.value.trim())}
            />
          </div>
        </div>
        {props.isNew && (
          <div className="row mt-4">
            <div className="col-md-6 col-12 mt-3">
              <TextField
                required
                label="Password"
                variant="outlined"
                name="password"
                autoComplete="off"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-12 mt-3">
              <TextField
                required
                label="Confirm password"
                variant="outlined"
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPassword((show) => !show)}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={values.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
          </div>
        )}
        {props.isAttorney && (
          <>
            <div className="row mt-4">
              <div className="col-md-6 col-12 mt-3">
                <TextField
                  required
                  error={!!errors.companyName && !!touched.companyName}
                  label="Law firm"
                  variant="outlined"
                  name="companyName"
                  value={values.companyName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!errors.companyName && !!touched.companyName && (
                  <FormHelperText>Required</FormHelperText>
                )}
              </div>
              <div className="col-md-6 col-12 mt-3">
                <FormControl
                  required
                  disabled={!props.isEdit}
                  error={!!errors.practiceArea && !!touched.practiceArea}
                  variant="outlined"
                  sx={{ m: 1, minWidth: 120 }}
                >
                  <InputLabel id="practice-label">Practice area</InputLabel>
                  <Select
                    labelId="practice-label"
                    label="Practice area"
                    name="practiceArea"
                    value={values.practiceArea}
                    onChange={handleChange}
                  >
                    {Object.keys(Category).map((key) => {
                      return (
                        <MenuItem key={key} value={Category[key]}>
                          {HelpersService.camelToString(Category[key])}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {!!errors.practiceArea && !!touched.practiceArea && (
                    <FormHelperText>Required</FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6 col-12 mt-3">
                <TextField
                  required
                  error={!!errors.state && !!touched.state}
                  label="State"
                  variant="outlined"
                  name="state"
                  value={values.state}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!errors.state && !!touched.state && (
                  <FormHelperText>Required</FormHelperText>
                )}
              </div>
              <div className="col-md-6 col-12 mt-3">
                <TextField
                  label="City"
                  variant="outlined"
                  name="city"
                  value={values.city}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 mb-4">
                <TextField
                  className="multiline"
                  required
                  error={!!errors.bio && !!touched.bio}
                  multiline
                  label="Bio"
                  variant="outlined"
                  name="bio"
                  value={values.bio}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!errors.bio && !!touched.bio && (
                  <FormHelperText>Required</FormHelperText>
                )}
              </div>
              <div className="col-12 mt-3">
                {props.isEdit ? (
                  <Autocomplete
                    multiple
                    options={[]}
                    freeSolo
                    autoSelect
                    disableCloseOnSelect
                    value={
                      values.awardsAchievements !== null
                        ? values.awardsAchievements
                        : undefined
                    }
                    onChange={(event: any, newValue: string[]) => {
                      handleChange({
                        target: {
                          value: newValue,
                          name: "awardsAchievements",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Honors and awards"
                        inputRef={inputAwardRef}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {inputAwardRef.current &&
                                inputAwardRef.current.value.length > 0 ? (
                                <div
                                  style={{
                                    marginLeft: "8px",
                                    color: "#888",
                                  }}
                                >
                                  Press Enter to start a new award
                                </div>
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                ) : (
                  <>
                    {values.awardsAchievements && (
                      <>
                        <h5>Honors and awards</h5>
                        <ul>
                          {values.awardsAchievements.map((bar) => {
                            return <li key={bar}>{bar}</li>;
                          })}
                        </ul>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 mt-3">
                <TextField
                  required
                  type="number"
                  placeholder="1990"
                  error={
                    !!errors.barAdmissionYear && !!touched.barAdmissionYear
                  }
                  label="Year of Bar Admission"
                  variant="outlined"
                  name="barAdmissionYear"
                  value={values.barAdmissionYear}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!errors.barAdmissionYear && !!touched.barAdmissionYear && (
                  <FormHelperText>Required</FormHelperText>
                )}
              </div>
              <div className="col-12 mt-4">
                {props.isEdit ? (
                  <Autocomplete
                    multiple
                    options={barAndTradeOrgs}
                    freeSolo
                    autoSelect
                    disableCloseOnSelect
                    value={
                      values.barAndTradeOrgs !== null
                        ? values.barAndTradeOrgs
                        : undefined
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    onChange={(event: any, newValue: string[]) => {
                      handleChange({
                        target: {
                          value: newValue,
                          name: "barAndTradeOrgs",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Bar and Trade organizations"
                        placeholder="Other"
                        inputRef={inputOrgsRef}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {inputOrgsRef.current &&
                                inputOrgsRef.current.value.length > 0 ? (
                                <div
                                  style={{
                                    marginLeft: "8px",
                                    color: "#888",
                                  }}
                                >
                                  Press Enter to start a new organization
                                </div>
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                ) : (
                  <>
                    {values.barAndTradeOrgs && (
                      <>
                        <h5>Bar and Trade organizations</h5>
                        <ul>
                          {values.barAndTradeOrgs.map((bar) => {
                            return <li key={bar}>{bar}</li>;
                          })}
                        </ul>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 mt-3">
                <h5>Education</h5>
                <EducationTable
                  initValues={values.education ?? null}
                  isEdit={props.isEdit}
                  onChange={educationUpdate}
                ></EducationTable>
              </div>
            </div>
          </>
        )}

        <div className="row btn-holder mt-4">
          {(props.isNew || props.isEdit) && (
            <>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="submit-btn"
                onClick={() => console.log(errors)}
              >
                {props.isNew ? "Submit" : "Save changes"}
              </Button>
              {!!errors.education && <div className="error-message">Valid education is required</div>}
            </>
          )}
        </div>
      </fieldset>
    </form>
  );
}
var resetFormWith;
UserForm.resetFormWith = (value?) => {
  resetFormWith(value);
};
