import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@mui/material";
import "react-tabulator/lib/styles.css";
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";
import {
  ColumnDefinition,
  ReactTabulator,
  ReactTabulatorOptions,
} from "react-tabulator";
import { useEffect, useState } from "react";
import {
  ReportRequest,
  LeadsService,
  ReportTypes,
  LeadsUserReport,
  LeadsDateReport,
  ClioService,
  UsersModel,
  ApplicationRole,
  OpenAPI,
  ReportModel,
} from "../../api";
import { DatePicker } from "@mui/x-date-pickers";
import { TabsHelpers } from "../../components/basic/TabsHelpers";
import {
  AllSelection,
  Presets,
  ReportDetails,
  ReportSource,
  ReportsData,
} from "./ReportsOptions";
import { HelpersService } from "../../services/Helpers";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { startSpinner, stopSpinner } from "../../redux/actions/spinnerActions";
import { DateTime } from "luxon";
import "./Reports.scss";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useParams } from "react-router-dom";

const MySwal = withReactContent(Swal);

interface ReportsProps {
  source: ReportSource;
}

export default function Reports(props: ReportsProps) {
  const [tab, setTab] = useState<number>(0);
  const [tableData, setTableData] = useState<
    LeadsUserReport[] | LeadsDateReport[] | ReportModel[]
  >([]);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [selectedPreset, setSelectedPreset] = useState<any>("Month");
  const [selectedReportData, setSelectedReportData] = useState<ReportDetails>();
  const [usersList, setUsersList] = useState<UsersModel[]>([]);
  const [selectedUser, setSelectedUser] = useState<string | null | undefined>(
    null
  );
  const [selectedReport, setSelectedReport] = useState<ReportTypes>();
  let table;
  const dispatch = useDispatch();
  const { reportType } = useParams();

  const zoomPhoneKey = "ZOOM_EXT_ID";
  const phoneId = localStorage.getItem(zoomPhoneKey);
  const user = useSelector((state: any) => state.user?.user);
  const userAdminRoles = [
    ApplicationRole.ADMIN,
    ApplicationRole.CLIO_ADMIN,
    ApplicationRole.CLIO_MANAGER,
  ];

  // @ts-ignore
  if (!window.DateTime) {
    // @ts-ignore
    window.DateTime = DateTime;
  }
  // @ts-ignore
  if (!window.luxon) {
    // @ts-ignore
    window.luxon = DateTime;
  }

  useEffect(() => {
    ClioService.getApiClioClioUsers().then((users) => {
      setUsersList(users);
    });
  }, [props.source]);

  useEffect(() => {
    if (!reportType) {
      return;
    }
    setSelectedReport(reportType as ReportTypes);
  }, [reportType]);

  useEffect(() => {
    setTableData([]);
    if (!selectedReport) {
      return;
    }
    setSelectedPreset("Month");
    if(selectedReport == ReportTypes.SOLES){
      setStartDate(null);
      setEndDate(null);
    }
    else if (
      selectedReport == ReportTypes.HOURS ||
      selectedReport == ReportTypes.MATTER_STAGE_CHANGE
    ) {
      setStartDate(dayjs().startOf("month"));
      setEndDate(dayjs(new Date()));
    } else {
      clearData();
    }
    setSelectedReportData(
      ReportsData.find((data) => data.type == selectedReport)
    );
  }, [selectedReport]);

  const clearData = () => {
    setEndDate(
      reportType == ReportTypes.SETTLEMENT_LIT ||
        reportType == ReportTypes.SETTLEMENT_PRELIT
        ? null
        : dayjs(new Date())
    );
    setStartDate(null);
    setSelectedPreset(AllSelection);
    setSelectedUser(null);
  };

  const handleGenerate = async () => {
    table.current.download("csv", "export.csv", {});
  };

  const updatePropertyNames = (data) => {
    return data.map((item) => {
      const updatedItem = { ...item };

      //user report
      if (updatedItem.leadsByStatus) {
        updatedItem["_children"] = updatePropertyNames(
          updatedItem.leadsByStatus
        );
        delete updatedItem.leadsByStatus;
      }
      if (updatedItem.leadsByCategory) {
        updatedItem["_children"] = updatePropertyNames(
          updatedItem.leadsByCategory
        );
        delete updatedItem.leadsByCategory;
      }

      if (updatedItem.leads) {
        updatedItem["_children"] = updatePropertyNames(updatedItem.leads);
        delete updatedItem.leads;
      }

      if (updatedItem.createdAt) {
        updatedItem.createdAt = new Date(
          updatedItem.createdAt
        ).toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        });
      }
      if (updatedItem.completedAt) {
        updatedItem.completedAt =
          updatedItem.completedAt == "N/A"
            ? "N/A"
            : new Date(updatedItem.completedAt).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              });
      }
      if (updatedItem.lastChangeAt) {
        updatedItem.lastChangeAt =
          updatedItem.lastChangeAt == "N/A"
            ? "N/A"
            : new Date(updatedItem.lastChangeAt).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              });
      }

      //Clio
      if (updatedItem.level4Currency) {
        updatedItem.level4Currency = `${
           isNaN(+updatedItem.level4Currency)
            ? "N/A"
            : new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(+updatedItem.level4Currency)
        }`;
      }
      if (
        (reportType == ReportTypes.SETTLEMENT_LIT ||
          reportType == ReportTypes.SETTLEMENT_PRELIT) &&
        updatedItem.totalClio
      ) {
        updatedItem.totalClio = `${
          updatedItem.totalClio == "N/A"
            ? "N/A"
            : new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(updatedItem.totalClio)
        }`;
      }

      if (!!updatedItem.total && !!updatedItem.totalClio) {
        updatedItem.totalHours = `${updatedItem.totalHours} || ${updatedItem.totalClioHours}`;
      }

      if (updatedItem.reportLevel2) {
        updatedItem["_children"] = updatePropertyNames(
          updatedItem.reportLevel2
        );
        delete updatedItem.reportLevel2;
      }
      if (updatedItem.reportLevel3) {
        updatedItem["_children"] = updatePropertyNames(
          updatedItem.reportLevel3
        );
        delete updatedItem.reportLevel3;
      }
      if (updatedItem.reportLevel4) {
        updatedItem["_children"] = updatePropertyNames(
          updatedItem.reportLevel4
        );
        delete updatedItem.reportLevel4;
      }
      if (updatedItem.reportLevel5) {
        updatedItem["_children"] = updatePropertyNames(
          updatedItem.reportLevel5
        );
        delete updatedItem.reportLevel5;
      }
      if (updatedItem.reportLevel6) {
        updatedItem["_children"] = updatePropertyNames(
          updatedItem.reportLevel6
        );
        delete updatedItem.reportLevel6;
      }

      return updatedItem;
    });
  };

  const loadData = () => {
    if (selectedReport === ReportTypes.EMPTY_MATTERS) {
      dispatch(startSpinner());
      ClioService.getApiClioEmptyMattersReport()
        .then((report) => {
          if (!!report) {
            setTableData(report);
            console.log(report);
          }
        })
        .catch(() =>
          MySwal.fire({
            icon: "error",
            title: (
              <p className="title">
                Error loading data. Please make sure you selected your user in
                Zoom Integration and try again in a couple minutes.
              </p>
            ),
          })
        )
        .finally(() => dispatch(stopSpinner()));
      return;
    }
    if (
      selectedReport === ReportTypes.OVERVIEW &&
      user?.roles?.filter((role) => userAdminRoles.includes(role)).length !==
        0 &&
      selectedUser === null
    ) {
      MySwal.fire({
        icon: "error",
        title: <p className="title">Please select staff</p>,
      });
      return;
    } else if (
      selectedReport === ReportTypes.OVERVIEW &&
      user?.roles?.filter((role) => userAdminRoles.includes(role)).length ===
        0 &&
      !phoneId
    ) {
      MySwal.fire({
        icon: "error",
        title: (
          <p className="title">Please set up Zoom before accessing reports</p>
        ),
      });
      return;
    }
    dispatch(startSpinner());
    var reportRequest = {
      type: selectedReport,
      startDate: startDate,
      selectedStaff:
        selectedUser !== AllSelection
          ? usersList.find((user) => user.name === selectedUser)
          : null,
      endDate: endDate,
    } as ReportRequest;
    if (props.source === ReportSource.Leads) {
      LeadsService.postApiLeadsLeadsReport(reportRequest)
        .then((report) => {
          if (!!report) {
            setTableData(report.leadsByUser ?? report.leadsByDate ?? []);
          }
        })
        .catch(() =>
          MySwal.fire({
            icon: "error",
            title: <p className="title">Error loading data</p>,
          })
        )
        .finally(() => dispatch(stopSpinner()));
    } else {
      reportRequest.zoomId = phoneId;
      ClioService.postApiClioUserReport(reportRequest)
        .then((report) => {
          if (!!report) {
            setTableData(report);
            console.log(report);
          }
        })
        .catch((error) => {
          console.log(error);
          MySwal.fire({
            icon: "error",
            title: <p className="title">Error loading data</p>,
          });
        })
        .finally(() => dispatch(stopSpinner()));
    }
  };

  const handlePresetChange = (event) => {
    setSelectedPreset(event.target.value);
    const currentDate = dayjs();
    let selectedDate;
    let endDate = dayjs(new Date());

    switch (event.target.value) {
      case "LastMonth":
        selectedDate = currentDate.startOf("month").add(-1, "month");
        endDate = currentDate.startOf("month").add(-1, "day");
        break;
      case "Month":
        selectedDate = currentDate.startOf("month");
        break;
      case "Week":
        selectedDate = currentDate.startOf("week");
        break;
      case "Day":
        selectedDate = currentDate.startOf("day");
        break;
      default:
        selectedDate = null;
        break;
    }
    setStartDate(selectedDate);
    setEndDate(endDate);
  };

  const tableOptions: ReactTabulatorOptions = {
    dataTree: true,
    height: 1000,
  };

  const DateRangeSelect = () => {
    return (
      <>
        <h4>Select date range</h4>
        <div className="col-3 mb-3">
          <DatePicker
            disableFuture={reportType !== ReportTypes.SOLES}
            label="Start date"
            className="me-3 w-100"
            value={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </div>
        <div className="col-3">
          <DatePicker
            disableFuture={reportType !== ReportTypes.SOLES}
            label="End date"
            className="w-100"
            value={endDate}
            onChange={(date) => setEndDate(date)}
          />
        </div>
        <div className="col-3">
          <FormControl className="w-100">
            <InputLabel>Select preset</InputLabel>
            <Select
              className="min-width-200"
              value={selectedPreset ?? "All time"}
              key={selectedPreset}
              label="Select preset"
              onChange={handlePresetChange}
            >
              <MenuItem key={0} value={AllSelection}>
                Select All
              </MenuItem>
              {Object.keys(Presets)
                .filter((key) => isNaN(Number(key)))
                .map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {HelpersService.getEnumKeyByEnumValue(
                        Presets,
                        Presets[key]
                      )}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      </>
    );
  };

  const AddSelect = (hideAll: boolean = false) => {
    return (
      <div className="col-3">
        <FormControl className="w-100">
          <InputLabel id="type-label" required>
            Select User
          </InputLabel>
          <Select
            labelId="type-label"
            className="min-width-200"
            label="Select attorney*"
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
          >
            {!hideAll && (
              <MenuItem key={0} value={AllSelection}>
                Select All
              </MenuItem>
            )}
            {usersList.map((user) => {
              return (
                <MenuItem key={user.id} value={user.name!}>
                  {user.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    );
  };

  const GenerateTab = () => {
    if (!selectedReportData) {
      return;
    }
    return (
      <div className="row">
        <div className="row mt-3 pt-3 pb-3">
          {!!selectedReportData.showDateRange && <DateRangeSelect />}
          {selectedReport === ReportTypes.OVERVIEW &&
            user?.roles?.filter((role) => userAdminRoles.includes(role))
              .length > 0 && <>{AddSelect(true)}</>}
          {(selectedReport === ReportTypes.TASKS ||
            selectedReport === ReportTypes.HOURS ||
            selectedReport === ReportTypes.SETTLEMENT_PRELIT ||
            selectedReport === ReportTypes.SETTLEMENT_LIT) &&
            user?.roles?.filter((role) => userAdminRoles.includes(role))
              .length > 0 && <>{AddSelect()}</>}
        </div>
        <div className="row ms-1 pb-3">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="submit-btn col-3"
            onClick={() => loadData()}
          >
            Load report
          </Button>
          {selectedReport === ReportTypes.TASKS &&
            user?.roles?.filter((role) => userAdminRoles.includes(role))
              .length > 0 && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  className="submit-btn col-3 ms-3"
                  onClick={DownloadActivitiesReport}
                >
                  Download Full Report
                </Button>
              </>
            )}
          {selectedReport === ReportTypes.LEADS_ATTORNEY &&
            user.roles.includes(ApplicationRole.ADMIN) && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  className="submit-btn col-3 ms-3"
                  onClick={DownloadAttorneyLeadsReport}
                >
                  Download Report
                </Button>
              </>
            )}
          {selectedReport === ReportTypes.KPI &&
            user?.roles?.filter((role) => userAdminRoles.includes(role))
              .length > 0 && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  className="submit-btn col-3 ms-3"
                  onClick={DownloadKPIReport}
                >
                  Download Report
                </Button>
              </>
            )}
          {selectedReport === ReportTypes.EMPTY_MATTERS &&
            user?.roles?.filter((role) => userAdminRoles.includes(role))
              .length > 0 && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  className="submit-btn col-3 ms-3"
                  onClick={DownloadEmptyMatters}
                >
                  Download Report
                </Button>
              </>
            )}
            {selectedReport === ReportTypes.SOLES &&
            user?.roles?.filter((role) => userAdminRoles.includes(role))
              .length > 0 && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  className="submit-btn col-3 ms-3"
                  onClick={DownloadMontlySoles}
                >
                  Download Monthly Report
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  className="submit-btn col-3 ms-3"
                  onClick={DownloadAttorneySoles}
                >
                  Download By Attorney Report
                </Button>
              </>
            )}
            {selectedReport === ReportTypes.HOURS &&
            user?.roles?.filter((role) => userAdminRoles.includes(role))
              .length > 0 && (
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  className="submit-btn col-3 ms-3"
                  onClick={DownloadHourly}
                >
                  Download Report
                </Button>
            )}
        </div>
        <div id="table-container">
          <ReactTabulator
            onRef={(ref) => (table = ref)}
            columns={selectedReportData.columns}
            data={updatePropertyNames(tableData)}
            options={tableOptions}
            events={
              {
                //rowClick: onRowClick,
              }
            }
            className={`css-class-${selectedReport}`}
          />
        </div>
      </div>
    );
  };

  const DownloadActivitiesReport = () => {
    dispatch(startSpinner());
    fetch(`${OpenAPI.BASE}/api/File/downloadUsersTasksReport`, {
      method: "GET",
      headers: {
        Authorization: (OpenAPI.HEADERS as any)?.Authorization,
      },
    })
      .then((response: any) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `UserTasks.xlsx`;
          document.body.appendChild(a);
          a.click();
        });
      })
      .finally(() => dispatch(stopSpinner()));
  };

  const DownloadAttorneyLeadsReport = () => {
    dispatch(startSpinner());
    var reportRequest = {
      type: selectedReport,
      startDate: startDate,
      endDate: endDate,
    } as ReportRequest;
    fetch(`${OpenAPI.BASE}/api/File/getAttorneyLeadsReport`, {
      method: "POST",
      headers: {
        Authorization: (OpenAPI.HEADERS as any)?.Authorization,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reportRequest),
    })
      .then((response: any) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `RejectedLeadsByAttorney${startDate}--${endDate}.xlsx`;
          document.body.appendChild(a);
          a.click();
        });
      })
      .finally(() => dispatch(stopSpinner()));
  };
  const DownloadKPIReport = () => {
    dispatch(startSpinner());
    var reportRequest = {
      type: selectedReport,
      startDate: startDate,
      endDate: endDate,
    } as ReportRequest;
    fetch(`${OpenAPI.BASE}/api/File/getKPIReport`, {
      method: "POST",
      headers: {
        Authorization: (OpenAPI.HEADERS as any)?.Authorization,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reportRequest),
    })
      .then((response: any) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `KPI-report-${startDate}--${endDate}.xlsx`;
          document.body.appendChild(a);
          a.click();
        });
      })
      .finally(() => dispatch(stopSpinner()));
  };

  const DownloadEmptyMatters = () => {
    dispatch(startSpinner());
    fetch(`${OpenAPI.BASE}/api/File/getMattersWithOldChanges`, {
      method: "GET",
      headers: {
        Authorization: (OpenAPI.HEADERS as any)?.Authorization,
        "Content-Type": "application/json",
      },
    })
      .then((response: any) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `EmptyMatters.xlsx`;
          document.body.appendChild(a);
          a.click();
        });
      })
      .finally(() => dispatch(stopSpinner()));
  };

  const DownloadMontlySoles = () => {
    dispatch(startSpinner());
    var reportRequest = {
      type: selectedReport,
      startDate: startDate,
      endDate: endDate,
    } as ReportRequest;
    fetch(`${OpenAPI.BASE}/api/File/getSolesMonthReport`, {
      method: "POST",
      headers: {
        Authorization: (OpenAPI.HEADERS as any)?.Authorization,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reportRequest),
    })
      .then((response: any) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `SolesMontly.xlsx`;
          document.body.appendChild(a);
          a.click();
        });
      })
      .finally(() => dispatch(stopSpinner()));
  };

  const DownloadAttorneySoles = () => {
    dispatch(startSpinner());
    var reportRequest = {
      type: selectedReport,
      startDate: startDate,
      endDate: endDate,
    } as ReportRequest;
    fetch(`${OpenAPI.BASE}/api/File/getSolesAttyReport`, {
      method: "POST",
      headers: {
        Authorization: (OpenAPI.HEADERS as any)?.Authorization,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reportRequest),
    })
      .then((response: any) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `SolesAttorney.xlsx`;
          document.body.appendChild(a);
          a.click();
        });
      })
      .finally(() => dispatch(stopSpinner()));
  };
  const DownloadHourly = () => {
    dispatch(startSpinner());
    var reportRequest = {
      type: selectedReport,
      startDate: startDate,
      endDate: endDate,
      selectedStaff: usersList.find((user) => user.name === selectedUser)
    } as ReportRequest;
    fetch(`${OpenAPI.BASE}/api/File/getHoursReport`, {
      method: "POST",
      headers: {
        Authorization: (OpenAPI.HEADERS as any)?.Authorization,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reportRequest),
    })
      .then((response: any) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `HoursReport.xlsx`;
          document.body.appendChild(a);
          a.click();
        });
      })
      .finally(() => dispatch(stopSpinner()));
  };

  if (!user && !phoneId) {
    return null;
  }
  const handleTabChange = (event, newValue: number) => {
    switch (newValue) {
      case 0: {
        setSelectedReport(ReportTypes.OVERVIEW);
        break;
      }
      case 1: {
        setSelectedReport(ReportTypes.TASKS);
        break;
      }
      case 2: {
        setSelectedPreset("Month");
        setStartDate(dayjs().startOf("month"));
        setEndDate(dayjs(new Date()));
        setSelectedReport(ReportTypes.HOURS);
        break;
      }
      case 3: {
        setSelectedReport(ReportTypes.EMPTY_MATTERS);
        break;
      }
    }
    setTab(newValue);
    setTableData([]);
  };

  return (
    <>
      <h2 className="title">{selectedReportData?.header}</h2>
      <div>
        <pre>{selectedReportData?.description}</pre>
      </div>

      {reportType == "Overview" && (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab
              hidden={props.source !== ReportSource.Clio}
              label="Overview"
              {...TabsHelpers.parseProps(0)}
            />
            <Tab
              hidden={props.source !== ReportSource.Clio}
              label="Task Activity"
              {...TabsHelpers.parseProps(1)}
            />
            <Tab
              hidden={props.source !== ReportSource.Clio}
              label="Hours"
              {...TabsHelpers.parseProps(2)}
            />
            <Tab
              hidden={props.source !== ReportSource.Clio}
              label="Empty matters"
              {...TabsHelpers.parseProps(3)}
            />
          </Tabs>
        </Box>
      )}
      {GenerateTab()}
    </>
  );
}
