import { Elements } from "@stripe/react-stripe-js";
import { Appearance, loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PaymentsService } from "../../../api";
import { PaymentData } from "../../../redux/reducers/paymentReducers";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(
  //"pk_test_51OWlJHDPK6itkdefydqPJVfvdOpUiqybZctMuBjLHybrndpu5jcfefdwB5jX5zIwd8rrIfjEPt2FHRDWXLyDbeHi00iu9hFx32"
  "pk_live_51OWlJHDPK6itkdef0kbD9ERxpumzw1CvUvJYZE5VSIsUz68sLFucS1XCMVe0uxzvwej1FXLnsnyZdgRxmT7DTLta00Mh0A8bf2" //Stripe public key
);

const appearance: Appearance = {
  variables: {
    spacingUnit: '5px',
  }
};

export default function StripeCheckout() {
  const [options, setOptions] = useState<StripeElementsOptions>();
  const paymentInfo: PaymentData = useSelector((state: any) => state.payment);

  useEffect(() => {
    setOptions({
      clientSecret: paymentInfo.secret,
      appearance: appearance
    });
  }, [paymentInfo]);

  return (
    <div className="row pt-5">
      <div className="col-12 col-md-6">
        <h4>Admin fee: ${paymentInfo.adminFee.toFixed(2)}</h4>
        <h4>Stripe payment processing fee: ${paymentInfo.stripeFee.toFixed(2)}</h4>
        <h4>Total: ${paymentInfo.totalFee.toFixed(2)}</h4>
        <div>We use Stripe to provide the best-in-class practices to maintain a high level of security.</div>
      </div>
      <div className="col-12 col-md-6">
        {options && (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm />
          </Elements>
        )}
      </div>
    </div>
  );
}
