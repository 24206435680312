import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { UsersService } from "../../api";
import { ResetPasswordRequest } from "../../api/models/ResetPasswordRequest";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import useNavigateSearch from "../../services/useNavigateSearch";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
const MySwal = withReactContent(Swal);

export default function PasswordReset() {
  const [password, setPassword] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigateSearch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const handleResetPassword = () => {
    UsersService.postApiUsersResetPassword({
      token: token,
      newPassword: password,
    } as ResetPasswordRequest).then((result) => {
      if (!result.success) {
        MySwal.fire({
            icon: "error",
            title: <p className="title">Error resetting password</p>,
            html: <div className="alert-text">{result.message}</div>,
          });
      } else {
        MySwal.fire({
          icon: "success",
          title: <p className="title">Your password was reset</p>,
        }).then(() => navigate("/login"));
      }
    });
  };

  return (
    <>
      <div>
      <TextField
            required
            label="New password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((show) => !show)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
      </div>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        className="submit-btn mt-3"
        onClick={handleResetPassword}
      >
        Reset
      </Button>
    </>
  );
}
