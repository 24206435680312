/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AttorneyLeadStatus {
    NEW = 'New',
    ACCEPTED = 'Accepted',
    REJECTED = 'Rejected',
    INTRODUCTION = 'Introduction',
    LOST = 'Lost',
    WON = 'Won',
    SIGNED = 'Signed',
    PAID = 'Paid',
}
