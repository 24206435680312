/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum VehicleType {
    BUSINESS = 'Business',
    COMMERCIAL = 'Commercial',
    PERSONAL = 'Personal',
    MOTORCYCLE = 'Motorcycle',
}
