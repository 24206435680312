/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ReportTypes {
    OVERVIEW = 'Overview',
    TASKS = 'Tasks',
    HOURS = 'Hours',
    MATTER_STAGE_CHANGE = 'MatterStageChange',
    TASK_ASSIGNEE_CHANGE = 'TaskAssigneeChange',
    EXTENSIONS = 'Extensions',
    EMPTY_MATTERS = 'EmptyMatters',
    SETTLEMENT_LIT = 'SettlementLit',
    SETTLEMENT_PRELIT = 'SettlementPrelit',
    KPI = 'KPI',
    NEGOTIATION_RESULT = 'NegotiationResult',
    SOLES = 'Soles',
    LEADS_STAFF = 'LeadsStaff',
    LEADS_ATTORNEY = 'LeadsAttorney',
    LEADS_DATE = 'LeadsDate',
}
