import * as yup from "yup";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button/Button";
import { ApplicationRole, LoginRequest, UsersService } from "../../api";
import { useContext, useEffect, useState } from "react";
import useNavigateSearch from "../../services/useNavigateSearch";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton/IconButton";
import { useLocation } from "react-router-dom";
import { LeadType } from "../../pages/Admin/LeadsManagement/shared/Constants";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/actions/userActions";
import { startSpinner, stopSpinner } from "../../redux/actions/spinnerActions";
import { useGoogleLogin } from "@react-oauth/google";

const MySwal = withReactContent(Swal);

const emptyData = {
  username: "",
  password: "",
};

const schema = yup.object().shape({
  username: yup.string().required("username"),
  password: yup.string().required("password"),
});
interface LoginParams {
  redirectTo?: string;
}

export default function Login(params: LoginParams) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [redirectLink, setRedirectLink] = useState<string | null>();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const user = useSelector((state: any) => state.user?.user);
  const navigate = useNavigateSearch();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    var redirectTo: string | null = params.redirectTo ?? null;
    if (!params.redirectTo) {
      const queryParams = new URLSearchParams(location.search);
      redirectTo = queryParams.get("redirectTo");
    }
    setRedirectLink(redirectTo);
  }, [location]);

  useEffect(() => {
    if (user != null) {
      navigateOnLoggedIn(new Set(user.roles))
    }
  }, [user]);

  const loginExternal = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      dispatch(startSpinner());
      UsersService.postApiUsersLoginExternal({
        accessToken: tokenResponse.access_token,
      })
        .then((data) => {
          processLoginResponse(data);
        })
        .finally(() => dispatch(stopSpinner()));
    },
  });

  const onSubmit = (value) => {
    dispatch(startSpinner());
    UsersService.postApiUsersLogin(value as LoginRequest)
      .then((data) => {
        processLoginResponse(data);
      })
      .finally(() => dispatch(stopSpinner()));
  };

  function processLoginResponse(data: any) {
    if (data.error) {
      MySwal.fire({
        icon: "error",
        title: <p className="title">Wrong username or password</p>,
      });
      return;
    }
    dispatch(setUser(data));
    navigateOnLoggedIn(new Set(data.roles));
  }

  const navigateOnLoggedIn = (rolesSet) => {
    switch (true) {
      case rolesSet.has(ApplicationRole.ADMIN):
      case rolesSet.has(ApplicationRole.STAFF): {
        navigate(
          !!redirectLink
            ? redirectLink.includes("lead")
              ? `${redirectLink}?leadType=${LeadType.Admin}&isEdit=false`
              : redirectLink
            : "/new-leads"
        );
        break;
      }
      case rolesSet.has(ApplicationRole.STAFF_ATTORNEY): {
        navigate(
          !!redirectLink
            ? redirectLink.includes("lead")
              ? `${redirectLink}?leadType=${LeadType.StaffAttorney}&isEdit=false`
              : redirectLink
            : "/approve-leads"
        );
        break;
      }
      case rolesSet.has(ApplicationRole.ATTORNEY): {
        navigate(redirectLink ?? "/attorney-leads");
        break;
      }
      case rolesSet.has(ApplicationRole.USER): {
        navigate(redirectLink ?? "/attorney-info");
        break;
      }
      default: {
        navigate(redirectLink ?? "/");
      }
    }
  }

  const handlePasswordReset = () => {
    if (!values.username) {
      MySwal.fire({
        icon: "error",
        title: <p className="title">Please fill in email</p>,
      });
      return;
    }
    UsersService.postApiUsersRequestPasswordReset(values.username).then((error) => {
      if (!!error) {
        MySwal.fire({
          icon: "error",
          title: (
            <p className="title">
              {error}
            </p>
          ),
        });
      }
      MySwal.fire({
        icon: "success",
        title: (
          <p className="title">
            Email was sent to you with link to reset password.
          </p>
        ),
      });
    }
    ).catch(() => MySwal.fire({
      icon: "error",
      title: (
        <p className="title">
          Error requesting password reset. Please make sure you typed in right email.
        </p>
      ),
    }));
  };

  const { values, handleBlur, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: emptyData,
      validationSchema: schema,
      onSubmit,
    });

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 mt-3">
          <TextField
            required
            label="Username"
            variant="outlined"
            name="username"
            value={values.username}
            onBlur={handleBlur}
            onChange={(e) => setFieldValue("username", e.target.value.trim())}
          />
        </div>
        <div className="col-12 mt-3">
          <TextField
            required
            label="Password"
            variant="outlined"
            name="password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={values.password}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-12 mt-3">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className="submit-btn"
        >
          Login
        </Button>
      </div>
       <div className="col-12 mt-3">
        {/* <Button
          variant="contained"
          color="primary"
          className="submit-btn"
          onClick={() => loginExternal()}
        >
          Login with Google
        </Button> */}
      </div> 
      <div className="col-12 mt-3">
        <Button
          variant="contained"
          color="secondary"
          className="submit-btn"
          onClick={handlePasswordReset}
        >
          Forgot password
        </Button>
      </div>
    </form>
  );
}
