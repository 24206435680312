import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import Button from "@mui/material/Button/Button";
import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody/TableBody";
import TableCell from "@mui/material/TableCell/TableCell";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import TextField from "@mui/material/TextField/TextField";
import { useEffect, useState } from "react";
import { UserEducationViewModel } from "../../api/models/UserEducationViewModel";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { HelpersService } from "../../services/Helpers";

interface EducationTableProps {
  initValues: UserEducationViewModel[] | null;
  isEdit?: boolean;
  onChange: Function;
}

export type EducationRow = UserEducationViewModel & { rowId: string };

export default function EducationTable(props: EducationTableProps) {
  const [educationRows, setEducationRows] = useState<
    (UserEducationViewModel & { rowId: number })[]
  >([]);
  const [disableAddButton, setDisableAddButton] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    setIsMobile(HelpersService.checkMobile());
  }, []);

  useEffect(() => {
    if (!props.initValues) {
      return;
    }
    var rows = props.initValues.map((row, index) => {
      var mappedRow = row as UserEducationViewModel & { rowId: number };
      mappedRow.rowId = index;
      return mappedRow;
    });
    setEducationRows(rows);
  }, [props.initValues]);

  useEffect(() => {
    calculateDisable();
  }, [educationRows]);

  const removeRow = (id) => {
    const newRows = educationRows.filter((row) => row.rowId !== id);
    setEducationRows(newRows);
    props.onChange(newRows);
  };

  const addNewEducation = () => {
    const newRows = [
      ...educationRows!,
      {
        rowId: educationRows.length ?? 0,
        location: "",
        year: "",
        course: "",
        awards: [],
      },
    ];
    setDisableAddButton(true);
    setEducationRows(newRows);
  };

  const calculateDisable = () => {
    var unfilledRow = educationRows.filter(
      (row) => !row.location || !row.course || !row.year
    );
    setDisableAddButton(unfilledRow.length > 0);
  };

  const handleChange = (value, rowId, field: string) => {
    const newRows = educationRows.map((row) => {
      if (row.rowId === rowId) {
        row[field] = value;
      }
      return row;
    });

    calculateDisable();
    setEducationRows(newRows);
    props.onChange(newRows);
  };
  return (
    <>
      {props.isEdit ? (
        <>
          {isMobile ? (
            <div>
              {educationRows.length > 0
                ? educationRows.map((row, index) => (
                    <div key={index}>
                      <div className="row">
                        <div className="col-10">
                          <TextField
                            required
                            variant="outlined"
                            label="School"
                            className="mt-3"
                            value={row.location}
                            onChange={(event) =>
                              handleChange(
                                event.target.value,
                                row.rowId,
                                "location"
                              )
                            }
                          />
                        </div>
                        <div className="row col-2">
                          <IconButton
                            onClick={() => removeRow(row.rowId)}
                            sx={{ padding: 0 }}
                          >
                            <DeleteIcon
                              color="error"
                              style={{ opacity: 0.55 }}
                              fontSize="inherit"
                            />
                          </IconButton>
                        </div>
                      </div>
                      <TextField
                        required
                        variant="outlined"
                        label="Degree"
                        className="mt-3"
                        value={row.course}
                        onChange={(event) =>
                          handleChange(event.target.value, row.rowId, "course")
                        }
                      />
                      <TextField
                        required
                        variant="outlined"
                        label="Year"
                        className="mt-3"
                        value={row.year}
                        onChange={(event) =>
                          handleChange(event.target.value, row.rowId, "year")
                        }
                        inputProps={{ maxLength: 4 }}
                      />
                      <Autocomplete
                        multiple
                        options={[]}
                        freeSolo
                        autoSelect
                        disableCloseOnSelect
                        className="mt-3"
                        value={row.awards ?? undefined}
                        onChange={(event: any, newValue: string[]) =>
                          handleChange(newValue, row.rowId, "awards")
                        }
                        renderInput={(params) => (
                          <TextField
                            label="Honors and awards"
                            {...params}
                          />
                        )}
                      />
                      <hr></hr>
                    </div>
                  ))
                : null}
            </div>
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 750 }} size="medium">
                <TableHead className="table-head">
                  <TableRow>
                    <TableCell key="location">School*</TableCell>
                    <TableCell align="left">Degree*</TableCell>
                    <TableCell align="left">Year*</TableCell>
                    <TableCell align="left">Awards & honors</TableCell>
                    {props.isEdit && <TableCell></TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody className="table-body">
                  {educationRows.length > 0
                    ? educationRows.map((row) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={row.rowId}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell align="left">
                              <TextField
                                required
                                variant="outlined"
                                value={row.location}
                                onChange={(event) =>
                                  handleChange(
                                    event.target.value,
                                    row.rowId,
                                    "location"
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell align="left">
                              <TextField
                                required
                                variant="outlined"
                                value={row.course}
                                onChange={(event) =>
                                  handleChange(
                                    event.target.value,
                                    row.rowId,
                                    "course"
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell align="left" className="year-cell">
                              <TextField
                                required
                                variant="outlined"
                                value={row.year}
                                onChange={(event) =>
                                  handleChange(
                                    event.target.value,
                                    row.rowId,
                                    "year"
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell align="left">
                              <Autocomplete
                                multiple
                                options={[]}
                                freeSolo
                                autoSelect
                                disableCloseOnSelect
                                value={row.awards ?? undefined}
                                onChange={(event: any, newValue: string[]) =>
                                  handleChange(newValue, row.rowId, "awards")
                                }
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </TableCell>
                            <TableCell align="left">
                              <Button
                                variant="contained"
                                color="gray"
                                size="small"
                                onClick={() => removeRow(row.rowId)}
                              >
                                Remove
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Button
            disabled={disableAddButton}
            variant="contained"
            color="secondary"
            onClick={() => addNewEducation()}
            className="mt-4"
          >
            Add new education
          </Button>
        </>
      ) : (
        <>
          {educationRows ? (
            <ul>
              {educationRows.map((row) => {
                return (
                  <li key={row.rowId}>
                    {row.location}
                    <ul>
                      <li>
                        {row.course}, {row.year}
                      </li>
                      {!!row.awards?.length && (
                        <li>
                          Honors and Awards
                          <ul>
                            {row.awards.map((award) => {
                              return <li key={award}>{award.trim()}</li>;
                            })}
                          </ul>
                        </li>
                      )}
                    </ul>
                  </li>
                );
              })}
            </ul>
          ) : null}
        </>
      )}
    </>
  );
}
