import {
  SET_PAYMENT_AMOUNT,
  SET_PAYMENT_INTENT,
  SET_PAYMENT_SECRET,
} from "../actions/paymentActions";

export interface PaymentData {
  adminFee: number;
  totalFee: number;
  stripeFee: number;
  secret: string;
  intent: string;
}

const initialAttorneyState: PaymentData = { adminFee: 0, totalFee: 0, stripeFee: 0, secret: "", intent: "" };
const referAttorneyReducers = (state = initialAttorneyState, action) => {
  switch (action.type) {
    case SET_PAYMENT_AMOUNT:
      return {
        ...state,
        adminFee: action.payload.adminFee,
        totalFee: action.payload.totalFee,
        stripeFee: action.payload.stripeFee,
      };
    case SET_PAYMENT_SECRET:
      return {
        ...state,
        secret: action.payload,
      };
    case SET_PAYMENT_INTENT:
      return {
        ...state,
        intent: action.payload,
      };
    default:
      return state;
  }
};

export default referAttorneyReducers;
